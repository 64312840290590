import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { FormInputField } from "../formInputField/FormInputField";
import * as yup from "yup";
import { useFormik } from "formik";
import { ObjectType } from "../../../../types";

type Props = {
  isDisabled?: boolean;
  wrapperClass?: string;
  getFieldValues?: (data: ObjectType) => void;
  customFieldsArray: {
    id: number;
    field_name: string;
    mandatory_field: boolean;
    show_in_pdf: boolean;
    is_deleted: boolean;
  }[];
  handleSubmit?: () => ObjectType;
  initialValues: { [key: string]: string };
  editId?: number | null;
};
export interface FormInputFieldsRef {
  handleSubmit: () => void;
  errors: ObjectType;
}
export const FormInputFieldsInRows = forwardRef(
  (props: Props, ref: React.ForwardedRef<any>) => {
    const isErrorRef = useRef(false);
    const nameGenerator = (name: string) => {
      return name.replace(" ", "_");
    };
    const propertiesArray = props.customFieldsArray
      .filter(
        (field) =>
          (field.is_deleted === false && !props.editId) ||
          (props.editId &&
            Object.keys(props.initialValues).includes(field.field_name))
      )
      .map((field, index) => {
        return {
          type: "text",
          id: nameGenerator(field.field_name),
          name: field.field_name,
          className: "form-control custom-field-input-field",
          placeholder: `Enter ${field.field_name.toLowerCase()}`,
          label: field.field_name,
          isRequired: field.mandatory_field ? true : false,
          disabled: props.isDisabled,
          value: "",
          error: "",
        };
      });
    const validationSchema = yup.object().shape({
      ...propertiesArray.reduce((schema: any, property: any) => {
        if (property.isRequired) {
          schema[property.name] = yup
            .string()
            .required(`${property.label} is required`);
        }
        return schema;
      }, {}),
    });
    const propertiesInRows = [];
    for (let i = 0; i < propertiesArray.length; i += 2) {
      propertiesInRows.push(propertiesArray.slice(i, i + 2));
    }
    const formik = useFormik({
      initialValues: {
        ...Object.fromEntries(
          propertiesArray.map((property) => [property.name, property.value])
        ),
        ...props.initialValues,
      },
      enableReinitialize: true,
      validationSchema: validationSchema,
      validateOnChange: true,
      validateOnBlur: true,
      onSubmit: (values) => {
        props.getFieldValues &&
          props.getFieldValues({
            values: values,
            isError: formik.errors.length ? true : false,
          });
      },
    });

    useImperativeHandle(
      ref,
      () => ({
        handleSubmit: async () => {
          await formik.submitForm();
        },
        errors: Object.keys(formik.errors).length > 0,
        values: formik.values,
        validate: formik.validateForm,
        errorList: formik.errors,
      }),
      [formik, isErrorRef.current]
    );

    return (
      <form
        onSubmit={formik.handleSubmit}
        className={`${props.wrapperClass ? props.wrapperClass : ""}`}
      >
        {propertiesInRows.map((rowProperties, rowIndex) => (
          <div key={rowIndex} className="row custom-row mb-0">
            {rowProperties.map((property, index) => {
              return (
                <FormInputField
                  key={index}
                  type={property.type}
                  id={property.id}
                  name={property.name}
                  className={`${property.className} mw-325 ${
                    formik.errors[property.name] &&
                    formik.touched[property.name]
                      ? "error"
                      : ""
                  }`}
                  placeholder={property.placeholder}
                  label={property.label}
                  isRequired={property.isRequired}
                  disabled={property.disabled}
                  value={formik.values[property.name]}
                  error={
                    formik.touched[property.name]
                      ? formik.errors[property.name]
                      : undefined
                  }
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    formik.handleChange(e);
                  }}
                />
              );
            })}
          </div>
        ))}
      </form>
    );
  }
);
