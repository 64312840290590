import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useImperativeHandle,
} from "react";

import ListActions from "./ListActions";
import useCommonData from "../../../../../hooks/useCommon";
import CouponTable from "./CouponTable";
import { usePermissionHandler } from "../../../../../hooks/usePermissionHandler";
import { usePaginationDispatch } from "../../../../../hooks/usePaginationDispatch";
import { couponList } from "../../../couponSlice";
import useListData from "../../../../../hooks/useListData";
import { StatusColor } from "../../../../../../helpers/helper";
import { setLoaderState } from "../../../../../common/commonSlice";
import { coustomCase } from "../../../../../../helpers/planHelper";
import { useAppSelector } from "../../../../../../app/hooks";
import { filterAppliedState, listFilterSelector } from "../../../../../../appSlice";
import { getStatusFilterString } from "../../../../../../helpers/statusFilterHelper";
import { Transactionlimit } from "../../../../../../types";
import "./couponTableContainer.css";

type Props = {
  deleteListData: (actionType: string, id: number, planName: string) => void;
  voidListData: (id: number, planName: string) => void;
  stopResumeListData: (id: number, planName: string, triggerAction: boolean) => void;
  isFilterApplied: boolean;
  transactionLimit: Transactionlimit;
  initializeFilter: () => void;
};

function CouponTableContainer(props: Props, ref: any) {
  const { dispatch, currentUserInfo, orgCurrencyList } = useCommonData();
  const { couponRolePermission } = usePermissionHandler();
  const { setStartCount, setEndCount, setItemsPerPage, setPage, setTotalList } =
    usePaginationDispatch();
  const { page, itemsPerPage, startCount, endCount, totalList } = useListData();
  const [couponListData, setCouponListData] = useState<any[]>([]);
  const isFilterApplied = useAppSelector(filterAppliedState);
  const selectedFilterData = useAppSelector(listFilterSelector);
  useImperativeHandle(
    ref,
    () => ({
      fetchRecurringInvoiceList: fetchRecurringInvoiceList,
    }),
    []
  );

  useEffect(() => {
    fetchRecurringInvoiceList();
  }, [page, itemsPerPage,selectedFilterData, currentUserInfo.organization_id]);

  const columns = useMemo(
    () => [
      {
        Header: "Coupon Name",
        accessor: "show.coupon_name",
        sort: false,
        sortOrder: "",
        className: "coupon_name",
      },
      {
        Header: "Status",
        accessor: "show.coupon_status",
        sort: false,
        sortOrder: "",
        className: "coupon_status",
        Cell: ({ cell: { value } }: any) => {
          return <span className={StatusColor(value)}>{value}</span>;
        },
      },
      {
        Header: "Discount",
        accessor: "show.discount",
        sort: false,
        sortOrder: "",
        className: "discount",
      },
      {
        Header: "Account",
        accessor: "show.account",
        sort: false,
        sortOrder: "",
        className: "account",
      },
      {
        Header: "Duration",
        accessor: "show.duration",
        sort: false,
        sortOrder: "",
        className: "duration",
      },
      {
        Header: "Validity",
        accessor: "show.validity",
        sort: false,
        sortOrder: "",
        className: "validity",
      },
      {
        Header: "Redemptions",
        accessor: "show.redemptions",
        sort: false,
        sortOrder: "",
        className: "redemptions",
      },
      {
        Header: "Organization",
        accessor: "show.organization",
        sort: false,
        sortOrder: "",
        className: "org",
      },
      {
        Header: "",
        accessor: "show.actions",
        sort: false,
        sortOrder: "",
        className: "actions coupon_action",
        Cell: ({ cell: { value } }: any) => {
          return (!couponRolePermission.includes("Update") || value.status.toLowerCase() === "cancelled") ||
          value.organizationId !== currentUserInfo.organization_id ? null : (
            <ListActions
              value={value}
              onClickDelete={() => {props.deleteListData('Coupon', value.id, value.name)}}
              onClickVoid={() => {props.voidListData(value.id, value.name)}}
              onStopResume={() => {props.stopResumeListData(value.id, value.name, value.status.toLowerCase() === 'stopped')}}
            />
          );
        },
      },
    ],
    [currentUserInfo.organization_id, couponRolePermission]
  );

  const dateFormat = (timestamp: any) => {
    const dateObj = new Date(timestamp);

    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const date = String(dateObj.getDate()).padStart(2, "0");

    const hours = String(dateObj.getHours()).padStart(2, "0");
    const minutes = String(dateObj.getMinutes()).padStart(2, "0");
    const seconds = String(dateObj.getSeconds()).padStart(2, "0");

    const formattedDate = `${date}/${month}/${year}`;
    const formattedTime = `${hours}:${minutes}:${seconds}`;

    return `${formattedDate}`;
  };

  const fetchRecurringInvoiceList = useCallback(async () => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      couponList({
        page,
        itemsPerPage,
        orgId: currentUserInfo.organization_id,
        orgIds: selectedFilterData.Organizations
        ? selectedFilterData.Organizations
        : [],
        statusList: selectedFilterData.Status
        ? getStatusFilterString(selectedFilterData.Status).map((eachStatus: string) => eachStatus.toUpperCase())
        : [],
      })
    );
    if (responseAction.payload) {
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 500);
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        
        setCouponListData(response.coupons);
        // setCouponListData([]);
        let length = response.coupons.length;
        setTotalList(response.total);
        if (response.total !== 0) setStartCount((page - 1) * itemsPerPage + 1);
        setEndCount((page - 1) * itemsPerPage + length);
      } else if ("error" in response) {
      }
    }
  }, [page, itemsPerPage, currentUserInfo.organization_id, couponRolePermission, selectedFilterData]);
  
  useEffect(() => {
    if (isFilterApplied) fetchRecurringInvoiceList();
  }, [isFilterApplied]);
  
  const data = couponListData
    ? couponListData.map((coupon) => ({
        show: {
          coupon_name: coupon.name,
          coupon_status: coustomCase(coupon.coupon_status),
          discount: coupon.discount,
          account: coupon.account_name,
          duration: coupon.duration,
          validity: coupon.validity,
          redemptions: coupon.redemptions,
          // last_modified: dateFormat(coupon.last_modified),
          organization: coupon.organization,
          actions: {
            id: coupon.id,
            status: coupon.coupon_status,
            organizationId: coupon.organization_id,
            name: coupon.name,
            is_deletable: coupon.is_deletable,
          },
        },
      }))
    : [];

  return (
    <>
      <CouponTable
        totalList={totalList}
        startCount={startCount}
        endCount={endCount}
        itemsPerPage={itemsPerPage}
        invoicesRolePermission={couponRolePermission}
        columns={columns}
        data={data}
        isFilterApplied={props.isFilterApplied}
        initializeFilter={props.initializeFilter}
        transactionLimit={props.transactionLimit}
        setPageInfo={(countPerPage, page) => {
          setItemsPerPage(countPerPage);
          setPage(page);
        }}
      />
    </>
  );
}

export default React.memo(forwardRef(CouponTableContainer));
