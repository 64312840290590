import { useEffect, useState } from "react";
import { FormSelectField } from "../../../common/components";
import { ModalContext } from "../../../common/components/sideModal/sideModalContext";
import { SideModal } from "../../../common/components/sideModal/SideModal";
import { useFormik } from "formik";
import "./planBillingSideMenuForm.css";
import { customSelectStyle } from "../../../common/components/SelectCustomStyle";
import CustomDatepicker from "../../../utils/atoms/datepicker";
import { usePermissionHandler } from "../../../hooks/usePermissionHandler";
import { chooseDayOption, planInvoicingInitialValue, selectFrequencyOption } from "../calenderBillingStateInitialization";
import { PlanInvoicingInitialType } from "../calendarBillingTypes";
import { useFetchPlanSpecificDropdown } from "../hooks/useFetchPlanSpecificDropdown";
import { SelectValues } from "../../../../types";
import { MultiSelectFieldWithScroll } from "../../../common/components/formSelectField/MultiSelectFieldWithScroll";
import { customMultiSelectStyle } from "../../../common/components/MultiSelectCustomStyle";
import moment from "moment";
import { getFormatedDate } from "../../../../helpers/helper";
import { validatePlanSpecificForm } from "../list/calendarInvoicingForValidation";
import { useCreatePlanSpecificInvoicing, useEditPlanSpecificInvoicing } from "../hooks/useFetchPlanSpecificCreateEdit";
import { getPlanDropdownInEdit, recurringInvoicingValue } from "../helpers/calendarInvoicingHelper";
import { Link } from "react-router-dom";
import { EditDropdownIcon } from "../../../common/components/customSvgIcons/editDropdownIcon";
import { useFetchListDetails } from "../hooks/useFetchBillingList";

type Props = {
  refreshPlanSpecificList: () => void;
  id?: number;
}

export const PlanBillingSideMenuForm = (props: Props) => {
  const { subscriptionRolePermission } = usePermissionHandler();
  const [planSpecificDropdown, setPlanSpecificDropdown] = useState<SelectValues[]>([])
  const [planCount, setPlanCount] = useState<number>(1)
  const [isNextEnabled, setIsNextEnabled] = useState<boolean>(false)
  const { createPlanSpecificInvoicing } = useCreatePlanSpecificInvoicing();
  const { editPlanSpecificInvoicing } = useEditPlanSpecificInvoicing();
  const [onSaveActionClick, setOnSaveActionClick] = useState<boolean>(false)
  const { getListDetails } = useFetchListDetails();

  const formik = useFormik({
    initialValues: { ...planInvoicingInitialValue } as PlanInvoicingInitialType,
    enableReinitialize: true,
    validationSchema: validatePlanSpecificForm,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async (value) => {
      let data: any = {
        invoicing_frequency: value.invoicing_frequency,
        invoicing_type: value.invoicing_type,
        set_date: null,
        set_day: null,
        pricing_ids: value.choose_plan.map((data: SelectValues) => data.value)
      }; 

      if (value.invoicing_frequency === 'WEEKLY' && value.invoicing_type === "SET_DATE") {
        data.set_day = value.set_day
      }
      if (value.invoicing_frequency !== 'WEEKLY' && value.invoicing_type === "SET_DATE") {
        data.set_date = value.set_date
      }
      if(props.id) {
        await editPlanSpecificInvoicing(data, props.refreshPlanSpecificList, props.id)
      } else {
        await createPlanSpecificInvoicing(data, props.refreshPlanSpecificList)
      }
      setOnSaveActionClick(true)
      onFormClose()
    }
  });
  const {getPlanDropdown} = useFetchPlanSpecificDropdown()
  
  const onFormClose = () => {
    formik.resetForm()
    setIsNextEnabled(false)
    setPlanCount(1)
    setPlanSpecificDropdown([])
  }
  const ConfigureButton = () => {
    return (
      <>
       {props.id ? (
          <Link to="#" className="dropdown-item" onClick={() => {getListDetails(
            props.id,
            formik,
            setPlanSpecificDropdown,
            setPlanCount,
            setIsNextEnabled
            )}}>
            <>
              <EditDropdownIcon />
              Edit
            </>
          </Link>
        ) : (<div>
          {subscriptionRolePermission.includes("Create") ? <button
            id="calendar-billing-button"
            className="btn create-invoice-button green-button right-button sub-add-coupon-btn-green-background"
            type="button"
          >
            <img
              src="/static/media/plus_small.2f281160561f3c06b529f10af809e435.svg"
              className="Group"
              alt="plus-small-img"
            />
            <span className="create-invoice-button-text green-button-text">
              Create Plan Specific Calendar Invoicing
            </span>
          </button> : null}
        </div>)}
      </>
    );
  };

    
  const getPlanDropdownOnScroll = () => {
    getPlanDropdown(
      setPlanSpecificDropdown,
      planSpecificDropdown ,
      formik.values.invoicing_frequency,
      planCount,
      setPlanCount,
      setIsNextEnabled,
      props.id
      )
  }

 const onInvoicingfrequencyChange = (option: any) => {
  formik.setFieldValue("invoicing_frequency", option.value);
  formik.setFieldValue("choose_plan", [])
  getPlanDropdown(
    setPlanSpecificDropdown,
    [] ,
    option.value,
    1,
    setPlanCount,
    setIsNextEnabled,
    props.id
    )
 }

  const handleDate = (date: Date | null) => {
    if (date) {
      if (date?.toString() === "Invalid Date") {
       formik.setFieldValue("set_date", 'Invalid date')
      } else {
        const dateFormated = getFormatedDate(date);
        if (moment(dateFormated, "YYYY-MM-DD", true).isValid()) {
          formik.setFieldValue("set_date", dateFormated)
        } else {
          formik.setFieldValue("set_date", 'Invalid date')
        }
      }
    } else if (date === null) {
      formik.setFieldValue("set_date", '')
    }
  };

  return (
    <SideModal
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onCloseModal } = context;
            return (
              <div className="billing-and-proration-modal-container">
                <div className="billing-and-proration-modal-inner-container">
                  <FormSelectField
                    name="invoicing_frequency"
                    id="invoicing_frequency"
                    label="Select Frequency"
                    options={selectFrequencyOption}
                    wrapperClass="mw-325"
                    className="state-select custom-select billing-and-proration-select"
                    value={
                      selectFrequencyOption.filter(
                        (data: any) =>
                          data.value === formik.values.invoicing_frequency
                      )[0] || ""
                    }
                    onChange={onInvoicingfrequencyChange}
                    placeholder={"Choose Plan Frequency"}
                    isSearchable={true}
                    isDisabled={false}
                    error={formik.errors.invoicing_frequency}
                    isRequired={true}
                    isOnlyInRow={false}
                    messagePlacement="top-end"
                    styles={customSelectStyle}
                  />

                  <FormSelectField
                    name="invoicing_type"
                    id="invoicing_type"
                    label="Set a Recurring Billing type"
                    options={recurringInvoicingValue(formik.values.invoicing_frequency)}
                    wrapperClass="mw-325"
                    className="state-select custom-select billing-and-proration-select"
                    value={
                      recurringInvoicingValue(formik.values.invoicing_frequency).filter(
                        (data: any) =>
                          data.value ===
                          formik.values.invoicing_type
                      )[0] || ""
                    }
                    onChange={(option: any) => {
                      formik.setFieldValue(
                        "invoicing_type",
                        option.value
                      );
                    }}
                    placeholder={"Choose Set a Recurring Billing Type"}
                    isSearchable={true}
                    isDisabled={false}
                    error=""
                    isRequired={false}
                    isOnlyInRow={false}
                    messagePlacement="top-end"
                    styles={customSelectStyle}
                  />

                  {formik.values.invoicing_type === "SET_DATE" &&
                    formik.values.invoicing_frequency === "WEEKLY" && (
                      <FormSelectField
                        name="set_day"
                        id="set_day"
                        label="Choose Day"
                        options={chooseDayOption}
                        wrapperClass="mw-325"
                        className="state-select custom-select billing-and-proration-select choose-day-select-mb"
                        value={
                          chooseDayOption.filter(
                            (data: any) =>
                              data.value === formik.values.set_day
                          )[0] || ""
                        }
                        onChange={(option: any) => {
                          formik.setFieldValue("set_day", option.value);
                        }}
                        placeholder={"Choose Day"}
                        isSearchable={true}
                        isDisabled={false}
                        error={formik.errors.set_day}
                        isRequired={false}
                        isOnlyInRow={false}
                        messagePlacement="top-end"
                        styles={customSelectStyle}
                      />
                    )}

                  {formik.values.invoicing_type === "SET_DATE" &&
                    formik.values.invoicing_frequency === "MONTHLY" && (
                      <div className={"col-12 col-lg-4 mw-325 "}>
                        <label htmlFor="check_expiry" className="">
                        Choose Date
                        </label>
                        <div
                          id="set_date"
                          className={`date-selector-wrapper choose-day-select-mb`}
                        >
                          <CustomDatepicker
                            date={
                              formik.values.set_date
                                ? new Date(formik.values.set_date)
                                : null
                            }
                            handleDate={handleDate}
                            type="set_date"
                            error={!formik.errors.set_date ? "" : "error"}
                            id="set_date"
                            zIndex={0}
                            isDisabled={false}
                            placeholder="Enter start date"
                            enableFutureDate={true}
                            name="set_date"
                            customClass={
                              "billing-and-proration-datepicker-monthly"
                            }
                          />
                           <span className="error">{!formik.errors.set_date ? "" : formik.errors.set_date}</span>
                        </div>
                      </div>
                    )}

                  {formik.values.invoicing_type === "SET_DATE" &&
                    formik.values.invoicing_frequency === "YEARLY" && (
                      <div className={"col-12 col-lg-4 mw-325 choose-day-select-mb"}>
                        <label htmlFor="check_expiry" className="">
                        Choose Date
                        </label>
                        <div
                          id="set_date"
                          className={`date-selector-wrapper`}
                        >
                          <CustomDatepicker
                            date={
                              formik.values.set_date
                                ? new Date(formik.values.set_date)
                                : null
                            }
                            handleDate={handleDate}
                            type="set_date"
                            error={!formik.errors.set_date ? "" : "error"}
                            id="set_date"
                            zIndex={0}
                            isDisabled={false}
                            placeholder="Enter start date"
                            enableFutureDate={true}
                            name="set_date"
                            views={["day"]}
                            customClass={
                              "billing-and-proration-datepicker-yearly"
                            }
                          />
                          <span className="error">{!formik.errors.set_date ? "" : formik.errors.set_date}</span>
                        </div>
                      </div>
                    )}
                  <div className="relative-wrapper choose-plan-wrapper">
                    <MultiSelectFieldWithScroll 
                       count={planCount}
                       name="choose_plan"
                       id="subscription_select_plan"
                       label="Choose Plan"
                       options={planSpecificDropdown}
                       wrapperClass="mw-325"
                       className="custom-select"
                       value={props.id ? getPlanDropdownInEdit(formik.values.choose_plan, planSpecificDropdown) : formik.values.choose_plan}
                       onChange={(newValue: any) => {
                         formik.setFieldValue("choose_plan", newValue)
                       }}
                       onMenuScrollToBottom={() => {
                        if (isNextEnabled){
                          getPlanDropdownOnScroll()
                         }
                       }
                       }
                       styles={customMultiSelectStyle}
                       placeholder={"Choose Plan"}
                       isDisabled={!formik.values.invoicing_frequency}
                       error={formik.errors.choose_plan as string}
                       isRequired={true}
                       isOnlyInRow={false}
                       isClearable={false}
                       isSearchable={false}
                       dropdownCustomMessage='No Plans available'
                    />
                  </div>
                </div>
                <div className="billing-and-proration-modal-footer">
                  <button
                    type="button"
                    className="save-button mx-0"
                    onClick={() => {
                      formik.submitForm();
                    }}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="text-decoration-none cancel-button mx-4"
                    onClick={() => onCloseModal()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            );
          }}
        </ModalContext.Consumer>
      }
      onCloseFunction={onFormClose}
      modalTitle="Plan-Specific Invoicing"
      isButtonDisabled={false}
      buttonContent={<ConfigureButton />}
      drawerWrapperClass="calendar-billing billing-and-proration-modal"
      wrapperClass={`${
        !props.id ? "calendar-billing-button" : "calendar-billing-button-edit"
      } w-100 d-flex`}
      buttonClass={`add-coupon-button add-tags-button`}
      onSaveActionClick={onSaveActionClick}
    />
  );
};
