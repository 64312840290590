import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import {
  TemplateSkeleton,
  SelectionIcon,
  CloseImg,
} from "../../../../../../assets/images";
import { colors } from "../../../../../constants/colors";
// import ModalComponent from "../../../../common/components/ModalComponent"

type Props = {
  handleClose: () => void;
  showTemplatePreviewModal: () => void;
};

const ChooseTemplate: React.FC<Props> = ({
  handleClose,
  showTemplatePreviewModal,
}) => {
  const [open, setOpen] = useState<Boolean>(true);
  const initialState = [
    {
      id: 1,
      name: "Standard Template",
      selected: true,
    },
    {
      id: 2,
      name: "Template two",
      selected: false,
    },
    {
      id: 3,
      name: "Template three",
      selected: false,
    },
    {
      id: 4,
      name: "Template four",
      selected: false,
    },
  ];
  const [templates, setTemplates] = useState(initialState);

  const chooseTemplateType = (id: number) => {
    let templateArr = templates;
    templateArr.map((item) => {
      if (item.id == id) {
        item.selected = true;
      } else {
        item.selected = false;
      }
    });
    setTemplates([...templateArr]);
    showTemplatePreviewModal();
  };

  useEffect(() => {}, [templates]);

  return (
    <>
      <Modal
        open={true}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        id="customiseView"
        className="modal-wrap"
      >
        <div className="modal-dialog modal-fullscreen w-100">
          <div className="modal-content">
            <div className="modal-header close-button-container">
              <button
                type="button"
                className="close-btn pull-right"
                onClick={handleClose}
                aria-label="Close"
              >
                {/* <img src={CloseImg} width="24px" alt="close modal" /> */}
                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <g fill="none" fillRule="evenodd">
                      <path d="M0 0h24v24H0z"/>
                      <path fill={colors.slate} fillRule="nonzero" d="m12 10.586 4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"/>
                  </g>
                </svg>
              </button>
            </div>
            <div className="modal-body pt-0">
              <div>
                <h1>Choose Template</h1>
              </div>
              <div className="template-group">
                <Grid container spacing={2} flexWrap="wrap">
                  {templates.map((item, index) => {
                    return (
                      <>
                        <Grid
                          key={index}
                          item
                          xs={6}
                          id={"template" + index}
                          className="ms-auto template"
                        >
                          <div className="template-button">
                            <img
                              src={TemplateSkeleton}
                              className="template-skeleton"
                              alt="template"
                            />
                            <div>{item.name}</div>
                          </div>
                          {!item.selected && (
                            <div className="template-button-wrap">
                              <button
                                className="choose-button"
                                onClick={() => {
                                  chooseTemplateType(item.id);
                                }}
                              >
                                Choose template
                              </button>
                              <button
                                className="preview-button mt-3"
                                data-bs-toggle="modal"
                                data-bs-target="#previewTemplate"
                              >
                                Preview
                              </button>
                            </div>
                          )}
                          {item.selected && (
                            <div className="selection-wrap selection text-center">
                              <img src={SelectionIcon} alt="selected image" />
                              <div className="selection-label">
                                Selected Template
                              </div>
                            </div>
                          )}
                        </Grid>
                      </>
                    );
                  })}
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ChooseTemplate;
