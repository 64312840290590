import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import DateRangePicker from "./DateRangePicker";
import "./PredefinedDateRage-bootstrap.min.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import "./PredefinedDateRage.css";
import { calendarIcon } from "../../../../assets/images";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { currentUserSelector } from "../../../common/commonSlice";
import { getFiscalYear } from "../../../../helpers/fiscalYearHelper";
import { getFiscalQuarterYear } from "../../../../helpers/fiscalYearHelper";
import { colors } from "../../../constants/colors";
import { ObjectType } from "../../../../types";
import { DownArrowIcon } from "../../../common/components/customSvgIcons/downArrowIcon";
import { generateRandomString } from "../../../../helpers/helper";
import {
  dateRangeSelector,
  reportCustFlagSelector,
  setDateRange,
  setReportCustomizationFlag,
} from "../ReportSlice";

const PredefinedDateRangePicker = (props: {
  isSingleDatePicker: boolean;
  isAdvanced: boolean;
  defaultValue: string;
  reportType: string;
  resetType: string;
  handleDateRange: (start: string, end: string, range: string) => void;
  filterdata?: ObjectType;
}) => {
  const customizationFlag = useAppSelector(reportCustFlagSelector);
  const dispatch = useAppDispatch();
  const initialSettingsValue = {
    startDate: moment().toDate(),
    endDate: moment().toDate(),
    singleDatePicker: props.isSingleDatePicker,
    ranges: {
      Today: [moment().toDate(), moment().toDate()],
      "This Week": [
        moment().startOf("week").toDate(),
        moment().endOf("week").toDate(),
      ],
      "This Month": [
        moment().startOf("month").toDate(),
        moment().endOf("month").toDate(),
      ],
    },
  };
  const currentUserInfo = useAppSelector(currentUserSelector);
  const [state, setState] = useState({
    start: moment(),
    end: moment(),
    label: "Today",
  });
  const [defaultDateRangeValues, setDefaultDateRangeValues] = useState({});
  const [advancedDateRangeValues, setAdvancedDateRangeValues] = useState({});
  const [initialSettings, setInitialSettings] =
    useState<ObjectType>(initialSettingsValue);
  const [key, setKey] = useState("");
  const dateRange = useAppSelector(dateRangeSelector);
  const { start, end, label } = state;

  useEffect(() => {
    if (currentUserInfo.organization_fiscal_year) {
      dateRangeValueToState();
    }
    setKey(
      Object.keys(initialSettings.ranges).join("_") + "_" + props.resetType
    );
  }, [currentUserInfo, props.resetType]);

  useEffect(() => {
    if (customizationFlag === true) {
      dateRangeValueToState();
      setKey(
        Object.keys(initialSettings.ranges).join("_") +
          "_" +
          generateRandomString(5)
      );
      dispatch(setReportCustomizationFlag(false));
    }
  }, [props.filterdata]);

  useEffect(() => {
    setInitialSettings({
      ...initialSettings,
      startDate:
        initialSettings?.ranges && state.label in initialSettings.ranges
          ? initialSettings?.ranges[state.label][0]
          : initialSettings.startDate,
      endDate:
        initialSettings?.ranges && state.label in initialSettings.ranges
          ? initialSettings?.ranges[state.label][1]
          : initialSettings.endDate,
      selectedRange: state.label,
      showDropdowns: true,
    });
    dispatch(
      setDateRange({
        start_date: state.start.format("YYYY-MM-DD"),
        end_date: state.end.format("YYYY-MM-DD"),
        date_range: state.label.replace(" ", "_").toLowerCase(),
      })
    );
  }, [state]);

  /**
   * Date picker options for SOA
   */
  useEffect(() => {
    setKey("");
    const tempRanges = {
      Today: [moment().toDate(), moment().toDate()],
      "This Week": [
        moment().startOf("week").toDate(),
        moment().endOf("week").toDate(),
      ],
      "This Month": [
        moment().startOf("month").toDate(),
        moment().endOf("month").toDate(),
      ],
      "This Quarter": [
        moment(
          getFiscalQuarterYear(
            currentUserInfo.organization_fiscal_year
              ? currentUserInfo.organization_fiscal_year
              : "",
            "Current"
          ).startDate
        ).toDate(),
        moment(
          getFiscalQuarterYear(
            currentUserInfo.organization_fiscal_year
              ? currentUserInfo.organization_fiscal_year
              : "",
            "Current"
          ).endDate
        ).toDate(),
      ],
      "This Year": [
        moment(
          getFiscalYear(
            currentUserInfo.organization_fiscal_year
              ? currentUserInfo.organization_fiscal_year
              : "",
            "current"
          ).startDate
        ).toDate(),
        moment(
          getFiscalYear(
            currentUserInfo.organization_fiscal_year
              ? currentUserInfo.organization_fiscal_year
              : "",
            "current"
          ).endDate
        ).toDate(),
      ],
    };
    setDefaultDateRangeValues({ ...tempRanges });
  }, [currentUserInfo]);
  /**
   * Date picker options for PL, BS, TB
   */
  useEffect(() => {
    setAdvancedDateRangeValues({
      ...defaultDateRangeValues,
      Yesterday: [
        moment().subtract(1, "days").toDate(),
        moment().subtract(1, "days").toDate(),
      ],
      "Last Week": [
        moment().subtract(1, "week").startOf("week").toDate(),
        moment().subtract(1, "week").endOf("week").toDate(),
      ],
      "Last Month": [
        moment().subtract(1, "month").startOf("month").toDate(),
        moment().subtract(1, "month").endOf("month").toDate(),
      ],
      "Last Quarter": [
        moment(
          getFiscalQuarterYear(
            currentUserInfo.organization_fiscal_year
              ? currentUserInfo.organization_fiscal_year
              : "",
            "Previous"
          ).startDate
        ).toDate(),
        moment(
          getFiscalQuarterYear(
            currentUserInfo.organization_fiscal_year
              ? currentUserInfo.organization_fiscal_year
              : "",
            "Previous"
          ).endDate
        ).toDate(),
      ],
      "Last Year": [
        moment(
          getFiscalYear(
            currentUserInfo.organization_fiscal_year
              ? currentUserInfo.organization_fiscal_year
              : "",
            "previous"
          ).startDate
        ).toDate(),
        moment(
          getFiscalYear(
            currentUserInfo.organization_fiscal_year
              ? currentUserInfo.organization_fiscal_year
              : "",
            "previous"
          ).endDate
        ).toDate(),
      ],
    });
  }, [defaultDateRangeValues]);

  useEffect(() => {
    handleInitialSettings();
  }, [defaultDateRangeValues, advancedDateRangeValues]);

  const handleInitialSettings = () => {
    const tempInitial = {
      startDate: props?.filterdata?.startDate
        ? moment(props.filterdata.startDate).toDate()
        : start.toDate(),
      endDate: props?.filterdata?.endDate
        ? moment(props.filterdata.endDate).toDate()
        : end.toDate(),
      singleDatePicker: props.isSingleDatePicker,
      ranges:
        props.reportType !== "DB"
          ? !props.isAdvanced
            ? defaultDateRangeValues
            : advancedDateRangeValues
          : {},
      selectedRange: props?.filterdata?.dateRange
        ? capitalizeFirstLetters(props?.filterdata?.dateRange.replace("_", " "))
        : label,
      showDropdowns: true,
    };
    setInitialSettings({ ...tempInitial });
  };

  function capitalizeFirstLetters(inputString: string) {
    return inputString.replace(/\b\w/g, (match) => match.toUpperCase());
  }
  useEffect(() => {
    setKey(Object.keys(initialSettings.ranges).join("_"));
  }, [initialSettings]);

  const dateRangeValueToState = () => {
    setState({
      start:
        dateRange?.start_date !== ""
          ? moment(dateRange.start_date)
          : props.defaultValue === "quarter"
          ? moment(
              getFiscalQuarterYear(
                currentUserInfo.organization_fiscal_year
                  ? currentUserInfo.organization_fiscal_year
                  : "",
                "Current"
              ).startDate
            )
          : moment(),
      end:
        dateRange.end_date !== ""
          ? moment(dateRange.end_date)
          : props.defaultValue === "quarter"
          ? moment(
              getFiscalQuarterYear(
                currentUserInfo.organization_fiscal_year
                  ? currentUserInfo.organization_fiscal_year
                  : "",
                "Current"
              ).endDate
            )
          : moment(),
      label:
        dateRange.date_range !== ""
          ? dateRange.date_range
              .split("_")
              .map(
                (word: string) => word.charAt(0).toUpperCase() + word.slice(1)
              )
              .join(" ")
          : props.defaultValue === "quarter"
          ? "This Quarter"
          : "Today",
    });
  };

  const handleCallback = (start: any, end: any, label: any) => {
    if (label === "Custom Range") label = "Custom";
    setState({ start, end, label });
  };
  const selectedLabel = !props.isSingleDatePicker
    ? label +
      " (" +
      start.format("DD-MM-YYYY") +
      " to " +
      end.format("DD-MM-YYYY") +
      ")"
    : label + " (" + end.format("DD-MM-YYYY") + ")";

  const handleKeyDown = (e: any) => {
    let _keyPressed = e.which || e.keyCode;
    let _key = e.key;
    if (_keyPressed == "13" || _key === "Enter") {
      $("#reportrange").trigger("click");
    } else if (_keyPressed == "40" || _key === "ArrowDown") {
      $("#reportrange").trigger("click");
    }
  };
  return (
    <div className="daterange-wrapper" tabIndex={0} onKeyDown={handleKeyDown}>
      <DateRangePicker
        key={key}
        initialSettings={initialSettings}
        onCallback={handleCallback}
      >
        <div
          id="reportrange"
          className="col-4 reportrangeclass"
          style={{
            background: colors.white,
            cursor: "pointer",
            padding: "5px 10px",
            border: `1px solid ${colors.veryLightGrey}`,
            width: "100%",
          }}
        >
          <img width={20} height={20} src={calendarIcon} alt="calendar icon" />
          <span>{selectedLabel}</span>
          <DownArrowIcon color={colors.slate} />
        </div>
      </DateRangePicker>
    </div>
  );
};
export default PredefinedDateRangePicker;
