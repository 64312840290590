import { useEffect, useState, useRef } from "react";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/style.css";
import "./EmailTo.css";

const removeDuplicates = (list: string[]) => Array.from(new Set(list));

type Props = {
  onChange: (email: string[]) => void;
  emailList: string[] | [];
  removeEmailHandler: (email: string) => void;
  className?: string;
  onError?: (error: string) => void;
};

const MultiEmailBox: React.FC<Props> = ({
  onChange,
  emailList,
  removeEmailHandler,
  className,
  onError,
}) => {
  const [emails, setEmails] = useState<string[] | []>([]);
  const [invalidEmails, setInvalidEmails] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const containerRef = useRef<HTMLDivElement>(null);
  // const [focused, setFocused] = useState(false);

  let _invalidEmails: string[] = [];

  useEffect(() => {
    setEmails(emailList);
  }, [emailList]);

  useEffect(() => {
    // console.log("invalid in useEffect >>>> ", invalidEmails);
    if (invalidEmails.length > 0) {
      let invalidEmailLength = invalidEmails.length;
      if (invalidEmails[invalidEmailLength - 1] === "undefined") {
        setInvalidEmails([]);
        onError && onError("");
      } else {
        onError && onError("Please enter a valid email address");
      }
    } else {
      onError && onError("");
    }
  }, [invalidEmails]);

  //validate function for email
  const validateEmail = (email: string) => {
    const emailRegex =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    const isValid = emailRegex.test(email);
    return isValid;
  };

  //validate text for valid email and update the states
  const handleValidateEmail = (email: string) => {
    // if (invalidEmails.length !== 0) {
    //   setInvalidEmails([]);
    // }

    // const isValid = emailRegex.test(email);
    // if (!isValid && email && _invalidEmails.indexOf(email) === -1) {
    //   _invalidEmails.push(email);
    // }
    // return isValid;

    if (email === undefined) {
      return false;
    }
    // const isValid = emailRegex.test(email);
    const isValid = validateEmail(email);
    console.log("isValid ::: ", isValid);
    if (!isValid && !invalidEmails.includes(email)) {
      setInvalidEmails((prev) => [...prev, email]);
      console.log("not in invalidEmail and not isValid :::: ", invalidEmails);
      _invalidEmails.push(email);
    } else if (isValid && invalidEmails.includes(email)) {
      const emailIndex = _invalidEmails.indexOf(email);
      if (emailIndex > -1) {
        _invalidEmails.splice(emailIndex, 1);
      }
    }
    return isValid;
    // }
  };
  //handle onChange when valid email
  const handleEmailChange = (_emails: string[]) => {
    if (invalidEmails.includes("undefined")) {
      setInvalidEmails([]);
    } else {
      setInvalidEmails(removeDuplicates(_invalidEmails));
    }
    setEmails(removeDuplicates(_emails));
    onChange(_emails);
  };

  //custom handle onblur event
  const handleBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    if (!containerRef.current?.contains(event.relatedTarget)) {
      const inputElem = containerRef.current?.querySelector(
        'input[type="text"]'
      ) as HTMLInputElement;
      console.log(inputElem);
      if (inputElem && inputElem.value.trim() === "") {
        if (invalidEmails.length > 0) {
          setInvalidEmails([]);
        }
      }
    }
  };
  return (
    <div
      ref={containerRef}
      onBlur={handleBlur}
      tabIndex={-1}
      id="email"
      className="react-multi-email-wrapper multiEmail"
    >
      <ReactMultiEmail
        placeholder="Input your email"
        className={`multi-email-box ${className ? className : ""} `} //${hasError ? "error" : ""}
        emails={emails}
        validateEmail={handleValidateEmail}
        onChange={handleEmailChange}
        // onChangeInput={(value: string)=> {}}
        // autoFocus={true}
        // onFocus={() => setFocused(true)}
        // onBlur={() => setFocused(false)}
        getLabel={(
          email: string,
          index: number,
          removeEmail: (index: number) => void
        ) => {
          return (
            <div data-tag key={index}>
              {email}
              <span
                data-tag-handle
                onClick={() => {
                  removeEmail(index);
                  removeEmailHandler(email);
                }}
              >
                ×
              </span>
            </div>
          );
        }}
      />
    </div>
  );
};

export default MultiEmailBox;
