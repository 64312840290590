import * as yup from "yup";
import { message } from "../../../../../constants/messages";
import { ObjectType } from "../../paymentReceivedForm/imports";
import messages from "./messages";

export const refundFormValidation = (data: ObjectType) => {
  return yup.object({
    refunded_on: yup
      .string()
      .test({
        name: "refunded_on_required",
        message: messages.errors.refundDate_required,
        test: function (value: any) {
          if (!value) {
            return false;
          } else {
            return true;
          }
        },
      })
      .test({
        name: "refunded_on_invalid",
        message: messages.errors.refundDate_invalid,
        test: function (value: any) {
          if (value === "Invalid date") {
            return false;
          } else {
            return true;
          }
        },
      })
      .test({
        name: "refunded_on_min",
        message: messages.errors.refundDate_min,
        test: function (value: any) {
          const { payment_date, fiscal_year_start } = this.options
            .context as any;
          const refundMin =
            payment_date > fiscal_year_start ? payment_date : fiscal_year_start;
          if (value < refundMin) {
            return false;
          } else {
            return true;
          }
        },
      })
      .test({
        name: "refunded_on_max",
        message: messages.errors.refundDate_max,
        test: function (value: any) {
          const { fiscal_year_end } = this.options.context as any;
          if (value > fiscal_year_end) {
            return false;
          } else {
            return true;
          }
        },
      }),
    payment_mode: yup
      .string()
      .required(messages.errors.paymentMode_required)
      .nonNullable(),
    check_date: yup
      .string()
      .test({
        name: "check_date_required",
        message: messages.errors.checkDate_required,
        test: function (value: any) {
          const { payment_mode } = this.options.context as any;
          if (payment_mode !== "Check") {
            return true;
          }
          if (!value) {
            return false;
          } else {
            return true;
          }
        },
      })
      .test({
        name: "refunded_on_invalid",
        message: messages.errors.checkDate_invalid,
        test: function (value: any) {
          const { payment_mode } = this.options.context as any;
          if (payment_mode !== "Check") {
            return true;
          }
          if (value === "Invalid Date") {
            return false;
          } else {
            return true;
          }
        },
      }),
    check_expiry_in: yup.mixed().test({
      name: "check_expiry_in_required",
      message: messages.errors.checkExpiryIn_required,
      test: function (value: any) {
        const { refunded_on, payment_mode } = this.options.context as any;
        if (payment_mode !== "Check") {
          return true;
        }
        if (value) {
          return true;
        } else {
          return false;
        }
      },
    }),
    check_expiry: yup.mixed().test({
      name: "check_expiry_required",
      message: messages.errors.checkExpiry_shouldGreater_refundDate,
      test: function (value: any) {
        const { refunded_on, payment_mode } = this.options.context as any;
        if (payment_mode !== "Check") {
          return true;
        }
        if (value > refunded_on) {
          return true;
        } else {
          return false;
        }
      },
    }),
    check_number: yup.mixed().test({
      name: "check_number_required",
      message: messages.errors.checkNumber_required,
      test: function (value: any) {
        const { payment_mode } = this.options.context as any;
        if (payment_mode !== "Check") {
          return true;
        }
        if (value) {
          return true;
        } else {
          return false;
        }
      },
    }),
    from_account: yup
      .string()
      .required(messages.errors.account_required)
      .nonNullable(),
    amount: yup
      .string()
      .test({
        name: "amount_non_zero",
        message: messages.errors.refundAmount_invalid,
        test: function () {
          const { amount } = this.options.context as any;
          if (!amount || Number(amount) <= 0) {
            return false;
          } else {
            return true;
          }
        },
      })
      .test({
        name: "amount_greater_than_excess",
        message: messages.errors.refundAmount_exceedBalance,
        test: function () {
          const { amount, excess_amount } = this.options.context as any;
          if (
            data.editId &&
            Number(amount) > excess_amount + data.initialFormValues.amount
          ) {
            return false;
          } else if (!data.editId && Number(amount) > excess_amount) {
            return false;
          } else {
            return true;
          }
        },
      }),
    bank_charges_amount: yup.string().test({
      name: "bank_charges",
      message: messages.errors.bankCharge_required,
      test: function () {
        const { bank_charges_account_id, bank_charges_amount } = this.options
          .context as any;
        if (bank_charges_account_id && Number(bank_charges_amount) <= 0) {
          return false;
        } else {
          return true;
        }
      },
    }),
  });
};
