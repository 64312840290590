import { statusFilters, itemType, transactionCategory } from "../features/constants/constants";

export const getStatusFilterString = (statusIds: number[]) => {
  const numberSet = new Set(statusIds);
  const names: string[] = [];

  for (const object of statusFilters) {
    if (numberSet.has(object.id)) {
      names.push(object.name);
    }
  }

  return names;
};

export const getItemTypeFilterString = (itemTypeIds: number[]) => {
  const numberSet = new Set(itemTypeIds);
  const names: string[] = [];

  for (const object of itemType) {
    if (numberSet.has(object.id)) {
      names.push(object.name);
    }
  }

  return names;
};
export const getTransactionCategoryFilterString = (transactionCategoryIds: number[]) => {
  const numberSet = new Set(transactionCategoryIds);
  const names: string[] = [];

  for (const object of transactionCategory) {
    if (numberSet.has(object.id)) {
      names.push(object.name);
    }
  }

  return names;
};

export const getModuleStatusFilter = (module: string) => {
  function filterObjects(
    idArray: number[],
    objectArray: { id: number; name: string }[]
  ): { id: number; name: string }[] {
    return objectArray.filter((obj) => idArray.includes(obj.id));
  }
  switch (module) {
    case "Invoices":
      return filterObjects([1, 2, 3, 4, 5, 9, 10, 20], statusFilters);
      break;
    case "CN":
      return filterObjects([1, 2, 5, 20], statusFilters);
      break;
    case "PR":
      return filterObjects([5, 6, 7, 19], statusFilters);
      break;
    case "RI":
      return filterObjects([11, 12, 13, 14], statusFilters);
      break;
    case "Bills":
      return filterObjects([1, 2, 3, 4, 5, 9, 10, 20], statusFilters);
      break;
    case "DN":
      return filterObjects([1, 2, 5, 20], statusFilters);
      break;
    case "PM":
      return filterObjects([5, 6, 7, 19], statusFilters);
      break;
    case "JV":
      return filterObjects([5, 8], statusFilters);
      break;
    case "ADDON":
    case "Plan":
      return filterObjects([11, 14], statusFilters);
      break;
    case "Subscription":
      return filterObjects([16, 17, 18, 11, 13, 14], statusFilters);
      break;
    case "Items":
      return filterObjects([11, 15], statusFilters);
      break;
    default:
      return filterObjects([1, 2, 3, 4, 5], statusFilters);
      break;
  }
};

export const getStatusIds = (status: string[]) => {
  let statusIds: number[] = [];
  statusFilters.map((row) => {
    if (status.includes(row.name)) statusIds.push(row.id);
  });
  return statusIds;
};
