import React from 'react';
import { colors } from '../../../../constants/colors';
import './InvoicePrint.css';

type Props = {
  invoiceId: number | undefined;
  invoiceUrl: string;
};
const InvoicePrint: React.FC<Props> = ({ invoiceId, invoiceUrl }) => {
  return (
    <div
      className="modal add-customer-modal fade"
      id="printInvoiceModal"
      tabIndex={-1}
      aria-labelledby="add_customer_modal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header position-relative">
              <h1 className="fw-bold">Print Invoice</h1>
              <div className="modal-header close-button-container">
                <button
                  type="button"
                  className="cancel-button close-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g fill="none" fillRule="evenodd">
                      <path d="M0 0h24v24H0z" />
                      <path
                        fill={colors.slate}
                        fillRule="nonzero"
                        d="m12 10.586 4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
                      />
                    </g>
                  </svg>
                </button>
              </div>
            </div>
            <div className="modal-body">
              <embed
                type="application/pdf"
                src={invoiceUrl}
                id="pdfDocument"
                width="100%"
                height="100%"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoicePrint;
