import RadioButtonChecked from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUnchecked from "@mui/icons-material/RadioButtonUnchecked";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useFormik } from "formik";
import { SuccessToaster } from "../../../../helpers/toastHelper";
import { setLoaderState } from "../../../common/commonSlice";
import { DockedButtonGroup } from "../../../common/components/button/DockedButtonGroup";
import { Container } from "../../../common/components/htmlTags/Container";
import { Form } from "../../../common/components/htmlTags/Form";
import { Label } from "../../../common/components/htmlTags/Label";
import { RadioButtonGroup } from "../../../common/components/radioButtonGroup/radioButtonGroup";
import useCommonData from "../../../hooks/useCommon";
import { useMeApiTrigger } from "../../../hooks/useTriggerOrgDetails";
import { configureReportingTags } from "../reportingTagAPIFiles/reportingTagSlice";

type Props = {
  handleClose: () => void;
};
export const ReportingTagSettingsForm = (props: Props) => {
  const { dispatch, currentUserInfo } = useCommonData();
  const { triggerMeApi } = useMeApiTrigger();
  const shouldEnable = false;
  const formik = useFormik({
    initialValues: {
      is_enabled: currentUserInfo.is_tags_enabled,
      is_mandatory: currentUserInfo.is_tags_mandatory,
    },
    onSubmit: async (value) => {
      dispatch(setLoaderState(true));
      const responseAction = await dispatch(
        configureReportingTags({
          orgId: currentUserInfo.organization_id,
          isEnabled: Boolean(value.is_enabled),
          isMandatory:
            Boolean(value.is_enabled) === false ? false : value.is_mandatory,
        })
      );
      const response = responseAction.payload;
      if (!("error" in response)) {
        SuccessToaster(
          "Updated reporting tags configuration!",
          "tags-config-succcess"
        );
        await triggerMeApi();
        props.handleClose();
        dispatch(setLoaderState(false));
      } else {
        dispatch(setLoaderState(false));
      }
    },
  });
  return (
    <Form
      className="reporting-tag-settings-form-wrapper"
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <Container className="reporting-tags-settings-form-checkbox-wrapper">
        <FormControlLabel
          id="is_enabled"
          name="is_enabled"
          control={
            <Checkbox
              value={formik.values.is_enabled}
              checked={formik.values.is_enabled}
              onChange={formik.handleChange}
              className="reporting-tags-settings-form-checkbox"
            />
          }
          label="Enable reporting tag"
        />
      </Container>
      {formik.values.is_enabled === true && shouldEnable && (
        <Container className="reporting-tags-settings-form-radio-btn mb-2">
          <Label className="fw-bold required" htmlFor="is_enabled">
            Make Reporting Tags Mandatory
          </Label>
          <RadioButtonGroup
            id="is_mandatory"
            name="is_mandatory"
            value={formik.values.is_mandatory}
            valuesArray={[
              { label: "Yes", value: "true" },
              { label: "No", value: "false" },
            ]}
            formik={formik}
            isDisabled={false}
            checkedIcon={<RadioButtonChecked />}
            icon={<RadioButtonUnchecked />}
          />
        </Container>
      )}
      <Container className="w-100">
        <DockedButtonGroup
          isDeleteButtonDisabled={true}
          isDeleteButtonEnabled={false}
          isEdit={false}
          isSaveDisabled={false}
          isModal={true}
          onSave={() => {
            formik.submitForm();
          }}
          onDelete={() => {}}
          onCancel={props.handleClose}
          saveButtonType="button"
          shouldDockToBottom={false}
        />
      </Container>
    </Form>
  );
};
