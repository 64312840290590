import React from "react";
import { InfoIconImg } from "../../../../assets/images";
import CustomTooltips, { position } from "../Tooltip";
import "./InfoTipMessage.css";
type Props = {
  title: string;
  placement?: position;
  className?: string;
  iconClassName?: string;
};
export default function InfoTooltipMessage(props: Props) {
  return (
    <CustomTooltips
      title={props.title}
      // className={props.className}
      placement={props.placement ? props.placement : "top-end"}
    >
      <a
        href="#"
        className={`info-btn position-relative ${
          props.iconClassName ? props.iconClassName : ""
        }`}
        onClick={(e) => e.preventDefault()}
      >
        <img src={InfoIconImg} width="16" alt="info icon" />
      </a>
    </CustomTooltips>
  );
}
