import "../../../../../common//styles/AlertModal.css";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { RadioButtonGroup } from "../../../../../common/components/radioButtonGroup/radioButtonGroup";
import { DurationTypeDropdown, invoicingOptions } from "../../../SubscriptionStateInitialization";
import { message } from "../../../../../constants/messages";
import { customSelectStyle } from "../../../../../common/components/SelectCustomStyle";
import { FormSelectField } from "../../../../../common/components";
import CustomDatepicker from "../../../../../utils/atoms/datepicker";
import { formateDateMonth, getNextDate } from "../../../../../../helpers/subscriptionHelper";
import { getFormatedDate } from "../../../../../../helpers/helper";
import { ObjectType } from "../../../../../../types";
import moment from "moment";
import { NextInvoiceDateType } from "../../../subscriptionType";
import { NumberFormat } from "../../../../../../helpers/numberFormatHelper";
import useCommonData from "../../../../../hooks/useCommon";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Grid } from "@mui/material";
import info from "../../../../../../assets/images/email-info-msg-icon.svg";

type Props = {
  heading: string;
  subHeading: string;
  modalCancelHandler: () => void;
  formik: any;
  isPlanUpdated: boolean
  nextRenewableDate: string;
  updateFormErrors: ObjectType;
  nextInvoiceDateDetails: NextInvoiceDateType | null
  getNextInvoiceAction: (date: string, invoicingOption: string) => void
  currencyCode: string;
  subscriptionStatus: string
};

const SubscriptionUpdateModal = (props: Props) => {
  const { orgCurrencyList, currentUserInfo } = useCommonData();
  const handleDate = (date: Date | null) => {
    if (date) {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const targetDate = new Date(date);
      targetDate.setHours(0, 0, 0, 0);
      if (date?.toString() === "Invalid Date" || currentDate > targetDate) {
       props.formik.setFieldValue("updation_date", 'Invalid date')
      } else {
        const dateFormated = getFormatedDate(date);
        if (moment(dateFormated, "YYYY-MM-DD", true).isValid()) {
          props.formik.setFieldValue("updation_date", dateFormated)
          props.getNextInvoiceAction(dateFormated, props.formik.values.invoicing_option)
        } else {
          props.formik.setFieldValue("updation_date", 'Invalid date')
        }
      }
    } else if (date === null) {
      props.formik.setFieldValue("updation_date", 'Empty date')
    }
  };

  return (
    <>
      <div className="row custom-row">
        <div>
          <RadioButtonGroup
            name="update_type"
            id="update_type"
            valuesArray={DurationTypeDropdown.filter((value) => props.subscriptionStatus !== "CANCELLED" || value.value === "IMMEDIATELY")}
            value={props.formik.values.update_type}
            icon={
              <span>
                <RadioButtonUncheckedIcon />
              </span>
            }
            checkedIcon={
              <span>
                <RadioButtonCheckedIcon className="radio-btn-tds" />
              </span>
            }
            isDisabled={false}
            formik={props.formik}
            onClick={(e) => {
              props.formik.setFieldValue("update_type", e)
              if (e === 'SPECIFIC_DATE') {
                props.formik.setFieldValue("updation_date", getNextDate())
                props.getNextInvoiceAction(getFormatedDate(getNextDate()), 'IMMEDIATELY')
              }
              else if (e === 'END_OF_CURRENT_TERM' ){ 
                if (props.nextRenewableDate){
                const [day, month, year] = props.nextRenewableDate.split('-').map(Number);
                props.formik.setFieldValue("updation_date", new Date(year, month - 1, day))
                // props.formik.setFieldValue("is_proration_enabled", false) // need to add after API integartion
                props.getNextInvoiceAction(getFormatedDate(new Date(year, month - 1, day)), 'IMMEDIATELY')
              }else props.formik.setFieldValue("updation_date", '')
              } 
              else if ( e === 'IMMEDIATELY'){ 
                props.getNextInvoiceAction(getFormatedDate(new Date()), props.formik.values.invoicing_option)
                props.formik.setFieldValue("updation_date", getFormatedDate(new Date()))
              }
            }}
          />
        </div>
        {['IMMEDIATELY', 'SPECIFIC_DATE'].includes(props.formik.values.update_type) ? <div className="col-12 col-lg-4 mw-325 subscription_proration">
        <FormControlLabel
          id="is_subscription_proration_enabled"
          name="is_proration_enabled"
          control={
            <Checkbox
              value={props.formik.values.is_proration_enabled}
              checked={!props.formik.values.is_proration_enabled}
              disabled={true} // need to remove after API integartion
              onChange={(e) => {
                props.formik.handleChange(e)
                // props.getNextInvoiceAction(getFormatedDate(new Date()), props.formik.values.invoicing_option) // need to pass proration data
              }}
            />
          }
          label="Enable Proration"
        />
        </div> : null}
          <div className="col-12 col-lg-4 mw-325 p-t-24">
            <label
              className="pause-date-label"
              htmlFor="pause_date"
            >
              Updation Date
            </label>
            <br />
            <div className={`date-selector-wrapper ${
              ['IMMEDIATELY', 'END_OF_CURRENT_TERM'].includes(props.formik.values.update_type)
              ? 'update-subscription-date-disabled' : ''
              }`}>
              <CustomDatepicker
              date={props.formik.values.updation_date
                ? new Date(props.formik.values.updation_date)
                : null}
              handleDate={handleDate}
              type="updation_date"
              error={!props.updateFormErrors.updation_date ? "" : "error"}
              id="updation_date"
              zIndex={0}
              placeholder="Enter Updation date"
              minDate={getNextDate()}
              enableFutureDate={true}
              isDisabled={['IMMEDIATELY', 'END_OF_CURRENT_TERM'].includes(props.formik.values.update_type)}
              />
            </div>
            <span className="error">{!props.updateFormErrors.updation_date
            ? ''
            : props.updateFormErrors.updation_date}</span>
        </div>
        <div className={"col-12 col-lg-4 mw-325 p-t-24"}>
        {props.formik.values.update_type === 'IMMEDIATELY' && props.isPlanUpdated || !props.formik.values.is_proration_enabled || props.subscriptionStatus === "CANCELLED"? // remove ! once proration is integrated
        <FormSelectField
            name="invoicing_option"
            id="invoicing_option"
            label="Invoicing Option"
            options={invoicingOptions}
            wrapperClass="mw-325"
            className="state-select custom-select"
            value={invoicingOptions.filter((data:any) => data.value === props.formik.values.invoicing_option)[0] || ''}
            onChange={(option: any) => {
              props.formik.setFieldValue('invoicing_option', option.value)
              props.getNextInvoiceAction(props.formik.values.updation_date, option.value)
            }}
            styles={customSelectStyle}
            placeholder={"Choose invoicing option"}
            isSearchable={true}
            isDisabled={false}
            error=""
            isRequired={false}
            isOnlyInRow={false}
            tooltipMessage={message().subscription.updateInvoicingOption}
            messagePlacement="top-end"
          /> : null}
          </div>
      </div>
     {props.nextInvoiceDateDetails ? <div className="subscription-org subscription-preview-container">
    <div className="subscription-popup-preview-cart">
            <div className="subscription-preview-cart-header-container">
              <div className="subscription-preview-cart-header subscription-preview-cart-header-heading">
                Next Invoice
              </div>
              <div className="subscription-preview-cart-header">
                {/* <a href="#">View Preview</a> */}
              </div>
            </div>
            <div className="subscription-preview-cart-data-cols">
              <div className="subscription-preview-cart-data-col-value subscription-preview-cart-data-col-value-left">
                Amount
              </div>
              <div className="subscription-preview-cart-data-col-value subscription-preview-cart-data-col-value-right">
              {`${props?.currencyCode || ''} ${NumberFormat(
              Number(props.nextInvoiceDateDetails.first_invoice_amount),
              props?.currencyCode || '',
              orgCurrencyList
            )}`}
              </div>
            </div>
            <div className="subscription-preview-cart-data-cols">
              <div className="subscription-preview-cart-data-col-value subscription-preview-cart-data-col-value-left">
              Next Invoice Date
              </div>
              <div className="subscription-preview-cart-data-col-value subscription-preview-cart-data-col-value-right">
                {formateDateMonth(props.nextInvoiceDateDetails.first_invoice_start_date)}
              </div>
            </div>
            <div className="subscription-preview-cart-data-cols">
              <div className="subscription-preview-cart-data-col-value subscription-preview-cart-data-col-value-left">
                Period
              </div>
              <div className="subscription-preview-cart-data-col-value subscription-preview-cart-data-col-value-right">
              {`${formateDateMonth(props.nextInvoiceDateDetails.first_invoice_start_date)} to ${formateDateMonth(props.nextInvoiceDateDetails.first_invoice_end_date)}`}
              </div>
            </div>
          </div>
      </div> : null}
        {props.subscriptionStatus === "CANCELLED" ? <Grid container className="m-t-24 subscription-impact-update-conatiner" wrap="nowrap">
         <Grid item xs="auto">
                <img src={info} id="message-img" alt="clone-alt" />
              </Grid>
              <Grid item xs="auto">
              <div className="m-l-10">
              Subscription will be moved to Active status from Cancelled on update
              </div>
              </Grid>
        </Grid> : null}
    </>
  );
};

export default SubscriptionUpdateModal;
