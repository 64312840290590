import { getFormatedDate } from "../../../helpers/helper"
import { getNextDate } from "../../../helpers/subscriptionHelper"
import { sourceTaxTypes, tdsApplyLevel } from "../../constants/constants"

export const deleteInitialData = {
    actionType: '',
    id: -1,
    planName: '',
    invoice_exists: null
  }

export const startSubscriptionOption = [
  { label: "Immediately", value: "IMMEDIATELY" },
  { label: "Choose Date", value: "CHOOSE_DATE" },
]

export const invoicingCycleOption = [
  { label: "Forever", value: "FOREVER" },
  { label: "Fixed", value: "FIXED" },
]

export const invoicingOptions = [
  { label: "Immediately", value: "IMMEDIATELY" },
  { label: "Add to unbilled charges", value: "UNBILLED" },
]

export const DurationTypeDropdown = [
  { label: "Immediately", value: "IMMEDIATELY" },
  { label: "On next renewal", value: "END_OF_CURRENT_TERM" },
  { label: "On specific date", value: "SPECIFIC_DATE" },
]

export const manageUnpaidAmountDropdown = [
  {
    label: "Retain as unpaid",
    value: "RETAIN_AS_UNPAID",
  },
  { label: "Write off", value: "WRITE_OFF" },
];

export const unusedCreditsDropdown = [
  {
    label: "Dont issue credit note",
    value: "NO_CREDIT",
  },
  {
    label: "Issue prorated credit note",
    value: "PRORATED",
  },
  {
    label: "Issue full credit note",
    value: "FULL_CREDIT",
  },
];

export const handlePaidAmountDropdown = [
  {
    label: "Issue credit note",
    value: "ISSUE_CREDIT_NOTE",
  },
  {
    label: "Refund",
    value: "REFUND",
  },
];
export const initialTaxValues = {
  tax_id: "",
  tax_percentage: 0,
  item_tax_amount: 0
};

export const initialItemValues = {
  pricing_id: 0,
  is_plan: false,
  item_id: 0,
  account_name: "",
  item_name: "",
  hsn_or_sac_code: 0,
  quantity: 1.0,
  rate: 0.0,
  discount: 0.0,
  discount_type: "ABSOLUTE",
  discount_account_id: "",
  discount_account_name: "",
  taxes: [initialTaxValues],
  inventory_item_id: null,
  tds_id: null,
  tds_amount: 0,
  tds_account_id: 0,
  tds_percentage: 0,
  tds_name: "",
  gst_tax: null,
  currency_id: null,
  account_id: 0,
  gst_id: 0,
};

export const initialSubscriptionValue = {
  customer_id: "",
  customer_name: "",
  start_date: getFormatedDate(getNextDate()),
  subscription_number: "",
  payment_term_id: "",
  currency_id: "",
  place_of_supply: "",
  is_mutable: true,
  customer_notes: "",
  subscription_emails: [],
  start_subscription: 'IMMEDIATELY',
  invoicing_option: 'IMMEDIATELY',
  conversion_id: 0,
  files: [],
  addons: [],
  coupons: [],
  plans: {
    planId: 0,
    planName: '',
    frequency: '',
    pricing_model: '',
    pricing_id: 0,
    itemDetails: []
  },
  tds_percentage: 0,
  tds_level: tdsApplyLevel.TRANSACTION_LEVEL,
  source_tax_type: sourceTaxTypes.TCS,
  tds_id: 0,
  tds_amount: 0,
  tds_account_id: 0,
  conversion_date: "",
  tcs_id: 0,
  tcs_amount: 0,
  tcs_percentage: 0,
  tcs_account_id: 0,
  total: 0, 
  address: [],
  is_email_reminder_automatic: false
};

export const initialDefaultValues = {
  user_id: 0,
  customer_id: "",
  customer_name: "",
  subscription_number: "",
  start_subscription: 'IMMEDIATELY',
  start_date: getFormatedDate(new Date()),
  is_mutable: true,
  payment_term_id: "",
  currency_id: "",
  place_of_supply: "",
  subscription_emails: [],
  conversion_id: 0,
  files: [],
  tds_percentage: 0,
  tds_level: tdsApplyLevel.TRANSACTION_LEVEL,
  source_tax_type: sourceTaxTypes.TCS,
  tds_id: 0,
  tds_amount: 0,
  tds_account_id: 0,
  conversion_date: "",
  tcs_id: 0,
  tcs_amount: 0,
  tcs_percentage: 0,
  tcs_account_id: 0,
  total: 0,
  address: [],
  invoicing_option: 'IMMEDIATELY',
  invoicing_cycle: '',
  invoicing_cycle_fixed_count: 0,
}

export const addPlanFormInitialValue = {
  count: 1,
  isNextEnabled: false,
  openPlanPopover: false,
  planDropdownData: [],
  frequencyDropdownData: [],
  selectedPlan: {label: '', value: ''},
  selectedFrequency: {label: '', value: ''},
  planFrequencyData: []
}
export const selectedCouponData = {
    id: 0,
    name: '',
    discount:  '',
    duration:  '',
    validity:  '',
    max_redemption: 0,
    organization_id:0,
    organization:  '',
}

export const addCouponFormInitialValue = {
  count: 1,
  isNextEnabled: false,
  couponList: [],
  searchTerm: ''
}

export const addAddonFormInitialValue = {
  count: 1,
  isNextEnabled: false,
  openPlanPopover: false,
  addonDropdownData: [],
  frequencyDropdownData: [],
  selectedAddon: {label: '', value: ''},
  selectedFrequency: {label: '', value: ''},
  addonFrequencyData: []
}
export const previewDetailsInitialValues = {
  id: 0,
  customer_id: 0,
  customer_name: "",
  user_id: 0,
  conversion_id: 0,
  currency_id: 0,
  organization_id: 0,
  payment_term_id: 0,
  payment_term: "",
  reference_number: "",
  invoice_date: "",
  currency_code: "",
  total: 0,
  created_at: "",
  start_on: "",
  ends_on: "",
  invoice_status: "",
  custom_repeat_count: 0,
  custom_repeat_frequency: "",
  repeat_every: "",
  profile_name: "",
  address_details: [],
  organization_name: "",
  never_expire: null,
  organization_currency_code: "",
  total_unpaid_amount: 0,
  next_invoice_date: ""
};

export const previewDetailsInitialValuesView = {
  id: 0,
  customer_id: 0,
  customer_name: "",
  user_id: 0,
  conversion_id: 0,
  currency_id: 0,
  organization_id: 0,
  payment_term_id: 0,
  payment_term: "",
  reference_number: "",
  invoice_date: "",
  currency_code: "",
  total: 0,
  created_at: "",
  start_on: "",
  ends_on: "",
  invoice_status: "",
  custom_repeat_count: 0,
  custom_repeat_frequency: "",
  repeat_every: "",
  profile_name: "",
  address_details: [],
  organization_name: "",
  never_expire: null,
  organization_currency_code: "",
  total_unpaid_amount: 0,
  next_invoice_date: "",
  roundoff_enabled: false,
  tcs_percentage: 0,
  default_tds_percentage: 0,
  tcs_name: "",
  tds_percentage: 0,
  tcs_total: 0,
  total_details: {
    sub_total: 0,
    total: 0,
    tax_amount: 0,
    transaction_tds_amount: 0,
    transaction_tcs_amount: 0
  },
  tds_name: "",
  custom_fields: [],
  item_details: [],
  tds_level: "",
  recurring_invoice_files: [],
};

export const subscriptionDetailsInitialValues = {
  start_date: "",
  billing_cycle: "",
  subscription_period: "",
  currency_code: "",
  total_details: {
    total_taxable_amount: 0,
    tax_amount: 0,
    total: 0,
    balance_due: 0,
    payment_made: 0,
    roundoff_amount: 0,
    transaction_tds_amount: 0,
    taxes: {}
  },
  payment_terms: "",
  place_of_supply: "",
  address: [],
  plan_details: [],
  addon_details: [],
  coupon_details: [],
  customer_notes: "",
  tds_name: "",
  default_tds_percentage: 0,
  tcs_total: 0,
  tcs_name: null,
  files: [],
  charge_option: "",
  is_edit_scheduled: false,

  id: 0,
  customer_id: 0,
  customer_name: "",
  user_id: 0,
  conversion_id: 0,
  currency_id: 0,
  organization_id: 0,
  payment_term_id: 0,
  payment_term: "",
  reference_number: "",
  invoice_date: "",
  total: 0,
  created_at: "",
  start_on: "",
  ends_on: "",
  subscription_status: "",
  custom_repeat_count: 0,
  custom_repeat_frequency: "",
  repeat_every: "",
  profile_name: "",
  organization_name: "",
  never_expire: null,
  organization_currency_code: "",
  total_unpaid_amount: 0,
  next_renewal: "",
  roundoff_enabled: false,
  tcs_percentage: 0,
  tds_percentage: 0,
  custom_fields: [],
  item_details: [],
  tds_level: "",
  recurring_invoice_files: [],
}

export const recurringInvoiceAddressInitialValues = {
  country_id: null,
  address: "",
  city: "",
  state_id: null,
  zip_code: null,
  phone: null,
  is_billing: false,
  is_shipping: false,
  customer_address_id: 0,
  id: "",
  country_name: "",
  state_name:"",
};

export const initialPlanData = {
  planId: 0,
  planName: "",
  frequency: "",
  pricing_model: "",
  pricing_id: 0,
  itemDetails: [],
};
export const initialSubscriptionItemValues = {
  id: 0,
  account_id: 0,
  account_name: "",
  item_name: "",
  hsn_or_sac_code: "",
  quantity: 1.0,
  rate: 0.0,
  discount: 0.0,
  discount_type: "ABSOLUTE",
  discount_account_id: "",
  discount_account_name: "",
  taxes: [initialTaxValues],
  inventory_item_id: null,
  tds_id: null,
  tds_amount: 0,
  tds_account_id: 0,
  tds_percentage: 0,
  tds_name: "",
  gst_tax: null,
  currency_id: null,
  item_exemption_type: "",
  item_exemption_id: "",
  price: 0,
  free_quantity: 0,
  range_price: [],
  is_restore_enabled: false
};

export const subscriptionPlanInitialValue = {
  planId: 0,
  planName: '',
  frequency: '',
  pricing_model: '',
  itemDetails: []
 }

 export const subscriptionAddonInitialValue = {
  addonId: 0,
  addonName: '',
  frequency: '',
  pricing_model: '',
  itemDetails: []
 }

 export const subscriptionItemsDetail = {
  "plan_details": [
      {
          "plan_name": "stairstep-plan",
          "invoicing_period": "30-07-2024 - 30-07-2024",
          "frequency": "DAILY",
          "pricing_model": "STAIRSTEP",
          "items": [
              {
                  "item_name": "laptop",
                  "inventory_item_id": 252,
                  "account_id": 87515,
                  "account_name": "Income account_01",
                  "hsn_or_sac_code": 995412,
                  "quantity": 1,
                  "rate": 44,
                  "discount_account_id": null,
                  "discount_account_name": null,
                  "discount_type": "Absolute",
                  "id": 25859,
                  "discount": 0,
                  "taxable_amount": 44,
                  "taxes": [],
                  "gst_tax": {
                      "gst_tax_item": [],
                      "tax_id": 35084,
                      "id": 11765,
                      "item_exemption_id": null,
                      "item_exemption_type": "TAXABLE",
                      "item_exemption_reason": null,
                      "gst_tax_name": "IGST12",
                      "tax_percentage": 12,
                      "is_group": false
                  },
                  "tds_id": null,
                  "tds_account_id": 0,
                  "tds_percentage": 0,
                  "tds_amount": 0,
                  "tds_total": 0,
                  "tds_name": null,
                  "default_tds_percentage": 0,
                  "section_name": null,
                  "item_exemption_id": null,
                  "item_exemption_reason": null,
                  "item_exemption_type": "TAXABLE",
                  "type": null
              },
              {
                  "item_name": "boat",
                  "inventory_item_id": 842,
                  "account_id": 87509,
                  "account_name": "Forex gain/loss",
                  "hsn_or_sac_code": 12121211,
                  "quantity": 1,
                  "rate": 2,
                  "discount_account_id": null,
                  "discount_account_name": null,
                  "discount_type": "Absolute",
                  "id": 25860,
                  "discount": 0,
                  "taxable_amount": 2,
                  "taxes": [],
                  "gst_tax": {
                      "gst_tax_item": [],
                      "tax_id": 45674,
                      "id": 11766,
                      "item_exemption_id": 109,
                      "item_exemption_type": "NON_TAXABLE",
                      "item_exemption_reason": "non taxable item",
                      "gst_tax_name": "Non-Taxable",
                      "tax_percentage": 0,
                      "is_group": false
                  },
                  "tds_id": null,
                  "tds_account_id": 0,
                  "tds_percentage": 0,
                  "tds_amount": 0,
                  "tds_total": 0,
                  "tds_name": null,
                  "default_tds_percentage": 0,
                  "section_name": null,
                  "item_exemption_id": 109,
                  "item_exemption_reason": "non taxable item",
                  "item_exemption_type": "NON_TAXABLE",
                  "type": null
              }
          ]
      }
  ],
  "addon_details": [
      {
          "addon_name": "Testing 1",
          "invoicing_period": "30-07-2024 - 30-07-2024",
          "frequency": "DAILY",
          "pricing_model": "STAIRSTEP",
          "items": [
              {
                  "item_name": "item non gst supply",
                  "inventory_item_id": 628,
                  "account_id": 87515,
                  "account_name": "Income account_01",
                  "hsn_or_sac_code": 441079,
                  "quantity": 1,
                  "rate": 99,
                  "discount_account_id": null,
                  "discount_account_name": null,
                  "discount_type": "Absolute",
                  "id": 25861,
                  "discount": 0,
                  "taxable_amount": 99,
                  "taxes": [],
                  "gst_tax": {
                      "gst_tax_item": [],
                      "tax_id": 45676,
                      "id": 11767,
                      "item_exemption_id": null,
                      "item_exemption_type": "NON_GST_SUPPLY",
                      "item_exemption_reason": null,
                      "gst_tax_name": "Non-GST Supply",
                      "tax_percentage": 0,
                      "is_group": false
                  },
                  "tds_id": null,
                  "tds_account_id": 0,
                  "tds_percentage": 0,
                  "tds_amount": 0,
                  "tds_total": 0,
                  "tds_name": null,
                  "default_tds_percentage": 0,
                  "section_name": null,
                  "item_exemption_id": null,
                  "item_exemption_reason": null,
                  "item_exemption_type": "NON_GST_SUPPLY",
                  "type": null
              },
              {
                  "item_name": "No tax item",
                  "inventory_item_id": 535,
                  "account_id": 87509,
                  "account_name": "Forex gain/loss",
                  "hsn_or_sac_code": null,
                  "quantity": 1,
                  "rate": 12,
                  "discount_account_id": null,
                  "discount_account_name": null,
                  "discount_type": "Absolute",
                  "id": 25862,
                  "discount": 0,
                  "taxable_amount": 12,
                  "taxes": [],
                  "gst_tax": {
                      "gst_tax_item": [],
                      "tax_id": 45674,
                      "id": 11768,
                      "item_exemption_id": 109,
                      "item_exemption_type": "NON_TAXABLE",
                      "item_exemption_reason": "non taxable item",
                      "gst_tax_name": "Non-Taxable",
                      "tax_percentage": 0,
                      "is_group": false
                  },
                  "tds_id": null,
                  "tds_account_id": 0,
                  "tds_percentage": 0,
                  "tds_amount": 0,
                  "tds_total": 0,
                  "tds_name": null,
                  "default_tds_percentage": 0,
                  "section_name": null,
                  "item_exemption_id": 109,
                  "item_exemption_reason": "non taxable item",
                  "item_exemption_type": "NON_TAXABLE",
                  "type": null
              },
              {
                  "item_name": "item non taxable",
                  "inventory_item_id": 626,
                  "account_id": 87515,
                  "account_name": "Income account_01",
                  "hsn_or_sac_code": null,
                  "quantity": 1,
                  "rate": 81,
                  "discount_account_id": null,
                  "discount_account_name": null,
                  "discount_type": "Absolute",
                  "id": 25863,
                  "discount": 0,
                  "taxable_amount": 81,
                  "taxes": [],
                  "gst_tax": {
                      "gst_tax_item": [],
                      "tax_id": 45674,
                      "id": 11769,
                      "item_exemption_id": 2717,
                      "item_exemption_type": "NON_TAXABLE",
                      "item_exemption_reason": "Customer is non-taxable",
                      "gst_tax_name": "Non-Taxable",
                      "tax_percentage": 0,
                      "is_group": false
                  },
                  "tds_id": null,
                  "tds_account_id": 0,
                  "tds_percentage": 0,
                  "tds_amount": 0,
                  "tds_total": 0,
                  "tds_name": null,
                  "default_tds_percentage": 0,
                  "section_name": null,
                  "item_exemption_id": 2717,
                  "item_exemption_reason": "Customer is non-taxable",
                  "item_exemption_type": "NON_TAXABLE",
                  "type": null
              }
          ]
      }
  ],
  "coupon_details": [
      {
          "name": "test 5",
          "discount": "56%",
          "duration": "Forever"
      }
  ]
}