import { Box, Grid } from "@mui/material";
import MUICard from "@mui/material/Card";
import { useEffect, useMemo, useState } from "react";
import { ReportTagFormValues } from "../../../../types";
import EmptyReport from "../../../common/components/EmptyReport";
import { listboxCustomStyle } from "../../../common/components/ListBoxCustomStyle";
import { Card } from "../../../common/components/htmlTags/Card/Card";
import { CardHeader } from "../../../common/components/htmlTags/Card/CardHeader";
import { Container } from "../../../common/components/htmlTags/Container";
import ListCellPopover from "../../../common/components/listCellPopover/ListCellPopover";
import TableContainer from "../../../common/components/table/TableContainer";
import TableInfo from "../../../common/components/table/TableInfo";
import useCommonData from "../../../hooks/useCommon";
import useListData from "../../../hooks/useListData";
import { usePaginationDispatch } from "../../../hooks/usePaginationDispatch";
import PaginationContainer from "../../items/components/list/PaginationContainer";
import "../ReportingTag.css";
import { ListActions } from "../components/listAction/ListAction";
import { useFetchReportingTagList } from "../hooks/useFetchReportingTagList";
import { ReportingTagFormModal } from "../reportingTagForm/ReportingTagFormModal";
import { ReportingTagSettingsFormModal } from "../reportingTagSettings/ReportingTagsSettingsFormModal";
import { useReportTagSettingsDetail } from "../../../hooks/useReportTagSettingsDetail";
import { useSubModulePermission } from "../../../hooks/useSubModulePermissions";
import { usePermissionHandler } from "../../../hooks/usePermissionHandler";
export const ReportingTagsList = () => {
  const { currentUserInfo } = useCommonData();
  const { getReportingTags } = useFetchReportingTagList();
  const { page, itemsPerPage, startCount, endCount, totalList } = useListData();
  const { setItemsPerPage, setPage } = usePaginationDispatch();
  const { isReportingTagEnabled } = useReportTagSettingsDetail();
  const subModulePermission = useSubModulePermission();
  const [reportingTags, setReportingTags] = useState<ReportTagFormValues[]>([]);
  const { reportinTagsPermission } = usePermissionHandler();
  useEffect(() => {
    getReportingTags(setReportingTags);
  }, [
    page,
    itemsPerPage,
    currentUserInfo.organization_id,
    currentUserInfo.is_tags_enabled,
  ]);
  /**
   * set table column headings
   */
  const columns = useMemo(
    () => [
      {
        Header: "Tag Name",
        accessor: "show.name",
        sort: false,
        sortOrder: "",
        className: "reporting-tag-name",
      },
      {
        Header: "Options ",
        accessor: "show.options",
        sort: false,
        sortOrder: "",
        className: "options-array-join",
        Cell: ({ cell: { value } }: any) => {
          return (
            <ListCellPopover
              valuesArray={value.split(",")}
              displayElementCount={3}
            />
          );
        },
      },
      {
        Header: "",
        accessor: "show.actions",
        sort: false,
        sortOrder: "",
        className: "action",
        Cell: ({ cell: { value } }: any) => {
          return (
            <ListActions
              tagId={value.id}
              setReportingTagsList={setReportingTags}
              isDeleteDisabled={!currentUserInfo.is_super_parent}
              isEditDisabled={!currentUserInfo.is_super_parent}
              tagDetails={value.tagDetails}
              isAssociated={value.isAssociated}
            />
          );
        },
      },
    ],
    [currentUserInfo.organization_id, page, itemsPerPage]
  );
  /**
   * set table row data
   */
  const data: any = Array.isArray(reportingTags)
    ? reportingTags.map((tags) => ({
        show: {
          name: tags.name,
          options: tags.options
            .map((option) => {
              return option.name;
            })
            .join(", "),
          actions: {
            id: tags.id,
            isMutable: true,
            tagDetails: tags,
            isAssociated: tags.options.some(
              (option) => option.is_associated === true
            ),
          },
        },
      }))
    : [];
  return (
    <Card>
      <CardHeader
        headerActions={
          <Container className="d-flex w-100 reporting-tag-header-btn-group">
            {currentUserInfo.is_super_parent &&
              reportinTagsPermission.includes("Create") &&
              reportinTagsPermission.includes("Update") &&
              reportinTagsPermission.includes("Read") &&
              reportinTagsPermission.includes("Delete") && (
                <ReportingTagSettingsFormModal />
              )}
            {currentUserInfo.is_tags_enabled &&
              reportinTagsPermission.includes("Create") && (
                <ReportingTagFormModal
                  setReportingTagsList={setReportingTags}
                />
              )}
          </Container>
        }
        wrapperClass="reporting-tag-list-header"
        key={`${
          String(currentUserInfo.is_super_parent) +
          String(currentUserInfo.is_tags_enabled) +
          String(currentUserInfo.organization_id)
        }`}
      >
        Reporting Tags
      </CardHeader>
      <MUICard variant="outlined" sx={listboxCustomStyle.cardBox}>
        <Box
          component="div"
          id="invoice-table-wrapper"
          className={
            "list-table-wrapper dataTables_wrapper dt-bootstrap5 no-footer " +
            `${totalList > 0 ? "" : "no-data"}`
          }
        >
          <TableInfo
            total={totalList}
            startCount={startCount}
            endCount={endCount}
            itemsPerPage={itemsPerPage}
            setPageInfo={(countPerPage, page) => {
              setItemsPerPage(countPerPage);
              setPage(page);
            }}
          />
          <Grid>
            <TableContainer
              className="list-table table table-striped w-100"
              columns={columns}
              data={data}
              url={"/users"}
              sortHandler={() => {}}
              editPermission={false}
              isDetailViewExist={false}
            >
              <EmptyReport
                heading="No reporting tags found!"
                alt="no reporting tag"
              />
            </TableContainer>
          </Grid>
        </Box>
      </MUICard>
      {totalList > itemsPerPage ? <PaginationContainer /> : ""}
    </Card>
  );
};
