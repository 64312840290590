import { NumberFormat } from "../../../../helpers/numberFormatHelper";
import { CurrencyFormType, ObjectType } from "../../../../types";

const customerDetailsSetter = (searchResult: ObjectType) => {
  const {
    customer_display_name,
    short_code,
    customer_contact_email,
    customer_address_phone,
    customer_id,
    organization_name,
  } = searchResult;

  return {
    id: customer_id,
    "Customer Name": customer_display_name,
    "Short Code": short_code,
    Email: customer_contact_email,
    Phone: customer_address_phone,
    Organization: organization_name,
  };
};

const vendorDetailsSetter = (searchResult: ObjectType) => {
  const {
    vendor_display_name,
    short_code,
    tag_name,
    vendor_address_phone,
    vendor_id,
    organization_name,
  } = searchResult;

  return {
    id: vendor_id,
    "Vendor Name": vendor_display_name,
    "Short Code": short_code,
    Tags: Array.isArray(tag_name) ? tag_name.join(", ") : "",
    Phone: vendor_address_phone,
    "Organization Name": organization_name,
  };
};

const invoiceDetailsSetter = (
  searchResult: ObjectType,
  orgCurrencyList: CurrencyFormType[]
) => {
  const {
    invoice_id,
    invoice_number,
    reference_number,
    tag_name,
    customer_name,
    invoice_date,
    invoice_status,
    due_date,
    total,
    total_taxable_amount,
    balance_due,
    tax_deducted,
    sales_invoice_billing_address,
    sales_invoice_shipping_address,
    created_by_user_name,
    organization_name,
    currency_code,
  } = searchResult;

  return {
    "Invoice Number": invoice_number,
    "Reference Number": reference_number,
    Tags: Array.isArray(tag_name) ? tag_name.join(", ") : "",
    "Customer Name": customer_name,
    "Invoice Date": invoice_date,
    Status: invoice_status,
    "Due Date": due_date,
    "Billing Address": sales_invoice_billing_address,
    "Shipping Address": sales_invoice_shipping_address,
    "Created by": created_by_user_name,
    Organization: organization_name,
    "Tax Deducted": currency_code
      ? currency_code +
        " " +
        NumberFormat(Number(tax_deducted), currency_code, orgCurrencyList)
      : tax_deducted,
    Total: currency_code
      ? currency_code +
        " " +
        NumberFormat(Number(total), currency_code, orgCurrencyList)
      : total,
    "Total Taxable Amount": currency_code
      ? currency_code +
        " " +
        NumberFormat(
          Number(total_taxable_amount),
          currency_code,
          orgCurrencyList
        )
      : total_taxable_amount,
    "Balance Due": currency_code
      ? currency_code +
        " " +
        NumberFormat(Number(balance_due), currency_code, orgCurrencyList)
      : balance_due,
    id: invoice_id,
  };
};

const billDetailsSetter = (
  searchResult: ObjectType,
  orgCurrencyList: CurrencyFormType[]
) => {
  const {
    bill_id,
    bill_date,
    bill_number,
    reference_number,
    due_date,
    tag_name,
    vendor_name,
    organization_name,
    total,
    total_taxable_amount,
    amount_due,
    tax_deducted,
    currency_code,
    bill_status,
  } = searchResult;

  return {
    "Bill Number": bill_number,
    "Reference Number": reference_number,
    Tags: Array.isArray(tag_name) ? tag_name.join(", ") : "",
    "Vendor Name": vendor_name,
    "Organization Name": organization_name,
    Total: currency_code
      ? currency_code +
        " " +
        NumberFormat(Number(total), currency_code, orgCurrencyList)
      : total,
    "Total Taxable Amount": currency_code
      ? currency_code +
        " " +
        NumberFormat(
          Number(total_taxable_amount),
          currency_code,
          orgCurrencyList
        )
      : total_taxable_amount,
    "Tax Deducted(TDS)": currency_code
      ? currency_code +
        " " +
        NumberFormat(Number(tax_deducted), currency_code, orgCurrencyList)
      : tax_deducted,
    "Balance Due": currency_code
      ? currency_code +
        " " +
        NumberFormat(Number(amount_due), currency_code, orgCurrencyList)
      : amount_due,
    "Bill Date": bill_date,
    Status: bill_status,
    "Due Date": due_date,
    id: bill_id,
  };
};

const creditNoteDetailsSetter = (
  searchResult: ObjectType,
  orgCurrencyList: CurrencyFormType[]
) => {
  const {
    credit_note_id,
    credit_note_number,
    reference_number,
    customer_name,
    credit_note_date,
    credit_note_status,
    invoice_number,
    credit_note_balance,
    total,
    currency_code,
    total_taxable_amount,
    organization_name,
  } = searchResult;

  return {
    "Credit Note Number": credit_note_number,
    "Reference Number": reference_number,
    "Customer Name": customer_name,
    "Credit Note Date": credit_note_date,
    "Credit Note Balance":
      currency_code +
      " " +
      NumberFormat(Number(credit_note_balance), currency_code, orgCurrencyList),
    "Invoice Numbers":
      invoice_number && Array.isArray(invoice_number)
        ? invoice_number.join(",")
        : "",
    "Total Taxable Amount": currency_code
      ? currency_code +
        " " +
        NumberFormat(
          Number(total_taxable_amount),
          currency_code,
          orgCurrencyList
        )
      : total_taxable_amount,
    Organization: organization_name,
    Total: currency_code
      ? currency_code +
        " " +
        NumberFormat(Number(total), currency_code, orgCurrencyList)
      : total,
    Status: credit_note_status,
    id: credit_note_id,
  };
};

const debitNoteDetailsSetter = (
  searchResult: ObjectType,
  orgCurrencyList: CurrencyFormType[]
) => {
  const {
    debit_note_id,
    debit_note_number,
    reference_number,
    vendor_name,
    debit_note_date,
    debit_note_status,
    organization_name,
    bill_number,
    debit_note_balance,
    total,
    currency_code,
    total_taxable_amount,
  } = searchResult;

  return {
    "Debit Note Number": debit_note_number,
    "Reference Number": reference_number,
    "Vendor Name": vendor_name,
    "Organization Name": organization_name,
    Status: debit_note_status,
    "Debit Note Date": debit_note_date,
    "Bill Numbers":
      bill_number && Array.isArray(bill_number) ? bill_number.join(",") : "",
    id: debit_note_id,
    Total: currency_code
      ? currency_code +
        " " +
        NumberFormat(Number(total), currency_code, orgCurrencyList)
      : total,
    "Debit Note Balance": currency_code
      ? currency_code +
        " " +
        NumberFormat(Number(debit_note_balance), currency_code, orgCurrencyList)
      : debit_note_balance,
    "Total Taxable Amount": currency_code
      ? currency_code +
        " " +
        NumberFormat(
          Number(total_taxable_amount),
          currency_code,
          orgCurrencyList
        )
      : total_taxable_amount,
  };
};
const coaDetailSetter = (
  searchResult: ObjectType,
  orgCurrencyList: CurrencyFormType[]
) => {
  return {
    id: searchResult.account_id,
    "Account Name": searchResult.account_name,
    "Ledger Code": searchResult.ledger_code,
    "Account Type": searchResult.account_type,
    Description: searchResult.description,
  };
};
const prDetailSetter = (
  searchResult: ObjectType,
  orgCurrencyList: CurrencyFormType[]
) => {
  const {
    payment_received_id,
    payment_number,
    payment_date,
    customer_name,
    currency_code,
    pr_status,
    reference_number,
    invoice_number,
    amount_received,
    organization_name,
    amount_in_excess,
    tax_deducted,
  } = searchResult;

  return {
    id: payment_received_id,
    "Customer Name": customer_name,
    Status: pr_status,
    "Payment Number": payment_number,
    "Payment Date": payment_date,
    "Amount Received": currency_code
      ? currency_code +
        " " +
        NumberFormat(Number(amount_received), currency_code, orgCurrencyList)
      : amount_received,
    "Amount Excess": currency_code
      ? currency_code +
        " " +
        NumberFormat(Number(amount_in_excess), currency_code, orgCurrencyList)
      : amount_in_excess,
    "Tax Deducted(TDS)": currency_code
      ? currency_code +
        " " +
        NumberFormat(Number(tax_deducted), currency_code, orgCurrencyList)
      : tax_deducted,
    "Organization Name": organization_name,
    "Invoice Numbers": Array.isArray(invoice_number)
      ? invoice_number.join(", ")
      : "",
    "Reference Number": reference_number,
  };
};
const pmDetailSetter = (
  searchResult: ObjectType,
  orgCurrencyList: CurrencyFormType[]
) => {
  const {
    payment_made_id,
    payment_number,
    organization_name,
    payment_date,
    vendor_name,
    paid_through,
    bill_number,
    pm_status,
    currency_code,
    amount_paid,
    amount_in_excess,
    reference_number,
  } = searchResult;

  return {
    id: payment_made_id,
    "Vendor Name": vendor_name,
    "Payment Number": payment_number,
    "Payment Date": payment_date,
    Status: pm_status,
    "Paid Through Account": paid_through,
    "Organization Name": organization_name,
    "Amount Paid": currency_code
      ? currency_code +
        " " +
        NumberFormat(Number(amount_paid), currency_code, orgCurrencyList)
      : amount_paid,
    "Amount Excess": currency_code
      ? currency_code +
        " " +
        NumberFormat(Number(amount_in_excess), currency_code, orgCurrencyList)
      : amount_in_excess,
    "Bill Numbers":
      bill_number && Array.isArray(bill_number) ? bill_number.join(", ") : "",
    "Reference Number": reference_number,
  };
};
const jvDetailSetter = (
  searchResult: ObjectType,
  orgCurrencyList: CurrencyFormType[]
) => {
  const {
    journal_voucher_id,
    journal_voucher_number,
    reference_number,
    journal_status,
    journal_voucher_date,
    currency_amount,
    notes,
    created_by_user_name,
    organization_name,
  } = searchResult;
  return {
    id: journal_voucher_id,
    "Journal Voucher Number": journal_voucher_number,
    Status: journal_status,
    Date: journal_voucher_date,
    Amount: currency_amount,
    Notes: notes,
    Reference: reference_number,
    "Organization Name": organization_name,
    "Created By User": created_by_user_name,
  };
};
export const searchDetailsSetter = (
  searchResult: ObjectType,
  module: string,
  orgCurrencyList: CurrencyFormType[]
) => {
  switch (module) {
    case "customers":
      return customerDetailsSetter(searchResult);
    case "vendors":
      return vendorDetailsSetter(searchResult);
    case "invoices":
      return invoiceDetailsSetter(searchResult, orgCurrencyList);
    case "bills":
      return billDetailsSetter(searchResult, orgCurrencyList);
    case "credit_notes":
      return creditNoteDetailsSetter(searchResult, orgCurrencyList);
    case "debit_notes":
      return debitNoteDetailsSetter(searchResult, orgCurrencyList);
    case "payment_received":
      return prDetailSetter(searchResult, orgCurrencyList);
    case "payment_made":
      return pmDetailSetter(searchResult, orgCurrencyList);
    case "journal_voucher":
      return jvDetailSetter(searchResult, orgCurrencyList);
    case "chart_of_accounts":
      return coaDetailSetter(searchResult, orgCurrencyList);
    default:
      throw new Error(`Module '${module}' is not supported.`);
  }
};
