import "./cancelDetailsModalContent.css";

function CancelDetailsModalContent(props: any) {
  return (
    <div className="cancel-details-modal-content">
      <div className="container">
        <p>
          Are you sure you want to cancel the scheduled changes ?
        </p>
        <div className="buttons">
          <button onClick={props.onCloseModal} id="scheduler-cancel-btn" className="btn btn-cancel cancel-btn">Cancel</button>
          <button onClick={props.cancelModalSubmitHandler} className="btn btn-cancel proceed-btn">Yes Proceed</button>
        </div>
      </div>
    </div>
  );
}

export default CancelDetailsModalContent;
