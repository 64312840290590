import KebabMenu from "../../../../../common/components/kebabMenu/KebabMenu";
import Edit from "../../../../../common/components/kebabMenu/Edit";
import Delete from "../../../../../common/components/Delete";
import Void from "../../../../../common/components/Void";
import StopResume from "../../../../../common/components/kebabMenu/StopResume";
import { usePermissionHandler } from "../../../../../hooks/usePermissionHandler";
import Clone from "../../../../../common/components/kebabMenu/CloneRole";

export type ListActionProps = {
  value: any;
  onClickDelete: () => void;
  onClickVoid: () => void;
  onStopResume: () => void;
};

function ListActions(props: ListActionProps) {
  const { couponRolePermission } = usePermissionHandler();
  return (
    <KebabMenu>
      {couponRolePermission.includes("Update") ? 
      <>
      <li>
        <Edit
          url={`/coupons/edit/${props.value.id}`}
          isMutable
          name="Coupon"
          isLocked={false}
          className={"dropdown-item"}
          lockDate={""}
          isDisabled={false}
        />
      </li>
        <li>
          <Clone url={`/coupons/clone/${props.value.id}`} />
        </li>
       <li>
        <Void
          className="dropdown-item delete"
          deleteIcon={true}
          isMutable
          onClick={props.onClickVoid}
          isLocked={false}
          lockDate={""}
          name="Coupon"
          status={props.value.status}
        />
      </li>
      </> : null }
      {couponRolePermission.includes("Delete") && props.value.is_deletable ? <li>
        <Delete
          className="dropdown-item delete"
          deleteIcon={true}
          isMutable
          onClick={props.onClickDelete}
          isLocked={false}
          lockDate={""}
          name="Coupon"
        />
      </li> : null }
    </KebabMenu>
  );
}

export default ListActions;
