import PaymentMadeDetailHeader from "./paymentMadeDetailHeader";
import "../../../../utils/paymentPreview/PaymentPreview.css"

function PaymentMadeDetail() {
  return (
    <div className="">
      <div
        className="card card-dashboard bills-detail-card cd-detail-card w-100"
        id="bills-detail-card"
      >
        <PaymentMadeDetailHeader />
      </div>
    </div>
  );
}

export default PaymentMadeDetail;
