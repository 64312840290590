import KebabMenu from "../../../../../common/components/kebabMenu/KebabMenu";
import Edit from "../../../../../common/components/kebabMenu/Edit";
import Delete from "../../../../../common/components/Delete";
import Void from "../../../../../common/components/Void";
import { usePermissionHandler } from "../../../../../hooks/usePermissionHandler";

export type ListActionProps = {
  value: any;
  onClickDelete: () => void;
  onClickVoid: () => void;
};

function ListActions(props: ListActionProps) {
  const { addonsRolePermission } = usePermissionHandler();
  return (
    <KebabMenu>
      {addonsRolePermission.includes("Update") ? 
      <>
      {/* Uncomment while integrating Edit flow */}
      <li>
        <Edit
          url={`/addons/edit/${props.value.id}`}
          // url={`#`}
          isMutable
          name="Addon"
          isLocked={false}
          className={"dropdown-item"}
          lockDate={""}
          isDisabled={false}
        />
      </li>
       <li>
        <Void
          className="dropdown-item delete"
          deleteIcon={true}
          isMutable
          onClick={props.onClickVoid}
          isLocked={false}
          lockDate={""}
          name="Addon"
          status={props.value.status}
        />
      </li>
      </> : null }
      {addonsRolePermission.includes("Delete") ? <li>
        <Delete
          className="dropdown-item delete"
          deleteIcon={true}
          isMutable
          onClick={props.onClickDelete}
          isLocked={false}
          lockDate={""}
          name="Addon"
          isTransactionExist={props.value.addon_exists_subscription}
          planExistsSubscription={props.value.addon_exists_subscription}
        />
      </li> : null }
    </KebabMenu>
  );
}

export default ListActions;
