import React, { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../../../../app/hooks";
import {
  creditNoteAutoFillDataSelector,
  latestCurrencyRateSelector,
  setCreditNoteAutofillData,
  setLoaderState,
} from "../../../../common/commonSlice";
import DefaultDetails from "./DefaultDetails";
import ItemDetails from "../../../../common/components/lineItems/ItemDetails";
import {
  ObjectType,
  CustomerItemValues,
  ItemTaxValues,
  FilesValues,
  CreditNoteValues,
  TotalAmountCalculationValues,
  TotalCreditValues,
  CNDefaultValues,
  ItemRef,
  CNDefaultRef,
  TransactionItemValues,
  TDSTotalValues,
  TCSTotalValues,
} from "../../../../../types";
import { customerDetailsForTransactions } from "../../../customerManagement/customerSlice";
import { toast } from "react-toastify";
import {
  createCreditNote,
  editCreditNoteDetails,
  deleteCreditNoteItemTaxDetails,
  deleteCreditNoteItemDetails,
  checkCreditNoteNumber,
  attachFiles,
  creditNoteList,
  deleteCreditNoteFile,
  deleteCreditNoteRecipientDetails,
  creditNoteDetails,
  getTaxableAmount,
  getAmountCalculation,
  updateCreditAllocation,
} from "../../creditNoteSlice";
import { useParams, useSearchParams } from "react-router-dom";
import {
  DateFormatHandler,
  getFormatedDate,
} from "../../../../../helpers/helper";
import MultiEmailBox from "../../../../common/components/multiEmailComponent/EmailTo";
import "../../CreditNote.css";
import DeleteCreditNote from "../Delete";
import NoteTextArea from "../../../../common/components/NoteTextArea/NoteTextArea";
import DropZone from "../../../../common/components/DropZone/DropZone";
import _ from "lodash";
import ScrollToError from "../../../../common/components/scrollToError";
import { setErrorID, setListFilters } from "../../../../../appSlice";
import TransactionHeader from "../../../../common/components/TransactionHeader";
import {
  noAccessErrorCodes,
  sourceTaxTypes,
  tdsApplyLevel,
  gstRegistrationTypes,
} from "../../../../constants/constants";
import CreditNoteFormFooter from "./CreditNoteFormFooter";
import {
  initialDefaultValues,
  initialTotalValues,
  initialValues,
} from "./StateInitialization";
import usePreservedLocation from "../../../../hooks/usePreservedLocation";
import { validate, validateItemValues } from "./ValidateCreditNoteForm";
import {
  getLineItemDetails,
  initialItemValues,
} from "../../../../../helpers/transactionHelper";
import InfoBox from "../../../../common/components/infoBox/InfoBox";
import { message } from "../../../../constants/messages";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import useCommonData from "../../../../hooks/useCommon";
import {
  initialTCSTotalValues,
  initialTDSTotalValues,
} from "../../../invoice/components/invoiceForm/StateInitialization";
import { tdsPreferenceSelector } from "../../../tds/tdsSlice";
import {
  ErrorToaster,
  SuccessToaster,
} from "../../../../../helpers/toastHelper";
import useGst from "../../../../hooks/useGst";
import { clearGstTaxList, getGstTaxList } from "../../../gst/gstSlice";
import { fetchRoundOffPreference } from "../../../preferences/preferencesSlice";
import { TransactionEditTagRemovalAlertModal } from "../../../paymentsReceived/components/paymentReceivedForm/imports";

type LocationProps = {
  redirectTo?: string;
};
let interval: any = null;
function CreditNoteForm() {
  const location = usePreservedLocation();
  const { editId } = useParams();
  const [searchParams] = useSearchParams();
  let redirectUrl = searchParams.get("redirect");
  let replace = searchParams.get("replace");
  const { dispatch, navigate, currentUserInfo } = useCommonData();
  const { creditNoteRolePermission } = usePermissionHandler();
  const creditNoteAutofillData = useAppSelector(creditNoteAutoFillDataSelector);
  const defaultRef = useRef<CNDefaultRef | null>(null);
  const itemRef = useRef<ItemRef | null>(null);
  const fileRef = useRef<any>([]);
  const locationState = location?.state as LocationProps;
  const redirectTo = locationState?.redirectTo;
  const exchangeRateValues = useAppSelector(
    latestCurrencyRateSelector
  ).latest_conversion;
  const tdsPreference = useAppSelector(tdsPreferenceSelector);
  const [creditNoteFormValues, setCreditNoteFormValues] =
    useState<CreditNoteValues>(
      creditNoteAutofillData.creditNoteData.creditNoteFormValues
        ? creditNoteAutofillData.creditNoteData.creditNoteFormValues
        : initialValues
    );
  const [defaultFormValues, setDefaultFormValues] = useState<CNDefaultValues>(
    creditNoteAutofillData.creditNoteData.defaultFormValues
      ? creditNoteAutofillData.creditNoteData.defaultFormValues
      : initialDefaultValues
  );
  const [itemFormValues, setItemFormValues] = useState<CustomerItemValues[]>(
    creditNoteAutofillData.creditNoteData.itemFormValue
      ? creditNoteAutofillData.creditNoteData.itemFormValue
      : [initialItemValues]
  );
  const [taxFormValues, setTaxFormValues] = useState<CustomerItemValues[]>(
    creditNoteAutofillData.creditNoteData.itemFormValue
      ? creditNoteAutofillData.creditNoteData.itemFormValue
      : [initialItemValues]
  );
  const [totalFormValues, setTotalFormValues] =
    useState<TotalCreditValues>(initialTotalValues);
  const [formErrors, setFormErrors] = useState<ObjectType>({});
  const [itemFormErrors, setItemFormErrors] = useState<CustomerItemValues[]>(
    []
  );
  const [isCreditNumberExist, setIsCreditNumberExist] = useState(false);
  const [statesFlag, setStatesFlag] = useState<boolean>(false);
  const [isCustomerSelect, setIsCustomerSelect] = useState<boolean>(false);
  const [emailLists, setEmailLists] = useState<string[] | []>([]);
  const [customerId, setCustomerId] = useState<number>();
  const [currencyId, setCurrencyId] = useState<number | undefined>(
    Number(
      creditNoteAutofillData.creditNoteData.defaultFormValues.currency_id
    ) || undefined
  );
  const [taxValues, setTaxValues] = useState(true);
  const [currencyCode, setCurrencyCode] = useState(
    creditNoteAutofillData.creditNoteData.creditNoteFormValues.currency_code ||
      ""
  );
  const [emailData, setEmailData] = useState<{ email: string; id: number }[]>(
    []
  );
  const [deleteEmailIds, setDeleteEmailIds] = useState<{ emaiId: number }[]>(
    []
  );
  const [uploadedFiles, setUploadedFiles] = useState<any>([]);
  const [deleteCreditNoteId, setDeleteCreditNoteId] = useState(-1);
  const [deleteCreditItemId, setDeleteCreditItemId] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [dateSortOrder, setDateSortOrder] = useState("ASC");
  const [fileIds, setFileIds] = useState<any>([]);
  const [insertedFiles, setInsertedFiles] = useState<FilesValues[]>([]);
  const [formSubmit, setFormSubmit] = useState(false);
  const [isError, setIsError] = useState(false);
  const [action, setAction] = useState("");
  const [cnStatus, setCNStatus] = useState("");
  const [formSubmitAction, setFormSubmitAction] = useState(true);
  const [conversionDate, setConversionDate] = useState(
    getFormatedDate(new Date())
  );
  const [conversionCurrencyId, setConversionCurrencyId] = useState<
    number | undefined
  >(
    Number(
      creditNoteAutofillData.creditNoteData.defaultFormValues.currency_id
    ) || undefined
  );
  const [newConversionDate, setNewConversionDate] = useState<string>("");
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [customFields, setCustomFields] = useState<ObjectType>({});
  const [totalTdsValues, setTotalTdsValues] = useState<TDSTotalValues>(
    initialTDSTotalValues
  );
  const [totalTcsValues, setTotalTcsValues] = useState<TCSTotalValues>(
    initialTCSTotalValues
  );

  const [total, setTotal] = useState<number | string>(0);
  const [sourceTaxType, setSourceTaxType] = useState(sourceTaxTypes.TCS);

  const [tdsIds, setTdsIds] = useState<number[]>([]);
  const [tcsIds, setTcsIds] = useState<number[]>([]);

  const [isRoundOffEnabled, setIsRoundOffEnabled] = useState(false);
  const [isTotalEditable, setIsTotalEditable] = useState(false);

  const [stateType, setStateType] = useState("");
  const [initialStateType, setInitialStateType] = useState("");
  const [supplyState, setSupplyState] = useState("");
  const [transactionTaxDetails, setTransactionTaxDetails] =
    useState<[{ is_group: boolean; tax_id: number }]>();
  const [isSez, setIsSez] = useState(true);
  const [gstRegistrationType, setGstRegistrationType] = useState("");
  const custom_fields = useRef<ObjectType>({});
  const customFieldRef = useRef<any>();
  const { isGstOrg } = useGst();
  const [editTotal, setEditTotal] = useState(null);
  const [calculatedTotal, setCalculatedTotal] = useState(0);
  const [isCustomerChanged, setIsCustomerChanged] = useState(false);
  const [isStateChanged, setIsStateChanged] = useState(false);

  const [didAlertModalOpen, setDidAlertModalOpen] = useState(false);
  const [buttonAction, setButtonAction] = useState<{
    status: string;
    action: string;
  }>({ status: "", action: "" });
  const openEditAlertRef = useRef<any>();
  const [customerSalesDefault, setCustomerSalesDefault] = useState(null);
  const [emailFormErrors, setEmailFormErrors] = useState("");

  useEffect(() => {
    let onProgressFiles = uploadedFiles.filter(
      (files: any) => files.progressInfo === 0
    ).length;
    if (onProgressFiles) {
      setFormSubmit(false);
    } else {
      setFormSubmit(true);
    }
  }, [uploadedFiles]);

  useEffect(() => {
    return () => {
      dispatch(
        setCreditNoteAutofillData({
          isFromInvoice: false,
          balanceDue: 0,
          invoiceId: null,
          invoiceStatus: "",
          creditNoteData: {
            creditNoteFormValues: initialValues,
            defaultFormValues: initialDefaultValues,
            itemFormValues: initialItemValues,
          },
        })
      );
    };
  }, []);
  useEffect(() => {
    dispatch(setLoaderState(true));
    ($(".selectpicker") as any).selectpicker("refresh");
    if (!editId) {
      getRoundOffPreference();
    }
    if (editId) {
      detailsResponse();
    }
  }, [currentUserInfo.organization_id]);

  useEffect(() => {
    ($(".selectpicker") as any).selectpicker("refresh");
  }, [creditNoteFormValues, statesFlag]);

  const getRoundOffPreference = async () => {
    const response = await dispatch(
      fetchRoundOffPreference({
        orgId: currentUserInfo.organization_id,
      })
    );
    let payload = response.payload;
    setIsRoundOffEnabled(payload.is_enabled);
    setIsTotalEditable(payload.is_total_editable);
  };

  /**
   * Get details for the edit credit note purpose.
   */
  const detailsResponse = async () => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      creditNoteDetails({
        creditNoteId: Number(editId),
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 500);
      ($(".selectpicker") as any).selectpicker("refresh");
      const response = responseAction.payload;
      const isSameOrg =
        response.organization_id === currentUserInfo.organization_id;
      const isLocked = response.is_locked;
      if (
        Object.keys(response).length &&
        !("error" in response) &&
        isSameOrg &&
        !isLocked
      ) {
        let defaultValues = {
          customer_id: response.customer_id,
          customer_name: response.customer_name,
          credit_note_number: response.credit_note_number,
          subject: response.subject,
          credit_note_date: response.credit_note_date,
          currency_id: response.currency_id,
          reference_number: response.reference_number,
          tds_level: response.tds_level,
          source_tax_type: response.source_tax_type,
          reason: response.reason,
          place_of_supply: response.place_of_supply,
          customer_exemption_type: response.customer_exemption_type,
          customer_exemption_id: response.customer_exemption_id,
          customer_exemption_reason: response.customer_exemption_reason,
        };
        const customerResponseAction = await dispatch(
          customerDetailsForTransactions({
            customerId: response.customer_id,
            orgId: currentUserInfo.organization_id,
          })
        );
        let customerResponse = customerResponseAction.payload;

        const preferenceResponse = await dispatch(
          fetchRoundOffPreference({
            orgId: currentUserInfo.organization_id,
            roundoff_transaction_type: "Sales",
          })
        );
        let preferencePayload = preferenceResponse.payload;

        setIsRoundOffEnabled((prevValue) => {
          return response?.roundoff_enabled || preferencePayload.is_enabled
            ? true
            : false;
        });

        setIsTotalEditable((prevValue) => {
          if (response?.roundoff_enabled || preferencePayload.is_enabled) {
            if (response?.roundoff_enabled) {
              return response?.is_total_editable;
            } else {
              return preferencePayload.is_total_editable;
            }
          } else {
            return false;
          }
        });

        if (isGstOrg) {
          let stateType =
            !response.place_of_supply || !currentUserInfo.organization_gst_state
              ? ""
              : response.place_of_supply ===
                currentUserInfo.organization_gst_state
              ? "Intra State"
              : "Inter State";
          if (
            customerResponse.gst_registration_type ===
              gstRegistrationTypes.sez ||
            customerResponse.gst_registration_type ===
              gstRegistrationTypes.sezDeveloper ||
            customerResponse.gst_registration_type ===
              gstRegistrationTypes.overseas
          ) {
            stateType = "Inter State";
          }
          setInitialStateType(stateType);
          setStateType(stateType);

          let transaction_tax_details = [] as any;
          response.item_details.forEach((item: any) => {
            let gst_tax = item.gst_tax;
            if (gst_tax?.tax_id) {
              transaction_tax_details.push({
                is_group: gst_tax?.is_group ? true : false,
                tax_id: gst_tax?.tax_id,
              });
            }
          });
          await fetchGstTaxList(
            stateType,
            customerResponse.gst_registration_type,
            transaction_tax_details
          );
          setTransactionTaxDetails(transaction_tax_details);
          setGstRegistrationType(customerResponse.gst_registration_type);
          setCustomerSalesDefault(customerResponse.sales_default);
        }
        setSourceTaxType(response.source_tax_type);
        let otherValues = {
          customer_notes: response.customer_notes,
          file_ids: response.credit_note_files.map((file: any) => file.id),
          tag_ids: response.tags.map((tag: any) => tag.id),
          items: response.item_details,
          email_to: response.email_to,
          conversion_id: response.conversion_id,
          is_locked: response.is_locked,
          lock_date: response.lock_date,
        };
        let totalTdsValue = {
          tds_id: response.tds_id,
          tds_account_id: response.tds_account_id,
          tds_percentage: response.tds_percentage ? response.tds_percentage : 0,
          tds_amount: response.tds_amount,
          tds_name: response.tds_name,
        };
        let totalTcsValue = {
          tcs_id: response.tcs_id,
          tcs_account_id: response.tcs_account_id,
          tcs_percentage: response.tcs_percentage,
          tcs_amount: response.tcs_amount,
          tcs_name: response.tcs_name,
        };
        setTotalTdsValues(totalTdsValue);
        setTotalTcsValues(totalTcsValue);
        setConversionDate(getFormatedDate(response.credit_note_date));
        setUploadedFiles(response.credit_note_files);
        setFileIds(response.credit_note_files.map((file: any) => file.id));
        setInsertedFiles(
          response.credit_note_files.map((file: any) => file.id)
        );
        setCurrencyId(response.currency_id);
        setCustomFields(response.custom_fields);
        custom_fields.current = response.custom_fields;
        setConversionCurrencyId(response.currency_id);
        setCurrencyCode(response.currency_code);
        setDefaultFormValues({ ...defaultValues });
        setCreditNoteFormValues({ ...defaultValues, ...otherValues });
        let itemDetails = getLineItemDetails(response.item_details);
        let tdsIdArray: number[] = [];
        itemDetails.map((item: any, index: number) => {
          if (item.tds_id) {
            tdsIdArray.push(item.tds_id);
          }
        });
        if (itemDetails.length) {
          setItemFormValues([...itemDetails]);
          setTaxFormValues(itemDetails);
        } else {
          setItemFormValues([initialItemValues]);
          setTaxFormValues([initialItemValues]);
        }
        if (response.tds_id) {
          tdsIdArray.push(response.tds_id);
        }
        setTdsIds(tdsIdArray);
        let tcsIdArray: number[] = [];
        if (response.tcs_id) {
          tcsIdArray.push(response.tcs_id);
        }
        setTcsIds(tcsIdArray);
        let totalDetails = response.total_details;
        setTotalFormValues(totalDetails);
        let emailContacts = response.email_to;
        let contactEmail: string[] = [];
        let emailDetails: any[] = [];
        emailContacts.map((contact: any) => {
          contactEmail.push(contact.email);
          emailDetails.push(contact);
        });
        setEmailLists(contactEmail);
        setEmailData(emailDetails);
        setCustomerId(response.customer_id);
        setCNStatus(response.credit_note_status);
        setEditTotal(response?.total_details.total);
      } else {
        if (isLocked) {
          navigate(-1);
          ErrorToaster(
            `Transactions before ${DateFormatHandler(
              response.lock_date
            )} have been locked. Hence action cannot be performed`
          );
        } else {
          dispatch(setErrorID(noAccessErrorCodes.CNEdit));
        }
      }
    }
  };
  /**
   * Check credit number already exist
   */
  const checkCreditNoteNumberExist = async (creditNoteNumber: string) => {
    if (!creditNoteNumber.includes("undefined")) {
      if (interval) {
        clearTimeout(interval);
      }
      interval = setTimeout(async () => {
        const responseAction = await dispatch(
          checkCreditNoteNumber({
            creditNoteNumber: creditNoteNumber,
            orgId: currentUserInfo.organization_id,
            creditNoteId: editId ? Number(editId) : 0,
          })
        );
        if (responseAction.payload) {
          const response = responseAction.payload;
          if (Object.keys(response).length && !("error" in response)) {
            if (response.credit_note_number_available === false) {
              setFormErrors({
                ...formErrors,
                ["credit_note_number"]:
                  "Credit note number already exists. Please choose a different one",
              });
              setIsCreditNumberExist(true);
            } else {
              setIsCreditNumberExist(false);
              setFormErrors({ ...formErrors, ["credit_note_number"]: "" });
            }
          }
        }
      }, 1000);
    }
  };
  /**
   * Set credit note default form values
   */
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "customer_notes" && value.length <= 300) {
      setCreditNoteFormValues({ ...creditNoteFormValues, [name]: value });
    } else {
      setCreditNoteFormValues({ ...creditNoteFormValues, [name]: value });
    }
  };
  const handleCustomerChange = async (e: any) => {
    if (e.value) {
      setIsCustomerChanged(true);
      let response = await getCustomerDetails(Number(e.value));
      setIsCustomerSelect(true);
      setFormSubmit(true);
      setCustomerId(Number(e.value));
      let state_type = "";
      if (
        response.gst_registration_type === gstRegistrationTypes.sez ||
        response.gst_registration_type === gstRegistrationTypes.sezDeveloper ||
        response.gst_registration_type === gstRegistrationTypes.overseas ||
        response.other_details.tax_preference === "NON_TAXABLE"
      ) {
        state_type = "Inter State";
      } else {
        state_type = stateType;
      }
      if (state_type !== "") {
        await fetchGstTaxList(
          state_type,
          response.gst_registration_type,
          transactionTaxDetails
        );
      } else {
        dispatch(clearGstTaxList());
      }

      setIsSez(response.is_sez);
      setGstRegistrationType(response.gst_registration_type);
      setCustomerSalesDefault(response.sales_default);
    }
  };
  /**
   * Fetch customer details by id
   */
  const getCustomerDetails = async (customerId: number) => {
    const responseAction = await dispatch(
      customerDetailsForTransactions({
        customerId: customerId,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        if (defaultRef.current?.defaultData) {
          let defaultFormValues = {
            ...defaultRef.current?.defaultData,
            currency_id: response.other_details.currency_id,
            customer_exemption_type: response.other_details.tax_preference,
            customer_exemption_id: response.other_details.exemption_reason_id,
          };
          setDefaultFormValues({ ...defaultFormValues });
        }
        setCurrencyId(response.other_details.currency_id);
      }
      return response;
    }
  };

  const handleCustomSubmission = async () => {
    await customFieldRef.current.handleSubmit();
  };

  const asynchronousSubmit = async (status: string, action: string) => {
    await handleCustomSubmission();
    setButtonAction({ status: status, action: action });
    handleSubmit(status, action);
  };

  const getTdsApplyLevel = () => {
    const itemDetails = itemRef.current?.itemValues
      .filter((item) => item.tds_id)
      .map((item) => item);
    if (itemRef.current?.totalTcsValues.tcs_id) {
      return tdsApplyLevel.NO_TDS;
    }
    if (itemRef?.current?.totalTdsValues.tds_id) {
      return tdsApplyLevel.TRANSACTION_LEVEL;
    }
    if (itemDetails?.length) {
      return tdsApplyLevel.LINE_ITEM_LEVEL;
    }
    if (
      tdsPreference.is_enabled &&
      tdsPreference.support_tds_for.includes("Customers")
    ) {
      if (tdsPreference.apply_tds_at === "Transaction level") {
        return itemRef.current?.totalTdsValues.tds_id
          ? tdsApplyLevel.TRANSACTION_LEVEL
          : tdsApplyLevel.NO_TDS;
      } // need to update this condition
      if (tdsPreference.apply_tds_at === "Line item level") {
        return itemDetails?.length
          ? tdsApplyLevel.LINE_ITEM_LEVEL
          : tdsApplyLevel.NO_TDS;
      }
    }
    return tdsApplyLevel.NO_TDS;
  };

  const getSourceTaxType = () => {
    const itemDetails = itemRef.current?.itemValues
      .filter((item) => item.tds_id)
      .map((item) => item);

    if (itemRef.current?.totalTcsValues.tcs_id) {
      return sourceTaxTypes.TCS;
    }
    if (itemRef.current?.totalTdsValues.tds_id || itemDetails?.length) {
      return sourceTaxTypes.TDS;
    }
    return sourceTaxTypes.NO_SOURCE_TAX;
  };

  /**
   * Handle form submit and set form validation errors
   */
  const handleSubmit = (status: string, action: string) => {
    if (status === "Draft") {
      setAction("save as draft");
    } else if (status === "Open") {
      setAction("save as open");
    }
    dispatch(setListFilters({}));
    let defaultValues = defaultRef.current?.defaultData;
    let itemValues: TransactionItemValues[] = itemRef.current?.itemValues
      ? itemRef.current?.itemValues
      : itemFormValues;
    let totalTdsValues = itemRef.current?.totalTdsValues;
    let totalTcsValues = itemRef.current?.totalTcsValues;
    let creditNoteInputs: any = {
      ...creditNoteFormValues,
      ...defaultValues,
      ...totalTdsValues,
      ...totalTcsValues,
      custom_fields: custom_fields.current.values,
      tds_level: getTdsApplyLevel(),
      source_tax_type: getSourceTaxType(),
    };
    let errors,
      itemErrorResponse: any = [];
    let itemErrors: any = [];

    errors = validate(
      creditNoteInputs,
      action,
      isCreditNumberExist,
      itemValues,
      fileRef,
      isGstOrg,
      gstRegistrationType
    );
    if (errors) {
      ScrollToError(Object.keys(errors)[0]);
    }
    let errorExist = false;
    let taxValues = itemRef.current?.taxValues
      ? itemRef.current?.taxValues
      : taxFormValues;
    itemValues.map((item, itemIndex) => {
      itemErrorResponse = validateItemValues(
        creditNoteInputs,
        item,
        itemIndex,
        taxValues,
        gstRegistrationType
      );

      if (itemErrorResponse) {
        errorExist = true;
        itemErrors.push(itemErrorResponse);
        ScrollToError(Object.keys(itemErrorResponse)[0]);
      } else {
        itemErrors.push({});
      }
    });
    if (
      Object.keys(errors).length ||
      (errorExist && itemErrors.length) ||
      customFieldRef.current.errors ||
      emailFormErrors !== ""
    ) {
      setFormErrors({
        ...errors,
        email: emailFormErrors,
      });
      setItemFormErrors(itemErrors);
      setIsError(true);
    } else {
      setFormErrors({});
      setItemFormErrors([]);
      setIsError(false);
      if (editId) {
        if (editId && currentUserInfo.is_tags_enabled && !didAlertModalOpen) {
          setDidAlertModalOpen(true);
          openEditAlertRef.current?.openAlertModal &&
            openEditAlertRef.current?.openAlertModal();
          return;
        } else {
          UpdateCreditNoteSubmit(creditNoteInputs, itemValues, status, action);
        }
      } else {
        createCreditNoteSubmit(creditNoteInputs, itemValues, status, action);
      }
    }
  };
  /**
   * Create new credit note
   */
  const createCreditNoteSubmit = async (
    creditNoteInputs: any,
    itemValues: TransactionItemValues[],
    creditNoteStatus: string,
    action: string
  ) => {
    dispatch(setLoaderState(true));
    creditNoteInputs.conversion_id = exchangeRateValues.id;
    let key: keyof typeof creditNoteInputs;
    for (key in creditNoteInputs) {
      if (creditNoteInputs[key] === "") {
        if (key === "tds_percentage" || key === "tds_amount") {
          creditNoteInputs[key] = 0;
        } else {
          creditNoteInputs[key] = null;
        }
      }
      if (
        key === "credit_note_number" &&
        creditNoteInputs[key] !== "" &&
        creditNoteInputs[key] !== null
      ) {
        creditNoteInputs[key] = defaultRef.current?.creditNoteNumber;
      }
      if (key === "reference_number" && creditNoteInputs[key]) {
        creditNoteInputs[key] = creditNoteInputs[key].toString().trim();
      }
      if (isGstOrg && key === "place_of_supply") {
        creditNoteInputs[key] = creditNoteInputs[key];
      }
      if (key === "tds_amount" || key === "tcs_amount") {
        creditNoteInputs[key] =
          creditNoteInputs[key] === null ? 0 : creditNoteInputs[key];
      }
    }
    let creditNoteItems = JSON.parse(JSON.stringify(itemValues));
    for (let items of creditNoteItems) {
      let itemInputs = items;

      if (isGstOrg) {
        // updating gst_id in request payload
        itemInputs.gst_id = itemInputs?.gst_tax?.tax_id
          ? itemInputs?.gst_tax?.tax_id
          : null;
      } else {
        delete itemInputs.gst_id;
        delete itemInputs.gst_tax;
      }
      let itemkey: keyof typeof itemInputs;
      for (itemkey in itemInputs) {
        if (itemInputs[itemkey] === "") {
          if (itemkey === "rate" || itemkey === "quantity") {
            itemInputs[itemkey] = 0;
          } else {
            itemInputs[itemkey] = null;
          }
        }
        if (itemkey === "discount") {
          if (itemInputs[itemkey]?.discount_value == 0) {
            itemInputs[itemkey] = itemInputs[itemkey]?.discount_value;
          } else if (itemInputs[itemkey]?.discount_percentage == 0) {
            itemInputs[itemkey] = itemInputs[itemkey]?.discount_percentage;
          } else {
            itemInputs[itemkey] = Number(itemInputs[itemkey]);
          }
        }
      }
      let itemTax = itemInputs.taxes;
      let taxData: ItemTaxValues[] = [];
      itemTax.map((item: any) => {
        if (item.tax_percentage === "") {
          item.tax_percentage = 0;
        }
        if (item.tax_id !== "" || item.tax_percentage !== 0) {
          taxData.push(item);
        }
      });
      items.taxes = taxData;
    }
    creditNoteInputs.items = creditNoteItems;
    creditNoteInputs.file_ids = fileRef.current.fileIds;
    if (emailLists.length > 0) {
      creditNoteInputs.email_to = emailLists;
    }
    $("#form-btn-invoice").addClass("customer-form-section-disable");
    creditNoteInputs.total = calculatedTotal;
    creditNoteInputs.roundoff_enabled = isRoundOffEnabled;
    creditNoteInputs.is_total_editable = isTotalEditable;

    const createResponseAction = await dispatch(
      createCreditNote({
        values: creditNoteInputs,
        status: creditNoteStatus,
        orgId: currentUserInfo.organization_id,
      })
    );
    const createResponse = createResponseAction.payload;
    if (Object.keys(createResponse).length && !("error" in createResponse)) {
      dispatch(setLoaderState(false));
      let creditNoteId = createResponse.id;
      if (
        creditNoteAutofillData.isFromInvoice &&
        creditNoteAutofillData.invoiceStatus !== "Closed" &&
        creditNoteStatus !== "Draft"
      ) {
        const allocationResponseAction = await dispatch(
          updateCreditAllocation({
            orgId: currentUserInfo.organization_id,
            creditNoteId: createResponse.id,
            values: [
              {
                invoice_id: Number(creditNoteAutofillData.invoiceId),
                amount_to_credit:
                  Number(creditNoteAutofillData.balanceDue) < Number(total)
                    ? Number(creditNoteAutofillData.balanceDue)
                    : Number(total),
              },
            ],
          })
        );
        const allocationResponse = allocationResponseAction.payload;
        if (
          Object.keys(allocationResponse).length &&
          !("error" in allocationResponse)
        ) {
          SuccessToaster(
            "Credit note allocated to the invoice successfully!",
            "credit-allocation-success"
          );
        }
      }
      // if (redirectTo && !creditNoteAutofillData.isFromInvoice) {
      //   navigate(redirectTo);
      // } else if (creditNoteAutofillData.isFromInvoice) {
      //   navigate(`/invoice/detail/${creditNoteAutofillData.invoiceId}`);
      // } else {
      //   navigate("/credit-note");
      // }
      if (creditNoteStatus === "Draft") navigate(-1);
      else navigate(`/credit-note/detail/${creditNoteId}`, { replace: true });
      toast.success("Credit note created successfully!", {
        toastId: "credit-note-create-success",
        closeButton: false,
        position: "top-center",
      });
    } else if ("error" in createResponse) {
      dispatch(setLoaderState(false));
      $("#form-btn-invoice").removeClass("customer-form-section-disable");
    } else {
      dispatch(setLoaderState(false));
    }
  };
  const UpdateCreditNoteSubmit = async (
    creditNoteInputs: any,
    itemValues: TransactionItemValues[],
    creditNoteStatus: string,
    action: string
  ) => {
    dispatch(setLoaderState(true));
    creditNoteInputs.conversion_id = exchangeRateValues.id;
    let key: keyof typeof creditNoteInputs;
    for (key in creditNoteInputs) {
      if (creditNoteInputs[key] === "") {
        if (key === "tds_percentage" || key === "tds_amount") {
          creditNoteInputs[key] = 0;
        } else {
          creditNoteInputs[key] = null;
        }
      }
      if (key === "payment_term_id" && creditNoteInputs[key] !== "") {
        if (creditNoteInputs[key] === null) {
          creditNoteInputs[key] = null;
        } else {
          creditNoteInputs[key] = Number(creditNoteInputs[key]);
        }
      }
      if (
        key === "credit_note_number" &&
        creditNoteInputs[key] !== "" &&
        creditNoteInputs[key] !== null
      ) {
        creditNoteInputs[key] = defaultRef.current?.creditNoteNumber;
      }
      if (key === "reference_number" && creditNoteInputs[key]) {
        creditNoteInputs[key] = creditNoteInputs[key].toString().trim();
      }
      if (isGstOrg && key === "place_of_supply") {
        creditNoteInputs[key] = creditNoteInputs[key];
      }
      if (key === "tds_amount" || key === "tcs_amount") {
        creditNoteInputs[key] =
          creditNoteInputs[key] === null ? 0 : creditNoteInputs[key];
      }
    }
    $("#form-btn-invoice").addClass("customer-form-section-disable");
    let deleteItems = itemRef.current?.deleteItems;
    if (deleteItems) {
      for (let item of deleteItems) {
        let res = await dispatch(
          deleteCreditNoteItemDetails({
            creditNoteId: Number(editId),
            itemId: item.itemId,
            orgId: currentUserInfo.organization_id,
          })
        );
      }
    }
    let deleteTaxItems = itemRef.current?.deleteTaxItems;
    if (deleteTaxItems) {
      for (let item of deleteTaxItems) {
        await dispatch(
          deleteCreditNoteItemTaxDetails({
            itemId: item.itemId,
            taxId: item.taxItemId,
            orgId: currentUserInfo.organization_id,
          })
        );
      }
    }
    if (isGstOrg) {
      let deleteGstItems = itemRef.current?.deleteGstItems;
      if (deleteGstItems) {
        for (let item of deleteGstItems) {
          await dispatch(
            deleteCreditNoteItemTaxDetails({
              itemId: item.itemId,
              taxId: item.id,
              orgId: currentUserInfo.organization_id,
            })
          );
        }
      }
    }
    let deleteContactIds: number[] = [];
    deleteEmailIds.map((contact) => {
      deleteContactIds.push(contact.emaiId);
    });

    if (deleteContactIds.length) {
      await dispatch(
        deleteCreditNoteRecipientDetails({
          creditNoteId: Number(editId),
          emailIds: deleteContactIds,
          orgId: currentUserInfo.organization_id,
        })
      );
    }
    let creditNoteItems = JSON.parse(JSON.stringify(itemValues));
    for (let items of creditNoteItems) {
      let itemInputs = items;
      if (isGstOrg) {
        // updating gst_id in request payload
        itemInputs.gst_id = itemInputs?.gst_tax?.tax_id
          ? itemInputs?.gst_tax?.tax_id
          : null;
      } else {
        delete itemInputs.gst_id;
        delete itemInputs.gst_tax;
      }
      let key: keyof typeof itemInputs;
      for (key in itemInputs) {
        if (itemInputs[key] === "") {
          if (key === "rate" || key === "quantity") {
            itemInputs[key] = 0;
          } else {
            itemInputs[key] = null;
          }
        }
        if (key === "discount") {
          if (itemInputs[key]?.discount_value) {
            itemInputs[key] = itemInputs[key]?.discount_value;
          } else if (itemInputs[key]?.discount_percentage) {
            itemInputs[key] = itemInputs[key]?.discount_percentage;
          } else {
            itemInputs[key] = Number(itemInputs[key]);
          }
        }
      }

      let itemTax = itemInputs.taxes;
      let taxData: ItemTaxValues[] = [];
      itemTax.map((item: any) => {
        if (item.tax_percentage === "") {
          item.tax_percentage = 0;
        }
        if (item.tax_id !== "" || item.tax_percentage !== 0) {
          taxData.push(item);
        }
      });
      items.taxes = taxData;
    }
    creditNoteInputs.items = creditNoteItems;
    creditNoteInputs.file_ids = fileRef.current.fileIds;
    creditNoteInputs.email_to = emailLists;

    creditNoteInputs.total = calculatedTotal;
    creditNoteInputs.roundoff_enabled = isRoundOffEnabled;
    creditNoteInputs.is_total_editable = isTotalEditable;

    const updateResponseAction = await dispatch(
      editCreditNoteDetails({
        id: Number(editId),
        values: creditNoteInputs,
        status: creditNoteStatus,
        orgId: currentUserInfo.organization_id,
      })
    );
    const updateResponse = updateResponseAction.payload;
    if (Object.keys(updateResponse).length && !("error" in updateResponse)) {
      dispatch(setLoaderState(false));
      let creditNoteId = updateResponse.id;
      // if (redirectTo) {
      //   navigate(redirectTo);
      // } else {
      //   navigate("/credit-note");
      // }
      if (creditNoteStatus === "Draft") navigate(-1);
      else {
        redirectUrl
          ? navigate(
              `/credit-note/detail/${creditNoteId}?redirect=${redirectUrl}`,
              { replace: true }
            )
          : navigate(`/credit-note/detail/${creditNoteId}`, { replace: true });
      }
      toast.success("Credit note updated successfully!", {
        toastId: "credit-note-update-success",
        closeButton: false,
        position: "top-center",
      });
    } else if ("error" in updateResponse) {
      dispatch(setLoaderState(false));
    } else {
      dispatch(setLoaderState(false));
    }
  };
  const setEmailHandler = (email: string[]) => {
    setEmailLists(email);
  };
  /**
   * Function to store emailIds to be deleted in Edit invoice
   */
  const removeEmailHandler = (email: string) => {
    emailData.map((contact) => {
      if (contact.email === email) {
        setDeleteEmailIds((prevItems) => [
          ...prevItems,
          { emaiId: contact.id },
        ]);
      }
    });
  };
  /**
   * function to get taxable amount
   */
  const amountCalculation = async (data: {
    values: TotalAmountCalculationValues;
    orgId: number;
    signal: Object;
  }) => {
    const responseAction = await dispatch(getAmountCalculation(data));
    return responseAction;
  };
  /**
   * function to get taxable amount
   */
  const taxableAmount = async (data: {
    values: CustomerItemValues;
    orgId: number;
    signal: Object;
  }) => {
    const taxResponseAction = await dispatch(getTaxableAmount(data));
    return taxResponseAction;
  };
  /**
   * Upload selected files
   */
  const uploadAttachedFiles = async (file: any) => {
    let formData = new FormData();
    formData.append("credit_note_file", file);
    let responseAction = await dispatch(
      attachFiles({
        file: formData,
        orgId: currentUserInfo.organization_id,
      })
    );
    return responseAction;
  };
  /**
   * Remove uploaded files
   */
  const removeFile = async (fileId: any) => {
    if (insertedFiles.includes(fileId)) {
      await dispatch(
        deleteCreditNoteFile({
          creditNoteId: Number(editId),
          fileId,
          orgId: currentUserInfo.organization_id,
        })
      );
    }
    let defaultValues = defaultRef.current?.defaultData;
    let itemValues: TransactionItemValues[] = itemRef.current?.itemValues
      ? itemRef.current?.itemValues
      : itemFormValues;
    let creditNoteInputs: any = { ...creditNoteFormValues, ...defaultValues };
    let errors = validate(
      creditNoteInputs,
      "",
      isCreditNumberExist,
      itemValues,
      fileRef,
      isGstOrg,
      gstRegistrationType
    );
    if (Object.keys(errors).length) {
      setFormErrors(errors);
    } else {
      setFormErrors({});
    }
  };

  const fetchCreditNoteListAfterDelete = async () => {
    const responseAction = await dispatch(
      creditNoteList({
        page,
        itemsPerPage,
        dateSortOrder,
        orgId: currentUserInfo.organization_id,
        orgIds: [],
        customerList: [],
        statusList: [],
      })
    );
    // if (redirectTo) {
    //   navigate(redirectTo);
    // } else {
    //   navigate("/credit-note");
    // }
    redirectUrl ? navigate(redirectUrl) : replace ? navigate(-2) : navigate(-1);
  };
  const handleSubmitAction = (status: boolean) => {
    if (status) {
      setFormSubmitAction(false);
    } else {
      setFormSubmitAction(true);
    }
  };
  const setErrorOnItemTaxChange = (errors: any) => {
    setItemFormErrors([...errors]);
  };
  const handleNewConversionDate = (value: string) => {
    setNewConversionDate(value);
  };
  const closeCustomerModal = () => {
    setShowCustomerModal(false);
    document.body.style.overflow = "auto";
  };

  const getCustomFieldData = (customFieldData: ObjectType) => {
    custom_fields.current = customFieldData;
    setCustomFields(customFieldData.values);
  };
  const getTotalValue = (total: number | string) => {
    setTotal(total);
  };
  const fetchGstTaxList = async (
    stateType: string,
    gstRegType?: string,
    transaction_tax_details?: [{ is_group: boolean; tax_id: number }]
  ) => {
    if (!transaction_tax_details && transactionTaxDetails) {
      transaction_tax_details = transactionTaxDetails;
    }
    if (
      gstRegType === gstRegistrationTypes.sez ||
      gstRegType === gstRegistrationTypes.sezDeveloper ||
      gstRegType === gstRegistrationTypes.overseas
    ) {
      stateType = "Inter State";
    }
    dispatch(clearGstTaxList());
    await dispatch(
      getGstTaxList({
        stateType: stateType,
        orgId: currentUserInfo.organization_id,
        is_sez: false,
        transaction_tax_details,
        transactionSource: "Credit Notes",
      })
    );
  };
  const updateCalculatedTotal = (val: any) => {
    setCalculatedTotal(val);
  };

  const handleEmailError = (error: string) => {
    setEmailFormErrors(error);
  };

  return (
    <>
      <div
        className="card card-user-management card-customer card-create-item main-card overflowX-hidden h-100 card-bill-management"
        id="invoice-create"
      >
        <div className="card-header card-user-management-header card-customer-header card-no-bg-header card-main-header">
          <TransactionHeader
            transactionName="Credit Note"
            transactionNumber={Number(editId)}
          />
        </div>
        <div className="card-body">
          <form
            name="add_invoice_form"
            id="add_invoice_form"
            className={`cn-form add-module-item-form ${
              defaultRef.current?.defaultData?.customer_id ? "" : " inactive"
            } ${isError ? "with-form-error-alert" : ""}`}
            tabIndex={-1}
          >
            <div className="fields-wrapper pt-0">
              <div className="fields-wrappers">
                <DefaultDetails
                  defaultFormValues={defaultFormValues}
                  creditNoteFormValues={creditNoteFormValues}
                  handleCustomerChange={handleCustomerChange}
                  checkCreditNoteNumberExist={checkCreditNoteNumberExist}
                  formErrors={formErrors}
                  handleNewConversionDate={handleNewConversionDate}
                  ref={defaultRef}
                  customFieldRef={customFieldRef}
                  getCustomFieldData={getCustomFieldData}
                  customFieldValue={customFields}
                  setStateType={setStateType}
                  fetchGstTaxList={fetchGstTaxList}
                  setSupplyState={setSupplyState}
                  initialStateType={initialStateType}
                  gstRegistrationType={gstRegistrationType}
                  setIsStateChanged={setIsStateChanged}
                />
                <ItemDetails
                  formValues={creditNoteFormValues}
                  itemFormValues={itemFormValues}
                  totalFormValues={totalFormValues}
                  formErrors={formErrors}
                  itemFormErrors={itemFormErrors}
                  currencyId={currencyId}
                  currencyCode={currencyCode}
                  amountCalculation={amountCalculation}
                  taxableAmount={taxableAmount}
                  isHsnCode={false}
                  taxType="payable"
                  lineItemBaseAccount="Incomes"
                  discountBaseAccount="Expenses"
                  conversionDate={conversionDate}
                  newConversionDate={newConversionDate}
                  conversionCurrencyId={conversionCurrencyId}
                  userId={customerId}
                  componentType={"creditNote"}
                  setErrorOnItemTaxChange={setErrorOnItemTaxChange}
                  totalTdsValues={totalTdsValues}
                  totalTcsValues={totalTcsValues}
                  ref={itemRef}
                  isInititialCustomConversionFetch={
                    creditNoteAutofillData.isFromInvoice
                  }
                  getTotalValue={getTotalValue}
                  sourceTaxType={sourceTaxType}
                  tdsIds={tdsIds}
                  tcsIds={tcsIds}
                  stateType={stateType}
                  supplyState={supplyState}
                  isSez={isSez}
                  gstRegistrationType={gstRegistrationType}
                  isRoundOffEnabled={isRoundOffEnabled}
                  isTotalEditable={isTotalEditable}
                  editTotal={editTotal}
                  updateCalculatedTotal={updateCalculatedTotal}
                  isCustomerVendorChanged={isCustomerChanged}
                  isStateChanged={isStateChanged}
                  customerDetails={defaultFormValues}
                  consumerId={customerId}
                  consumerTradeDefault={customerSalesDefault}
                >
                  <NoteTextArea
                    label={
                      "Customer Notes  (This info will not be display in the credit note pdf )"
                    }
                    name={"customer_notes"}
                    id={"customerNotes"}
                    className={"notes-textarea"}
                    placeholder={"Enter notes here..."}
                    value={creditNoteFormValues.customer_notes}
                    onChange={handleChange}
                    formErrors={formErrors}
                  />
                </ItemDetails>
                <section id="upload_section">
                  <div className="upload-header pb-3">Attach Files</div>
                  <DropZone
                    uploadedFiles={uploadedFiles}
                    uploadFileIds={fileIds}
                    uploadAttachedFiles={uploadAttachedFiles}
                    removeFile={removeFile}
                    handleSubmitAction={handleSubmitAction}
                    ref={fileRef}
                  />
                  <span className="error">{formErrors.attachFiles}</span>
                </section>
                <div className="email-reciepients-wrapper">
                  <label htmlFor="deposit_to">Email to</label>
                  <br />
                  <MultiEmailBox
                    onChange={setEmailHandler}
                    emailList={emailLists}
                    removeEmailHandler={removeEmailHandler}
                    onError={handleEmailError}
                    className={emailFormErrors !== "" ? "email-error" : ""}
                  />
                  <span className="error">{emailFormErrors}</span>
                  {emailLists.length ? (
                    <InfoBox
                      message={message("Save as Open", "credit note").emailInfo}
                      className="info-alert email-info-alert"
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <CreditNoteFormFooter
                name="Credit Note"
                isFormSubmit={formSubmit && formSubmitAction}
                isError={isError}
                action={action}
                transactionUserId={defaultRef.current?.defaultData?.customer_id}
                isUserSelect={isCustomerSelect}
                onClickSave={asynchronousSubmit}
                onClickDelete={() => {
                  setDeleteCreditNoteId(Number(editId));
                  setDeleteCreditItemId(
                    creditNoteFormValues.credit_note_number
                  );
                }}
                editId={editId}
                rolePermission={creditNoteRolePermission}
                transactionStatus={cnStatus}
                isLocked={creditNoteFormValues.is_locked}
                lockDate={creditNoteFormValues.lock_date}
                redirectUrl={"/credit-note"}
                isFromInvoice={creditNoteAutofillData.isFromInvoice}
              />
            </div>
          </form>
        </div>
        <DeleteCreditNote
          deleteCreditNoteId={deleteCreditNoteId}
          deleteCreditItemId={deleteCreditItemId}
          page={page}
          itemsPerPage={itemsPerPage}
          dateSortOrder={dateSortOrder}
          refreshCreditNotes={fetchCreditNoteListAfterDelete}
          organizationId={currentUserInfo.organization_id}
          creditNoteStatus={cnStatus}
        />
      </div>
      <TransactionEditTagRemovalAlertModal
        module="credit note"
        onCancel={() => {
          setDidAlertModalOpen(false);
        }}
        onSubmit={() => {
          handleSubmit(buttonAction.status, buttonAction.action);
        }}
        openAlertRef={openEditAlertRef}
      />
    </>
  );
}

export default React.memo(CreditNoteForm);
