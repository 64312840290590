import "./tableInfo.css";
import info from "../../../../../assets/images/email-info-msg-icon.svg";
import { Grid } from "@mui/material";
import { CancelVoidScheduledModal } from "./CancelVoidScheduledModal";
import { CancelChangesModal } from "./CancelChangesModal";

export default function ScheduledVoidDetailsInfo(props: any) {
  const stopIndex =
    props.scheduledSubscriptionData.status_change_scheduler?.findIndex(
      (item: { new_status: string }) => item.new_status === "CANCELLED"
    );

  return (
    <>
      <Grid container className="subscription-impact-details-container">
        <Grid item xs="auto">
          <Grid container>
            <Grid item xs="auto">
              <img src={info} id="message-img" alt="clone-alt" />
            </Grid>
            <Grid item xs="auto">
              <div className="m-l-10">
                This subscription is scheduled to be cancelled on {" "}
                {
                  props.scheduledSubscriptionData.status_change_scheduler[
                    stopIndex
                  ].scheduled_date
                }
              </div>
            </Grid>
          </Grid>
        </Grid>
        {/* EDIT section */}
        <Grid item xs="auto">
          {props.overviewSubscriptionData.subscription_status && (
            <button
              className="add-customer-link"
              data-bs-toggle="modal"
              data-bs-target="#stopResumeMessageModal"
              disabled={
                props.overviewSubscriptionData.subscription_status.toLowerCase() ===
                "expired"
              }
              onClick={() => props.editStopHandler("cancel")}
            >
              <span className="d-flex align-items-center">
                {" "}
                <span
                  className={
                    props.overviewSubscriptionData.subscription_status.toLowerCase() ===
                    "stopped"
                      ? ""
                      : "delete"
                  }
                >
                  Edit Cancel
                </span>
              </span>
            </button>
          )}
        </Grid>
        <Grid item xs="auto">
          {props.subscriptionStatus === "IN_TRIAL" ? (
            <></>
            // <CancelVoidScheduledModal
            //   type="stopResumeCancel"
            //   subscriptionStatus={props.subscriptionStatus}
            //   overviewSubscriptionData={props.overviewSubscriptionData}
            // />
          ) : (
            <CancelChangesModal
              type="stopResumeCancel"
              subscriptionStatus={props.subscriptionStatus}
              schedulerId={
                props.scheduledSubscriptionData.status_change_scheduler[
                  stopIndex
                ].id
              }
              subscriptionSchedulerDetails={props.subscriptionSchedulerDetails}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}
