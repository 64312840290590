import { toast } from "react-toastify";
import { useAppDispatch } from "../../../../app/hooks";
import ReminderModal from "../../../common/components/ReminderModal";
import useCommonData from "../../../hooks/useCommon";
import { sendReminderRI } from "../../../home/recurringInvoice/recurringInvoiceSlice";

type Props = {
    reminderRInvoiceId: number;
  refreshRInvoices: (action: string) => void;
  organizationId: number;
};
const SubscriptionReminder = (props: Props) => {
  const dispatch = useAppDispatch();
  const { currentUserInfo } = useCommonData();

  const modalCancelHandler = () => {
    $(".close-btn").click();
  };

  const modalSubmitHandler = async () => {
    $("#delete-button").addClass("customer-form-section-disable")
    const deleteRoleResponseAction = await dispatch(
        sendReminderRI({ invoiceId: props.reminderRInvoiceId, orgId: currentUserInfo.organization_id })
    );


    $("#delete-button").removeClass("customer-form-section-disable");
    if (deleteRoleResponseAction.payload) {
      const deleteRoleResponse = deleteRoleResponseAction.payload;
      if (Object.keys(deleteRoleResponse).length && !("error" in deleteRoleResponse)) {
        props.refreshRInvoices("delete");
        $(".close-btn").click();
        toast.success("Reminder mail has been successfully sent", {
          toastId: "role-delete-success",
          closeButton: false,
          position: "top-center",
        });
      } else if ("error" in deleteRoleResponse) {
        $(".close-btn").click();
      }
    }
  };
  return (
    <ReminderModal
      heading="Send Reminder!"
      subHeading="Reminder emails will be sent to invoices in open/partially paid/overdue status. Are you sure you want to send the reminder email?"
    //   transactionName={joinTransactionNumber(props.voidStatus)}
      modalCancelHandler={modalCancelHandler}
      modalSubmitHandler={modalSubmitHandler}
    />
  );
};
export default SubscriptionReminder;