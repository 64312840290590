import {
  CustomerItemValues,
  InvoiceValues,
  ObjectType,
  InvoiceErrorFormValues,
} from "../../../../../types";
import {
  ITEM_TYPES,
  gstRegistrationTypes,
} from "../../../../constants/constants";

/**
 * Validate form values
 */
export const validate = (
  invoiceFormValues: InvoiceErrorFormValues,
  action: string,
  status: string,
  emailLists: [] | string[],
  isInvoiceNumberExist: boolean,
  itemFormValues: CustomerItemValues[],
  fileRef: React.MutableRefObject<any>,
  isGstOrg: boolean,
  gstRegistrationType: string,
  totalAmount: number,
  emailFormErrors?: string
) => {
  const errors: ObjectType = {};
  if (!invoiceFormValues.customer_id) {
    errors.customer_name = "Please enter customer name";
  }
  const dateRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
  if (
    invoiceFormValues.invoice_date &&
    invoiceFormValues.invoice_date === "Invalid date" &&
    !dateRegex.test(invoiceFormValues.invoice_date)
  ) {
    errors.invoice_date = "Please enter valid invoice date";
  }
  if (
    isGstOrg &&
    gstRegistrationType !== gstRegistrationTypes.overseas &&
    !invoiceFormValues.place_of_supply
  ) {
    errors.place_of_supply = "Please select place of supply";
  }
  if (
    invoiceFormValues.invoice_date &&
    invoiceFormValues.invoice_date === "Future date"
  ) {
    errors.invoice_date =
      "You have selected a future date. Please select a valid date.";
  }
  if (
    invoiceFormValues.invoice_date &&
    invoiceFormValues.invoice_date === "Old date"
  ) {
    errors.invoice_date =
      "Invalid Transaction Date: Transaction date must be within the current fiscal year.";
  }
  if (
    !invoiceFormValues.due_date ||
    (invoiceFormValues.due_date === "Invalid date" &&
      !dateRegex.test(invoiceFormValues.due_date))
  ) {
    errors.due_date = "Please enter valid due date";
  }
  if (
    !invoiceFormValues.due_date ||
    (invoiceFormValues.due_date === "Old date" &&
      !dateRegex.test(invoiceFormValues.due_date))
  ) {
    errors.due_date =
      "Invalid Transaction Date: Transaction date must be within the current fiscal year.";
  }
  if (invoiceFormValues.tds_percentage > 100) {
    errors.tds_percentage = "TDS should be less than 100 %";
  }
  if (status !== "Draft") {
    if (!invoiceFormValues.invoice_number?.split("~")[1]?.trim()) {
      errors.invoice_number = "Please enter a valid invoice number";
    }
    if (emailLists?.length === 0 && action === "Send") {
      errors.email = "Please enter at least one email address";
    } else {
      errors.email = "";
      delete errors.email;
    }
    if (emailFormErrors !== "") {
      errors.multiEmail = emailFormErrors;
    }
    if (isInvoiceNumberExist) {
      errors.invoice_number =
        "Invoice number already exists. Please choose a different one";
    }
    if (
      Date.parse(invoiceFormValues.invoice_date) >
      Date.parse(invoiceFormValues.due_date)
    ) {
      errors.invoice_date = "Please enter valid invoice date";
    }
    if (itemFormValues.length <= 0) {
      errors.items = "* Please add at least one item";
    }
  }
  if (emailLists?.length === 0 && action === "Send") {
    errors.email = "Please enter at least one email address";
  }
  if (fileRef.current.fileIds.length > 3) {
    errors.attachFiles = "* Maximum File Limit Reached";
  }
  if (
    Number(invoiceFormValues?.tds_amount) !== 0 &&
    invoiceFormValues?.tds_id === null
  ) {
    errors.tds_name = "Please select TDS type";
  } else if (invoiceFormValues?.tds_id != null) {
    if (totalAmount < 0) {
      errors.tds_amount = "TDS amount must not exceed total taxable amount";
    }
  }
  if (
    Number(invoiceFormValues?.tcs_amount) !== 0 &&
    invoiceFormValues?.tcs_id === null
  ) {
    errors.tcs_name = "Please select TCS type";
  }
  return errors;
};
export const validateItemValues = (
  invoiceFormValues: any,
  values: CustomerItemValues | any,
  itemIndex: number,
  status: string,
  taxFormValues: CustomerItemValues[],
  gstRegistrationType: string
) => {
  const errors: ObjectType = {};
  let flag = false;
  if (status !== "Draft") {
    if (!values.quantity || values.quantity === 0) {
      errors.quantity = "Please enter quantity";
      flag = true;
    }
    if (!values.rate) {
      errors.rate = "Please enter rate";
      flag = true;
    }
    if (values.discount) {
      if (
        values.discount_type === "Absolute" &&
        Number(values.discount) > Number(values.rate) * Number(values.quantity)
      ) {
        errors.discount = "Discount cannot not be higher than rate * quantity";
        flag = true;
      } else if (
        values.discount_type === "Percentage" &&
        Number(values.discount) > 100
      ) {
        errors.discount = "Discount cannot not be higher than rate * quantity";
        flag = true;
      }
    }
  }
  if (values.tds_name === "") {
    if (values.tds_amount != 0) {
      errors.tds_name = "Please choose TDS type";
      flag = true;
    }
  } else if (values.tds_name != "") {
    if (values.tds_amount > values.rate) {
      errors.tds_amount = "TDS amount must not exceed total taxable amount";
      flag = true;
    }
  }
  if (!values.discount && values.discount_account_id) {
    errors.discount = "Please enter discount";
    flag = true;
  }
  if (values.discount && !values.discount_account_id) {
    errors.discount_account_id = "Please choose discount account";
    flag = true;
  }
  if (!values.item_name) {
    errors.item_name = "Please select an item or enter a description";
    flag = true;
  }
  if (
    values.type === ITEM_TYPES.service &&
    gstRegistrationType === gstRegistrationTypes.deemedExp
  ) {
    errors.item_name =
      "Only goods can be associated in the Invoice, if the Invoice is raised for the Deemed Export Supply";
    flag = true;
  }
  if (!values.account_id) {
    errors.account_id = "Please enter account";
    flag = true;
  }
  let hsnRegex = /^\d{6}$|^\d{8}$/;
  if (values.hsn_or_sac_code === null) {
    values.hsn_or_sac_code = "";
  }
  if (
    values.hsn_or_sac_code &&
    !hsnRegex.test(values.hsn_or_sac_code.toString()) &&
    values.hsn_or_sac_code !== ""
  ) {
    errors.hsn_or_sac_code = "Please enter valid hsn/sac code";
    flag = true;
  }
  if (taxFormValues[itemIndex]?.taxes) {
    let taxes = taxFormValues[itemIndex].taxes;
    let taxLabels: any[] = [];
    let taxError: any[] = [];
    for (let tax of taxes) {
      if (taxLabels.includes(tax.tax_id)) {
        flag = true;
        taxError.push({
          tax_id: "Tax label already used",
          tax_percentage: 0,
        });
      } else if (tax.tax_id === "" && Number(tax.tax_percentage) > 0) {
        flag = true;
        taxError.push({
          tax_id: "Please choose tax code",
          tax_percentage: 0,
        });
      } else {
        taxError.push({ tax_id: "", tax_percentage: 0 });
        if (tax.tax_id) {
          taxLabels.push(tax.tax_id);
        }
      }
    }
    errors.taxes = taxError;
  }
  if (flag) return errors;
  else return false;
};
