import { useParams, useSearchParams } from "react-router-dom";
import { ObjectType } from "../../../../../types";
import Header from "../../../../common/components/detailsPage/Header";
import useCommonData from "../../../../hooks/useCommon";
import Edit from "../../../../common/components/kebabMenu/Edit";
import Delete from "../../../../common/components/Delete";
import KebabMenu from "../../../../common/components/kebabMenu/KebabMenu";
import Void from "../../../../common/components/Void";
import OrgTag from "../../../../common/components/OrgTag";
import AddonDelete from "../AddonDelete";
import AddonVoid from "../AddonVoid";
import { DeleteInitialData, AddonDetailData } from "../../addonType";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";

type Props = {
    addonDetailData: AddonDetailData;
    deleteAction: DeleteInitialData;
    onClickDelete: (actionType: string, id: number, addonName: string) => void;
    refreshAddon: () => void;
}

const AddonViewHeader = (props: Props) => {
  const { currentUserInfo, navigate } = useCommonData();
  const { addonsRolePermission } = usePermissionHandler();
  const backFromAddonViewHandler = () => {
    navigate('/addons');
  };
  
  return (
    <>
    <Header
        onBackButtonClick={backFromAddonViewHandler}
        data={{
          num: props.addonDetailData.external_name,
          status: props.addonDetailData.addon_status.charAt(0).toUpperCase() + props.addonDetailData.addon_status.slice(1).toLowerCase(),
        }}
        dataId=''
      />
      <div className="card-body">
      <div className="invoice-actions-wrapper detail-pg-actions-wrapper d-flex justify-content-between">
          <div className="left-group addon-left-group">
          <div className="addon-org-tag">
          <OrgTag
                organization={props.addonDetailData.organization_name}
              />
           </div>
            {props.addonDetailData.description ? <><div className="biller-label ps-2 addon-description-label">
              Description
            </div>
              <span className="addon-description">
                {props.addonDetailData.description || ''}
              </span></> : null}
          </div> 
          {(!addonsRolePermission.includes("Update") || props.addonDetailData.addon_status.toLowerCase() === "cancelled") ||
          props.addonDetailData.organization_id !==
            currentUserInfo.organization_id ? null :<div className="actions-wrap d-flex flex-no-shrink">
            {/* Uncomment while integrating Edit flow */}
            <Edit
              url={`/addons/edit/${props.addonDetailData.id}`} 
              isMutable
              name="Addon"
              className=""
              isLocked={false}
              lockDate={''}
              isDisabled={false}
            />
          {addonsRolePermission.includes("Delete") ? (
            <Delete
              className="text-decoration-none delete-item"
              deleteIcon={true}
              isMutable
              onClick={() => {props.onClickDelete('Addon', props.addonDetailData.id, props.addonDetailData.external_name)}}
              isLocked={false}
              lockDate={''}
              name="Addon"
              isTransactionExist={props.addonDetailData.addon_exists_subscription}
              planExistsSubscription={props.addonDetailData.addon_exists_subscription}
            />
          ) : null}
          <KebabMenu wrapperClass="addon-detail-header detail-top-action-menu">
             <>
                <li>
                  <Void
                    className="dropdown-item delete"
                    deleteIcon={true}
                    isMutable
                    onClick={() => {}}
                    isLocked={false}
                    lockDate={""}
                    name="Addon"
                    status={props.addonDetailData.addon_status}
                  />
                </li>
             </>
          </KebabMenu>
          </div>}
      </div>
        </div>
        <AddonDelete
           deleteAddonId={props.deleteAction.id}
           deleteAddonName={props.deleteAction.addonName}
           deleteActionType={props.deleteAction.actionType}
           refreshAddon={props.deleteAction.actionType === 'Price' ? props.refreshAddon : backFromAddonViewHandler}
           organizationId={currentUserInfo.organization_id}
         />
         <AddonVoid
         addonId={props.addonDetailData.id}
         addonName={props.addonDetailData.external_name}
         refreshAddon={backFromAddonViewHandler}
         organizationId={currentUserInfo.organization_id}
         />
    </>
  );
};
export default AddonViewHeader;