import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../../../app/hooks";
import { setErrorID, setListFilters } from "../../../../../appSlice";
import {
  DateFormatHandler,
  downloadUsingURL,
  getFormatedDate,
} from "../../../../../helpers/helper";
import {
  AddressValues,
  CustomerItemValues,
  FilesValues,
  InvoiceDefaultRef,
  InvoiceDefaultValues,
  InvoiceValues,
  ItemRef,
  ItemTaxValues,
  ObjectType,
  TCSTotalValues,
  TDSTotalValues,
  TotalAmountCalculationValues,
  TotalDetailsValues,
} from "../../../../../types";
import {
  exchangeApiStatusSelector,
  latestCurrencyRateSelector,
  setLoaderState,
} from "../../../../common/commonSlice";
import AddressModal from "../../../../common/components/AddressModal";
import DropZone from "../../../../common/components/DropZone/DropZone";
import NoteTextArea from "../../../../common/components/NoteTextArea/NoteTextArea";
import TransactionHeader from "../../../../common/components/TransactionHeader";
import ItemDetails from "../../../../common/components/lineItems/ItemDetails";
import MultiEmailBox from "../../../../common/components/multiEmailComponent/EmailTo";
import ScrollToError from "../../../../common/components/scrollToError";
import {
  noAccessErrorCodes,
  sourceTaxTypes,
  tdsApplyLevel,
  gstRegistrationTypes,
} from "../../../../constants/constants";
import useCommonData from "../../../../hooks/useCommon";
import usePreservedLocation from "../../../../hooks/usePreservedLocation";
import {
  addAddress,
  addressReset,
  addressSelector,
} from "../../../customerManagement/components/customerForm/AddressDetails/addressSlice";
import { customerDetailsForTransactions } from "../../../customerManagement/customerSlice";
import "../../Invoice.css";
import {
  attachInvoiceFiles,
  checkInvoiceNumber,
  createInvoice,
  deleteInvoiceFile,
  deleteInvoiceItemDetails,
  deleteInvoiceItemTaxDetails,
  deleteInvoiceRecipientDetails,
  editInvoiceDetails,
  getAmountCalculation,
  getTaxableAmount,
  invoiceDetails,
  invoiceList,
} from "../../invoiceSlice";
import DeleteInvoice from "../Delete";
import DefaultDetails from "./DefaultDetails";
import InvoiceFormFooter from "./InvoiceFormFooter";
import InvoicePrint from "./InvoicePrint";
import OrganizationDetails from "./OrganizationDetail";
import {
  billingEmptyObject,
  initialDefaultValues,
  initialInvoiceValues,
  initialItemValues,
  initialTCSTotalValues,
  initialTDSTotalValues,
  initialTaxValues,
  initialTotalValues,
  shippingEmptyObject,
} from "./StateInitialization";
import { validate, validateItemValues } from "./ValidateInvoiceForm";
import ChooseTemplate from "./chooseTemplate/ChooseTemplate";
import ChooseTemplatePreview from "./chooseTemplate/ChooseTemplatePreview";
import InvoiceAddress from "./invoiceAddress/InvoiceAddress";

import useGst from "../../../../hooks/useGst";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import { clearGstTaxList, getGstTaxList } from "../../../gst/gstSlice";
import { tdsPreferenceSelector } from "../../../tds/tdsSlice";
import "./invoiceForm.css";
import AlertModalPopup from "../../../../common/components/alertModalPopup/AlertModalPopup";
import { ErrorToaster } from "../../../../../helpers/toastHelper";
import { fetchRoundOffPreference } from "../../../preferences/preferencesSlice";
import { TransactionEditTagRemovalAlertModal } from "../../../paymentsReceived/components/paymentReceivedForm/imports";

let interval: any = null;
function InvoiceForm() {
  const { editId } = useParams();
  const [searchParams] = useSearchParams();
  let redirectUrl = searchParams.get("redirect");
  let replace = searchParams.get("replace");
  const { dispatch, navigate, currentUserInfo } = useCommonData();
  const { invoicesRolePermission } = usePermissionHandler();
  const defaultRef = useRef<InvoiceDefaultRef | null>(null);
  const itemRef = useRef<ItemRef | null>(null);
  const addressList = useAppSelector(addressSelector);
  const exchangeRateValues = useAppSelector(
    latestCurrencyRateSelector
  ).latest_conversion;
  const exchangeRateAPiStatus = useAppSelector(exchangeApiStatusSelector);
  const tdsPreference = useAppSelector(tdsPreferenceSelector);

  const [invoiceFormValues, setInvoiceFormValues] =
    useState<InvoiceValues>(initialInvoiceValues);
  const [defaultFormValues, setDefaultFormValues] =
    useState<InvoiceDefaultValues>({
      ...initialDefaultValues,
      customer_id: "",
      recalculate_exchange_rate: false,
    });
  const [itemFormValues, setItemFormValues] = useState<CustomerItemValues[]>([
    initialItemValues,
  ]);
  const [taxFormValues, setTaxFormValues] = useState<CustomerItemValues[]>([
    initialItemValues,
  ]);
  const [totalFormValues, setTotalFormValues] =
    useState<TotalDetailsValues>(initialTotalValues);
  const [formErrors, setFormErrors] = useState<ObjectType>({});
  const [itemFormErrors, setItemFormErrors] = useState<CustomerItemValues[]>(
    []
  );
  const [isInvoiceNumberExist, setIsInvoiceNumberExist] = useState(false);
  const [invoiceId, setInvoiceId] = useState<number>();
  const [billingAddressFormValues, setBillingAddressFormValues] =
    useState<AddressValues | undefined>();
  const [shippingAddressFormValues, setShippingAddressFormValues] =
    useState<AddressValues | undefined>();
  const [billingAddressFormValuesForEdit, setBillingAddressFormValuesForEdit] =
    useState<AddressValues | undefined>();
  const [
    shippingAddressFormValuesForEdit,
    setShippingAddressFormValuesForEdit,
  ] = useState<AddressValues | undefined>();
  const [isCustomerName, setIsCustomerName] = useState<boolean>(false);
  const [customerData, setCustomerData] =
    useState<AddressValues[] | undefined>();
  const [emailLists, setEmailLists] = useState<string[] | []>([]);
  const [showCustomiseModal, setShowCustomiseModal] = useState<boolean>(false);
  const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);
  const [customerId, setCustomerId] = useState<number>();
  const [currencyId, setCurrencyId] = useState<number | undefined>();
  const [currencyCode, setCurrencyCode] = useState("");
  const [emailData, setEmailData] = useState<{ email: string; id: number }[]>(
    []
  );
  const [deleteEmailIds, setDeleteEmailIds] = useState<{ emailId: number }[]>(
    []
  );
  const [invoiceUrl, setInvoiceUrl] = useState("");
  const [customerIdForEdit, setCustomerIdForEdit] = useState();
  const [invoiceStatus, setInvoiceStatus] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState<any>([]);
  const [formSubmitAction, setFormSubmitAction] = useState(true);
  const [formSubmit, setFormSubmit] = useState(true);
  const [fileIds, setFileIds] = useState<any>([]);
  const [insertedFiles, setInsertedFiles] = useState<FilesValues[]>([]);
  const fileRef = useRef<any>([]);
  const [conversionDate, setConversionDate] = useState(
    getFormatedDate(new Date())
  );
  const [newConversionDate, setNewConversionDate] = useState<string>("");
  const [invoiceValuesForEdit, setInvoiceValuesForEdit] =
    useState<InvoiceValues>(initialInvoiceValues);
  const [conversionCurrencyId, setConversionCurrencyId] = useState<number>();
  const [selectedBillingAddressId, setSelectedBillingAddressId] =
    useState<number | string>(0);
  const [selectedShippingAddressId, setSelectedShippingAddressId] =
    useState<number | string>(0);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [addressId, setAddressId] = useState<number | string>("");
  const [customerBillingAddressId, setCustomerBillingAddressId] = useState(0);
  const [customerShippingAddressId, setCustomerShippingAddressId] = useState(0);
  const [isBillingAddressSame, setIsBillingAddressSame] = useState(false);
  const [isShippingAddressSame, setIsShippingAddressSame] = useState(false);
  const [customFields, setCustomFields] = useState<ObjectType>({});
  const [totalTdsValues, setTotalTdsValues] = useState<TDSTotalValues>(
    initialTDSTotalValues
  );
  const [totalTcsValues, setTotalTcsValues] = useState<TCSTotalValues>(
    initialTCSTotalValues
  );
  const [tdsIds, setTdsIds] = useState<number[]>([]);
  const [tcsIds, setTcsIds] = useState<number[]>([]);
  const [isRoundOffEnabled, setIsRoundOffEnabled] = useState(false);
  const [isTotalEditable, setIsTotalEditable] = useState(false);

  const [stateType, setStateType] = useState("");
  const [initialStateType, setInitialStateType] = useState("");
  const [supplyState, setSupplyState] = useState("");
  const [sourceTaxType, setSourceTaxType] = useState(sourceTaxTypes.TCS);
  const [totalAmount, setTotalAmount] = useState(0);
  const [unableToSaveModalTriggered, setUnableToSaveModalTriggered] =
    useState<number>();
  const [transactionTaxDetails, setTransactionTaxDetails] =
    useState<[{ is_group: boolean; tax_id: number }]>();
  const [isSez, setIsSez] = useState(true);
  const [gstRegistrationType, setGstRegistrationType] = useState("");
  const custom_fields = useRef<ObjectType>({});
  const customFieldRef = useRef<any>();
  const unableToSaveModalRef = useRef<any>();
  const { isGstOrg } = useGst();
  const [editTotal, setEditTotal] = useState(null);
  const [calculatedTotal, setCalculatedTotal] = useState(0);
  const [isCustomerChanged, setIsCustomerChanged] = useState(false);
  const [isStateChanged, setIsStateChanged] = useState(false);

  const [didAlertModalOpen, setDidAlertModalOpen] = useState(false);
  const [buttonAction, setButtonAction] = useState<{
    status: string;
    action: string;
  }>({ status: "", action: "" });
  const openEditAlertRef = useRef<any>();
  const [customerSalesDefault, setCustomerSalesDefault] = useState(null);
  const [emailFormErrors, setEmailFormErrors] = useState("");

  /**
   * To check whether invoice address is same as customer address
   */

  useEffect(() => {
    if (editId) {
      let updatedAddress = addressList.map((address: any) => {
        return {
          address: address.address,
          city: address.city,
          country_id: address.country_id,
          is_billing: address.is_billing,
          is_shipping: address.is_shipping,
          phone: address.phone,
          state_id: address.state_id,
          zip_code: address.zip_code,
        };
      });
      let billingAddress = { ...billingAddressFormValues };
      let shippingAddress = { ...shippingAddressFormValues };
      delete billingAddress.country_name;
      delete billingAddress.customer_address_id;
      delete billingAddress.id;
      delete billingAddress.state_name;
      delete shippingAddress.country_name;
      delete shippingAddress.customer_address_id;
      delete shippingAddress.id;
      delete shippingAddress.state_name;
      setIsBillingAddressSame(false);
      setIsShippingAddressSame(false);
      for (let address of updatedAddress) {
        if (address.is_billing) {
          if (_.isEqual(address, billingAddress)) {
            setSelectedBillingAddressId(customerBillingAddressId);
            setIsBillingAddressSame(true);
          }
        }
        if (address.is_shipping) {
          if (_.isEqual(address, shippingAddress)) {
            setSelectedShippingAddressId(customerShippingAddressId);
            setIsShippingAddressSame(true);
          }
        }
      }
    }
  }, [addressList]);

  useEffect(() => {
    dispatch(setLoaderState(true));
    if (!editId) {
      setIsCustomerName(false);
      getRoundOffPreference();
    }
    if (editId) {
      invoiceDetailsResponse();
    }
    if (defaultFormValues.customer_id) {
      fetchConsumerDetail(Number(defaultFormValues.customer_id));
    }
    dispatch(addressReset());
  }, [currentUserInfo.organization_id]);

  useEffect(() => {
    unableToSaveModalTriggered &&
      unableToSaveModalRef.current?.openAlertModal &&
      unableToSaveModalRef.current?.openAlertModal();
  }, [unableToSaveModalTriggered]);

  const getRoundOffPreference = async () => {
    const response = await dispatch(
      fetchRoundOffPreference({
        orgId: currentUserInfo.organization_id,
      })
    );
    let payload = response.payload;
    setIsRoundOffEnabled(payload.is_enabled);
    setIsTotalEditable(payload.is_total_editable);
  };

  const openAddressModal = () => {
    setShowAddressModal(true);
  };
  const closeAddressModal = () => {
    setShowAddressModal(false);
  };
  /**
   * Get invoice details for the edit invoice purpose.
   */
  const invoiceDetailsResponse = async () => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      invoiceDetails({
        invoiceId: Number(editId),
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 500);
      ($(".selectpicker") as any).selectpicker("refresh");
      const response = responseAction.payload;
      const isSameOrg =
        response.organization_id === currentUserInfo.organization_id;
      const isLocked = response.is_locked;
      if (
        Object.keys(response).length &&
        !("error" in response) &&
        isSameOrg &&
        !isLocked
      ) {
        let defaultValues = {
          customer_id: response.customer_id,
          customer_name: response.customer_name,
          invoice_number: response.invoice_number,
          invoice_date: getFormatedDate(response.invoice_date),
          payment_term_id: response.payment_term_id,
          due_date: getFormatedDate(response.due_date),
          tag_ids: response.tags.map((tag: any) => tag.id),
          currency_id: response.currency_id,
          reference_number: response.reference_number,
          tds_level: response.tds_level,
          source_tax_type: response.source_tax_type,
          place_of_supply: response.place_of_supply,
          invoice_status: response.invoice_status,
          is_mutable: response.is_mutable,
          customer_exemption_type: response.customer_exemption_type,
          customer_exemption_id: response.customer_exemption_id,
          customer_exemption_reason: response.customer_exemption_reason,
          conversion_id: response.conversion_id,
        };
        setSourceTaxType(response.source_tax_type);
        let otherValues = {
          customer_notes: response.customer_notes,
          account_id: response.account_id,
          conversion_id: response.conversion_id,
          file_ids: response.sales_invoice_files.map((file: any) => file.id),
          email_to: response.email_to,
          tds_percentage: response.total_details.tds_percentage,
          is_locked: response.is_locked,
          lock_date: response.lock_date,
        };
        let totalTdsValue = {
          tds_id: response.tds_id,
          tds_account_id: response.tds_account_id,
          tds_percentage: response.tds_percentage,
          tds_amount: response.tds_amount,
          tds_name: response.tds_name,
        };
        let totalTcsValue = {
          tcs_id: response.tcs_id,
          tcs_account_id: response.tcs_account_id,
          tcs_percentage: response.tcs_percentage,
          tcs_amount: response.tcs_amount,
          tcs_name: response.tcs_name,
        };
        setTotalTdsValues(totalTdsValue);
        setTotalTcsValues(totalTcsValue);
        setUploadedFiles(response.sales_invoice_files);
        setFileIds(response.sales_invoice_files.map((file: any) => file.id));
        setInsertedFiles(
          response.sales_invoice_files.map((file: any) => file.id)
        );
        let customerDetails = await getCustomerDetails(
          response.customer_id,
          "invoice"
        );

        const preferenceResponse = await dispatch(
          fetchRoundOffPreference({
            orgId: currentUserInfo.organization_id,
            roundoff_transaction_type: "Sales",
          })
        );
        let preferencePayload = preferenceResponse.payload;

        setIsRoundOffEnabled((prevValue) => {
          return response?.roundoff_enabled || preferencePayload.is_enabled
            ? true
            : false;
        });

        setIsTotalEditable((prevValue) => {
          if (response?.roundoff_enabled || preferencePayload.is_enabled) {
            if (response?.roundoff_enabled) {
              return response?.is_total_editable;
            } else {
              return preferencePayload.is_total_editable;
            }
          } else {
            return false;
          }
        });

        if (isGstOrg) {
          let stateType =
            !response.place_of_supply || !currentUserInfo.organization_gst_state
              ? ""
              : response.place_of_supply ===
                currentUserInfo.organization_gst_state
              ? "Intra State"
              : "Inter State";
          if (
            customerDetails.gst_registration_type ===
              gstRegistrationTypes.sez ||
            customerDetails.gst_registration_type ===
              gstRegistrationTypes.sezDeveloper ||
            customerDetails.gst_registration_type ===
              gstRegistrationTypes.overseas
          ) {
            stateType = "Inter State";
          }
          setStateType(stateType);
          setInitialStateType(stateType);

          let transaction_tax_details = [] as any;
          response.item_details.forEach((item: any) => {
            let gst_tax = item.gst_tax;
            if (gst_tax?.tax_id) {
              transaction_tax_details.push({
                is_group: gst_tax?.is_group ? true : false,
                tax_id: gst_tax?.tax_id,
              });
            }
          });

          setTransactionTaxDetails(transaction_tax_details);

          await fetchGstTaxList(
            stateType,
            customerDetails.gst_registration_type,
            transaction_tax_details
          );
          // setIsSez((prevValue) => {
          //   return customerDetails.gst_registration_type === gstRegistrationTypes.sez ? true : false
          // });
          setGstRegistrationType(customerDetails.gst_registration_type);
          setCustomerSalesDefault(customerDetails.sales_default);
        }
        setConversionDate(getFormatedDate(response.invoice_date));
        setInvoiceStatus(response.invoice_status);
        setCustomerId(response.customer_id);
        setCustomerIdForEdit(response.customer_id);
        setCurrencyCode(response.currency_code);
        setCurrencyId(response.currency_id);
        setCustomFields(response.custom_fields);
        custom_fields.current = response.custom_fields;
        setConversionCurrencyId(response.currency_id);
        setInvoiceFormValues({ ...defaultValues, ...otherValues });
        setInvoiceValuesForEdit({ ...defaultValues, ...otherValues });
        setDefaultFormValues({ ...defaultValues });
        let addressData = response.address_details;
        addressData.map((values: AddressValues) => {
          if (values.is_billing) {
            setBillingAddressFormValues(values);
            setBillingAddressFormValuesForEdit(values);
            if (values.customer_address_id) {
              setCustomerBillingAddressId(values.customer_address_id);
            }
          }
          if (values.is_shipping) {
            setShippingAddressFormValues(values);
            setShippingAddressFormValuesForEdit(values);
            if (values.customer_address_id) {
              setCustomerShippingAddressId(values.customer_address_id);
            }
          }
        });
        let itemDetails = response.item_details;
        let tdsIdArray: number[] = [];
        itemDetails.map((item: any, index: number) => {
          if (item.discount_type === "Absolute") {
            item.discount = item.discount.discount_value;
          } else if (item.discount_type === "Percentage") {
            item.discount = item.discount.discount_percentage;
          }
          if (!item.taxes.length) {
            item.taxes = [initialTaxValues];
          }
          if (item.tds_id) {
            tdsIdArray.push(item.tds_id);
          }
        });
        if (itemDetails.length) {
          setItemFormValues(itemDetails);
          // setTaxFormValues(itemDetails);
        } else {
          setItemFormValues([initialItemValues]);
          // setTaxFormValues([initialItemValues]);
        }
        if (response.tds_id) {
          tdsIdArray.push(response.tds_id);
        }
        setTdsIds(tdsIdArray);
        let tcsIdArray: number[] = [];
        if (response.tcs_id) {
          tcsIdArray.push(response.tcs_id);
        }
        setTcsIds(tcsIdArray);
        let totalDetails = response.total_details;
        setTotalFormValues(totalDetails);
        let emailContacts = response.email_to;
        let contactEmail: string[] = [];
        let emailDetails: any[] = [];
        emailContacts.map((contact: any) => {
          contactEmail.push(contact.email);
          emailDetails.push(contact);
        });
        setEmailLists(contactEmail);
        setEmailData(emailDetails);
        setEditTotal(response?.total);
      } else {
        if (isLocked) {
          navigate(-1);
          ErrorToaster(
            `Transactions before ${DateFormatHandler(
              response.lock_date
            )} have been locked. Hence action cannot be performed`
          );
        } else {
          dispatch(setErrorID(noAccessErrorCodes.invoiceEdit));
        }
      }
    }
  };
  /**
   * Check invoice number already exist
   */
  const checkInvoiceNumberExist = async (invoiceNumber: string) => {
    if (!invoiceNumber.includes("undefined")) {
      if (interval) {
        clearTimeout(interval);
      }
      interval = setTimeout(async () => {
        const responseAction = await dispatch(
          checkInvoiceNumber({
            invoiceNumber: invoiceNumber,
            orgId: currentUserInfo.organization_id,
            invoiceId: editId ? Number(editId) : 0,
          })
        );
        if (responseAction.payload) {
          const response = responseAction.payload;
          if (Object.keys(response).length && !("error" in response)) {
            if (response.invoice_number_available === false) {
              setIsInvoiceNumberExist(true);
              setFormErrors({
                ...formErrors,
                ["invoice_number"]:
                  "Invoice number already exists. Please choose a different one",
              });
            } else {
              setIsInvoiceNumberExist(false);
              setFormErrors({ ...formErrors, ["invoice_number"]: "" });
            }
          }
        }
      }, 1000);
    }
  };
  /**
   * Set invoice default form values
   */
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setInvoiceFormValues({ ...invoiceFormValues, [name]: value });
  };

  const handleNewConversionDate = (value: string) => {
    setNewConversionDate(value);
  };

  const handleCustomSubmission = async () => {
    await customFieldRef.current.handleSubmit();
  };

  const asynchronousSubmit = async (status: string, action: string) => {
    await handleCustomSubmission();
    setButtonAction({ status: status, action: action });
    handleSubmit(status, action);
  };

  const handleCustomerChange = async (e: any) => {
    if (e.value && e.value !== customerIdForEdit) {
      setIsCustomerChanged(true);
      let response = await getCustomerDetails(Number(e.value), "customer");
      let state_type = "";
      if (
        response.gst_registration_type === gstRegistrationTypes.sez ||
        response.gst_registration_type === gstRegistrationTypes.sezDeveloper ||
        response.gst_registration_type === gstRegistrationTypes.overseas ||
        response.other_details.tax_preference === "NON_TAXABLE"
      ) {
        state_type = "Inter State";
      } else {
        state_type = stateType;
      }

      if (state_type !== "") {
        await fetchGstTaxList(
          state_type,
          response.gst_registration_type,
          transactionTaxDetails
        );
      } else {
        dispatch(clearGstTaxList());
      }
      setGstRegistrationType(response.gst_registration_type);
      setIsCustomerName(true);
      setCustomerId(Number(e.value));
      setBillingAddressFormValues(undefined);
      setShippingAddressFormValues(undefined);
      setCustomerSalesDefault(response.sales_default);
      setDefaultFormValues((previousValues) => {
        return {
          ...previousValues,
          payment_term_id: response.sales_default.payment_term_id,
        };
      });
    } else {
      getCustomerDetails(Number(e.value), "invoice");
      setInvoiceFormValues(invoiceValuesForEdit);
      setBillingAddressFormValues(billingAddressFormValuesForEdit);
      setShippingAddressFormValues(shippingAddressFormValuesForEdit);
      setCustomerId(customerIdForEdit);

      let emailContacts = invoiceValuesForEdit.email_to;
      let contactEmail: string[] = [];
      let emailDetails: any[] = [];
      let removeEmails: { emailId: number }[] = deleteEmailIds;
      emailContacts.map((contact: any) => {
        contactEmail.push(contact.email);
        emailDetails.push(contact);
        removeEmails = removeEmails.filter(
          (email) => email.emailId !== contact.id
        );
      });
      setEmailLists(contactEmail);
      setEmailData(emailDetails);
      setDeleteEmailIds(removeEmails);
      setCurrencyId(Number(invoiceValuesForEdit.currency_id));
    }
    setSelectedBillingAddressId(0);
    setSelectedShippingAddressId(0);
  };

  useEffect(() => {
    customerId &&
      invoiceStatus === "Draft" &&
      getCustomerDetails(customerId, "invoice");
  }, [customerId]);

  /**
   * Store customer email contacts to delete if customer name is changed on Edit invoice
   */
  useEffect(() => {
    if (editId) {
      emailData.map((contact) => {
        setDeleteEmailIds((prevItems) => [
          ...prevItems,
          { emailId: contact.id },
        ]);
      });
    }
  }, [isCustomerName]);

  const setErrorOnItemTaxChange = (errors: CustomerItemValues[]) => {
    setItemFormErrors([...errors]);
  };

  /**
   * Fetch customer details by id
   */
  const getCustomerDetails = async (customerId: number, type: string) => {
    const responseAction = await dispatch(
      customerDetailsForTransactions({
        customerId: customerId,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        dispatch(addressReset());
        let addressData = response.address_details;
        addressData.forEach(async (addressDetails: any) => {
          let address = {
            country_id: addressDetails.country_id
              ? addressDetails.country_id
              : 0,
            address: addressDetails.address,
            city: addressDetails.city,
            state_id: addressDetails.state_id ? addressDetails.state_id : 0,
            zip_code: addressDetails.zip_code,
            phone: addressDetails.phone ? addressDetails.phone : null,
            is_billing: addressDetails.is_billing,
            is_shipping: addressDetails.is_shipping,
            is_primary: addressDetails.is_primary,
            id: addressDetails.id,
            state_name: addressDetails.state_name,
            country_name: addressDetails.country_name,
          };
          dispatch(addAddress(address));
        });
        if (type === "invoice") {
          setCustomerData(response.address_details);
          let defaultFormValues = {
            customer_exemption_type: response.other_details.tax_preference,
            customer_exemption_id: response.other_details.exemption_reason_id,
          };
          if (defaultRef.current?.defaultData) {
            setDefaultFormValues((prevData) => {
              return {
                ...prevData,
                ...defaultRef.current?.defaultData,
                ...defaultFormValues,
              };
            });
            setCustomerSalesDefault(response.sales_default);
          }
        } else {
          setCustomerData(response.address_details);
          if (defaultRef.current?.defaultData) {
            const exemptionType =
              response.other_details.tax_preference === "Non-Taxable"
                ? "NON_TAXABLE"
                : response.other_details.tax_preference === "Taxable"
                ? "TAXABLE"
                : "";
            let defaultFormValues = {
              ...defaultRef.current?.defaultData,
              tag_ids: response.other_details.tags.map((tag: any) => tag.id),
              payment_term_id: response.other_details.payment_term_id,
              currency_id: response.other_details.currency_id,
              customer_exemption_type: response.other_details.tax_preference,
              customer_exemption_id: response.other_details.exemption_reason_id,
            };
            setDefaultFormValues({ ...defaultFormValues });
          }
          if (response.other_details.payment_term_id)
            defaultRef.current?.calculateDueDate(
              response.other_details.payment_term_id,
              new Date(defaultRef.current?.defaultData.invoice_date)
            );

          let contacts = response.contact_persons;
          let contactEmail: string[] = [];
          contacts.map((contact: any) => {
            if (contact.email) contactEmail.push(contact.email);
          });
          setEmailLists(contactEmail);
          setCurrencyId(response.other_details.currency_id);
        }
      }
      return responseAction.payload;
    }
  };

  const getTdsApplyLevel = () => {
    const itemDetails = itemRef.current?.itemValues
      .filter((item) => item.tds_id)
      .map((item) => item);
    if (itemRef.current?.totalTcsValues.tcs_id) {
      return tdsApplyLevel.NO_TDS;
    }
    if (itemRef?.current?.totalTdsValues.tds_id) {
      return tdsApplyLevel.TRANSACTION_LEVEL;
    }
    if (itemDetails?.length) {
      return tdsApplyLevel.LINE_ITEM_LEVEL;
    }
    if (
      tdsPreference.is_enabled &&
      tdsPreference.support_tds_for.includes("Customers")
    ) {
      if (tdsPreference.apply_tds_at === "Transaction level") {
        return itemRef.current?.totalTdsValues.tds_id
          ? tdsApplyLevel.TRANSACTION_LEVEL
          : tdsApplyLevel.NO_TDS;
      }
      if (tdsPreference.apply_tds_at === "Line item level") {
        return itemDetails?.length
          ? tdsApplyLevel.LINE_ITEM_LEVEL
          : tdsApplyLevel.NO_TDS;
      }
    }
    return tdsApplyLevel.NO_TDS;
  };

  const getSourceTaxType = () => {
    const itemDetails = itemRef.current?.itemValues
      .filter((item) => item.tds_id)
      .map((item) => item);

    if (itemRef.current?.totalTcsValues.tcs_id) {
      return sourceTaxTypes.TCS;
    }
    if (itemRef.current?.totalTdsValues.tds_id || itemDetails?.length) {
      return sourceTaxTypes.TDS;
    }
    return sourceTaxTypes.NO_SOURCE_TAX;
  };

  /**
   * Handle form submit and set form validation errors
   */
  const handleSubmit = (status: string, action: string) => {
    dispatch(setListFilters({}));
    let defaultValues = defaultRef.current?.defaultData;
    if (!editId && defaultValues) {
      defaultValues.due_date = defaultRef.current?.dueDate
        ? defaultRef.current?.dueDate
        : "";
    }
    let itemValues: CustomerItemValues[] = itemRef.current?.itemValues
      ? itemRef.current?.itemValues
      : itemFormValues;
    let totalTdsValues = itemRef.current?.totalTdsValues;
    let totalTcsValues = itemRef.current?.totalTcsValues;
    let invoiceInputs: any = {
      ...invoiceFormValues,
      ...defaultValues,
      ...totalTdsValues,
      ...totalTcsValues,
      custom_fields: custom_fields.current.values,
      tds_level: getTdsApplyLevel(),
      source_tax_type: getSourceTaxType(),
    };
    let errors,
      itemErrorResponse: any = [];
    let itemErrors: any = [];

    errors = validate(
      invoiceInputs,
      action,
      invoiceStatus,
      emailLists,
      isInvoiceNumberExist,
      itemValues,
      fileRef,
      isGstOrg,
      gstRegistrationType,
      totalAmount,
      emailFormErrors
    );
    if (errors) {
      ScrollToError(Object.keys(errors)[0]);
    }
    let errorExist = false;
    let taxValues = itemRef.current?.taxValues
      ? itemRef.current?.taxValues
      : taxFormValues;
    itemValues.map((item, itemIndex) => {
      itemErrorResponse = validateItemValues(
        invoiceInputs,
        item,
        itemIndex,
        invoiceStatus,
        taxValues,
        gstRegistrationType
      );
      if (itemErrorResponse) {
        if (itemErrorResponse) {
          ScrollToError(Object.keys(itemErrorResponse)[0]);
        }
        errorExist = true;
        itemErrors.push(itemErrorResponse);
      } else {
        itemErrors.push({});
      }
    });
    // console.log(formErrors.email);
    // if (
    //   emailFormErrors === "" &&
    //   emailLists?.length === 0 &&
    //   action === "Send"
    // ) {
    //   setEmailFormErrors("Please enter at least one email address");
    // } else if (emailLists?.length === 0) {
    //   setEmailFormErrors("");
    // }
    // if (formErrors.email) {
    //   setEmailFormErrors(formErrors.email);
    // }
    if (
      Object.keys(errors).length ||
      (errorExist && itemErrors.length) ||
      customFieldRef.current.errors ||
      emailFormErrors !== ""
    ) {
      setFormErrors({
        ...errors,
        email: emailFormErrors,
      });
      setItemFormErrors(itemErrors);
    } else {
      setFormErrors({});
      setItemFormErrors([]);
      if (editId) {
        if (
          totalAmount <
          Number(totalFormValues.total) - Number(totalFormValues.balance_due)
        ) {
          setUnableToSaveModalTriggered(Math.floor(Math.random() * 1000));
        } else {
          if (editId && currentUserInfo.is_tags_enabled && !didAlertModalOpen) {
            setDidAlertModalOpen(true);
            openEditAlertRef.current?.openAlertModal &&
              openEditAlertRef.current?.openAlertModal();
            return;
          } else {
            UpdateInvoiceSubmit(invoiceInputs, itemValues, status, action);
          }
          // UpdateInvoiceSubmit(invoiceInputs, itemValues, status, action);
        }
      } else {
        createInvoiceSubmit(invoiceInputs, itemValues, status, action);
      }
    }
  };
  /**
   * Create new invoice
   */
  const createInvoiceSubmit = async (
    invoiceInputs: any,
    itemValues: CustomerItemValues[],
    invoiceStatus: string,
    action: string
  ) => {
    dispatch(setLoaderState(true));
    invoiceInputs.conversion_id = exchangeRateValues.id;
    let key: keyof typeof invoiceInputs;
    for (key in invoiceInputs) {
      if (invoiceInputs[key] === "") {
        invoiceInputs[key] = null;
      }
      if (
        key === "invoice_number" &&
        invoiceInputs[key] !== "" &&
        invoiceInputs[key] !== null
      ) {
        invoiceInputs[key] = defaultRef.current?.invoiceNumber;
      }
      if (key === "tds_percentage") {
        invoiceInputs[key] =
          invoiceInputs[key] === "" || invoiceInputs[key] === null
            ? 0
            : Number(invoiceInputs[key]);
      }
      if (key === "reference_number" && invoiceInputs[key]) {
        invoiceInputs[key] = invoiceInputs[key].toString().trim();
      }
      if (isGstOrg && key === "place_of_supply") {
        invoiceInputs[key] = invoiceInputs[key];
      }
      if (key === "tds_amount" || key === "tcs_amount") {
        invoiceInputs[key] =
          invoiceInputs[key] === null ? 0 : invoiceInputs[key];
      }
    }
    $("#form-btn-invoice").addClass("customer-form-section-disable");

    let invoiceItems = JSON.parse(JSON.stringify(itemValues));

    for (let items of invoiceItems) {
      let itemInputs = items;
      if (isGstOrg) {
        // updating gst_id in request payload
        itemInputs.gst_id = itemInputs?.gst_tax?.tax_id
          ? itemInputs?.gst_tax?.tax_id
          : null;
      } else {
        delete itemInputs.gst_id;
        delete itemInputs.gst_tax;
      }

      let itemkey: keyof typeof itemInputs;
      for (itemkey in itemInputs) {
        if (itemInputs[itemkey] === "") {
          if (itemkey === "rate" || itemkey === "quantity") {
            itemInputs[itemkey] = 0;
          } else {
            itemInputs[itemkey] = null;
          }
        }
        if (itemkey === "discount") {
          if (itemInputs[itemkey]?.discount_value == 0) {
            itemInputs[itemkey] = itemInputs[itemkey]?.discount_value;
          } else if (itemInputs[itemkey]?.discount_percentage == 0) {
            itemInputs[itemkey] = itemInputs[itemkey]?.discount_percentage;
          } else {
            itemInputs[itemkey] = Number(itemInputs[itemkey]);
          }
        }
      }

      let itemTax = itemInputs.taxes;
      let taxData: ItemTaxValues[] = [];
      itemTax.map((item: any) => {
        if (item.tax_percentage === "") {
          item.tax_percentage = 0;
        }
        if (item.tax_id !== "" || item.tax_percentage !== 0) {
          taxData.push(item);
        }
      });
      items.taxes = taxData;
    }
    invoiceInputs.items = invoiceItems;
    invoiceInputs.file_ids = fileRef.current.fileIds;
    if (emailLists.length > 0) {
      invoiceInputs.email_to = emailLists;
    }
    let address = [];
    if (billingAddressFormValues?.is_billing === true) {
      let payload: any = billingAddressFormValues;
      if (payload.phone === "") {
        payload.phone = null;
      }
      if (payload.zip_code === "") {
        payload.zip_code = null;
      }
      address.push({
        ...payload,
        customer_address_id: selectedBillingAddressId,
      });
    } else {
      address.push(billingEmptyObject);
    }
    if (shippingAddressFormValues?.is_shipping === true) {
      let payload: any = shippingAddressFormValues;
      if (payload.phone === "") {
        payload.phone = null;
      }
      if (payload.zip_code === "") {
        payload.zip_code = null;
      }
      address.push({
        ...payload,
        customer_address_id: selectedShippingAddressId,
      });
    } else {
      address.push(shippingEmptyObject);
    }
    invoiceInputs.address = address;
    let downloadResult;

    invoiceInputs.total = calculatedTotal;
    invoiceInputs.roundoff_enabled = isRoundOffEnabled;
    invoiceInputs.is_total_editable = isTotalEditable;

    const createInvoiceResponseAction = await dispatch(
      createInvoice({
        values: invoiceInputs,
        status: invoiceStatus,
        isSendMail: action === "Send" ? true : false,
        isPrintOrDownload:
          action === "Download" || action === "Print" ? true : false,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (createInvoiceResponseAction.payload) {
      const createInvoiceResponse = createInvoiceResponseAction.payload;
      if (
        Object.keys(createInvoiceResponse).length &&
        !("error" in createInvoiceResponse)
      ) {
        dispatch(setLoaderState(false));
        if (
          invoiceStatus === "Open" &&
          (action === "Download" || action === "Print")
        ) {
          setInvoiceId(createInvoiceResponse.id);
          setInvoiceUrl(createInvoiceResponse.download_link);
          if (action === "Download") {
            downloadResult = await downloadUsingURL(
              createInvoiceResponse.download_link,
              createInvoiceResponse.id + ".pdf"
            );
          } else if (action === "Print") {
            $("#printInvoiceModal").modal("show");
          }
        }
        if (action !== "Print") {
          if (invoiceStatus === "Draft") navigate(-1);
          else
            navigate(`/invoice/detail/${createInvoiceResponse.id}`, {
              replace: true,
            });
          toast.success("Invoice created successfully!", {
            toastId: "invoice-create-success",
            closeButton: false,
            position: "top-center",
          });
        }
        if (
          invoiceStatus === "Open" &&
          action === "Download" &&
          downloadResult
        ) {
          toast.success("Invoice downloaded successfully!", {
            toastId: "invoice-download-success",
            closeButton: false,
            position: "top-center",
          });
        }
      } else if ("error" in createInvoiceResponse) {
        dispatch(setLoaderState(false));
      } else {
        dispatch(setLoaderState(false));
      }
    }
  };

  const UpdateInvoiceSubmit = async (
    invoiceInputs: any,
    itemValues: CustomerItemValues[],
    invoiceStatus: string,
    action: string
  ) => {
    dispatch(setLoaderState(true));
    invoiceInputs.conversion_id = exchangeRateValues.id;
    if(defaultRef.current?.defaultData?.conversion_id !== exchangeRateValues.id) {
      invoiceInputs.recalculate_exchange_rate = true;
    }
    let key: keyof typeof invoiceInputs;
    for (key in invoiceInputs) {
      if (invoiceInputs[key] === "") {
        invoiceInputs[key] = null;
      }

      if (key === "payment_term_id" && invoiceInputs[key] !== "") {
        if (invoiceInputs[key] === null) {
          invoiceInputs[key] = null;
        } else {
          invoiceInputs[key] = Number(invoiceInputs[key]);
        }
      }

      if (
        key === "invoice_number" &&
        invoiceInputs[key] !== "" &&
        invoiceInputs[key] !== null
      ) {
        invoiceInputs[key] = defaultRef.current?.invoiceNumber;
      }
      if (key === "tds_percentage") {
        invoiceInputs[key] =
          invoiceInputs[key] === "" || invoiceInputs[key] === null
            ? 0
            : Number(invoiceInputs[key]);
      }
      if (key === "reference_number" && invoiceInputs[key]) {
        invoiceInputs[key] = invoiceInputs[key].toString().trim();
      }
      if (isGstOrg && key === "place_of_supply") {
        invoiceInputs[key] = invoiceInputs[key];
      }
      if (key === "tds_amount" || key === "tcs_amount") {
        invoiceInputs[key] =
          invoiceInputs[key] === null ? 0 : invoiceInputs[key];
      }
    }
    $("#form-btn-invoice").addClass("customer-form-section-disable");
    if (editId) {
      let deleteItems = itemRef.current?.deleteItems;
      if (deleteItems) {
        for (let item of deleteItems) {
          if (item.itemId) {
            await dispatch(
              deleteInvoiceItemDetails({
                invoiceId: Number(editId),
                itemId: item.itemId,
                orgId: currentUserInfo.organization_id,
              })
            );
          }
        }
      }
    }
    let deleteTaxItems = itemRef.current?.deleteTaxItems;
    if (deleteTaxItems) {
      for (let item of deleteTaxItems) {
        await dispatch(
          deleteInvoiceItemTaxDetails({
            itemId: item.itemId,
            taxId: item.taxItemId,
            orgId: currentUserInfo.organization_id,
          })
        );
      }
    }
    if (isGstOrg) {
      let deleteGstItems = itemRef.current?.deleteGstItems;
      if (deleteGstItems) {
        for (let item of deleteGstItems) {
          await dispatch(
            deleteInvoiceItemTaxDetails({
              itemId: item.itemId,
              taxId: item.id,
              orgId: currentUserInfo.organization_id,
            })
          );
        }
      }
    }
    let deleteContactIds: number[] = [];
    deleteEmailIds.map((contact) => {
      deleteContactIds.push(contact.emailId);
    });

    if (deleteContactIds.length) {
      await dispatch(
        deleteInvoiceRecipientDetails({
          invoiceId: Number(editId),
          emailIds: deleteContactIds,
          orgId: currentUserInfo.organization_id,
        })
      );
    }

    let invoiceItems = JSON.parse(JSON.stringify(itemValues));
    for (let items of invoiceItems) {
      let itemInputs = items;
      if (isGstOrg) {
        // updating gst_id in request payload
        itemInputs.gst_id = itemInputs?.gst_tax?.tax_id
          ? itemInputs?.gst_tax?.tax_id
          : null;
      } else {
        delete itemInputs.gst_id;
        delete itemInputs.gst_tax;
      }
      let key: keyof typeof itemInputs;

      for (key in itemInputs) {
        if (itemInputs[key] === "") {
          if (key === "rate" || key === "quantity") {
            itemInputs[key] = 0;
          } else {
            itemInputs[key] = null;
          }
        }
        if (key === "discount") {
          if (itemInputs[key]?.discount_value) {
            itemInputs[key] = itemInputs[key]?.discount_value;
          } else if (itemInputs[key]?.discount_percentage) {
            itemInputs[key] = itemInputs[key]?.discount_percentage;
          } else {
            itemInputs[key] = Number(itemInputs[key]);
          }
        }
      }
      let itemTax = itemInputs.taxes;
      let taxData: ItemTaxValues[] = [];
      itemTax.map((item: any) => {
        if (item.tax_percentage === "") {
          item.tax_percentage = 0;
        }
        if (item.tax_id !== "" || item.tax_percentage !== 0) {
          taxData.push(item);
        }
      });

      items.taxes = taxData;
    }
    invoiceInputs.items = invoiceItems;
    invoiceInputs.file_ids = fileRef.current.fileIds;
    invoiceInputs.email_to = emailLists;
    let address = [];
    if (billingAddressFormValues?.is_billing === true) {
      let payload: any = billingAddressFormValues;
      if (payload.phone === "") {
        payload.phone = null;
      }
      if (payload.zip_code === "") {
        payload.zip_code = null;
      }
      address.push({
        ...payload,
        customer_address_id: selectedBillingAddressId,
      });
    } else {
      address.push(billingEmptyObject);
    }
    if (shippingAddressFormValues?.is_shipping === true) {
      let payload: any = shippingAddressFormValues;
      if (payload.phone === "") {
        payload.phone = null;
      }
      if (payload.zip_code === "") {
        payload.zip_code = null;
      }
      address.push({
        ...payload,
        customer_address_id: selectedShippingAddressId,
      });
    } else {
      address.push(shippingEmptyObject);
    }
    invoiceInputs.address = address;

    invoiceInputs.total = calculatedTotal;
    invoiceInputs.roundoff_enabled = isRoundOffEnabled;
    invoiceInputs.is_total_editable = isTotalEditable;

    const updateInvoiceResponseAction = await dispatch(
      editInvoiceDetails({
        id: Number(editId),
        status: invoiceStatus,
        isSendMail: action === "Send" ? true : false,
        isPrintOrDownload:
          action === "Download" || action === "Print" ? true : false,
        editInvoiceInputs: invoiceInputs,
        orgId: currentUserInfo.organization_id,
      })
    );
    const updateInvoiceResponse = updateInvoiceResponseAction.payload;
    if (
      Object.keys(updateInvoiceResponse).length &&
      !("error" in updateInvoiceResponse)
    ) {
      dispatch(setLoaderState(false));
      // if(currentUserInfo.is_tags_enabled === true){
      //   navigate(`/invoice/edit/${updateInvoiceResponse.id}/reporting-tags`, {
      //     replace: true,
      //   });
      //   return;
      // }
      let downloadResult;
      if (
        invoiceStatus === "Open" &&
        (action === "Download" || action === "Print")
      ) {
        setInvoiceId(Number(editId));
        setInvoiceUrl(updateInvoiceResponse.download_link);
        if (action === "Download") {
          downloadResult = await downloadUsingURL(
            updateInvoiceResponse.download_link,
            Number(editId) + ".pdf"
          );
        } else if (action === "Print") {
          $("#printInvoiceModal").modal("show");
        }
      }
      if (action !== "Print") {
        if (invoiceStatus === "Draft") navigate(-1);
        else {
          redirectUrl
            ? navigate(
                `/invoice/detail/${Number(editId)}?redirect=${redirectUrl}`,
                {
                  replace: true,
                }
              )
            : navigate(`/invoice/detail/${Number(editId)}`, { replace: true });
        }
        toast.success("Invoice updated successfully!", {
          toastId: "invoice-update-success",
          closeButton: false,
          position: "top-center",
        });
      }
      if (invoiceStatus === "Open" && action === "Download" && downloadResult) {
        toast.success("Invoice downloaded successfully!", {
          toastId: "invoice-download-success",
          closeButton: false,
          position: "top-center",
        });
      }
    } else if ("error" in updateInvoiceResponse) {
      dispatch(setLoaderState(false));
    } else {
      dispatch(setLoaderState(false));
    }
  };

  $("#printInvoiceModal").on("hidden.bs.modal", function () {
    redirectUrl
      ? navigate(
          `/invoice/detail/${Number(invoiceId)}?redirect=${redirectUrl}`,
          {
            replace: true,
          }
        )
      : navigate(`/invoice/detail/${Number(invoiceId)}`, { replace: true });
    if (invoiceId) {
      toast.success("Invoice updated successfully!", {
        toastId: "invoice-update-success",
        closeButton: false,
        position: "top-center",
      });
    } else {
      toast.success("Invoice created successfully!", {
        toastId: "invoice-create-success",
        closeButton: false,
        position: "top-center",
      });
    }
  });

  const setEmailHandler = (email: string[]) => {
    setEmailLists(email);
  };

  const handleAddressFormValues = async (values: AddressValues) => {
    if (values.is_billing === true) {
      setBillingAddressFormValues(values);
    }
    if (values.is_shipping === true) {
      setShippingAddressFormValues(values);
    }
  };

  useEffect(() => {
    customerData?.map((item: any) => {
      if (
        item.is_billing === true &&
        ((item.is_primary && selectedBillingAddressId === 0) ||
          item.id === selectedBillingAddressId) &&
        (item.country_id !== null ||
          item.address !== null ||
          item.city !== null ||
          item.state_id !== null ||
          item.zip_code !== null ||
          item.phone !== null)
      ) {
        if (editId && customerId === customerIdForEdit) {
          if (!isBillingAddressSame) setSelectedBillingAddressId(0);
          const billingAddressCopy = { ...billingAddressFormValuesForEdit };
          delete billingAddressCopy.id;
          delete billingAddressCopy.is_billing;
          delete billingAddressCopy.is_shipping;
          const isNullish = Object.values(billingAddressCopy).every((value) => {
            if (value === null) {
              return true;
            }
            return false;
          });
          if (!isNullish) {
            setBillingAddressFormValues(billingAddressFormValuesForEdit);
          } else {
            setSelectedBillingAddressId(item.id);
            setBillingAddressFormValues(item);
          }
        } else {
          setSelectedBillingAddressId(item.id);
          setBillingAddressFormValues(item);
        }
      }
      if (
        item.is_shipping === true &&
        ((item.is_primary && selectedShippingAddressId === 0) ||
          item.id === selectedShippingAddressId) &&
        (item.country_id !== null ||
          item.address !== null ||
          item.city !== null ||
          item.state_id !== null ||
          item.zip_code !== null ||
          item.phone !== null)
      ) {
        if (editId && customerId === customerIdForEdit) {
          if (!isShippingAddressSame) setSelectedShippingAddressId(0);
          const shippingAddressCopy = { ...shippingAddressFormValuesForEdit };
          delete shippingAddressCopy.id;
          delete shippingAddressCopy.is_billing;
          delete shippingAddressCopy.is_shipping;
          const isNullish = Object.values(shippingAddressCopy).every(
            (value) => {
              if (value === null) {
                return true;
              }
              return false;
            }
          );
          if (!isNullish) {
            setShippingAddressFormValues(shippingAddressFormValuesForEdit);
          } else {
            setSelectedShippingAddressId(item.id);
            setShippingAddressFormValues(item);
          }
        } else {
          setSelectedShippingAddressId(item.id);
          setShippingAddressFormValues(item);
        }
      }
    });
  }, [customerData]);

  /**
   * Function to set Primary billing address
   */
  const selectBillingAddressHandler = (index: number | undefined | string) => {
    if (index) {
      setSelectedBillingAddressId(index);
    }
  };
  /**
   * Function to set Primary shipping address
   */
  const selectShippingAddressHandler = (index: number | undefined | string) => {
    if (index) {
      setSelectedShippingAddressId(index);
    }
  };

  const showInvoicePreview = () => {
    navigate("../invoice-detail");
  };

  /**
   * Function to store emailIds to be deleted in Edit invoice
   */
  const removeEmailHandler = (email: string) => {
    emailData.map((contact) => {
      if (contact.email === email) {
        setDeleteEmailIds((prevItems) => [
          ...prevItems,
          { emailId: contact.id },
        ]);
      }
    });
  };

  /**
   * function to get taxable amount
   */
  const amountCalculation = async (data: {
    values: TotalAmountCalculationValues;
    orgId: number;
    signal: Object;
  }) => {
    const responseAction = await dispatch(getAmountCalculation(data));
    return responseAction;
  };
  /**
   * function to get taxable amount
   */
  const taxableAmount = async (data: {
    values: CustomerItemValues;
    orgId: number;
    signal: Object;
  }) => {
    const taxResponseAction = await dispatch(getTaxableAmount(data));
    return taxResponseAction;
  };

  /**
   * function to get upload files
   */
  const uploadAttachedFiles = async (file: any) => {
    let formData = new FormData();
    formData.append("invoice_file", file);
    let responseAction = await dispatch(
      attachInvoiceFiles({
        file: formData,
        orgId: currentUserInfo.organization_id,
      })
    );
    return responseAction;
  };

  /**
   * Remove uploaded files
   */
  const removeFile = async (fileId: any) => {
    if (insertedFiles.includes(fileId)) {
      await dispatch(
        deleteInvoiceFile({
          invoiceId: Number(editId),
          fileId,
          orgId: currentUserInfo.organization_id,
        })
      );
    }
    let defaultValues = defaultRef.current?.defaultData;
    let itemValues: CustomerItemValues[] = itemRef.current?.itemValues
      ? itemRef.current?.itemValues
      : itemFormValues;
    let totalTdsValues = itemRef.current?.totalTdsValues;
    let totalTcsValues = itemRef.current?.totalTcsValues;
    let invoiceInputs: any = {
      ...invoiceFormValues,
      ...defaultValues,
      ...totalTdsValues,
      ...totalTcsValues,
    };
    let errors = validate(
      invoiceInputs,
      "",
      "",
      emailLists,
      isInvoiceNumberExist,
      itemValues,
      fileRef,
      isGstOrg,
      gstRegistrationType,
      totalAmount
    );
    if (Object.keys(errors).length) {
      setFormErrors(errors);
    } else {
      setFormErrors({});
    }
  };

  useEffect(() => {
    let onProgressFiles = uploadedFiles.filter(
      (files: any) => files.progressInfo === 0 && files.status !== "removed"
    ).length;
    if (onProgressFiles) {
      setFormSubmit(false);
    } else {
      setFormSubmit(true);
    }
  }, [uploadedFiles]);

  const handleSubmitAction = (status: boolean) => {
    if (status) {
      setFormSubmitAction(false);
    } else {
      setFormSubmitAction(true);
    }
  };

  /**
   * set address ID handler
   */
  const setAddressIdHandler = (id: number | string) => {
    setAddressId(id);
  };

  const fetchConsumerDetail = (customerId: number) => {
    getCustomerDetails(customerId, "invoice");
  };

  const fetchInvoiceAfterDelete = async () => {
    await dispatch(
      invoiceList({
        page: 1,
        itemsPerPage: 10,
        dateSortOrder: "ASC",
        orgId: currentUserInfo.organization_id,
        orgIds: [],
        statusList: [],
        customerList: [],
      })
    );
    redirectUrl ? navigate(redirectUrl) : replace ? navigate(-2) : navigate(-1);
  };

  const getCustomFieldData = (customFieldData: ObjectType) => {
    custom_fields.current = customFieldData;
    setCustomFields(customFieldData.values);
  };
  const fetchGstTaxList = async (
    stateType: string,
    gstRegType?: string,
    transaction_tax_details?: [{ is_group: boolean; tax_id: number }]
  ) => {
    if (!transaction_tax_details && transactionTaxDetails) {
      transaction_tax_details = transactionTaxDetails;
    }
    if (
      gstRegType === gstRegistrationTypes.sez ||
      gstRegType === gstRegistrationTypes.sezDeveloper ||
      gstRegType === gstRegistrationTypes.overseas
    ) {
      stateType = "Inter State";
    }
    dispatch(clearGstTaxList());
    await dispatch(
      getGstTaxList({
        stateType: stateType,
        orgId: currentUserInfo.organization_id,
        is_sez: false,
        transaction_tax_details,
        transactionSource: "Invoices",
      })
    );
  };

  const getTotalValues = (total: number | string) => {
    setTotalAmount(Number(total));
  };

  const updateCalculatedTotal = (val: any) => {
    setCalculatedTotal(val);
  };
  const handleEmailError = (error: string) => {
    setEmailFormErrors(error);
  };
  return (
    <>
      <div
        className="card card-user-management card-customer card-create-item invoice-create main-card overflowX-hidden h-100"
        id="invoice-create"
      >
        <div className="card-header card-user-management-header card-customer-header card-no-bg-header card-main-header">
          <TransactionHeader
            transactionName="Invoice"
            transactionNumber={Number(editId)}
          />
          {/* hiding the Customize from Header of Create invoice screen as per ticket: https://app.clickup.com/t/85zrmgcae */}

          {/* <ul className="nav nav-pills button-list top-action-lists float-start p-0 m-0">
            <li className="nav-item">
              <button
                className="btn add-customer-button green-button"
                type="button"
                onClick={() => {
                  setShowCustomiseModal(true);
                }}
              >
                <img src={CustomiseIcon} className="Group" />
                <span className="invite-user-button-text green-button-text">
                  Customise
                </span>
              </button>
            </li>
          </ul> */}
        </div>
        <div className="card-body">
          <form
            name="add_invoice_form"
            id="add_invoice_form"
            className={`add-module-item-form ${
              defaultRef.current?.defaultData?.customer_id ? "" : " inactive"
            }`}
            tabIndex={-1}
          >
            <div className="fields-wrapper pt-0">
              <div className="fields-wrappers">
                <DefaultDetails
                  defaultFormValues={defaultFormValues}
                  invoiceFormValues={invoiceFormValues}
                  handleCustomerChange={handleCustomerChange}
                  checkInvoiceNumberExist={checkInvoiceNumberExist}
                  formErrors={formErrors}
                  organizationId={currentUserInfo.organization_id}
                  handleNewConversionDate={handleNewConversionDate}
                  ref={defaultRef}
                  customFieldRef={customFieldRef}
                  getCustomFieldData={getCustomFieldData}
                  customFieldValue={customFields}
                  setStateType={setStateType}
                  fetchGstTaxList={fetchGstTaxList}
                  setSupplyState={setSupplyState}
                  initialStateType={initialStateType}
                  gstRegistrationType={gstRegistrationType}
                  setIsStateChanged={setIsStateChanged}
                />
                {(isCustomerName || editId) && (
                  <InvoiceAddress
                    billingAddressFormValues={billingAddressFormValues}
                    shippingAddressFormValues={shippingAddressFormValues}
                    customerData={customerData}
                    createBillingAddress={(values) =>
                      handleAddressFormValues(values)
                    }
                    createShippingAddress={(values) =>
                      handleAddressFormValues(values)
                    }
                    selectBillingAddressHandler={selectBillingAddressHandler}
                    selectShippingAddressHandler={selectShippingAddressHandler}
                    selectedBillingAddressId={selectedBillingAddressId}
                    selectedShippingAddressId={selectedShippingAddressId}
                    openAddressModal={openAddressModal}
                    setAddressIdHandler={setAddressIdHandler}
                    customerId={customerId}
                    customerIdForEdit={customerIdForEdit}
                  />
                )}
                <ItemDetails
                  formValues={invoiceFormValues}
                  itemFormValues={itemFormValues}
                  totalFormValues={totalFormValues}
                  formErrors={formErrors}
                  itemFormErrors={itemFormErrors}
                  currencyId={currencyId}
                  currencyCode={currencyCode}
                  amountCalculation={amountCalculation}
                  taxableAmount={taxableAmount}
                  isHsnCode={true}
                  taxType="payable"
                  lineItemBaseAccount="Incomes"
                  discountBaseAccount="Expenses"
                  conversionDate={conversionDate}
                  newConversionDate={newConversionDate}
                  conversionCurrencyId={conversionCurrencyId}
                  userId={customerId}
                  componentType={"invoice"}
                  setErrorOnItemTaxChange={setErrorOnItemTaxChange}
                  totalTdsValues={totalTdsValues}
                  totalTcsValues={totalTcsValues}
                  sourceTaxType={sourceTaxType}
                  tdsIds={tdsIds}
                  tcsIds={tcsIds}
                  ref={itemRef}
                  stateType={stateType}
                  supplyState={supplyState}
                  getTotalValue={getTotalValues}
                  isSez={isSez}
                  gstRegistrationType={gstRegistrationType}
                  isRoundOffEnabled={isRoundOffEnabled}
                  isTotalEditable={isTotalEditable}
                  editTotal={editTotal}
                  updateCalculatedTotal={updateCalculatedTotal}
                  isCustomerVendorChanged={isCustomerChanged}
                  isStateChanged={isStateChanged}
                  customerDetails={defaultFormValues}
                  consumerId={customerId}
                  consumerTradeDefault={customerSalesDefault}
                >
                  <NoteTextArea
                    label="Customer Notes"
                    name="customer_notes"
                    id="customerNotes"
                    className="notes-textarea"
                    placeholder="Enter notes here..."
                    value={invoiceFormValues.customer_notes}
                    onChange={handleChange}
                    formErrors={formErrors}
                  />
                </ItemDetails>
                <section id="upload_section">
                  <div className="upload-header pb-3">Attach Files</div>
                  <DropZone
                    uploadedFiles={uploadedFiles}
                    uploadFileIds={fileIds}
                    uploadAttachedFiles={uploadAttachedFiles}
                    removeFile={removeFile}
                    handleSubmitAction={handleSubmitAction}
                    ref={fileRef}
                  />
                  <span className="error">{formErrors.attachFiles}</span>
                </section>
                {/* hidding the Payment details as per ticket: https://app.clickup.com/t/85zrmgcae */}
                {/* <PaymentDerails /> */}
                <div className="email-reciepients-wrapper">
                  <label htmlFor="deposit_to">Email to</label>
                  <br />
                  {formErrors.email}
                  <MultiEmailBox
                    onChange={setEmailHandler}
                    emailList={emailLists}
                    removeEmailHandler={removeEmailHandler}
                    onError={handleEmailError}
                    className={emailFormErrors !== "" ? "email-error" : ""} //
                  />
                  <span className="error">{emailFormErrors}</span>
                </div>
                <OrganizationDetails />
              </div>
              <InvoiceFormFooter
                editId={editId}
                isLocked={invoiceFormValues.is_locked}
                lockDate={invoiceFormValues.lock_date}
                name="Invoice"
                isFormSubmit={formSubmit && formSubmitAction}
                onClickSubmit={asynchronousSubmit}
                rolePermission={invoicesRolePermission}
                isPrint={true}
                isDisabledDraft={
                  (editId && invoiceStatus !== "Draft") ||
                  exchangeRateAPiStatus ||
                  defaultRef.current?.defaultData?.customer_id === ""
                    ? true
                    : false
                }
                isDisabledSave={
                  defaultRef.current?.defaultData?.customer_id === ""
                    ? true
                    : false
                }
                onClickDelete={() => {}}
              />
            </div>
          </form>
        </div>
        {showAddressModal ? (
          <AddressModal
            closeAddressModal={closeAddressModal}
            addressId={addressId}
            createdFrom="invoice_page"
            fetchConsumerDetail={fetchConsumerDetail}
            consumerId={customerId}
            selectedBillingAddressId={selectedBillingAddressId}
            billingAddressFormValues={billingAddressFormValues}
            storeNewAddressIdHandler={() => {}}
          />
        ) : null}
        {showCustomiseModal && (
          <ChooseTemplate
            handleClose={() => {
              setShowCustomiseModal(false);
            }}
            showTemplatePreviewModal={() => {
              setShowPreviewModal(true);
            }}
          ></ChooseTemplate>
        )}
        {showPreviewModal && (
          <ChooseTemplatePreview
            handlePreviewClose={() => {
              setShowPreviewModal(false);
            }}
            open={true}
          ></ChooseTemplatePreview>
        )}
      </div>
      <DeleteInvoice
        deleteInvoiceId={Number(editId)}
        deleteInvoiceItemId={invoiceFormValues.invoice_number}
        refreshInvoices={fetchInvoiceAfterDelete}
        organizationId={currentUserInfo.organization_id}
      />
      <InvoicePrint invoiceId={invoiceId} invoiceUrl={invoiceUrl} />
      <AlertModalPopup
        message="The payment entered is more than the total amount due for this invoice. Please check and retry."
        modalTitle="Unable to save!"
        isCloseButtonEnabled={true}
        isSubmitEnabled={true}
        openRef={unableToSaveModalRef}
      />
      <TransactionEditTagRemovalAlertModal
        module="invoice"
        onCancel={() => {
          setDidAlertModalOpen(false);
        }}
        onSubmit={() => {
          handleSubmit(buttonAction.status, buttonAction.action);
        }}
        openAlertRef={openEditAlertRef}
      />
    </>
  );
}

export default React.memo(InvoiceForm);
