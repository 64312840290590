import { colors } from "../../constants/colors";
import "../styles/AlertModal.css";
import { RadioButtonGroup } from "./radioButtonGroup/radioButtonGroup";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import CustomDatepickerFormic from "../../utils/atoms/datepickerFormic";
import Select from "react-select";
import { SelectCustomStyleCustomer } from "../../common/components/SelectCustomStyleCustomer";
import { getNextDate } from "../../../helpers/subscriptionHelper";
import { getFormatedDate } from "../../../helpers/helper";
import { FormControlLabel, Grid, Switch } from "@mui/material";
import { useState } from "react";
import { DurationTypeDropdown, handlePaidAmountDropdown, manageUnpaidAmountDropdown, unusedCreditsDropdown } from "../../home/subscription/SubscriptionStateInitialization";
import moment from "moment";
import CustomDatepicker from "../../utils/atoms/datepicker";
import { customSelectStyle } from "./SelectCustomStyle";
import { FormSelectField } from "./formSelectField/FormSelectField";
import FormControl from "@mui/material/FormControl";
import AccountSelect from "./paymentFormInputs/AccountSelect";
import { ObjectType } from "../../../types";

type Props = {
  type?: string;
  modalCancelHandler: () => void;
  status: string;
  paymentStatus?: string;
  isUnpaidInvoiceExist?: boolean;
  modalType?: string;
  closeButtonClassName?: string;
  formik: any;
  nextRenewableDate: string;
  cancelFormErrors: ObjectType;
  unpaidAmount: string
};

const VoidModalSubscription = (props: Props) => {
  
  const onFormSelectChange = (value: any, name: any) => {
    props.formik.setFieldValue(name.name, value.value);
  };


  const handleDate = (date: Date | null) => {
    if (date) {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const targetDate = new Date(date);
      targetDate.setHours(0, 0, 0, 0);
      if (date?.toString() === "Invalid Date" || currentDate > targetDate) {
        props.formik.setFieldValue("cancel_date", 'Invalid date')
      } else {
        const dateFormated = getFormatedDate(date);
        if (moment(dateFormated, "YYYY-MM-DD", true).isValid()) {
          props.formik.setFieldValue("cancel_date", dateFormated)
        } else {
          props.formik.setFieldValue("cancel_date", 'Invalid date')
        }
      }
    } else if (date === null) {
      props.formik.setFieldValue("cancel_date", 'Empty date')
    }
  };
  
  return (
    <>
      <RadioButtonGroup
        name="cancel_status_option"
        id="cancel_status_option"
        valuesArray={DurationTypeDropdown.filter((data) => !["FUTURE", "STOPPED"].includes(props.status) || data.value === "IMMEDIATELY")}
        value={props.formik.values.cancel_status_option}
        icon={
          <span>
            <RadioButtonUncheckedIcon />
          </span>
        }
        checkedIcon={
          <span>
            <RadioButtonCheckedIcon className="radio-btn-tds" />
          </span>
        }
        isDisabled={false}
        formik={props.formik}
        onClick={(e) => {
          props.formik.setFieldValue("cancel_status_option", e);
          if (e === "SPECIFIC_DATE") {
            props.formik.setFieldValue(
              "cancel_date",
              getNextDate()
            );
          } else if (e === "END_OF_CURRENT_TERM") {
            const [day, month, year] = props.nextRenewableDate.split('-').map(Number);
            props.formik.setFieldValue("cancel_date", new Date(year, month - 1, day))
          } else if (e === "IMMEDIATELY") {
            props.formik.setFieldValue(
              "cancel_date",
              getFormatedDate(new Date())
            );
          }
        }}
      />
      <div>
        <div className="col-12 col-lg-4 mw-325 void-subscription-margin-container">
          <div className="date-selector-wrapper void-subscription-margin row">
          <label className="pause-date-label" htmlFor="cancel_date">
            Cancel Date
          </label>
            <CustomDatepicker
              date={props.formik.values.cancel_date ? new Date(props.formik.values.cancel_date) : new Date()}
              type="cancel_date"
              handleDate={handleDate}
              name="cancel_date"
              id="cancel_date"
              error={""}
              zIndex={0}
              placeholder="Enter Updation date"
              minDate={getNextDate()}
              enableFutureDate={true}
              isDisabled={['IMMEDIATELY', 'END_OF_CURRENT_TERM'].includes(props.formik.values.cancel_status_option)}
            />
            <span className="error">{ }</span>
          </div>
 
          {!["IN_TRIAL", "FUTURE"].includes(props.status)
            ? <>
              {!["STOPPED"].includes(props.status) ? <><label
                htmlFor="credit_note"
              >
                Handling Unused Credits (12 days)
              </label>
              <Select
                name="credit_note"
                id="credit_note"
                options={unusedCreditsDropdown}
                className={`discount-period-count trial-period-type-radius state-select form-select custom-select void-subscription-margin`}
                onChange={onFormSelectChange}
                isSearchable={false}
                placeholder=""
                styles={SelectCustomStyleCustomer}
                value={
                  unusedCreditsDropdown.filter(
                    (data) =>
                      data.value === props.formik.values.credit_note
                  )[0]
                }
                isDisabled={false}
              />

              {props.formik.values.credit_note ===
                "PRORATED" && (
                  <FormControlLabel
                    className="emailEnableAndDisable void-subscription-cancellation-date-include"
                    control={
                      <Switch
                        checked={props.formik.values.include_cancellation_date}
                        color="primary"
                        disableRipple
                        onChange={(event) => {
                          props.formik.setFieldValue(
                            "include_cancellation_date",
                            event.target.checked // Correctly update the Formik value
                          );
                        }}
                      />
                    }
                    label="Include cancellation date in the invoicing period"
                    labelPlacement="start"
                    name="include_cancellation_date"
                    value={props.formik.values.include_cancellation_date}
                  />
                )}</>: null}
                <FormSelectField
                    name="unpaid_amount"
                    id="unpaid_amount"
                    label={`Manage Unpaid Amount (${props.unpaidAmount})`}
                    options={manageUnpaidAmountDropdown}
                    wrapperClass="mw-325"
                    className="state-select custom-select"
                    value={manageUnpaidAmountDropdown.filter((data) => data.value === props.formik.values.unpaid_amount)[0]}
                    onChange={(option: any) => {
                        props.formik.setFieldValue('unpaid_amount', option.value)
                    }}
                    styles={customSelectStyle}
                    placeholder={""}
                    isSearchable={true}
                    isDisabled={false}
                    error=""
                    isRequired={false}
                    isOnlyInRow={false}
                />

              {props.formik.values.unpaid_amount ===
                "WRITE_OFF" && (
                <FormControl
                  className="form-control-account-select mw-325 subscription-cancel-select-account"
                >
                  <label htmlFor="account" className="fw-bold required">
                    Choose Account
                  </label>
                  <AccountSelect
                    handleBankCharge={(e) => {
                      props.formik.setFieldValue("bank_charges_account_id", e.value);
                    }}
                    bankChargesAccountId={props.formik.values.bank_charges_account_id}
                    accountNames={["Expenses", "Incomes"]}
                    error={!props.cancelFormErrors.bank_charges_account_id ? '' :  'error'}
                    wrapperClass="w-100"
                    placeholder="Choose Account"
                  />
                  <span className="error">
                    {!props.cancelFormErrors.bank_charges_account_id ? '' :  props.cancelFormErrors.bank_charges_account_id}
                  </span>
                </FormControl>
                )}

              {!["STOPPED"].includes(props.status) ? <><label
                htmlFor="handle_paid_amount"
              >
                How to handle the paid amount
              </label>
              <Select
                name="handle_paid_amount"
                id="handle_paid_amount"
                options={handlePaidAmountDropdown}
                className={`discount-period-count trial-period-type-radius state-select form-select custom-select void-subscription-margin`}
                onChange={onFormSelectChange}
                isSearchable={false}
                placeholder=""
                styles={SelectCustomStyleCustomer}
                value={
                  handlePaidAmountDropdown.filter(
                    (data) =>
                      data.value ===
                      props.formik.values.handle_paid_amount
                  )[0]
                }
                isDisabled={false}
              /></> : null}
            </> : null}

        </div>
      </div>

    </>
  );
};

export default VoidModalSubscription;
