import { useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { DownloadBlueImg, InviteBlueImg } from "../../../../../assets/images";
import { downloadUsingURL } from "../../../../../helpers/helper";
import { BillPaymentDetailValues } from "../../../../../types";
import { currentUserSelector } from "../../../../common/commonSlice";
import Delete from "../../../../common/components/Delete";
import Header from "../../../../common/components/detailsPage/Header";
import HeaderBody from "../../../../common/components/detailsPage/HeaderBody";
import SendEmailModal from "../../../../common/components/SendEmailModal";
import Loader from "../../../components/Loader";
import {
  billPaymentList,
  getPaymentMadePdfLink,
  paymentMadeDetails,
  sendPaymentMadeData,
} from "../../paymentsMadeSlice";
import DeleteBillPayment from "../Delete";
import PaymentMadeDetailBody from "./paymentDetailBody";
import { setErrorID } from "../../../../../appSlice";
import { noAccessErrorCodes } from "../../../../constants/constants";
import Edit from "../../../../common/components/kebabMenu/Edit";
import KebabMenu from "../../../../common/components/kebabMenu/KebabMenu";
import { colors } from "../../../../constants/colors";
import { SendEmailIcon } from "../../../../common/components/customSvgIcons/sendEmailIcon";
import { DownloadIcon } from "../../../../common/components";
import { TagIcon } from "../../../../common/components/customSvgIcons/TagIcon";
import CustomTooltips from "../../../../common/components/Tooltip";
import { joinTransactionNumber } from "../../../../../helpers/prefixHelper";
import RefundModal from "../refund/RefundModal";
import { RefundIcon } from "../../../../common/components/customSvgIcons/refundIcon";

function PaymentMadeDetailHeader() {
  const previewDetailsInitialValues = {
    id: "",
    user_id: "",
    organization_id: "",
    payment_number: "",
    payment_date: "",
    vendor_name: "",
    vendor_id: "",
    paid_through_account_name: "",
    paid_through_account_id: "",
    payment_mode: "",
    amount_paid: "",
    notes: "",
    emails: [],
    unpaid_bill_lines: [],
    pm_status: "",
    amount_for_payment: "",
    amount_excess: "",
    amount_total: "",
    custom_fields: {},
    organization_details: {
      organization_name: "",
      email: "",
      country: "",
      state: "",
      address: "",
      logo_path: "",
      phone_number: "",
      zip_code: "",
      city: "",
    },
    vendor_billing_address: {
      country_id: "",
      country_name: "",
      address: "",
      city: "",
      state_id: "",
      state_name: "",
      zip_code: "",
      phone: "",
      is_billing: false,
      is_shipping: false,
    },
    bank_charges_account_name: "",
    bank_charges_amount: "",
    bank_charges_account_id: "",
    currency_code: "",
    amount_in_words: "",
    files: [],
    unpaid_bill_lines_exist: false,
    reference_number: "",
    is_locked: false,
    lock_date: "",
    is_fiscal_closed: false,
  };
  type LocationProps = {
    redirectTo?: string;
  };
  const location = useLocation();
  const { editId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  let redirectUrl = searchParams.get("redirect");
  const locationState = location.state as LocationProps;
  const redirectTo = locationState?.redirectTo;
  const currentUserInfo = useAppSelector(currentUserSelector);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [dateSortOrder, setDateSortOrder] = useState("ASC");
  const [isLoading, setIsLoading] = useState(false);
  const [emailList, setEmailList] = useState<string[] | []>([]);
  const [deletePaymentMadeId, setDeletePaymentMadeId] = useState(-1);
  const [deletePaymentMadeItemId, setDeletePaymentMadeItemId] = useState("");
  const [deleteBillPaymentStatus, setDeleteBillPaymentStatus] = useState("");
  const [unpaidBillExist, setUnpaidBillExist] = useState(false);
  const [paymentMadeData, setPaymentMadeData] =
    useState<BillPaymentDetailValues>(previewDetailsInitialValues);

  const organizationRole = JSON.parse(
    sessionStorage.getItem("organizationRole") || "{}"
  );
  let paymentsMadeRolePermission = organizationRole.length
    ? organizationRole
        .filter(
          (orgPermission: any) => orgPermission.subModule === "Payments Made"
        )
        .map((permission: any) => {
          return permission.permission;
        })
    : [];

  useEffect(() => {
    if (editId) {
      paymentMadeDetailResponse();
    }
  }, [editId, currentUserInfo.organization_id]);

  /**
   * Fetch Payment made Details
   */
  const paymentMadeDetailResponse = async () => {
    setIsLoading(true);
    const responseAction = await dispatch(
      paymentMadeDetails({
        paymentMadeId: Number(editId),
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      setTimeout(function () {
        setIsLoading(false);
      }, 500);
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        if (response.pm_status === "Draft") {
          navigate("/payment-made");
        }
        setPaymentMadeData(response);
      } else {
        dispatch(setErrorID(noAccessErrorCodes.PMDetail));
        // redirectTo ? navigate(redirectTo) : navigate("/payment-made");
      }
    }
  };

  /**
   * Set the entered emails in the bill data to a state.
   */
  useEffect(() => {
    let emails = paymentMadeData.emails.map((data) => {
      return data.email;
    });
    setEmailList(emails);
  }, [paymentMadeData]);

  /**
   * Back button handler from Payment Made details page
   */
  const backFromPaymentMadeViewHandler = () => {
    // if (editId) {
    //   redirectTo ? navigate(redirectTo) : navigate("/payment-made");
    // } else {
    //   redirectTo ? navigate(redirectTo) : navigate("/payment-made/create");
    // }
    redirectUrl ? navigate(redirectUrl) : navigate(-1);
  };

  const fetchPaymentMadeList = async (action: string) => {
    await dispatch(
      billPaymentList({
        page,
        itemsPerPage,
        dateSortOrder,
        orgId: currentUserInfo.organization_id,
        orgIds: [],
        vendorList: [],
        statusList: [],
      })
    );
    if (action === "delete") {
      // redirectTo ? navigate(redirectTo) : navigate("/payment-made");
      redirectUrl ? navigate(redirectUrl) : navigate(-1);
    }
  };

  const downloadPdf = async () => {
    const responseAction = await dispatch(
      getPaymentMadePdfLink({
        paymentMadeId: Number(editId),
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (
        Object.keys(response).length &&
        !("error" in response) &&
        response.download_link?.length > 0
      ) {
        let result = await downloadUsingURL(
          response.download_link,
          Number(editId) + ".pdf"
        );
        if (result) {
          toast.success("Payment made downloaded successfully!", {
            toastId: "payment-made-download-success",
            closeButton: false,
            position: "top-center",
          });
        }
      } else {
        // toast.error("Failed to download PDF!", {
        //   icon: ({ theme, type }) => (
        //     <img src={ErrorImg} width="16" height="14" alt="error-img" />
        //   ),
        //   toastId: "pdf-download-failure",
        //   closeButton: false,
        //   className: "toast-error",
        //   position: "top-center",
        // });
      }
    }
  };

  const sendEmail = async (id: number, emailList: string[]) => {
    const responseAction = await dispatch(
      sendPaymentMadeData({
        paymentMadeId: id,
        emails: emailList,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        fetchPaymentMadeList("send");
      }
    }
    return responseAction;
  };

  return (
    <>
      <Header
        onBackButtonClick={backFromPaymentMadeViewHandler}
        data={{
          num: paymentMadeData.payment_number,
          status: paymentMadeData.pm_status,
        }}
        dataId={editId}
      />
      <div className="card-body">
        <HeaderBody
          dataId={editId}
          data={{
            num: paymentMadeData.payment_number,
            status: paymentMadeData.pm_status,
            userName: paymentMadeData.vendor_name,
            date: paymentMadeData.payment_date,
            orgName: paymentMadeData.organization_details.organization_name,
          }}
          isSameOrg={
            paymentMadeData.organization_id === currentUserInfo.organization_id
          }
        >
          {paymentsMadeRolePermission.includes("Update") &&
          !paymentMadeData.is_fiscal_closed ? (
            <Edit
              url={`/payment-made/edit/${paymentMadeData.id}?replace=true${
                redirectUrl ? "&redirect=" + redirectUrl : ""
              }`}
              isMutable={true}
              name="Payment Made"
              isLocked={paymentMadeData.is_locked}
              className=""
              lockDate={paymentMadeData.lock_date}
            />
          ) : null}
          {/* <Link
            to="#"
            data-bs-toggle="modal"
            data-bs-target="#emailToModal"
            style={{ textDecoration: "none" }}
          >
            <img src={InviteBlueImg} alt="invite-icon" /> Send Email
          </Link> */}
          {/* <Link to="#" onClick={downloadPdf} style={{ textDecoration: "none" }}>
            <img src={DownloadBlueImg} alt="download-icon" /> Download PDF
          </Link> */}
          {paymentsMadeRolePermission.includes("Delete") &&
          !paymentMadeData.is_fiscal_closed ? (
            <Delete
              className="text-decoration-none delete-item"
              deleteIcon={true}
              isMutable={true}
              onClick={() => {
                setDeletePaymentMadeId(Number(paymentMadeData.id));
                setDeletePaymentMadeItemId(paymentMadeData.payment_number);
                setDeleteBillPaymentStatus(paymentMadeData.pm_status);
                setUnpaidBillExist(paymentMadeData.unpaid_bill_lines_exist);
              }}
              isLocked={paymentMadeData.is_locked}
              lockDate={paymentMadeData.lock_date}
              name="Payment Made"
            />
          ) : null}
          <KebabMenu wrapperClass="detail-top-action-menu kebab-menu-dropdown-wrap">
            <li>
              <button
                className="dropdown-item"
                type="button"
                // to="#"
                data-bs-toggle="modal"
                data-bs-target="#emailToModal"
                style={{ textDecoration: "none", color: colors.charcoalGrey }}
              >
                <span>
                  <SendEmailIcon
                    color={colors.battleshipGrey}
                    margin="0px 5px 0px 0px"
                  />
                  Send Email
                </span>
              </button>
            </li>
            <li>
              <button
                // to="#"
                type="button"
                onClick={downloadPdf}
                style={{ textDecoration: "none", color: colors.charcoalGrey }}
                className="dropdown-item"
              >
                <span>
                  <DownloadIcon
                    color={colors.battleshipGrey}
                    margin="0px 8px 0px 0px"
                  />
                  Download PDF
                </span>
              </button>
            </li>
            {currentUserInfo.is_tags_enabled && (
              <li>
                <button
                  type="button"
                  style={{
                    textDecoration: "none",
                    color: colors.charcoalGrey,
                    opacity: paymentMadeData.unpaid_bill_lines_exist ? 1 : 0.5,
                  }}
                  className={`dropdown-item`}
                  onClick={(e) => {
                    if (!paymentMadeData.unpaid_bill_lines_exist) {
                      e.preventDefault();
                      e.stopPropagation();
                    } else {
                      navigate(
                        `/payment-made/edit/${Number(editId)}/reporting-tags`,
                        { replace: true }
                      );
                    }
                  }}
                >
                  {paymentMadeData.unpaid_bill_lines_exist ? (
                    <span>
                      <TagIcon
                        color={colors.battleshipGrey}
                        margin="0px 5px 0px 0px"
                      />
                      Reporting Tags
                    </span>
                  ) : (
                    <CustomTooltips
                      placement="bottom"
                      title={`There are no bills associated to ${joinTransactionNumber(
                        paymentMadeData.payment_number
                      )}`}
                    >
                      <span>
                        <TagIcon
                          color={colors.battleshipGrey}
                          margin="0px 5px 0px 0px"
                        />
                        Reporting Tags
                      </span>
                    </CustomTooltips>
                  )}
                </button>
              </li>
            )}
            {/* {Number(paymentMadeData.amount_excess) > 0 && (
              <li>
                <RefundModal
                  paymentMadeData={paymentMadeData}
                  editId={0}
                  refreshList={paymentMadeDetailResponse}
                >
                  <button
                    // to="#"
                    type="button"
                    style={{
                      textDecoration: "none",
                      color: colors.charcoalGrey,
                    }}
                    className="dropdown-item"
                  >
                    <span>
                      <RefundIcon
                        color={colors.battleshipGrey}
                        margin="0px 8px 0px 0px"
                      />
                      Refund
                    </span>
                  </button>
                </RefundModal>
              </li>
            )} */}
          </KebabMenu>
        </HeaderBody>
        {isLoading ? <Loader /> : null}
        <PaymentMadeDetailBody
          paymentMadeData={paymentMadeData}
          paymentMadeDetailResponse={paymentMadeDetailResponse}
        />
      </div>
      <SendEmailModal
        emailLists={emailList}
        id={Number(editId)}
        sendEmail={(id: number, emailList: string[]) =>
          sendEmail(id, emailList)
        }
      />
      <DeleteBillPayment
        deleteBillPaymentId={deletePaymentMadeId}
        deleteBillPaymentItemId={deletePaymentMadeItemId}
        deleteBillPaymentStatus={deleteBillPaymentStatus}
        // page={page}
        // itemsPerPage={itemsPerPage}
        // dateSortOrder={dateSortOrder}
        refreshBillPayments={fetchPaymentMadeList}
        organizationId={currentUserInfo.organization_id}
        isUnpaidBillExist={unpaidBillExist}
      />
    </>
  );
}

export default PaymentMadeDetailHeader;
