import moment from "moment";
import React, {
  Dispatch,
  ForwardRefRenderFunction,
  SetStateAction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { getFormatedDate } from "../../../../../helpers/helper";
import {
  joinForPayload,
  splitTransactionNumber,
} from "../../../../../helpers/prefixHelper";
import {
  InvoiceDefaultRef,
  InvoiceDefaultValues,
  InvoiceValues,
  ObjectType,
  PaymentTerms,
} from "../../../../../types";
import {
  currentUserSelector,
  fiscalTearFilterForValidationSelector,
  paymentTermsSelector,
  setLoaderState,
} from "../../../../common/commonSlice";
import CustomerSelect from "../../../../common/components/CustomerSelect";
import PaymentTermSelect from "../../../../common/components/PaymentTermSelect";
import PrefixInput from "../../../../common/components/PrefixInput/PrefixInput";
import SupplyStates from "../../../../common/components/SupplyStates";
import TagInput from "../../../../common/components/TagInput";
import { FormInputField } from "../../../../common/components/formInputField/FormInputField";
import { FormInputFieldsInRows } from "../../../../common/components/formInputFieldInRows/FormInputFieldInRows";
import { PaymentTermsModal } from "../../../../common/components/paymentTerms/paymentTermsModal";
import { TagsModal } from "../../../../common/components/tags/TagsModal";
import { useModuleCustomFieldList } from "../../../../hooks/useModuleCustomFieldList";
import usePreservedLocation from "../../../../hooks/usePreservedLocation";
import { useSubModulePermission } from "../../../../hooks/useSubModulePermissions";
import CustomDatepicker from "../../../../utils/atoms/datepicker";
import { AddCustomerModal } from "../../../customerManagement/components/addCustomerModal/AddCustomerModal";
import { customerListSelector } from "../../../customerManagement/customerSlice";
import { getNewInvoiceNumber } from "../../invoiceSlice";
import { initialDefaultValues } from "./StateInitialization";
import useGst from "../../../../hooks/useGst";
import AlertModalPopup from "../../../../common/components/alertModalPopup/AlertModalPopup";
import {
  gstRegistrationTypes,
  invoiceStatus,
} from "../../../../constants/constants";
import NotificationModal from "../../../../common/components/NotificationModal";
import { ErrorToaster } from "../../../../../helpers/toastHelper";

type Props = {
  defaultFormValues: InvoiceDefaultValues;
  invoiceFormValues: InvoiceValues;
  formErrors: any;
  handleCustomerChange: (e: any) => void;
  checkInvoiceNumberExist: (invoiceNumber: string) => void;
  organizationId: number;
  handleNewConversionDate: (value: string) => void;
  customFieldValue: ObjectType;
  customFieldRef: any;
  getCustomFieldData: (data: ObjectType) => void;
  setStateType?: any;
  fetchGstTaxList: (
    stateType: string,
    gst_registration_type: string | undefined
  ) => Promise<void>;
  setSupplyState?: any;
  initialStateType: string;
  gstRegistrationType?: string;
  setIsStateChanged: Dispatch<SetStateAction<boolean>>;
};

const DefaultDetails: ForwardRefRenderFunction<InvoiceDefaultRef, Props> = (
  props,
  ref
) => {
  type LocationProps = {
    consumerId?: string;
  };
  const location = usePreservedLocation();
  const dispatch = useAppDispatch();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const { customerId, editId } = useParams();
  const locationState = location.state as LocationProps;
  const consumerId = customerId; //locationState?.consumerId;
  const customerList = useAppSelector(customerListSelector);
  const paymentTerms = useAppSelector(paymentTermsSelector);
  const subModulePermission = useSubModulePermission();

  const [defaultFormValues, setDefaultFormValues] =
    useState<InvoiceDefaultValues>(initialDefaultValues);
  const [dueDate, setDueDate] = useState<Date | null>(new Date());
  const [isCustom, setIsCustom] = useState<boolean>(false);
  const [invoiceDate, setInvoiceDate] = useState(new Date());
  const [isTermsChange, setIsTermsChange] = useState<boolean>(true);
  const [invoiceNumberObj, setInvoiceNumberObj] = useState<ObjectType>({});
  const [
    placeOfSupplyUpdateModalTriggered,
    setPlaceOfSupplyUpdateModalTriggered,
  ] = useState<number>();
  const invoiceCustomFields = useModuleCustomFieldList("Invoices");
  const { isGstOrg } = useGst();
  const placeOfSupplyUpdateModalRef = useRef<any>();
  const invoiceDateTempRef = useRef<Date | null>(null);
  const fiscalYearFilter = useAppSelector(
    fiscalTearFilterForValidationSelector
  );
  useImperativeHandle(
    ref,
    () => ({
      defaultData: defaultFormValues,
      calculateDueDate: calculateDueDate,
      invoiceNumber: joinForPayload(invoiceNumberObj),
      dueDate: getFormatedDate(dueDate as Date),
    }),
    [defaultFormValues, invoiceNumberObj]
  );

  useEffect(() => {
    if (!editId) invoiceNumber();
  }, [currentUserInfo.organization_id]);

  useEffect(() => {
    setDefaultFormValues({ ...defaultFormValues, ...props.defaultFormValues });
    if (editId) {
      if (props.defaultFormValues.payment_term_id === null) setIsCustom(true);
      const transaction_obj = splitTransactionNumber(
        props.defaultFormValues.invoice_number
      );
      const invoice_obj = {
        invoice_prefix: transaction_obj["prefix"],
        invoice_number: transaction_obj["number"],
      };
      setInvoiceNumberObj(invoice_obj);
    }
  }, [props.defaultFormValues]);

  useEffect(() => {
    if (consumerId) {
      defaultFormValues.customer_id = consumerId;
      let customer = customerList
        .filter((customer) => customer.id === Number(consumerId))
        .map((customer) => ({
          label: customer.customer_display_name,
          value: customer.id,
        }))[0];
      if (customer) handleCustomerChange(customer);
    }
  }, [consumerId, customerList]);

  useEffect(() => {
    handleDate(dueDate, "dueDate");
  }, [dueDate]);
  useEffect(() => {
    if (!editId) {
      handleDate(invoiceDate, "invoice");
    }
  }, [invoiceDate]);

  useEffect(() => {
    placeOfSupplyUpdateModalTriggered &&
      placeOfSupplyUpdateModalRef.current?.openAlertModal &&
      placeOfSupplyUpdateModalRef.current?.openAlertModal();
  }, [placeOfSupplyUpdateModalTriggered]);
  /**
   * For checking if the invoice number exist on prefix change
   */
  useEffect(() => {
    props.checkInvoiceNumberExist(joinForPayload(invoiceNumberObj));
  }, [invoiceNumberObj["invoice_prefix"]]);

  useEffect(() => {
    ($(".selectpicker") as any).selectpicker("refresh");
  }, [paymentTerms, defaultFormValues]);

  useEffect(() => {
    if (!editId) {
      paymentTerms.map((term: PaymentTerms, index) => {
        if (term.term_name == "Due on receipt") {
          setDefaultFormValues((values) => ({
            ...values,
            payment_term_id: term.id,
          }));
        }
      });
    }
  }, [paymentTerms]);

  /**
   * Get invoice number
   */
  const invoiceNumber = async () => {
    const responseAction = await dispatch(
      getNewInvoiceNumber(currentUserInfo.organization_id)
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setInvoiceNumberObj(response);
        let invoice_number = joinForPayload(response);
        setDefaultFormValues((values) => ({
          ...values,
          invoice_number: invoice_number ? invoice_number : "",
        }));
      }
    }
    dispatch(setLoaderState(false));
  };

  /**
   * Set invoice default form values
   */
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    const invNumberRegex = /^[0-9]+$/;
    if (name === "payment_term_id" && value === "Custom") {
      setDefaultFormValues({ ...defaultFormValues, [name]: null });
    } else {
      if (name === "invoice_number") {
        props.checkInvoiceNumberExist(
          invoiceNumberObj["invoice_prefix"] +
            "~" +
            value.replace("~", "").toUpperCase()
        );
        setDefaultFormValues({
          ...defaultFormValues,
          [name]:
            invoiceNumberObj["invoice_prefix"] +
            "~" +
            value.replace("~", "").toUpperCase(),
        });
        setInvoiceNumberObj({
          ...invoiceNumberObj,
          [name]: value.replace("~", "").toUpperCase(),
        });
      } else if (name === "reference_number") {
        if (value.length < 17) {
          setDefaultFormValues({ ...defaultFormValues, [name]: value });
        }
      } else {
        setDefaultFormValues({ ...defaultFormValues, [name]: value });
      }
    }
  };

  const handleTagChange = (selectedTag: never[]) => {
    setDefaultFormValues((values) => ({ ...values, tag_ids: selectedTag }));
  };

  const handleCustomerChange = (e: {
    label: string;
    value: string | number;
  }) => {
    setDefaultFormValues((values) => ({
      ...values,
      customer_id: e.value.toString(),
      customer_name: e.label.trim(),
    }));
    props.handleCustomerChange(e);
  };

  const onStateChanged = async (
    name: string,
    option: { label: string; value: string }
  ) => {
    let newDefaultFormValues = { ...defaultFormValues, [name]: option.value };
    setDefaultFormValues(newDefaultFormValues);
    props.setSupplyState(option.value);
    props.setIsStateChanged(true);

    if (
      newDefaultFormValues?.place_of_supply &&
      currentUserInfo.organization_gst_state
    ) {
      let stateType = "";
      if (
        newDefaultFormValues?.place_of_supply ===
        currentUserInfo.organization_gst_state
      ) {
        if (props.initialStateType === "Inter State") {
          setPlaceOfSupplyUpdateModalTriggered(
            Math.floor(Math.random() * 1000)
          );
        }
        stateType = "Intra State";
        props.setStateType("Intra State");
      } else {
        if (props.initialStateType === "Intra State") {
          setPlaceOfSupplyUpdateModalTriggered(
            Math.floor(Math.random() * 1000)
          );
        }
        stateType = "Inter State";
        props.setStateType("Inter State");
      }
      props.fetchGstTaxList(stateType, props?.gstRegistrationType);
    } else {
      props.setStateType("");
    }
  };
  const handlePaymentTermChange = (e: any) => {
    if (e.value) {
      let value = e.value;
      if (e.value === "Custom") {
        value = null;
      }
      setDefaultFormValues((values) => ({ ...values, payment_term_id: value }));
    }
  };

  const changeInvoiceDate = (date: Date | null, type: string) => {
    if (date) {
      if (date?.toString() === "Invalid Date") {
        setDefaultFormValues((values) => ({
          ...values,
          invoice_date: "Invalid date",
          recalculate_exchange_rate: false,
        }));
      } else {
        const dateFormated = getFormatedDate(date);
        setIsCustom(false);
        // if (new Date(fiscalYearFilter.startDate).setHours(5, 30).toLocaleString() > new Date(date).setHours(5, 30).toLocaleString()) {
        //   setDefaultFormValues((values) => ({
        //     ...values,
        //     invoice_date: "Old date",
        //   }));
        // } else
        if (moment(dateFormated, "YYYY-MM-DD", true).isValid()) {
          if (defaultFormValues.payment_term_id !== "") {
            if (defaultFormValues.payment_term_id !== null) {
              calculateDueDate(defaultFormValues.payment_term_id, date);
            }
          }
          if (defaultFormValues.payment_term_id === null) {
            setIsCustom(true);
          }
          setDefaultFormValues((values) => ({
            ...values,
            invoice_date: dateFormated,
            recalculate_exchange_rate: true,
          }));
          let conversionDate =
            new Date(dateFormated) > new Date()
              ? getFormatedDate(new Date())
              : dateFormated;
          props.handleNewConversionDate(conversionDate);
        } else {
          setDefaultFormValues((values) => ({
            ...values,
            invoice_date: "Invalid date",
            recalculate_exchange_rate: false,
          }));
        }
      }
    }
  };

  const handleInvoiceDate = (date: Date | null, type: string) => {
    if (
      ((editId &&
        defaultFormValues.invoice_status === invoiceStatus.partiallyPaid) ||
        defaultFormValues.invoice_status === invoiceStatus.closed ||
        defaultFormValues.invoice_status.includes(invoiceStatus.overdue)) &&
      date
    ) {
      if (fiscalYearFilter.startDate > getFormatedDate(date || undefined)) {
        ErrorToaster(
          "Invalid Transaction Date: Transaction date must be within the current fiscal year"
        );
        setDefaultFormValues((prevValue) => {
          return {
            ...prevValue,
            invoice_date: defaultFormValues.invoice_date,
          };
        });
        return;
      } else {
        setDefaultFormValues((prevValue) => {
          return {
            ...prevValue,
            invoice_date: defaultFormValues.invoice_date,
          };
        });
        invoiceDateTempRef.current = date;
        $("#notificationPopupModal").modal("show");
        return;
      }
    } else {
      changeInvoiceDate(date, type);
    }
  };
  const handleExchangeRateCancel = (date: Date | null, type: string) => {
    if (
      ((editId &&
        defaultFormValues.invoice_status === invoiceStatus.partiallyPaid) ||
        defaultFormValues.invoice_status === invoiceStatus.closed ||
        defaultFormValues.invoice_status.includes(invoiceStatus.overdue)) &&
      date
    ) {
      setDefaultFormValues((prevValue) => {
        return {
          ...prevValue,
          invoice_date: getFormatedDate(date),
        };
      });
      if (moment(getFormatedDate(date), "YYYY-MM-DD", true).isValid()) {
        if (defaultFormValues.payment_term_id !== "") {
          if (defaultFormValues.payment_term_id !== null) {
            calculateDueDate(defaultFormValues.payment_term_id, date);
          }
        }
      }
    }
  };
  const handleDate = (date: Date | null, type: string) => {
    if (date) {
      if (date?.toString() === "Invalid Date") {
        if (type === "invoice") {
          setDefaultFormValues((values) => ({
            ...values,
            invoice_date: "Invalid date",
          }));
        } else if (type === "dueDate") {
          setDefaultFormValues((values) => ({
            ...values,
            due_date: "Invalid date",
          }));
        }
        // } else if (new Date(fiscalYearFilter.startDate).setHours(5, 30).toLocaleString() > new Date(date).setHours(5, 30).toLocaleString() && type !== "dueDate") {
        //     setDefaultFormValues((values) => ({
        //       ...values,
        //       invoice_date: "Old date",
        //     }));
      } else if (new Date(date) > new Date() && type !== "dueDate") {
        setDefaultFormValues((values) => ({
          ...values,
          invoice_date: "Future date",
        }));
      } else {
        const dateFormated = getFormatedDate(date);
        setIsCustom(false);
        if (type === "invoice") {
          if (moment(dateFormated, "YYYY-MM-DD", true).isValid()) {
            if (defaultFormValues.payment_term_id !== "") {
              if (defaultFormValues.payment_term_id !== null) {
                calculateDueDate(defaultFormValues.payment_term_id, date);
              }
            }

            if (defaultFormValues.payment_term_id === null) {
              setIsCustom(true);
            }
            setDefaultFormValues((values) => ({
              ...values,
              invoice_date: dateFormated,
            }));
            props.handleNewConversionDate(dateFormated);
          } else {
            setDefaultFormValues((values) => ({
              ...values,
              invoice_date: "Invalid date",
            }));
          }
        } else if (type === "dueDate") {
          // if (new Date(fiscalYearFilter.startDate).setHours(5, 30).toLocaleString() > new Date(date).setHours(5, 30).toLocaleString()) {
          //   setDefaultFormValues((values) => ({
          //     ...values,
          //     due_date: "Old date",
          //   }));
          // } else
          if (moment(dateFormated, "YYYY-MM-DD", true).isValid()) {
            if (!isTermsChange) {
              setIsCustom(true);
              setDefaultFormValues((values) => ({
                ...values,
                payment_term_id: null,
              }));
            }
            setIsTermsChange(false);
            setDefaultFormValues((values) => ({
              ...values,
              due_date: dateFormated,
            }));
          } else {
            setDefaultFormValues((values) => ({
              ...values,
              due_date: "Invalid date",
            }));
          }
        }
      }
    }
  };

  /**
   * Function to calculate the due date of the invoice
   */
  const calculateDueDate = (termId: string | number, invoiceDate: Date) => {
    let currentDate = new Date();
    invoiceDate = invoiceDate ? invoiceDate : currentDate;
    if (termId) {
      setIsTermsChange(false);
      if (termId === "Custom") {
        setDueDate(null);
        setDefaultFormValues({
          ...defaultFormValues,
          payment_term_id: null,
          due_date: "",
        });
      } else {
        setIsTermsChange(true);
        let selectedTermId = Number(termId);
        let selectedPaymentTerm = paymentTerms.filter(
          (tag) => tag.id === selectedTermId
        )[0];
        if (selectedPaymentTerm.term_name === "Due on receipt") {
          setDueDate(invoiceDate);
        } else if (selectedPaymentTerm.term_name === "Due end of month") {
          setDueDate(
            new Date(invoiceDate.getFullYear(), invoiceDate.getMonth() + 1, 0)
          );
        } else if (selectedPaymentTerm.term_name === "Due end of next month") {
          setDueDate(
            new Date(invoiceDate.getFullYear(), invoiceDate.getMonth() + 2, 0)
          );
        } else {
          let termDays = selectedPaymentTerm.term_days;
          if (termDays) {
            invoiceDate.setDate(invoiceDate.getDate() + Number(termDays));
            setDueDate(invoiceDate);
          } else {
            setDueDate(invoiceDate);
          }
        }
      }
    } else {
      setDueDate(currentDate);
    }
  };

  /**
   * To get prefix from PrefixInput component.
   */
  const getPrefix = (value: string) => {
    setInvoiceNumberObj({
      ...invoiceNumberObj,
      ["invoice_prefix"]: value.toUpperCase(),
    });
  };

  const checkCustomerSelectIsDisabled = () => {
    if (editId) {
      return !props.defaultFormValues.is_mutable;
    }
    return false;
  };
  const notificationModalMessage =
    invoiceDateTempRef.current &&
    `If you select a ${
      getFormatedDate(invoiceDateTempRef.current) > getFormatedDate(new Date())
        ? "future"
        : getFormatedDate(invoiceDateTempRef.current) <
          getFormatedDate(new Date())
        ? "past"
        : "current"
    } date, the exchange rate as of ${
      getFormatedDate(invoiceDateTempRef.current) < getFormatedDate(new Date())
        ? "that day"
        : "today"
    } will be applied to this transaction. Would you like to recalculate the item rates based on the ${
      getFormatedDate(invoiceDateTempRef.current) < getFormatedDate(new Date())
        ? "historical"
        : "current"
    } exchange rate?`;

  return (
    <section className="form-top-section">
      <div
        className={`row custom-row ${
          !props.defaultFormValues.is_mutable ? "inactive-section" : ""
        }`}
      >
        <div className="col-12 col-lg-4 mw-325">
          <div className="link-label-grouped">
            <label htmlFor="customer" className="required">
              Customer Name
            </label>
            <AddCustomerModal />
          </div>
          <div>
            <CustomerSelect
              customerId={defaultFormValues.customer_id}
              errors={props.formErrors.customer_name}
              onChange={handleCustomerChange}
              isDisabled={checkCustomerSelectIsDisabled()}
            />
            <span className="error">{props.formErrors.customer_name}</span>
          </div>
        </div>
        <div className="col-12 col-lg-4 mw-325">
          <label htmlFor="invoice_number" className="required">
            Invoice Number
          </label>
          <br />
          <PrefixInput
            prefix={defaultFormValues.invoice_number.split("~")[0]}
            name="invoice_number"
            id="invoice_number"
            className="form-control border-end-0"
            placeholder="Enter invoice number"
            value={defaultFormValues.invoice_number.split("~")[1]}
            disabled={false}
            error={
              props.formErrors.invoice_number
                ? props.formErrors.invoice_number
                : ""
            }
            maxLength={12}
            getPrefix={getPrefix}
            onChange={handleChange}
          />
          <span className="error">{props.formErrors.invoice_number}</span>
        </div>
      </div>
      <div className="row custom-row">
        <div className="col-12 col-lg-4 mw-325">
          <label htmlFor="invoice_date">Invoice Date</label>
          <br />
          <div className="date-selector-wrapper">
            <CustomDatepicker
              date={
                editId ? new Date(defaultFormValues.invoice_date) : invoiceDate
              }
              handleDate={handleInvoiceDate}
              type="invoice"
              id="invoice_date"
              error={props.formErrors.invoice_date ? "error" : ""}
              zIndex={0}
              enableFutureDate={true}
              module="Invoice"
            />
          </div>
          <span className="error">{props.formErrors.invoice_date}</span>
        </div>
        <FormInputField
          type="text"
          name="reference_number"
          id="ref_no"
          className={
            props.formErrors.reference_number
              ? "form-control error"
              : "form-control"
          }
          label="Reference Number"
          placeholder="Enter reference number"
          maxLength={16}
          value={defaultFormValues.reference_number}
          onChange={handleChange}
          error={props.formErrors.reference_number}
        />
      </div>
      <div className="row custom-row">
        <div className="col-12 col-lg-4 mw-325">
          <div className="link-label-grouped">
            <label htmlFor="terms">Terms</label>
            {subModulePermission.includes("Payments Terms") ? (
              <PaymentTermsModal />
            ) : null}
          </div>
          <PaymentTermSelect
            paymentTermId={defaultFormValues.payment_term_id}
            haveCustom={true}
            isCustom={isCustom}
            dropPosition={"bottom"}
            onChange={(e: any) => {
              handlePaymentTermChange(e);
              calculateDueDate(
                e.value,
                new Date(defaultFormValues.invoice_date)
              );
            }}
          />
        </div>
        <div className="col-12 col-lg-4 mw-325">
          <label htmlFor="due_date">Due Date</label>
          <div id="date-two" className="date-selector-wrapper">
            <CustomDatepicker
              date={
                editId
                  ? defaultFormValues.due_date
                    ? new Date(defaultFormValues.due_date)
                    : null
                  : dueDate
              }
              handleDate={handleDate}
              type="dueDate"
              id="due_date"
              error={props.formErrors.due_date ? "error" : ""}
              zIndex={0}
              enableFutureDate={true}
            />
          </div>
          <span className="error">{props.formErrors.due_date}</span>
        </div>
      </div>
      <div className="row custom-row">
        {/* <div className="col-12 col-lg-4 mw-325">
          <span className="align-wrapper">
            <div className="link-label-grouped">
              <label htmlFor="tags">Tags</label>
              {subModulePermission.includes("Tags") ? <TagsModal /> : null}
            </div>
          </span>
          <TagInput
            tagIds={defaultFormValues.tag_ids}
            handleChange={handleTagChange}
          />
        </div> */}
        {isGstOrg &&
          props.gstRegistrationType !== gstRegistrationTypes.overseas && (
            <SupplyStates
              name="place_of_supply"
              id="place_of_supply"
              label="Place of Supply"
              value={
                defaultFormValues?.place_of_supply
                  ? {
                      value: defaultFormValues?.place_of_supply,
                      label: defaultFormValues?.place_of_supply,
                    }
                  : ""
              }
              onChange={(option: any) => {
                onStateChanged("place_of_supply", option);
              }}
              error={props.formErrors.place_of_supply}
            />
          )}
      </div>

      <div>
        <FormInputFieldsInRows
          getFieldValues={props.getCustomFieldData}
          customFieldsArray={invoiceCustomFields}
          initialValues={props.customFieldValue}
          ref={props.customFieldRef}
          editId={editId ? Number(editId) : null}
        />
      </div>
      <AlertModalPopup
        message="We will update the taxes applied to the items in this transaction as you've changed the place of supply"
        modalTitle="Alert!"
        isCloseButtonEnabled={true}
        isSubmitEnabled={false}
        openRef={placeOfSupplyUpdateModalRef}
      />
      <div className="notification-modal-popup">
        <NotificationModal
          modalHeader=""
          modalBody={notificationModalMessage ? notificationModalMessage : ""}
          modalSubmit="Yes"
          modalCancel="No"
          modalSubmitHandler={() =>
            changeInvoiceDate(invoiceDateTempRef.current, "invoice")
          }
          modalCancelHandler={() =>
            handleExchangeRateCancel(invoiceDateTempRef.current, "invoice")
          }
        />
      </div>
    </section>
  );
};

export default React.memo(forwardRef(DefaultDetails));
