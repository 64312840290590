import React, { useEffect, useState } from "react";
import RoleFormInputs from "./components/RoleFormInputs";
import {
  CreateUserRoleValues,
  CustomSubModuleValues,
  ModuleListValues,
  ModuleStatusValues,
  ModuleValues,
  ObjectType,
  RolePermissionValues,
} from "../../../../../../types";
import {
  initialModuleStatus,
  initialModules,
  initialRoleValues,
} from "./StateInitialization";
import PermissionMatrix from "./permissionMatrix/PermissionMatrix";
import RoleFormFooter from "./components/RoleFormFooter";
import _ from "lodash";
import useCommonData from "../../../../../hooks/useCommon";
import {
  cloneUserRole,
  createNewUserRole,
  getPermissionSection,
  rolesSelector,
  updateUserRole,
  userRolesList,
} from "../../../userSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../../../../../app/hooks";
import { toast } from "react-toastify";
import { ErrorImg } from "../../../../../../assets/images";
import { getSubmoduleEachPermission, getPermissionSubmodule } from "../../../../../../helpers/permissionHelper";
let moduleItemsListArray: CustomSubModuleValues[] = [];
const RoleForm = () => {
  const { currentUserInfo, dispatch } = useCommonData();
  const { editId } = useParams();
  const userRoles = useAppSelector(rolesSelector);
  const location = useLocation();
  const navigate = useNavigate();

  const [inputs, setInputs] = useState<CreateUserRoleValues>(initialRoleValues);
  const [initialInputs, setInitialInputs] =
    useState<CreateUserRoleValues>(initialRoleValues);
  const [path, setPath] = useState("");
  const [isSubModule, setIsSubModule] = useState(false);
  const [formErrors, setFormErrors] = useState<ObjectType>({});
  const [backendError, setBackendError] = useState<{ [key: string]: any }>({});

  const [changeInEditRole, setChangeInEditRole] = useState(false);

  const [submodulePermissions, setSubmodulePermissions] = useState<
    CustomSubModuleValues[]
  >([]);
  const [moduleListForFullAccess, setModuleListForFullAccess] = useState<
    ModuleListValues[]
  >([]);
  const [moduleListValues, setModuleListValues] = useState<ModuleListValues[]>(
    []
  );
  const [moduleList, setModuleList] = useState<ModuleListValues[]>([]);
  const [initialSubmodulePermissions, setInitialSubmodulePermissions] =
    useState<CustomSubModuleValues[]>([]);
  const [rolePermissions, setRolePermissions] = useState<
    RolePermissionValues[]
  >([]);
  const [createModuleValues, setCreateModuleValues] = useState<ModuleValues[]>([
    initialModules,
  ]);
  const [moduleStatus, setModuleStatus] =
    useState<ModuleStatusValues>(initialModuleStatus);
  const [isPermissionChanged, setIsPermissionChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getPermissionSectionResponse();
    fetchUserRolesList();
  }, [currentUserInfo.organization_id]);
  /**
   * Set the path to identify, whether it's create or edit or clone
   */
  useEffect(() => {
    setPath(location.pathname);
  }, [location]);

  /**
   * Setting selected role permissions to an array from roles list : for Edit / Clone role
   */
  useEffect(() => {
    if (editId) {
      userRoles.map((role) => {
        if (role.id === Number(editId)) {
          setRolePermissions(role.role_permissions);
        }
      });
    }
  }, [userRoles]);

  useEffect(() => {
    /**
     * Create an array with permission ID and submodule ID  from the selected roles's permissions.
     */
    const subModuleAndPermissionIds = rolePermissions.map((rolePermission) => {
      return {
        sub_module_id: rolePermission.sub_module_id,
        permission_id: rolePermission.permission_id,
      };
    });

    let subModulesPermissionList = JSON.parse(
      JSON.stringify(submodulePermissions)
    );
    let moduleIds: number[] = [];
    const modulesAndSubModulesList = JSON.parse(
      JSON.stringify(moduleListForFullAccess)
    );
    let totalModules = modulesAndSubModulesList.length;
    /**
     * Store Module Ids in an array
     */
    for (let module of modulesAndSubModulesList) {
      moduleIds.push(module.id);
    }
    /**
     * Set the selected permissions to selected or unselected : for Edit / Clone role
     */
    for (let subModule of subModulesPermissionList) {
      for (let subModuleAndPermissions of subModuleAndPermissionIds) {
        if (subModuleAndPermissions.sub_module_id === subModule.sub_id) {
          let countOfPermissions = 0;
          for (let permission of subModule.permissions) {
            if (subModuleAndPermissions.permission_id === permission.id) {
              permission.is_checked = true;
            }
            if (permission.is_checked === true) {
              countOfPermissions++;
            }
          }
          if (countOfPermissions === subModule.permissions.length) {
            subModule.is_full_access = true;
          }
        }
      }
    }
    /**
     * Calculate total permission count and length of permission array to give full access and check module status
     */
    for (let ids of moduleIds) {
      let totalPermissions = 0;
      let moduleName = "";
      let permissionLength = 0;
      for (let subModule of subModulesPermissionList) {
        if (subModule.module_id === ids) {
          moduleName = subModule.module_name;
          let permissions = subModule.permissions;
          permissionLength = permissionLength + permissions.length;
          for (let permission of permissions) {
            if (permission.is_checked === true) {
              totalPermissions = totalPermissions + 1;
            }
          }
        }
      }

      for (let module of modulesAndSubModulesList) {
        if (module.id === ids) {
          if (permissionLength === totalPermissions) {
            module.grant_full_access = true;
          } else {
            module.grant_full_access = false;
          }
        }
      }
      /**
       * Function call to set the permission status of each module in Edit / Clone role page
       */
      checkModuleStatus(moduleName, totalPermissions, permissionLength);
    }
    let modulesWithFullAccess = 0;
    for (let module of modulesAndSubModulesList) {
      if (module.grant_full_access === true) {
        modulesWithFullAccess++;
      }
    }
    if (modulesWithFullAccess === totalModules && modulesWithFullAccess !== 0) {
      setInputs((values) => ({ ...values, grant_full_access: true }));
    }
    setModuleListForFullAccess(modulesAndSubModulesList);
    setSubmodulePermissions(subModulesPermissionList);
    setInitialSubmodulePermissions(subModulesPermissionList);
  }, [rolePermissions, isSubModule]);
  /**
   * Fetch the input field for edit functionality
   */
  useEffect(() => {
    let roleExist = false;
    if (editId) {
      userRoles
        .filter((role) => role.id === Number(editId))
        .map((roles) => {
          roleExist = true;
          setInputs({
            name: path.includes("/clone") ? "" : roles.name,
            description: roles.description,
            permission_list: [],
            grant_full_access: false,
            is_changed: false,
          });
          setInitialInputs({
            name: path.includes("/clone") ? "" : roles.name,
            description: roles.description,
            permission_list: [],
            grant_full_access: false,
            is_changed: false,
          });
        });
      if (roleExist === false) {
        navigate("/roles");
      }
    }
  }, [userRoles, currentUserInfo.organization_id]);
  /**
   * Create a custom array of Object from given permission section response : to iterate through the permission table
   */
  useEffect(() => {
    let moduleItems = JSON.parse(JSON.stringify(moduleList));
    for (let items of moduleItems) {
      let itemInputs = items.sub_modules;

      for (let subModule of itemInputs) {
        let moduleObject = {
          module_id: 0,
          module_name: "",
          sub_id: 0,
          sub_module_name: "",
          is_full_access: false,
          permissions: [],
        };
        moduleObject.sub_id = subModule.id;
        moduleObject.module_id = subModule.module_id;
        moduleObject.sub_module_name = subModule.name;
        moduleObject.module_name = items.name;
        moduleObject.permissions = subModule.permission_list;

        moduleItemsListArray = [...moduleItemsListArray, moduleObject];
      }
    }
    setSubmodulePermissions(moduleItemsListArray);
    setInitialSubmodulePermissions(moduleItemsListArray);
    setIsSubModule(!isSubModule);
    moduleItemsListArray = [];
  }, [moduleList]);

  /**
   * Check If user made any changes in name, description or permission
   */
  useEffect(() => {
    if (
      _.isEqual(inputs, initialInputs) === false ||
      isPermissionChanged === true
    ) {
      setChangeInEditRole(true);
    } else {
      setChangeInEditRole(false);
    }
  }, [inputs, isPermissionChanged]);

  const inputChangeHandler = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const grantFullAccessHandler = (e: any) => {
    const name = e.target.name;
    const value = e.target.checked;
    const items = JSON.parse(JSON.stringify(submodulePermissions));
    let permissionLength = 0;

    for (let item of items) {
      permissionLength = 0;
      item.is_full_access = e.target.checked;
      let permissions = item.permissions;
      permissionLength = permissionLength + permissions.length;

      for (let permission of permissions) {
        permission.is_checked = e.target.checked;
      }
    }
    const modules = [...moduleListForFullAccess];
    let permissionArrayLength = permissionLength;
    if (e.target.checked === false) {
      permissionArrayLength = 0;
    }

    for (let module of modules) {
      module.grant_full_access = e.target.checked;

      checkModuleStatus(module.name, permissionArrayLength, permissionLength);
    }
    setInputs((values) => ({ ...values, [name]: value }));
    setSubmodulePermissions(items);

    for (let module of modules) {
      compare(items, module.id);
    }
    setModuleListForFullAccess(modules);
  };

  /**
   * Convert the selected array of response of permissions to required request body in the Create / Edit / Clone API call
   */
  const convertDataToRequestBody = () => {
    let permissionItems = JSON.parse(JSON.stringify(submodulePermissions));
    let moduleItems = JSON.parse(JSON.stringify(createModuleValues));
    let moduleIds: number[] = [];
    let submoduleIds: number[] = [];
    let isModuleExist = false;

    for (let items of permissionItems) {
      let itemInputs = items;
      for (let subItems of itemInputs.permissions) {
        if (subItems.is_checked === true) {
          isModuleExist = true;
          if (!moduleIds.includes(items.module_id)) {
            moduleIds.push(items.module_id);
          }
          if (!submoduleIds.includes(items.sub_id)) {
            submoduleIds.push(items.sub_id);
          }
        }
      }
    }

    if (isModuleExist === false) {
      moduleItems = [];
    }

    if (isModuleExist) {
      for (let items of permissionItems) {
        let subItemsObj: any = {
          submodule_id: 0,
          name: "",
          permissions: [{}],
        };
        if (subItemsObj.submodule_id == 0) {
          subItemsObj.permissions.pop();
        }
        if (
          moduleIds.includes(items.module_id) &&
          submoduleIds.includes(items.sub_id)
        ) {
          subItemsObj.submodule_id = items.sub_id;
          subItemsObj.name = items.sub_module_name;
          let itemInputs = items;
          for (let subItems of itemInputs.permissions) {
            if (subItems.is_checked === true) {
              let permissionObj: any = {};

              permissionObj.permission_id = subItems.id;
              permissionObj.name = subItems.name;
              permissionObj.selected = subItems.is_checked;

              subItemsObj.permissions.push(permissionObj);
            }
          }
          let moduleIDList: number[] = [];
          for (let modules of moduleItems) {
            let moduleInputs = modules;
            if (moduleInputs.module_id != 0) {
              moduleIDList.push(moduleInputs.module_id);
            }
          }
          if (submoduleIds.includes(items.sub_id)) {
            for (let modules of moduleItems) {
              let moduleInputs = modules;
              if (moduleInputs.module_id == 0) {
                moduleInputs.module_id = items.module_id;
                moduleInputs.name = items.module_name;
                moduleInputs.sub_modules.pop();
                moduleInputs.sub_modules.push(subItemsObj);
                break;
              } else {
                if (moduleInputs.module_id == items.module_id) {
                  moduleInputs.sub_modules.push(subItemsObj);
                  break;
                } else if (!moduleIDList.includes(items.module_id)) {
                  let moduleObj = {
                    module_id: 0,
                    name: "",
                    sub_modules: [{}],
                  };
                  if (moduleObj.module_id === 0) {
                    moduleObj.sub_modules.pop();
                  }
                  moduleObj.module_id = items.module_id;
                  moduleObj.name = items.module_name;
                  moduleObj.sub_modules.push(subItemsObj);
                  moduleItems.push(moduleObj);
                  break;
                } else {
                  continue;
                }
              }
            }
          }
        }
      }
    }
    return moduleItems;
  };

  /**
   * Form submission handler - create/edit/clone Role
   */
  const formSubmitHandler = (e: any) => {
    e.preventDefault();
    const errors = validate(inputs);
    if (Object.keys(errors).length) {
      setFormErrors(errors);
    } else {
      setFormErrors({});
      $("#role-create-form-btn").addClass("role-create-form-section-disable");
      if (path.includes("/create")) {
        createRoleSubmit(inputs);
      } else if (path.includes("/edit")) {
        updateRoleSubmit({
          id: Number(editId),
          values: inputs,
          orgId: currentUserInfo.organization_id,
        });
      } else {
        cloneRoleSubmit(inputs);
      }
    }
  };
  /**
   * Create Role Handler
   */
  const createRoleSubmit = async (data: CreateUserRoleValues) => {
    let updatedModuleItems = convertDataToRequestBody();
    data.permission_list = updatedModuleItems;
    delete data.is_changed;
    const responseAction = await dispatch(
      createNewUserRole({
        values: data,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setBackendError({});
        await dispatch(
          userRolesList({
            roleSort: "",
            orgId: currentUserInfo.organization_id,
          })
        );
        navigate("/roles");
        toast.success("Role created successfully!", {
          toastId: "role-create-success",
          closeButton: false,
          position: "top-center",
        });
      } else if ("error" in response) {
        $("#role-create-form-btn").removeClass(
          "role-create-form-section-disable"
        );
        const errorDetails = response.error.detail;
        if (Array.isArray(errorDetails) && errorDetails.length) {
          let errorObject: { [key: string]: any } = {};
          for (const error of errorDetails) {
            if (error.type === "value_error.role.name") {
              errorObject.name = error.msg;
              break;
            } else if (error.type === "value_error.role_count") {
              toast.error(
                "Sorry, You have reached the maximum limit of 15 roles.",
                {
                  icon: ({ theme, type }) => (
                    <img
                      src={ErrorImg}
                      width="16"
                      height="14"
                      alt="error-img"
                    />
                  ),
                  toastId: "create-role-failed-error-count",
                  closeButton: false,
                  className: "toast-error",
                  position: "top-center",
                }
              );
            }
          }
          setBackendError(errorObject);
        }
      }
    }
  };
  /**
   * Edit Role Handler
   */
  const updateRoleSubmit = async (data: {
    id: number;
    values: CreateUserRoleValues;
    orgId: number;
  }) => {
    let updatedModuleItems = convertDataToRequestBody();
    data.values.permission_list = updatedModuleItems;
    data.values.is_changed = isPermissionChanged;
    const responseAction = await dispatch(updateUserRole(data));
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setBackendError({});
        await dispatch(
          userRolesList({
            roleSort: "",
            orgId: currentUserInfo.organization_id,
          })
        );
        navigate("/roles");
        toast.success("Role updated successfully!", {
          toastId: "role-edit-success",
          closeButton: false,
          position: "top-center",
        });
      } else if ("error" in response) {
        $("#role-create-form-btn").removeClass(
          "role-create-form-section-disable"
        );
        const errorDetails = response.error.detail;
        if (Array.isArray(errorDetails) && errorDetails.length) {
          let errorObject: { [key: string]: any } = {};
          for (const error of errorDetails) {
            if (error.type === "value_error.role_name.exists") {
              errorObject.name = error.msg;
            }
          }
          setBackendError(errorObject);
        }
      }
    }
  };
  /**
   * Clone Role Handler
   */
  const cloneRoleSubmit = async (data: CreateUserRoleValues) => {
    let updatedModuleItems = convertDataToRequestBody();
    data.permission_list = updatedModuleItems;
    const responseAction = await dispatch(
      cloneUserRole({ values: data, orgId: currentUserInfo.organization_id })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setBackendError({});
        await dispatch(
          userRolesList({
            roleSort: "",
            orgId: currentUserInfo.organization_id,
          })
        );
        navigate("/roles");
        toast.success("Role cloned successfully!", {
          toastId: "role-clone-success",
          closeButton: false,
          position: "top-center",
        });
      } else if ("error" in response) {
        $("#role-create-form-btn").removeClass(
          "role-create-form-section-disable"
        );
        const errorDetails = response.error.detail;
        if (Array.isArray(errorDetails) && errorDetails.length) {
          let errorObject: { [key: string]: any } = {};
          for (const error of errorDetails) {
            if (error.type === "value_error.role.name") {
              errorObject.name = error.msg;
            }
          }
          setBackendError(errorObject);
        }
      }
    }
  };
  /**
   * Front end validation for role name input field.
   */
  const validate = (values: CreateUserRoleValues) => {
    const errors: ObjectType = {};
    if (!values.name) {
      errors.name = "Please enter your role name";
    }
    return errors;
  };
  /**
   * Function to get user role list
   */
  const fetchUserRolesList = async () => {
    await dispatch(
      userRolesList({
        roleSort: "",
        orgId: currentUserInfo.organization_id,
      })
    );
  };
  /**
   * Function to get the response of default permission section list and Module list
   */
  const getPermissionSectionResponse = async () => {
    setIsLoading(true);
    const responseAction = await dispatch(
      getPermissionSection(currentUserInfo.organization_id)
    );
    if (responseAction.payload) {
      setTimeout(function () {
        setIsLoading(false);
      });
    }
    const response = responseAction.payload;
    if (Object.keys(response).length && !("error" in response)) {
      setModuleList(response.module_list);
      setModuleListForFullAccess(response.module_list);
      setModuleListValues(response.module_list);
    }
  };

  /**
   * Select / Unselect permissions handler in permission matrix.
   */
  let permissionIdsChecked: number[] = [];
  let permissionIdsUnChecked: number[] = [];
  let totalPermissionCount = 0;
  const handlePermissionChange = (
    e: any,
    moduleId: number,
    submoduleId: number,
    permissionId: number,
    moduleName: string,
    subModuleName: string
  ) => {
    let items = JSON.parse(JSON.stringify(submodulePermissions));
    let count = 0;
    let rIPermissions = getPermissionSubmodule(items, "Recurring Invoices").permissions
    for (let item of items) {
      if (item.sub_id === submoduleId) {
        let permissions = item.permissions;
        for (let perm of permissions) {
          if (perm.id === permissionId) {
            if (e.target.checked === true) {
              permissionIdsChecked.push(perm.id);
            } else {
              permissionIdsUnChecked.push(perm.id);
            }
            perm.is_checked = e.target.checked;
          }
        }
        if(item.sub_module_name === "Invoices" && !e.target.checked){
          for (let perm of rIPermissions) {
            if (perm.id === permissionId) {
                permissionIdsUnChecked.push(perm.id);
                getPermissionSubmodule(items, "Recurring Invoices").is_full_access = false;
                perm.is_checked = e.target.checked;
            }
          }
        }
      }
    }
    /**
     * id
     * 1.Create, 2.Read, 3.Update, 4.Delete
     */
    for (let item of items) {
      if (item.sub_id === submoduleId) {
        let permissions = item.permissions;
        for (let perm of permissions) {
          if (perm.id === 1) {
            if (
              permissionIdsChecked.includes(3) ||
              permissionIdsChecked.includes(4)
            ) {
              perm.is_checked = true;
            }
            if (permissionIdsUnChecked.includes(2)) {
              perm.is_checked = false;
              if(item.sub_module_name === "Invoices" && !e.target.checked)  getSubmoduleEachPermission(rIPermissions, perm.id).is_checked = false 
            }
          } else if (perm.id === 2) {
            if (
              permissionIdsChecked.includes(1) ||
              permissionIdsChecked.includes(3) ||
              permissionIdsChecked.includes(4)
            ) {
              perm.is_checked = true;
            }
          } else if (perm.id === 3) {
            if (permissionIdsChecked.includes(4)) {
              perm.is_checked = true;
            }
            if (
              permissionIdsUnChecked.includes(1) ||
              permissionIdsUnChecked.includes(2)
            ) {
              perm.is_checked = false;
              if(item.sub_module_name === "Invoices" && !e.target.checked)  getSubmoduleEachPermission(rIPermissions, perm.id).is_checked = false 
            }
          } else if (perm.id === 4) {
            if (
              permissionIdsUnChecked.includes(1) ||
              permissionIdsUnChecked.includes(2) ||
              permissionIdsUnChecked.includes(3)
            ) {
              perm.is_checked = false;
              if(item.sub_module_name === "Invoices" && !e.target.checked) getSubmoduleEachPermission(rIPermissions, perm.id).is_checked = false 
            }
          }
        }
      }
    }

    for (let item of items) {
      if (item.sub_id === submoduleId) {
        let permissions = item.permissions;
        for (let perm of permissions) {
          if (perm.is_checked === true) {
            count++;
          }
        }
      }
    }

    let permissionLength = 0;
    for (let item of items) {
      if (item.sub_id === submoduleId) {
        if (count === item.permissions.length) {
          item.is_full_access = true;
        } else {
          item.is_full_access = false;
        }
        if (item.sub_module_name === "Invoices" && count === item.permissions.length) {
          const recurringInvoieModule = items.find((item: any) => item.sub_module_name === "Recurring Invoices")
          recurringInvoieModule.is_full_access = false;
        }
      }

      if (item.module_id === moduleId) {
        let permissions = item.permissions;
        permissionLength = permissionLength + permissions.length;
        for (let perm of permissions) {
          if (perm.is_checked === true) {
            totalPermissionCount++;
          }
        }
      }
    }
    if (subModuleName === "Users" || subModuleName === "Roles") {
      items = handleRolesPermission(items, moduleId);
    }

    const modules = [...moduleListForFullAccess];
    for (let module of modules) {
      if (module.id === moduleId) {
        if (permissionLength === totalPermissionCount) {
          module.grant_full_access = true;
        } else {
          module.grant_full_access = false;
        }
      }
    }
    handleFullAccessOfPermissionChange(modules);
    checkModuleStatus(moduleName, totalPermissionCount, permissionLength);
    setSubmodulePermissions(items);
    setModuleListForFullAccess(modules);
    compare(items, moduleId);
  };
  /**
   * Function to handle role permission with respect to Users permission
   */
  const handleRolesPermission = (
    items: CustomSubModuleValues[],
    moduleId: number
  ) => {
    let modulesArray = items
      .filter((item: any) => item.module_id === moduleId)
      .map((module: any) => {
        return module;
      });
    let isUserViewAccess = false;
    for (let modules of modulesArray) {
      if (modules.sub_module_name === "Users") {
        for (let perm of modules.permissions) {
          if (perm.name === "Read" && perm.is_checked === true) {
            isUserViewAccess = true;
          }
        }
      }
    }
    for (let item of items) {
      if (item.sub_module_name === "Roles") {
        for (let perm of item.permissions) {
          if (perm.name === "Read") {
            if (isUserViewAccess) {
              perm.is_checked = true;
            }
          }
        }
      }
    }
    return items;
  };
  /**
   * Function to check / uncheck "Grant Full access(similar to admin user)" check box based on other input changes
   */
  const handleFullAccessOfPermissionChange = (modules: ModuleListValues[]) => {
    let moduleArrayLength = modules.length;
    let moduleCount = 0;
    for (let module of modules) {
      if (module.grant_full_access === true) {
        moduleCount++;
      }
    }
    if (moduleCount === moduleArrayLength) {
      setInputs((values) => ({ ...values, grant_full_access: true }));
    } else {
      setInputs((values) => ({ ...values, grant_full_access: false }));
    }
  };
  /**
   * Update the module name to make module name same as module status name
   */
  const getUpdatedModuleName = (moduleName: string) => {
    moduleName = moduleName
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    moduleName = moduleName.charAt(0).toLowerCase() + moduleName.substring(1);
    return moduleName.split(" ").join("").concat("Status");
  };
  const updateModuleStatus = (moduleName: string, className: string) => {
    setModuleStatus((prevState) => ({
      ...prevState,
      [moduleName]: className,
    }));
  };
  /**
   * Function to check module status of each module
   */
  const checkModuleStatus = (
    moduleName: string,
    totalPermissionCount: number,
    permissionLength: number
  ) => {
    const updatedModuleName = getUpdatedModuleName(moduleName);

    if (totalPermissionCount === 0) {
      updateModuleStatus(updatedModuleName, "no-access");
    } else if (permissionLength === totalPermissionCount) {
      updateModuleStatus(updatedModuleName, "full-access");
    } else if (totalPermissionCount < permissionLength) {
      updateModuleStatus(updatedModuleName, "part-access");
    }
  };
  /**
   * Function to compare the changes in the permission matrix to give "unsaved" tag in each module
   */
  const moduleLists = JSON.parse(JSON.stringify(moduleListValues));
  const compare = (items: CustomSubModuleValues[], moduleId: number) => {
    const initialPermissions = initialSubmodulePermissions
      .filter((module) => module.module_id === moduleId)
      .map((submodule) => {
        return submodule;
      });

    const existingPermissions = items
      .filter((module) => module.module_id === moduleId)
      .map((submodule) => {
        return submodule;
      });
    /**
     * Deep checking of two nested object using lodash
     */
    for (let modules of moduleLists) {
      if (modules.id === moduleId) {
        if (_.isEqual(initialPermissions, existingPermissions)) {
          modules.is_unsaved = false;
        } else {
          modules.is_unsaved = true;
        }
      }
    }
    let unsavedExist = true;
    for (let modules of moduleLists) {
      if (modules?.is_unsaved === true) {
        unsavedExist = false;
        setIsPermissionChanged(true);
        break;
      }
    }
    if (unsavedExist === true) {
      setIsPermissionChanged(false);
    }

    setModuleListValues(moduleLists);
  };
  /**
   * Select / Unselect Full Access check box handler
   */
  const handleFullAccessOfEachSubModule = (
    e: any,
    moduleId: number,
    subModuleId: number,
    moduleName: string,
    subModuleName: string
  ) => {
    let items = JSON.parse(JSON.stringify(submodulePermissions));
    for (let item of items) {
      if (item.sub_id === subModuleId) {
        item.is_full_access = e.target.checked;
        let permissions = item.permissions;
        for (let perm of permissions) {
          perm.is_checked = e.target.checked;
        }
        if(item.sub_module_name === "Invoices" && !e.target.checked){
          const recurringInvoieModule = getPermissionSubmodule(items, "Recurring Invoices")
          recurringInvoieModule.is_full_access = false;
          let permissions = recurringInvoieModule.permissions;
          for (let perm of permissions) {
            perm.is_checked = e.target.checked;
          }
        }
      }
    }
    let permissionLength = 0;
    for (let item of items) {
      if (item.module_id === moduleId) {
        let permissions = item.permissions;
        permissionLength = permissionLength + permissions.length;
        for (let perm of permissions) {
          if (perm.is_checked === true) {
            totalPermissionCount++;
          }
        }
      }
    }
    const modules = [...moduleListForFullAccess];
    for (let module of modules) {
      if (module.id === moduleId) {
        if (permissionLength === totalPermissionCount) {
          module.grant_full_access = true;
        } else {
          module.grant_full_access = false;
        }
      }
    }
    if (subModuleName === "Users" || subModuleName === "Roles") {
      items = handleRolesPermission(items, moduleId);
    }
    handleFullAccessOfPermissionChange(modules);
    checkModuleStatus(moduleName, totalPermissionCount, permissionLength);
    setSubmodulePermissions(items);
    setModuleListForFullAccess(modules);
    compare(items, moduleId);
  };
  /**
   * Select / Unselect Grant Full access check box
   */
  const grantFullAccessOfEachModule = (
    e: any,
    moduleName: string,
    moduleId: number
  ) => {
    const items = JSON.parse(JSON.stringify(submodulePermissions));
    for (let item of items) {
      if (item.module_name === moduleName) {
        item.is_full_access = e.target.checked;
        let permissions = item.permissions;
        for (let perm of permissions) {
          perm.is_checked = e.target.checked;
        }
      }
    }
    let permissionLength = 0;

    for (let item of items) {
      if (item.module_name === moduleName) {
        let permissions = item.permissions;
        permissionLength = permissionLength + permissions.length;
        for (let perm of permissions) {
          if (perm.is_checked === true) {
            totalPermissionCount++;
          }
        }
      }
    }

    const modules = [...moduleListForFullAccess];
    for (let module of modules) {
      if (module.id === moduleId) {
        module.grant_full_access = e.target.checked;
      }
    }
    handleFullAccessOfPermissionChange(modules);
    checkModuleStatus(moduleName, totalPermissionCount, permissionLength);
    setSubmodulePermissions(items);
    setModuleListForFullAccess(modules);
    compare(items, moduleId);
  };
  /**
   * Function to restore previous state of permission matrix
   */
  const resetToDefault = (e: any, moduleId: number, moduleName: string) => {
    e.preventDefault();
    const initialPermissions = initialSubmodulePermissions
      .filter((module) => module.module_id === moduleId)
      .map((submodule) => {
        return submodule;
      });
    const items = JSON.parse(JSON.stringify(submodulePermissions));

    for (let item of items) {
      for (let values of initialPermissions) {
        if (values.sub_id === item.sub_id) {
          item.is_full_access = values.is_full_access;
          item.permissions = values.permissions;
        }
      }
    }
    let permissionLength = 0;

    for (let item of items) {
      if (item.module_id === moduleId) {
        let permissions = item.permissions;
        permissionLength = permissionLength + permissions.length;
        for (let perm of permissions) {
          if (perm.is_checked === true) {
            totalPermissionCount++;
          }
        }
      }
    }
    const modules = [...moduleListForFullAccess];
    for (let module of modules) {
      if (module.id === moduleId) {
        if (permissionLength === totalPermissionCount) {
          module.grant_full_access = true;
        } else {
          module.grant_full_access = false;
        }
      }
    }
    handleFullAccessOfPermissionChange(modules);
    checkModuleStatus(moduleName, totalPermissionCount, permissionLength);
    setSubmodulePermissions(items);
    setModuleListForFullAccess(modules);
    compare(items, moduleId);
  };

  return (
    <div className="card-body">
      <div className="row g-0">
        <div className="col">
          <form
            className="permission-control-form w-100"
            onSubmit={formSubmitHandler}
          >
            <RoleFormInputs
              inputs={inputs}
              formErrors={formErrors}
              backendError={backendError}
              inputChangeHandler={inputChangeHandler}
              grantFullAccessHandler={grantFullAccessHandler}
            />
            <PermissionMatrix
              handlePermissionChange={handlePermissionChange}
              isLoading={isLoading}
              moduleList={moduleList}
              moduleListForFullAccess={moduleListForFullAccess}
              moduleListValues={moduleListValues}
              submodulePermissions={submodulePermissions}
              handleFullAccessOfEachSubModule={handleFullAccessOfEachSubModule}
              grantFullAccessOfEachModule={grantFullAccessOfEachModule}
              moduleStatus={moduleStatus}
              resetToDefault={resetToDefault}
            />
            <RoleFormFooter
              submodulePermissions={submodulePermissions}
              changeInEditRole={changeInEditRole}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default RoleForm;
