import React, { useEffect, useState } from "react";
import Select from "react-select";
import { customSelectStyle } from "../../../../common/components/SelectCustomStyle";
import useCommonData from "../../../../hooks/useCommon";
import { getReason } from "../../creditNoteSlice";

type Props = {
  reason: string;
  onChange: (e: { label: string; value: string }) => void;
  error: string;
};
function ReasonSelect({ reason, onChange, error }: Props) {
  const { dispatch, currentUserInfo } = useCommonData();
  const [reasonOptions, setReasonOptions] = useState<string[]>([]);

  useEffect(() => {
    getReasonDropdown();
  }, []);

  const getReasonDropdown = async () => {
    const responseAction = await dispatch(
      getReason(currentUserInfo.organization_id)
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        let reasonData = response.map((reason: string, index: number) => {
          return {
            label: reason,
            value: reason,
          };
        });
        setReasonOptions(reasonData);
      }
    }
  };

  return (
    <>
      <label htmlFor="reason" className="required">
        Reason
      </label>
      <Select
        name="reason"
        options={reasonOptions}
        className={`${error ? "error" : ""} form-select custom-select mt-0`}
        value={
          reason === ""
            ? ""
            : {
                label: reason,
                value: reason,
              }
        }
        placeholder="Choose reason"
        onChange={(e: any) => onChange(e)}
        styles={customSelectStyle}
        isSearchable={false}
      />
      <span className="error">{error}</span>
    </>
  );
}

export default ReasonSelect;
