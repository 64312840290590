import Edit from "../../../../common/components/kebabMenu/Edit";
import KebabMenu from "../../../../common/components/kebabMenu/KebabMenu";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import { BillingSideMenuForm } from "../../calendarBillingSideMenu/BillingSideMenuForm";
import Delete from "../../../../common/components/Delete";

export type ListActionProps = {
  value: any;
  getSiteWideBilling: any;
  onClickDelete?: () => void;
};

function ListActions(props: ListActionProps) {
  const { subscriptionRolePermission } = usePermissionHandler();
  return (
    <KebabMenu>
      {subscriptionRolePermission.includes("Update") ? 
      <li>
        <BillingSideMenuForm id={props.value.id} getSiteWideBilling={props.getSiteWideBilling}/>
      </li> 
      : null }
      {subscriptionRolePermission.includes("Delete") ? <li>
        <Delete
          className="dropdown-item delete"
          deleteIcon={true}
          isMutable
          onClick={props.onClickDelete ? props.onClickDelete : ()=> {}}
          isLocked={false}
          lockDate={""}
          name="Side Wide Billing"
        />
      </li> : null }
    </KebabMenu>
  );
}

export default ListActions;
