import { colors } from "../../constants/colors";
import "../styles/AlertModal.css";
import { RadioButtonGroup } from "./radioButtonGroup/radioButtonGroup";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import CustomDatepickerFormic from "../../utils/atoms/datepickerFormic";
import {
  useEffect
} from "react";
import Select from "react-select";
import { SelectCustomStyleCustomer } from "../../common/components/SelectCustomStyleCustomer";
import CustomDatepicker from "../../utils/atoms/datepicker";
import { getNextDate } from "../../../helpers/subscriptionHelper";
import { getFormatedDate } from "../../../helpers/helper";

type Props = {
  heading: string;
  subHeading: string;
  triggerAction?: string;
  transactionName: string | string[];
  next_renewal?: string;
  modalCancelHandler: () => void;
  modalSubmitHandler?: () => void;
  formik: any;
  chargeOption: any;
  is_edit_scheduled: boolean;
  editStop?: any;
};

const StopResumeSubscriptionModal = (props: Props) => {
  useEffect(() => {
    props.formik.setFieldValue("pause_type", "immediately");
    props.formik.setFieldValue("pause_date", getFormatedDate(new Date()))
  }, [props.transactionName]);

  let DurationTypeDropdown = [
    { label: "Immediately", value: "immediately" },
    { label: "On next renewal", value: "end_of_current_term" },
    { label: "On specific date", value: "specific_date" },
  ];

  if (props.is_edit_scheduled) {
    DurationTypeDropdown = [{ label: "Immediately", value: "immediately" }];
  }

  const onFormSelectChange = (value: any, name: any) => {
    if (name.name === "unbilled_charge") {
      props.formik.setFieldValue(name.name, value.value);
    }
  };

  const unbilledChargesDropdown = [
    {
      label: "Retain as Unbilled Charges",
      value: "RETAIN_AS_UNBILLED_CHARGES",
    },
    { label: "Invoice Charges", value: "INVOICE_CHARGES" },
  ];

  const formatDate = (dateString: any) => {
    const [day, month, year] = dateString.split("-");
    const dateObject = new Date(year, month - 1, day);
    return dateObject;
  };

  return (
    <>
      <div
        className="modal alert-modal fade"
        id="stopResumeMessageModal"
        tabIndex={-1}
        aria-labelledby="delete_title"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered delete-modal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header position-relative">
                <button
                  type="button"
                  className={`close-btn close-delete-btn pull-right position-absolute`}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g fill="none" fillRule="evenodd">
                      <path d="M0 0h24v24H0z" />
                      <path
                        fill={colors.slate}
                        fillRule="nonzero"
                        d="m12 10.586 4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
                      />
                    </g>
                  </svg>
                </button>
                <h5 className="modal-title" id="delete_title">
                  {props.heading}
                </h5>
              </div>
              <form>
                <div className="modal-body subscription-modal-body">
                  <RadioButtonGroup
                    name="pause_type"
                    id="pause_type"
                    valuesArray={
                      props.triggerAction === "Resume"
                        ? DurationTypeDropdown.filter(
                            (eachData) => eachData.label != "On next renewal"
                          )
                        : DurationTypeDropdown
                    }
                    value={props.formik.values.pause_type}
                    icon={
                      <span>
                        <RadioButtonUncheckedIcon />
                      </span>
                    }
                    checkedIcon={
                      <span>
                        <RadioButtonCheckedIcon className="radio-btn-tds" />
                      </span>
                    }
                    isDisabled={false}
                    formik={props.formik}
                    onClick={(e) => {props.formik.setFieldValue("pause_type", e)
                    if (e === 'specific_date') {
                      props.formik.setFieldValue("pause_date", getNextDate())
                    }
                    else if (e === 'end_of_current_term'){ 
                      props.formik.setFieldValue("pause_date", formatDate(props.next_renewal))
                    }
                    else if ( e === 'immediately') { 
                      props.formik.setFieldValue("pause_date", getFormatedDate(new Date()))
                    }
                    }}
                  />
                  <div>
                    <div className="col-12 col-lg-4 mw-325">
                      {/* {props.formik.values.pause_type === "specific_date" && ( */}
                      <>
                        <label
                          className="pause-date-label"
                          htmlFor="pause_date"
                        >
                          { props.triggerAction === "Resume" ? "Resume Date" : "Stop Date"}
                        </label>
                        <br />
                        <div className="date-selector-wrapper">
                          <CustomDatepickerFormic
                            date={props.formik.values.pause_date
                              ? new Date(props.formik.values.pause_date)
                              : null}
                            type=""
                            name="pause_date"
                            id="pause_date"
                            error={""}
                            zIndex={0}
                            enableFutureDate={true}
                            formik={props.formik}
                            disabled={
                              props.formik.values.pause_type === "specific_date"
                                ? false
                                : true
                            }
                            isDisabled={
                              props.formik.values.pause_type === "specific_date"
                                ? false
                                : true
                            }
                            minDate={new Date()}
                          />
                        </div>
                        <span className="error">{}</span>
                      </>
                      {/* )} */}
                      {props.triggerAction === "Stop" &&
                        props.chargeOption === "UNBILLED" &&
                        props.formik.values.pause_type === "immediately" && (
                          <>
                            <label
                              htmlFor="unbilled_charge"
                              style={{
                                fontWeight: "400",
                                marginBottom: "8px",
                                fontSize: "14px",
                              }}
                            >
                              Unbilled Charges
                            </label>
                            <Select
                              name="unbilled_charge"
                              id="unbilled_charge"
                              options={unbilledChargesDropdown}
                              className={`discount-period-count trial-period-type-radius state-select form-select custom-select`}
                              onChange={onFormSelectChange}
                              isSearchable={false}
                              placeholder=""
                              styles={SelectCustomStyleCustomer}
                              value={
                                props.formik.values.pause_type === "immediately"
                                  ? unbilledChargesDropdown.filter(
                                      (data) =>
                                        data.value ===
                                        props.formik.values.unbilled_charge
                                    )[0]
                                  : null
                              }
                              isDisabled={false}
                            />
                          </>
                        )}
                    </div>
                  </div>
                </div>
                <div
                  className="modal-footer subscription-modal-footer"
                  id="delete-button"
                >
                  <button
                    type="button"
                    className="btn btn-ok btn-green btn-color-green subscription-save-btn"
                    onClick={(e: any) => {
                      e.preventDefault();
                      props.formik.submitForm();
                    }}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="btn btn-green btn-color-green"
                    onClick={props.modalCancelHandler}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StopResumeSubscriptionModal;
