import { ErrorToaster } from "../../../../../helpers/toastHelper";
import { ReportTagFormValues } from "../../../../../types";
import { DeleteAlertModal } from "../../../../common/components/deleteModal/DeleteAlertModal";
import KebabMenu from "../../../../common/components/kebabMenu/KebabMenu";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import "../../ReportingTag.css";
import { useDeleteTag } from "../../hooks/useDeleteTag";
import { ReportingTagFormModal } from "../../reportingTagForm/ReportingTagFormModal";

type ListActionProps = {
  tagId: number;
  isEditDisabled: boolean;
  isDeleteDisabled: boolean;
  setReportingTagsList: React.SetStateAction<any>;
  tagDetails: ReportTagFormValues;
  isAssociated: boolean;
};
export const ListActions = (props: ListActionProps) => {
  const { deleteTag } = useDeleteTag();
  const { reportinTagsPermission } = usePermissionHandler();

  return reportinTagsPermission.includes("Update") ||
    reportinTagsPermission.includes("Delete") ? (
    <KebabMenu>
      {reportinTagsPermission.includes("Update") ? (
        <li
          className="dropdown-item d-flex w-100"
          style={
            props.isEditDisabled === true
              ? { pointerEvents: "none", opacity: "0.5" }
              : {}
          }
        >
          <ReportingTagFormModal
            editId={props.tagId}
            tagDetails={props.tagDetails}
            buttonClass="reporting-tag-edit-modal-btn no-appearance p-0 w-100 d-flex"
            wrapperClass="reporting-tag-edit-modal w-100 p-0"
            setReportingTagsList={props.setReportingTagsList}
          />
        </li>
      ) : null}
      {reportinTagsPermission.includes("Delete") ? (
        <li
          style={
            props.isDeleteDisabled === true
              ? { pointerEvents: "none", opacity: "0.5" }
              : {}
          }
        >
          <DeleteAlertModal
            onCancel={() => {}}
            onSubmit={() => {
              deleteTag(props.tagId, props.setReportingTagsList);
            }}
            isSubmitDisabled={props.isDeleteDisabled}
            isButtonDisabled={props.isAssociated}
            deleteMessageContent={
              <span>
                Reporting tag will be deleted across the system since it is not
                associated with any transactions.
              </span>
            }
            deleteModalTitle="Delete Reporting Tag"
            disabledActionFunction={() => {
              ErrorToaster(
                "Reporting tag cannot be deleted since it has transactions associated with it!"
              );
            }}
          />
        </li>
      ) : null}
      <></>
    </KebabMenu>
  ) : null;
};
