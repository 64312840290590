import { useRef, useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import checkObjectFieldChanged from "../../../../utils/checkObjectFieldChanged";

import {
  TransactionHeader,
  AttachFiles,
  EmailTo,
  ScrollToFieldError,
  FormActionButtons,
} from "../../../../common/components";
import { PMFormValidation } from "./validations/PMFormValidation";
import PaymentDetailsForm from "./components/paymentDetails/PaymentDetailsForm";
import UnpaidBills from "./components/unpaidBills/UnpaidBills";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import DeleteBillPayment from "../Delete";
import { ExcessAllocationAlertModal } from "../excessAllocationAlert/excessAllocationAlertModal";
import {
  attachPaymentMadeFiles,
  deletePaymentMadeFile,
  setIsApiPending,
  deletePaymentMadeRecipients,
  updateBillPayment,
  unpaidVendorBillsList,
  paymentMadeDetails,
} from "../../paymentsMadeSlice";
import useCommonData from "../../../../hooks/useCommon";
import { setLoaderState } from "../../../../common/commonSlice";
import { useAppSelector } from "../../../../../app/hooks";
import {
  createBillPayment,
  getAmountCalculationResult,
  sendPaymentMadeData,
  getPaymentMadePdfLink,
  billPaymentList,
  isApiPendingSelector,
  setAmountCalculationResult,
} from "../../paymentsMadeSlice";
import { ObjectType } from "../../../../../types";
import {
  DateFormatHandler,
  downloadUsingURL,
  getFormatedDate,
} from "../../../../../helpers/helper";
import {
  ErrorToaster,
  SuccessToaster,
} from "../../../../../helpers/toastHelper";
import usePreservedLocation from "../../../../hooks/usePreservedLocation";
import { exchangeRateApiStatusSelector } from "../../../../common/components/exchangeRate/exchangeRateSlice";
import usePaymentMadeForm from "./usePaymentMadeForm";
import { message } from "../../../../constants/messages";
import "../../../paymentsMade/PaymentsMade.css";
import { setErrorID } from "../../../../../appSlice";
import { noAccessErrorCodes } from "../../../../constants/constants";
import { initialPMFormValues } from "./initialFormValues";
import { usePm } from "./usePm";
import { TransactionEditTagRemovalAlertModal } from "../../../paymentsReceived/components/paymentReceivedForm/imports";

// import "../../../invoice/components/invoiceForm/invoiceForm.css";

function PaymentMadeForm() {
  const [searchParams] = useSearchParams();
  let redirectUrl = searchParams.get("redirect");
  let replace = searchParams.get("replace");
  const { editId, vendorId } = useParams();
  const { paymentsMadeRolePermission } = usePermissionHandler();
  const openExcessAllocationAlertRef = useRef<any>();
  const openEditAlertRef = useRef<any>();
  const customFieldRef = useRef<any>();
  const { dispatch, navigate, currentUserInfo } = useCommonData();
  const totalDetails = useAppSelector(getAmountCalculationResult).total_details;
  const [buttonAction, setButtonAction] = useState<{
    status: string;
    action: string;
  }>({ status: "", action: "" });
  const [didAlertModalOpen, setDidAlertModalOpen] = useState(false);
  const [deleteBillPaymentId, setDeleteBillPaymentId] = useState(-1);
  const [deleteBillPaymentItemId, setDeleteBillPaymentItemId] = useState("");
  const isCurrencyApiPending = useAppSelector(exchangeRateApiStatusSelector);
  const isApiPending = useAppSelector(isApiPendingSelector);
  const [emailError, setEmailError] = useState("");

  const [initialValues, setInitialValues] = useState(initialPMFormValues);
  const [isUnpaidBillExist, setIsUnpaidBillExist] = useState(false);

  useEffect(() => {
    dispatch(setLoaderState(true));
    if (editId) {
      paymentMadeDetailsResponse();
    }
  }, [currentUserInfo.organization_id]);

  useEffect(() => {
    return () => {
      let initialData = {
        total_details: {
          total: 0,
          amount_used_payment: 0,
          amount_paid: 0,
          bank_charges: 0,
          amount_excess: 0,
        },
        allocatable_amount: 0,
      };
      dispatch(setAmountCalculationResult(initialData));
    };
  }, []);

  useEffect(() => {
    if (vendorId) {
      setInitialValues((prevInitialValues: any) => {
        return {
          ...prevInitialValues,
          vendor_id: vendorId,
          is_currency_changed: true,
        };
      });
    }
  }, [vendorId]);

  useEffect(() => {
    if (!editId) {
      formik.resetForm();
      dispatch(
        setAmountCalculationResult({
          total_details: {
            total: 0,
            amount_used_payment: 0,
            amount_paid: 0,
            bank_charges: 0,
            amount_excess: 0,
          },
          allocatable_amount: 0,
        })
      );
    }
  }, [currentUserInfo.organization_id]);

  /**
   * Fetch unpaid vendor bills list
   */
  const getUnpaidBillsListOfVendor = async (vendorsId: number) => {
    if (vendorsId) {
      const responseAction = await dispatch(
        unpaidVendorBillsList({
          vendorId: vendorsId,
          orgId: currentUserInfo.organization_id,
        })
      );
      if (responseAction.payload) {
        return responseAction.payload;
      }
    }
  };

  const submitBtnClickHandler = async (status: string, action: string) => {
    formik.setFieldValue("pm_status", status);
    formik.setFieldValue("action", action);
    // validating forms
    await customFieldRef.current.handleSubmit();
    formik.submitForm();

    if (action === "Send" && formik.values.email_to.length === 0) {
      setEmailError(message().pm.emailRequired);
      return;
    } else {
      setEmailError("");
    }

    if (
      Object.keys(formik.errors).length > 0 ||
      customFieldRef.current.errors
    ) {
      return;
    } else if (
      Object.keys(formik.errors).length === 0 &&
      !customFieldRef.current.errors &&
      totalDetails.amount_excess &&
      totalDetails.amount_excess > 0 &&
      status !== "Draft" &&
      (didAlertModalOpen || !editId)
    ) {
      formik.validateForm().then((res: any) => {
        if (!(Object.keys(res).length > 0)) {
          openExcessAllocationAlertRef.current?.openAlertModal &&
            openExcessAllocationAlertRef.current?.openAlertModal();
        }
      });
    } else {
      if (
        editId &&
        currentUserInfo.is_tags_enabled &&
        didAlertModalOpen === false
      ) {
        setDidAlertModalOpen(true);
        openEditAlertRef.current?.openAlertModal &&
          openEditAlertRef.current?.openAlertModal();
        return;
      } else {
        submitHandler(status, action);
      }
    }
  };

  const submitHandler = (status: string, action: string) => {
    if (editId) {
      updatePaymentSubmit(status, action);
    } else {
      createPaymentSubmit(status, action);
    }
  };

  const updatePaymentSubmit = async (status: string, action: string) => {
    dispatch(setLoaderState(true));
    let deletedIds = [] as Number[];

    let unPaidBillRequestPayload = formik.values.unpaid_bill_lines
      .filter((bill: any) => {
        if (
          Number(bill.payment_amount) !== 0 &&
          bill.payment_amount !== undefined &&
          bill.payment_amount !== ""
        ) {
          return true;
        } else {
          if (bill.id) {
            deletedIds.push(bill.id);
          }
          return false;
        }
      })
      .map((unpaidBill: ObjectType, index: number) => {
        let isFieldChanged = checkObjectFieldChanged(
          unpaidBill,
          formik.initialValues["unpaid_bill_lines"][index]
        );
        return {
          id: unpaidBill.id || null,
          bill_number: unpaidBill.bill_number,
          bill_date: unpaidBill.bill_date,
          bill_amount: unpaidBill.bill_amount,
          amount_due: unpaidBill.amount_due,
          payment_amount: unpaidBill.payment_amount
            ? unpaidBill.payment_amount
            : 0,
          allocation_date: getFormatedDate(unpaidBill.allocation_date),
          conversion_id: unpaidBill.conversion_id,
          bill_id: unpaidBill.bill_id,
          forex_amount: unpaidBill.forex_amount,
          is_line_item_changed:
            isFieldChanged
              ? true
              : false,
        };
      });
    let payload = {
      amount_paid: formik.values.amount_paid,
      amount_paid_bank_currency: formik.values.amount_paid_bank_currency
        ? formik.values.amount_paid_bank_currency
        : 0,
      bank_charges_account_id: formik.values.bank_charges_account_id,
      bank_charges_account_name: formik.values.bank_charges_account_name,
      bank_charges_amount: formik.values.bank_charges_amount
        ? formik.values.bank_charges_amount
        : 0,
      bank_charges_bank_currency: formik.values.bank_charges_bank_currency
        ? formik.values.bank_charges_bank_currency
        : 0,
      bank_conversion_id: formik.values.bank_conversion_id,
      bank_currency_id: formik.values.bank_currency_id,
      check_expiry:
        formik.values.payment_mode === "Check" && formik.values.check_expiry
          ? formik.values.check_expiry
          : null,
      check_date:
        formik.values.payment_mode === "Check"
          ? formik.values.check_date
            ? formik.values.check_date
            : null
          : null,
      check_expiry_in:
        formik.values.payment_mode === "Check"
          ? formik.values.check_expiry_in
          : null,

      check_number:
        formik.values.payment_mode === "Check"
          ? formik.values.check_number
          : "",
      conversion_id: formik.values.conversion_id,
      currency_id: formik.values.currency_id,
      custom_fields: customFieldRef.current.values,
      email_to: formik.values.email_to,
      file_ids: formik.values.file_ids,
      is_locked: false,
      lock_date: "",
      notes: formik.values.notes,
      paid_through_account_id: formik.values.paid_through_account_id,
      paid_through_account_name: formik.values.paid_through_account_name,
      payment_date: formik.values.payment_date,
      payment_mode: formik.values.payment_mode,
      payment_number: formik.values.payment_number,
      reference_number: formik.values.reference_number,
      unpaid_bill_lines: unPaidBillRequestPayload,
      vendor_id: formik.values.vendor_id,
      vendor_name: formik.values.vendor_name,
      deleted_allocation_ids: deletedIds,
    };
    if (formik.values.delete_email_ids.length) {
      await dispatch(
        deletePaymentMadeRecipients({
          paymentMadeId: Number(editId),
          emailIds: formik.values.delete_email_ids,
          orgId: currentUserInfo.organization_id,
        })
      );
    }
    const responseAction = await dispatch(
      updateBillPayment({
        billPaymentId: Number(editId),
        values: payload as any,
        status: status === "Draft" ? "Draft" : "Open",
        isVendorChanged:
          formik.initialValues.vendor_id !== formik.values.vendor_id
            ? true
            : false,
        orgId: currentUserInfo.organization_id,
      })
    );
    const response = responseAction.payload;
    if (response && Object.keys(response).length && !("error" in response)) {
      dispatch(setLoaderState(false));
      let paymentMadeId = response.id;
      if (
        status === "Open" &&
        action === "Send" &&
        formik.values.email_to.length > 0
      ) {
        await dispatch(
          sendPaymentMadeData({
            paymentMadeId: paymentMadeId,
            emails: formik.values.email_to,
            orgId: currentUserInfo.organization_id,
          })
        );
      }
      let downloadResult;
      if (status === "Open" && action === "Download") {
        const responseAction = await dispatch(
          getPaymentMadePdfLink({
            paymentMadeId: paymentMadeId,
            orgId: currentUserInfo.organization_id,
          })
        );
        if (responseAction.payload) {
          const response = responseAction.payload;
          if (Object.keys(response).length && !("error" in response)) {
            downloadResult = await downloadUsingURL(
              response.download_link,
              paymentMadeId + ".pdf"
            );
          }
        }
      }
      if (status === "Draft") navigate(-1);
      else {
        redirectUrl
          ? navigate(
              `/payment-made/detail/${paymentMadeId}?redirect=${redirectUrl}`,
              {
                replace: true,
              }
            )
          : navigate(`/payment-made/detail/${paymentMadeId}`, {
              replace: true,
            });
      }
      SuccessToaster(
        "Bill payment updated successfully!",
        "bill-payment-update-success"
      );
      if (status === "Open" && action === "Download" && downloadResult) {
        SuccessToaster(
          "Downloaded Successfully!",
          "bill-payment-download-success"
        );
      }
    } else if (response && "error" in response) {
      dispatch(setLoaderState(false));
    } else {
      dispatch(setLoaderState(false));
    }
  };

  const createPaymentSubmit = async (status: string, action: string) => {
    dispatch(setLoaderState(true));
    let unPaidBillRequestPayload = formik.values.unpaid_bill_lines
      .filter((bill: any) => {
        if (
          Number(bill.payment_amount) !== 0 &&
          bill.payment_amount !== undefined &&
          bill.payment_amount !== ""
        ) {
          return true;
        }
      })
      .map((unpaidBill: ObjectType) => {
        return {
          id: unpaidBill.id,
          bill_number: unpaidBill.bill_number,
          bill_date: unpaidBill.bill_date,
          bill_amount: unpaidBill.bill_amount,
          amount_due: unpaidBill.amount_due,
          payment_amount: unpaidBill.payment_amount
            ? unpaidBill.payment_amount
            : 0,
          allocation_date: getFormatedDate(unpaidBill.allocation_date),
          conversion_id: unpaidBill.conversion_id,
          bill_id: unpaidBill.bill_id,
          forex_amount: unpaidBill.forex_amount,
        };
      });

    let bank_charges = formik.values.bank_charges_amount;
    // if (formik.values.bank_currency_id === formik.values.currency_id) {
    //   bank_charges = formik.values.bank_charges_bank_currency;
    // }
    let payload = {
      amount_paid: formik.values.amount_paid,
      amount_paid_bank_currency: formik.values.amount_paid_bank_currency
        ? formik.values.amount_paid_bank_currency
        : 0,
      bank_charges_account_id: formik.values.bank_charges_account_id
        ? formik.values.bank_charges_account_id
        : null,
      bank_charges_account_name: formik.values.bank_charges_account_name,
      bank_charges_amount: bank_charges ? bank_charges : 0,
      bank_charges_bank_currency: formik.values.bank_charges_bank_currency
        ? formik.values.bank_charges_bank_currency
        : 0,
      bank_conversion_id:
        currentUserInfo.organization_currency === formik.values.bank_currency_id
          ? formik.values.conversion_id
          : formik.values.bank_conversion_id,
      bank_currency_id: formik.values.bank_currency_id,
      check_expiry:
        formik.values.check_expiry && formik.values.payment_mode === "Check"
          ? formik.values.check_expiry
          : null,
      check_number:
        formik.values.check_number && formik.values.payment_mode === "Check"
          ? formik.values.check_number
          : null,
      check_date:
        formik.values.payment_mode === "Check"
          ? formik.values.check_date
            ? formik.values.check_date
            : null
          : null,
      check_expiry_in:
        formik.values.payment_mode === "Check"
          ? formik.values.check_expiry_in
          : null,
      conversion_id: formik.values.conversion_id,
      currency_id: formik.values.currency_id,
      custom_fields: customFieldRef.current.values,
      email_to: formik.values.email_to,
      file_ids: formik.values.file_ids,
      is_locked: false,
      lock_date: "",
      notes: formik.values.notes,
      paid_through_account_id: formik.values.paid_through_account_id,
      paid_through_account_name: formik.values.paid_through_account_name,
      payment_date: formik.values.payment_date,
      payment_mode: formik.values.payment_mode,
      payment_number: formik.values.payment_number,
      reference_number: formik.values.reference_number,
      unpaid_bill_lines: unPaidBillRequestPayload,
      vendor_id: formik.values.vendor_id,
      vendor_name: formik.values.vendor_name,
    };

    const responseAction = await dispatch(
      createBillPayment({
        values: payload as any,
        status: status,
        orgId: currentUserInfo.organization_id,
      })
    );

    const response = responseAction.payload;
    if (response && Object.keys(response).length && !("error" in response)) {
      dispatch(setLoaderState(false));
      let paymentMadeId = response.id;
      if (
        status === "Open" &&
        action === "Send" &&
        formik.values.email_to.length > 0
      ) {
        await dispatch(
          sendPaymentMadeData({
            paymentMadeId: paymentMadeId,
            emails: formik.values.email_to,
            orgId: currentUserInfo.organization_id,
          })
        );
      }
      let downloadResult;
      if (status === "Open" && action === "Download") {
        const responseAction = await dispatch(
          getPaymentMadePdfLink({
            paymentMadeId: paymentMadeId,
            orgId: currentUserInfo.organization_id,
          })
        );
        if (responseAction.payload) {
          const response = responseAction.payload;
          if (Object.keys(response).length && !("error" in response)) {
            downloadResult = await downloadUsingURL(
              response.download_link,
              paymentMadeId + ".pdf"
            );
          }
        }
      }
      if (status === "Draft") navigate(-1);
      else navigate(`/payment-made/detail/${paymentMadeId}`, { replace: true });
      SuccessToaster(
        "Bill payment created successfully!",
        "bill-payment-create-success"
      );
      if (status === "Open" && action === "Download" && downloadResult) {
        SuccessToaster(
          "Downloaded Successfully!",
          "bill-payment-download-success"
        );
      }
    } else if (response && "error" in response) {
      dispatch(setLoaderState(false));
    } else {
      dispatch(setLoaderState(false));
    }
  };

  const fetchPaymentMadeListAfterDelete = async () => {
    const responseAction = await dispatch(
      billPaymentList({
        page: 1,
        itemsPerPage: 10,
        dateSortOrder: "ASC",
        orgId: currentUserInfo.organization_id,
        orgIds: [],
        vendorList: [],
        statusList: [],
      })
    );
    // if (redirectTo) {
    //   navigate(redirectTo);
    // } else {
    //   navigate("/payment-made");
    // }
    redirectUrl ? navigate(redirectUrl) : replace ? navigate(-2) : navigate(-1);
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: PMFormValidation,
    validateOnMount: false,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(false);
    },
  });

  const { fetchCustomConversions } = usePm({ formik: formik });

  const paymentMadeDetailsResponse = async () => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      paymentMadeDetails({
        paymentMadeId: Number(editId),
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      ($(".selectpicker") as any).selectpicker("refresh");
      const response = JSON.parse(JSON.stringify(responseAction.payload));
      const isSameOrg =
        response.organization_id === currentUserInfo.organization_id;
      const isLocked = response.is_locked;
      if (
        Object.keys(response).length &&
        !("error" in response) &&
        isSameOrg &&
        !isLocked
      ) {
        let defaultValues = {
          vendor_id: response.vendor_id,
          vendor_name: response.vendor_name,
          payment_number: response.payment_number,
          payment_date: response.payment_date,
          paid_through_account_name: response.paid_through_account_name,
          check_expiry: response.check_expiry
            ? response.check_expiry
            : getFormatedDate(new Date()),
          check_expiry_in: response.check_expiry_in,
          check_date: response.check_date,
          check_number: response.check_number,
          payment_mode: response.payment_mode,
          paid_through_account_id: response.paid_through_account_id,
          amount_paid: response.amount_paid,
          bank_charges_account_name: response.bank_charges_account_name,
          bank_charges_amount: response.bank_charges_amount,
          bank_charges_account_id: response.bank_charges_account_id,
          currency_id: response.currency_id,
          reference_number: response.reference_number,
          amount_paid_bank_currency: response.amount_paid_bank_currency,
          bank_charges_bank_currency: response.bank_charges_amount,
          bank_conversion_id: response.bank_conversion_id,
          bank_currency_id: response.bank_currency_id,
          notes: response.notes,
          files: response.files ? response.files : [],
          file_ids: response.files.map((file: any) => file.id),
          email_to: response.emails.map((email: any) => email.email),
          delete_email_ids: [],
          email_data: response.emails,
          unpaid_bill_lines: response.unpaid_bill_lines,
          conversion_id: response.conversion_id,
          currency_code: response.currency_code,
          is_locked: response.is_locked,
          lock_date: response.lock_date,
          custom_fields: response.custom_fields,
          pm_status: response.pm_status,
          total_refunded_amount: response.total_refunded_amount,
          refund_history: response.refund_history,
        };

        let unPaidBills = [...response.unpaid_bill_lines];
        let initialUnpaidBillIds: number[] = unPaidBills.map(
          (unpaidBill) => unpaidBill.bill_id
        );

        let unpaidBillItem = await getUnpaidBillsListOfVendor(
          response.vendor_id
        );
        for (let newBill of unpaidBillItem) {
          if (!initialUnpaidBillIds.includes(newBill.id)) {
            unPaidBills.push({
              bill_id: newBill.id,
              amount_due: newBill.amount_due,
              bill_amount: newBill.bill_amount,
              bill_date: newBill.bill_date,
              bill_number: newBill.bill_number,
              allocation_date: getFormatedDate(newBill.allocation_date),
              is_deleted: false,
              id: null,
              payment_amount: 0,
              conversion_id: newBill.conversion_id,
              forex_amount: 0,
            });
          }
        }
        defaultValues.unpaid_bill_lines = unPaidBills;
        setInitialValues(defaultValues as any);
        setIsUnpaidBillExist(response.unpaid_bill_lines_exist);
        await fetchCustomConversions(response.conversion_id, "user");
        if (response.bank_conversion_id) {
          await fetchCustomConversions(response.bank_conversion_id, "bank");
        }
        dispatch(setLoaderState(false));
      } else {
        if (isLocked) {
          navigate(-1);
          ErrorToaster(
            `Transactions before ${DateFormatHandler(
              response.lock_date
            )} have been locked. Hence action cannot be performed`
          );
        } else {
          dispatch(setErrorID(noAccessErrorCodes.PMEdit));
        }
      }
    }
  };

  // //Check for changes made in field values
  // const hasItemValuesChanged = (
  //   baseObject: ObjectType[],
  //   changeObject: ObjectType[]
  // ) => {
  //   if (baseObject && changeObject) {
  //     const hasChanged = !_isEqual(baseObject, changeObject);
  //     console.log(hasChanged);
  //     setHasItemChanged(hasChanged);
  //   }
  // };
  return (
    <>
      <div className="card card-user-management card-customer main-card overflowX-hidden h-100 payment-detail-card mbottom-100">
        <div className="card-header card-user-management-header card-customer-header card-no-bg-header card-main-header">
          <TransactionHeader
            transactionName="Bill Payment"
            transactionNumber={Number(editId)}
          />
        </div>
        <div className="card-body-wrapper d-flex h-100 overflowX-hidden">
          <div className="card-body">
            <form
              name="add_payment_form"
              id="add_invoice_form"
              className={`add_payment_form create-pm-form add-module-item-form ${
                (formik.submitCount > 0 &&
                  Object.keys(formik.errors).length > 0) ||
                customFieldRef.current?.errors ||
                emailError !== ""
                  ? "with-form-error-alert"
                  : ""
              }`}
              tabIndex={-1}
            >
              <ScrollToFieldError
                submitCount={formik.submitCount}
                errors={{
                  ...formik.errors,
                  ...customFieldRef.current?.errorList,
                  email_to: emailError,
                }}
              />

              <div className="fields-wrapper pt-0">
                <div className="fields-wrappers">
                  <PaymentDetailsForm
                    formik={formik}
                    customFieldRef={customFieldRef}
                  />
                  <UnpaidBills formik={formik} />
                  <div
                    className={!formik.values.vendor_id ? "disabled-item" : ""}
                  >
                    <AttachFiles
                      formik={formik}
                      appendName="pm_file"
                      attachFiles={attachPaymentMadeFiles}
                      deleteFile={deletePaymentMadeFile}
                      setIsApiPending={setIsApiPending}
                    />
                    <EmailTo formik={formik} error={emailError} />
                  </div>
                </div>
                <FormActionButtons
                  editId={editId}
                  isLocked={formik.values.is_locked}
                  lockDate={formik.values.lock_date}
                  name="Payment Made"
                  isFormSubmitting={formik.isSubmitting}
                  onClickSubmit={(status: string, action: string) => {
                    setButtonAction({ status: status, action: action });
                    submitBtnClickHandler(status, action);
                  }}
                  rolePermission={paymentsMadeRolePermission}
                  isPrint={false}
                  isError={
                    (formik.submitCount > 0 &&
                      Object.keys(formik.errors).length > 0) ||
                    customFieldRef.current?.errors ||
                    emailError !== ""
                      ? true
                      : false
                  }
                  action={formik.values.action}
                  isButtonGroupDisabled={
                    formik.isSubmitting || isCurrencyApiPending || isApiPending
                      ? true
                      : false
                  }
                  isDisabledSave={
                    formik.isSubmitting || isCurrencyApiPending || isApiPending
                      ? true
                      : false
                  }
                  isDisabledDraft={
                    formik.isSubmitting ||
                    isCurrencyApiPending ||
                    isApiPending ||
                    (editId && formik.values.pm_status !== "Draft")
                      ? true
                      : false
                  }
                  onClickDelete={() => {
                    setDeleteBillPaymentId(Number(editId));
                    setDeleteBillPaymentItemId(formik.values.payment_number);
                  }}
                />
              </div>
            </form>
          </div>
        </div>
        <DeleteBillPayment
          deleteBillPaymentId={deleteBillPaymentId}
          deleteBillPaymentItemId={deleteBillPaymentItemId}
          deleteBillPaymentStatus={formik.values.pm_status}
          refreshBillPayments={fetchPaymentMadeListAfterDelete}
          organizationId={currentUserInfo.organization_id}
          isUnpaidBillExist={isUnpaidBillExist}
        />
        <ExcessAllocationAlertModal
          openRef={openExcessAllocationAlertRef}
          excessAmount={totalDetails.amount_excess}
          currencyCode={
            formik.values.currency_code ? formik.values.currency_code : ""
          }
          module="Payments Made"
          handleSave={() => {
            submitHandler(formik.values.pm_status, formik.values.action);
          }}
        />
        <TransactionEditTagRemovalAlertModal
          module="Payments Made"
          onCancel={() => {
            formik.setSubmitting(false);
            setDidAlertModalOpen(false);
          }}
          onSubmit={() => {
            submitBtnClickHandler(buttonAction.status, buttonAction.action);
          }}
          openAlertRef={openEditAlertRef}
        />
      </div>
    </>
  );
}

export default PaymentMadeForm;
