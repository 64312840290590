import CenterModal from "../../../../common/components/centerModal/CenterModal";
import { ModalContext } from "../../../../common/components/centerModal/centerModalContext";
import OrganizationProfile from "../../../organizationProfile/components/OrganizationProfile";
import "../../UpdateOrganizationModal.css";

export const UpdateOrganizationProfileModal = () => {
  return (
    <CenterModal
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onCloseModal } = context;
            return <OrganizationProfile handleClose={onCloseModal} />;
          }}
        </ModalContext.Consumer>
      }
      buttonContent={
        <span className="notes-link">Set up Organizational details</span>
      }
      modalTitle="Update Organization Profile"
      buttonClass="no-appearance"
      wrapperClass="medium-form-modal org-profile-modal"
      isInline={true}
    />
  );
};
