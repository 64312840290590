import React, { useCallback, useEffect, useState } from "react";
import { Stack, TextField } from "@mui/material";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { SxProps } from "@mui/system";

import { useAppSelector } from "../../../../app/hooks";
import { fiscalTearFilterForValidationSelector } from "../../../common/commonSlice";
import useCommonData from "../../../hooks/useCommon";
import { ObjectType } from "../../../../types";
import "../datepicker.css";
import "./DatePicker.css";

type Props = {
  date: Date | undefined;
  handleDate: (date: Date | null, type: string) => void;
  error: string;
  format?: string;
  type?: string;
  id?: string;
  zIndex?: number;
  inset?: string;
  placeholder?: string;
  minDate?: Date | null;
  maxDate?: Date | null;
  fiscalYearFilter?: ObjectType;
  isOpen?: boolean;
  openPicker?: () => void;
  closePicker?: () => void;
  enableFutureDate?: boolean;
};

const DatePicker = (props: Props) => {
  const [value, setValue] = React.useState<Date | null>();
  //   const fiscalYearFilter = useAppSelector(fiscalTearFilterForValidationSelector);
  //   const { currentUserInfo } = useCommonData();
  // const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  useEffect(() => {
    setValue(props.date);
  }, [props.date]);

  const handleOpen = () => {
    if (props.openPicker) props.openPicker();
    // setIsDatePickerOpen(true);
  };

  const handleClose = () => {
    if (props.closePicker) props.closePicker();
    // setIsDatePickerOpen(false);
  };

  const dateHandleChange = (newValue: Date | null) => {
    setValue(newValue);
    props.handleDate(newValue, props.type ? props.type : "");
  };

  const sxProps: SxProps = {
    "&.MuiPickersPopper-root": {
      zIndex: props.zIndex,
      inset: props.inset,
    },
  };
  //   console.log(value);
  return (
    <>
      <div
        className={`date-container ${props.error ? "error" : ""} ${props.id}`}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack spacing={3}>
            <DesktopDatePicker
              inputFormat={props.format ? props.format : "dd/MM/yyyy"}
              value={value ? value : {}}
              open={props.isOpen}
              onOpen={handleOpen}
              onClose={handleClose}
              className={props.id}
              onChange={dateHandleChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!props.error}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: props.placeholder,
                  }}
                />
              )}
              minDate={props.minDate !== null ? props.minDate : undefined}
              maxDate={props.maxDate !== null ? props.maxDate : undefined}
              PopperProps={{
                ...(props.zIndex !== 0
                  ? { sx: { ...sxProps }, className: "custom-calendar-class" }
                  : {}),
              }}
            />
          </Stack>
        </LocalizationProvider>
      </div>
    </>
  );
};

export default DatePicker;
