import CustomDatepicker from "../../../../utils/atoms/datepicker";
import Container from "../../../gst/components/settings/GstSettingsContainer";
import "../../preferences.css";
import AmountInWords from "./AmountInWords";
import { useFormik } from "formik";

const ConfigureTransactions = () => {
  const initialValues = {
    date_format: null,
    showAmountInWords: true,
    invoice: true,
    creditNotes: true,
    paymentReceived: true,
    bills: true,
    debitNotes: true,
    paymentMade: true,
    journalVoucher: true,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
    },
  });

  return (
    <Container heading="Date and Amount Format">
      <form
        action=""
        name="configure-transaction-form"
        id="configure-transaction-form"
        className="preferences-settings-form configure-transaction-form w-100"
        onSubmit={() => {}}
      >
        <div className="fields-wrapper">
          <section className="form-fields-section">
            <div className="form-fields">
              <div className="row g-0">
                <div className="col">
                  <div className="col-12 col-lg-4 mw-325">
                    <label htmlFor="due_date">Date Format</label>
                    <div id="date-two" className="date-selector-wrapper">
                      <CustomDatepicker
                        type="date_format"
                        id="date_format"
                        error={""}
                        zIndex={0}
                        enableFutureDate={true}
                        minDate={new Date()}
                        placeholder="DD-MM-YYYY(eg-01-12-2024)"
                        date={
                          formik.values.date_format
                            ? new Date(formik.values.date_format)
                            : null
                        }
                        handleDate={(option: any) => {
                          formik.setFieldValue("choose_day", option);
                        }}
                      />
                    </div>
                    <span className="error">{""}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="border-bottom"></div>
            <AmountInWords formik={formik} />
          </section>
        </div>
        <section className="form-button-section">
          <div
            className="button-fields form-button-wrapper"
            id="form-btn-gst-profile"
          >
            <button
              className="save-button"
              type="submit"
              disabled={false}
              onClick={(e) => {
                e.preventDefault();
                formik.submitForm();
              }}
            >
              Save
            </button>
            <button
              className="text-decoration-none cancel-button close-btn"
              data-bs-dismiss="modal"
              aria-label="Close"
              type="button"
              onClick={() => formik.resetForm()}
            >
              Cancel
            </button>
          </div>
        </section>
      </form>
    </Container>
  );
};
export default ConfigureTransactions;
