import React, { useState, useEffect, useRef } from "react";
import { useMediaQuery } from "@react-hook/media-query";
import { SearchBox } from "../../../search/searchBox/searchBox";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CloseButton from "../../../../common/components/button/CloseButton";
import { colors } from "../../../../constants/colors";
import { ObjectType } from "../../../../../types";
import "./HeaderSearchModal.css";

const HeaderSearchModal: React.FC = () => {
  const [openSearchBar, setOpenSearchBar] = useState(false);
  const searchButtonMediaQuery = useMediaQuery("(max-width: 1024px)");
  const anchorRef = useRef(null);

  const handleToggle = (e: any) => {
    e.stopPropagation();
    setOpenSearchBar((prev) => !prev);
  };
  const searchModalClose = () => {
    setOpenSearchBar(false);
  };

  const searchbarCloseHandler = () => {
    setOpenSearchBar(false);
  };
  function checkDiviceForSearchBar() {
    if (!searchButtonMediaQuery) {
      searchbarCloseHandler();
    }
  }

  useEffect(() => {
    checkDiviceForSearchBar();
    window.addEventListener("resize", checkDiviceForSearchBar);
    return () => {
      window.removeEventListener("resize", checkDiviceForSearchBar);
    };
  }, [searchButtonMediaQuery]);

  return (
    <>
      <button
        className="header-search-button"
        ref={anchorRef}
        onClick={(e) => handleToggle(e)}
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="none" fillRule="evenodd">
            <path d="M0 0h20v20H0z" />
            <path
              d="M9.167 1.667c4.14 0 7.5 3.36 7.5 7.5 0 4.14-3.36 7.5-7.5 7.5-4.14 0-7.5-3.36-7.5-7.5 0-4.14 3.36-7.5 7.5-7.5zm0 13.333A5.832 5.832 0 0 0 15 9.167a5.832 5.832 0 0 0-5.833-5.834 5.831 5.831 0 0 0-5.834 5.834A5.832 5.832 0 0 0 9.167 15zm7.07.06 2.358 2.356-1.18 1.179-2.356-2.357 1.179-1.179z"
              fill="#747D84"
              fillRule="nonzero"
            />
          </g>
        </svg>
      </button>
      <Modal
        open={openSearchBar}
        onClose={searchModalClose}
        id="searchbar_modal"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ backgroundColor: colors.ceruleanBlue }}>
          <div className="searchbar-wrapper" id="searchbar_wrapper">
            <CloseButton closeModalHandler={searchbarCloseHandler} />
            <SearchBox
              variant="blue"
              moduleValue={"global_search"}
              searchValue={""}
              getSearchResult={(searchOutput: ObjectType[], value, module) => {
                // console.log(searchOutput, value);
              }}
            />
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default HeaderSearchModal;
