import KebabMenu from "../../../../../common/components/kebabMenu/KebabMenu";
import Edit from "../../../../../common/components/kebabMenu/Edit";
import Delete from "../../../../../common/components/Delete";
import Void from "../../../../../common/components/Void";
import StopResume from "../../../../../common/components/kebabMenu/StopResume";
import { usePermissionHandler } from "../../../../../hooks/usePermissionHandler";
import { toast } from "react-toastify";

export type ListActionProps = {
  value: any;
  onClickDelete: () => void;
  onClickVoid: () => void;
  onStopResume: () => void;
};

function ListActions(props: ListActionProps) {
  
  const { planRolePermission } = usePermissionHandler();
  return (
    <KebabMenu>
      {planRolePermission.includes("Update") ? 
      <>
      <li>
        <Edit
          url={`/plan/edit/${props.value.id}`}
          isMutable
          name="Plan"
          isLocked={false}
          className={"dropdown-item"}
          lockDate={""}
          isDisabled={false}
        />
      </li>
      {/* Commented the below code as it need to be integrate in future*/}
     {/* <li>
        <StopResume
          className="dropdown-item delete"
          isDisabled={false}
          isStopped={props.value.status.toLowerCase() === "stopped"}
          onStopResumeClick={props.onStopResume}
        />
      </li> */}
       <li>
        <Void
          className="dropdown-item delete"
          deleteIcon={true}
          isMutable
          onClick={props.onClickVoid}
          isLocked={false}
          lockDate={""}
          name="Plan"
          status={props.value.status}
        />
      </li>
      </> : null }
      {planRolePermission.includes("Delete") ? <li>
        <Delete
          className="dropdown-item delete"
          deleteIcon={true}
          isMutable
          onClick={props.onClickDelete}
          isLocked={false}
          lockDate={""}
          name="Plan"
          isTransactionExist={props.value.plan_exists_subscription}
          planExistsSubscription={props.value.plan_exists_subscription}
        />
      </li> : null }
    </KebabMenu>
  );
}

export default ListActions;
// && !props.value.plan_exists_subscription
