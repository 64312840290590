import { useEffect, useState } from "react";
import { FormSelectField } from "../../../common/components";
import { ModalContext } from "../../../common/components/sideModal/sideModalContext";
import { SideModal } from "../../../common/components/sideModal/SideModal";
import { useFormik } from "formik";
import "./billingSideMenuForm.css";
import { customSelectStyle } from "../../../common/components/SelectCustomStyle";
import CustomDatepicker from "../../../utils/atoms/datepicker";
import useCommonData from "../../../hooks/useCommon";
import { setLoaderState } from "../../../common/commonSlice";
import { SuccessToaster } from "../../../../helpers/toastHelper";
import {
  createSideWideCalenderInvoicingHandler,
  siteWideCalenderEditDetails,
  updateSideWideCalenderInvoicingHandler,
} from "../calenderBillingSlice";
import * as yup from "yup";
import { Link } from "react-router-dom";
import { EditDropdownIcon } from "../../../common/components/customSvgIcons/editDropdownIcon";
import { usePermissionHandler } from "../../../hooks/usePermissionHandler";
import { chooseDayOption, selectFrequencyOption } from "../calenderBillingStateInitialization";
import { recurringInvoicingValue } from "../helpers/calendarInvoicingHelper";
import { getFormatedDate } from "../../../../helpers/helper";
import moment from "moment";
import { SiteWideInvoicingInitialType } from "../calendarBillingTypes";

type Props = {
  getSiteWideBilling: () => void;
  savedFrequencies?: string[];
  id?: number}
export const BillingSideMenuForm = (props: Props) => {
  const { subscriptionRolePermission } = usePermissionHandler();
  const { dispatch, currentUserInfo } = useCommonData();
  const [initialValues, setInitialValues] = useState<SiteWideInvoicingInitialType>({
    select_frequency: "",
    set_recurring_invoicing_type: "AUTO_SET",
    set_date: "",
    set_day: "",
  });
  const [onSaveActionClick, setOnSaveActionClick] = useState<boolean>(false)

  const validate = yup.object({
    select_frequency: yup
      .string()
      .required("Please select a invoicing frequency")
      .test(
        "select_frequency",
        "This frequency is already added.",
        function (value) {
          if (props.savedFrequencies && props.savedFrequencies.length > 0 && props.savedFrequencies.includes(value)) {
            return false;
          }
          return true;
        }
      ),
    set_date: yup
      .string()
      .test(
        "set_date",
        "Please enter valid date.",
        function (value) {
          const { set_recurring_invoicing_type, select_frequency } = this.parent as any;
          if (set_recurring_invoicing_type === "SET_DATE" && select_frequency !== "WEEKLY" && (value === 'Invalid date' || !value)) {
            return false;
          }
          return true;
        }
      ),
    set_day: yup
      .string()
      .test(
        "set_day",
        "Please select the day.",
        function (value) {
          const { set_recurring_invoicing_type, select_frequency } = this.parent as any;
          if (set_recurring_invoicing_type === "SET_DATE" && select_frequency === "WEEKLY" && !value) {
            return false;
          }
          return true;
        }
      ),
  });

  async function getSideWideBillingDetails() {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      siteWideCalenderEditDetails({
        orgId: currentUserInfo.organization_id,
        calendarInvoicingId: props.id ? props.id : 0,
      })
    );
    const response = responseAction.payload;
    if (!("error" in response)) {
      setInitialValues({
        select_frequency: response.invoicing_frequency,
        set_recurring_invoicing_type: response.invoicing_type,
        set_date:  response.set_date ? response.set_date : '',
        set_day: response.set_day ? response.set_day : '',
      });
      dispatch(setLoaderState(false));
    } else {
      dispatch(setLoaderState(false));
    }
  }

  const onFormSubmit = async(data: any) => {
    dispatch(setLoaderState(true));
      if (props.id) {
        data.calendarInvoicingId = props.id;
        const responseAction = await dispatch(
          updateSideWideCalenderInvoicingHandler(data)
        );
        const response = responseAction.payload;
        if (!("error" in response)) {
          SuccessToaster(
            "Side-Wide Billing frequency updated successfully!",
            "tags-config-succcess"
          );
          props.getSiteWideBilling()
          setOnSaveActionClick(true)
        } else {
          dispatch(setLoaderState(false));
        }
      } else {
        const responseAction = await dispatch(
          createSideWideCalenderInvoicingHandler(data)
        );
        const response = responseAction.payload;
        if (!("error" in response)) {
          SuccessToaster(
            "Side-Wide Billing frequency created successfully!",
            "tags-config-succcess"
          );
          props.getSiteWideBilling()
          setOnSaveActionClick(true)
        } else {
          dispatch(setLoaderState(false));
        }
      }
  }

  const formik = useFormik({
    initialValues: { ...initialValues } as SiteWideInvoicingInitialType,
    enableReinitialize: true,
    validationSchema: validate,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: (value) => {
      let data: any = {
        orgId: currentUserInfo.organization_id,
        invoicing_frequency: value.select_frequency,
        invoicing_type: value.set_recurring_invoicing_type,
        set_date: null,
        set_day: null,
      };

      if (value.select_frequency === 'WEEKLY' && value.set_recurring_invoicing_type === "SET_DATE") {
        data.set_day = value.set_day
      }
      if (value.select_frequency !== 'WEEKLY' && value.set_recurring_invoicing_type === "SET_DATE") {
        data.set_date = value.set_date
      }
      
      onFormSubmit(data)
      formik.resetForm()
      setOnSaveActionClick(false)
    },
  });

  const ConfigureButton = () => {
    return (
      <>
        {props.id ? (
          <Link to="#" className="dropdown-item billing-side-wide-edit-button" onClick={() => getSideWideBillingDetails()}>
            <>
              <EditDropdownIcon />
              Edit
            </>
          </Link>
        ) : (
          <div>
           {subscriptionRolePermission.includes("Create") ? <button
              id="calendar-billing-button"
              className="btn create-invoice-button green-button right-button sub-add-coupon-btn-green-background"
              type="button"
            >
              <img
                src="/static/media/plus_small.2f281160561f3c06b529f10af809e435.svg"
                className="Group"
                alt="plus-small-img"
              />
              <span className="create-invoice-button-text green-button-text">
                Create Site-Wide Calendar Invoicing
              </span>
            </button> : null}
          </div>
        )}
      </>
    );
  };

  const handleDate = (date: Date | null) => {
    if (date) {
      if (date?.toString() === "Invalid Date") {
       formik.setFieldValue("set_date", 'Invalid date')
      } else {
        const dateFormated = getFormatedDate(date);
        if (moment(dateFormated, "YYYY-MM-DD", true).isValid()) {
          formik.setFieldValue("set_date", dateFormated)
        } else {
          formik.setFieldValue("set_date", 'Invalid date')
        }
      }
    } else if (date === null) {
      formik.setFieldValue("set_date", '')
    }
  };

  return (
    <SideModal
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onCloseModal } = context;
            return (
              <div className="billing-and-proration-modal-container">
                <div className="billing-and-proration-modal-inner-container">
                  <FormSelectField
                    name="select_frequency"
                    id="select_frequency"
                    label="Select Frequency"
                    options={selectFrequencyOption}
                    wrapperClass="mw-325"
                    className="state-select custom-select billing-and-proration-select"
                    value={
                      selectFrequencyOption.filter(
                        (data: any) =>
                          data.value === formik.values.select_frequency
                      )[0] || ""
                    }
                    onChange={(option: any) => {
                      formik.setFieldValue("select_frequency", option.value);
                    }}
                    placeholder={"Choose Plan Frequency"}
                    isSearchable={true}
                    isDisabled={false}
                    error={formik.errors.select_frequency}
                    isRequired={true}
                    isOnlyInRow={false}
                    messagePlacement="top-end"
                    styles={customSelectStyle}
                  />

                  <FormSelectField
                    name="set_recurring_invoicing_type"
                    id="set_recurring_invoicing_type"
                    label="Set a Recurring Invoicing Type"
                    options={recurringInvoicingValue(formik.values.select_frequency)}
                    wrapperClass="mw-325"
                    className="state-select custom-select billing-and-proration-select"
                    value={
                      recurringInvoicingValue(formik.values.select_frequency).filter(
                        (data: any) =>
                          data.value ===
                          formik.values.set_recurring_invoicing_type
                      )[0] || ""
                    }
                    onChange={(option: any) => {
                      formik.setFieldValue(
                        "set_recurring_invoicing_type",
                        option.value
                      );
                    }}
                    placeholder={"Choose Set a Recurring Invoicing Type"}
                    isSearchable={true}
                    isDisabled={false}
                    error={formik.errors.set_recurring_invoicing_type}
                    isRequired={false}
                    isOnlyInRow={false}
                    messagePlacement="top-end"
                    styles={customSelectStyle}
                  />

                  {formik.values.set_recurring_invoicing_type === "SET_DATE" &&
                    formik.values.select_frequency === "WEEKLY" && (
                      <FormSelectField
                        name="set_day"
                        id="set_day"
                        label={`${formik.values.select_frequency === "WEEKLY"?'Choose Day':'Choose Date'}`}
                        options={chooseDayOption}
                        wrapperClass="mw-325"
                        className="state-select custom-select billing-and-proration-select"
                        value={
                          chooseDayOption.filter(
                            (data: any) =>
                              data.value === formik.values.set_day
                          )[0] || ""
                        }
                        onChange={(option: any) => {
                          formik.setFieldValue("set_day", option.value);
                        }}
                        placeholder={"Choose Day"}
                        isSearchable={true}
                        isDisabled={false}
                        error={formik.errors.set_day}
                        isRequired={false}
                        isOnlyInRow={false}
                        messagePlacement="top-end"
                        styles={customSelectStyle}
                      />
                    )}

                  {formik.values.set_recurring_invoicing_type === "SET_DATE" &&
                    formik.values.select_frequency === "MONTHLY" && (
                      <div className={"col-12 col-lg-4 mw-325 "}>
                        <label htmlFor="check_expiry" className="">
                          Choose Date
                        </label>
                        <div
                          id="set_date"
                          className={`date-selector-wrapper`}
                        >
                          <CustomDatepicker
                            date={
                              formik.values.set_date
                                ? new Date(formik.values.set_date)
                                : null
                            }
                            handleDate={handleDate}
                            type="set_date"
                            error={!formik.errors.set_date ? "" : "error"}
                            id="set_date"
                            zIndex={0}
                            isDisabled={false}
                            placeholder="Enter start date"
                            enableFutureDate={true}
                            name="choose_day"
                            customClass={
                              "billing-and-proration-datepicker-monthly"
                            }
                          />
                          <span className="error">{!formik.errors.set_date ? "" : formik.errors.set_date}</span>
                        </div>
                      </div>
                    )}

                  {formik.values.set_recurring_invoicing_type === "SET_DATE" &&
                    formik.values.select_frequency === "YEARLY" && (
                      <div className={"col-12 col-lg-4 mw-325 "}>
                        <label htmlFor="check_expiry" className="">
                          Choose Date
                        </label>
                        <div
                          id="set_date"
                          className={`date-selector-wrapper`}
                        >
                          <CustomDatepicker
                            date={
                              formik.values.set_date
                                ? new Date(formik.values.set_date)
                                : null
                            }
                            handleDate={handleDate}
                            type="set_date"
                            error={!formik.errors.set_date ? "" : "error"}
                            id="set_date"
                            zIndex={0}
                            isDisabled={false}
                            placeholder="Enter start date"
                            enableFutureDate={true}
                            name="set_date"
                            views={["day"]}
                            customClass={
                              "billing-and-proration-datepicker-yearly"
                            }
                          />
                          <span className="error">{!formik.errors.set_date ? "" : formik.errors.set_date}</span>
                        </div>
                      </div>
                    )}
                </div>
                <div className="billing-and-proration-modal-footer">
                  <button
                    type="button"
                    className="save-button mx-0"
                    onClick={() => {
                      formik.submitForm();
                    }}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="text-decoration-none cancel-button mx-4"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      onCloseModal();
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            );
          }}
        </ModalContext.Consumer>
      }
      onCloseFunction={() => {
        formik.resetForm();
      }}
      modalTitle="Side-Wide Invoicing"
      isButtonDisabled={false}
      buttonContent={<ConfigureButton />}
      drawerWrapperClass="calendar-billing billing-and-proration-modal"
      wrapperClass={`${
        !props.id ? "calendar-billing-button" : "calendar-billing-button-edit"
      } w-100 d-flex`}
      buttonClass={`add-coupon-button add-tags-button`}
      onSaveActionClick={onSaveActionClick}
    />
  );
};
