import { useNavigate } from "react-router-dom";
import Delete from "../../../../common/components/Delete";
import Header from "../../../../common/components/detailsPage/Header";
import NotificationModal from "../../../../common/components/NotificationModal";
import RIDelete from "../RIDelete";
import OrgTag from "../../../../common/components/OrgTag";
import Edit from "../../../../common/components/kebabMenu/Edit";
import KebabMenu from "../../../../common/components/kebabMenu/KebabMenu";
import Clone from "../../../../common/components/kebabMenu/CloneRole";
import SendReminder from "../../../../common/components/kebabMenu/SendReminder";
import StopResume from "../../../../common/components/kebabMenu/StopResume";
import useCommonData from "../../../../hooks/useCommon";
import {
  InvoiceList,
  RecurringInvoiceDetailValues,
} from "../../../../../types";
import { sendReminderRI } from "../../../../home/recurringInvoice/recurringInvoiceSlice";
import { toast } from "react-toastify";
import "./recurringInvoiceView.css";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import { DateFormatHandler, StatusColor } from "../../../../../helpers/helper";
import RIStopResume from "../RIStopResume";
import Void from "../../../../common/components/Void";
import { useState } from "react";
import RIVoid from "../RIVoid";
import RIReminder from "../RIReminder";
import { id } from "date-fns/locale";
type Props = {
  overviewInvoiceData: RecurringInvoiceDetailValues;
  childInvoiceListData: InvoiceList[];
  recurringInvoiceDetail: () => void;
};

const RecurringInvoiceViewHeader = (props: Props) => {
  const [voidRInvoiceId, setVoidRInvoiceId] = useState<number>(-1);
  const [reminderRInvoiceId, setReminderRInvoiceId] = useState<number>(-1);
  const [voidStatus, setVoidStatus] = useState<string>("Void");
  const { dispatch, currentUserInfo } = useCommonData();
  const { recurringInvoicesRolePermission } = usePermissionHandler();
  const navigate = useNavigate();
  /**
   * Back button handler from vendor details page
   */
  const backFromRecurringViewHandler = () => {
    navigate(-1);
  };

  // const sendReminder = async (invoiceId: number) => {
  //   const responseAction = await dispatch(
  //     sendReminderRI({ invoiceId, orgId: currentUserInfo.organization_id })
  //   );

  //   if (responseAction) {
  //     const response = responseAction.payload;
  //     if (Object.keys(response).length && !("error" in response)) {
  //       props.recurringInvoiceDetail();
  //       toast.success("Reminder has been successfully sent!", {
  //         toastId: "send-reminder-invoice-success",
  //         closeButton: false,
  //         position: "top-center",
  //       });
  //     } else {
  //       let errMsg;
  //       if (response.error.response.detail[0].msg == "Invoice Not Found") {
  //         errMsg = "Child Invoice Not Found";
  //       } else {
  //         errMsg = response.error.response.detail[0].msg;
  //       }
  //       toast.error(errMsg, {
  //         toastId: "send-reminder-invoice-error",
  //         closeButton: false,
  //         className: "toast-error",
  //         position: "top-center",
  //       });
  //     }
  //   }
  // };

  const sendReminder = (invoiceId: number) => {
    setReminderRInvoiceId(invoiceId);
  };

  const voidListData = (id: number, status: string) => {
    setVoidRInvoiceId(id);
    setVoidStatus(status);
  };
  
  return (
    <>
      <Header
        onBackButtonClick={backFromRecurringViewHandler}
        data={{
          num: props.overviewInvoiceData.profile_name,
          status: props.overviewInvoiceData.invoice_status,
        }}
        dataId=""
      />
      <div className="card-body">
        <div className="invoice-actions-wrapper detail-pg-actions-wrapper d-flex justify-content-between">
          <div className="left-group">
          <span className="biller-label ps-2">
              {props.overviewInvoiceData.customer_name}
            </span>
            <span>|</span>
            <span className="bill-date pe-2 ps-2">
              {DateFormatHandler(props.overviewInvoiceData.created_at)}
            </span>
            <span>|</span>
            <span>
              <OrgTag
                organization={props.overviewInvoiceData.organization_name}
              />
            </span>
          </div>
          {(!recurringInvoicesRolePermission.includes("Update") && !recurringInvoicesRolePermission.includes("Create")) ||
          props.overviewInvoiceData.organization_id !==
            currentUserInfo.organization_id ? null : (
            <div className="actions-wrap d-flex flex-no-shrink">
              <div
                className={`${
                  props.overviewInvoiceData.invoice_status.toLowerCase() ===
                    "expired" ||
                  props.overviewInvoiceData.invoice_status.toLowerCase() ===
                    "cancelled"
                    ? "ri"
                    : ""
                }`}
              >
                {props.overviewInvoiceData.invoice_status.toLowerCase() !==
                  "cancelled" && recurringInvoicesRolePermission.includes("Update") && (
                  <Edit
                    url={`/recurring-inv/edit/${props.overviewInvoiceData.id}`}
                    isMutable={true}
                    name="Invoice"
                    isLocked={false}
                    className={`${
                      props.overviewInvoiceData.invoice_status.toLowerCase() ===
                        "expired" ||
                      props.overviewInvoiceData.invoice_status.toLowerCase() ===
                        "cancelled"
                        ? "li-disables"
                        : ""
                    }`}
                    lockDate=""
                    isDisabled={
                      props.overviewInvoiceData.invoice_status.toLowerCase() ===
                        "expired" ||
                      props.overviewInvoiceData.invoice_status.toLowerCase() ===
                        "cancelled"
                    }
                  />
                )}
              </div>
              {recurringInvoicesRolePermission.includes("Delete") ? (
                <Delete
                  className="text-decoration-none delete-item"
                  deleteIcon={true}
                  isMutable={true}
                  onClick={() => {}}
                  isLocked={false}
                  lockDate=""
                  name="Invoice"
                  child_invoice_exists={
                    props.overviewInvoiceData.child_invoice_exists
                      ? "child_invoice_exists"
                      : "child_invoice_not_exists"
                  }
                />
              ) : null}
              <KebabMenu wrapperClass="detail-top-action-menu">
                {recurringInvoicesRolePermission.includes("Create") ? <li>
                  <Clone
                    url={`/recurring-inv/clone/${props.overviewInvoiceData.id}`}
                  />
                </li> : null}
                {props.overviewInvoiceData.invoice_status.toLowerCase() !==
                  "cancelled" && recurringInvoicesRolePermission.includes("Update") ? (
                  <li>
                    <StopResume
                      className={"dropdown-item"}
                      isDisabled={
                        props.overviewInvoiceData.invoice_status.toLowerCase() ===
                        "expired"
                      }
                      isStopped={
                        props.overviewInvoiceData.invoice_status.toLowerCase() ===
                        "stopped"
                      }
                      onStopResumeClick={() => {}}
                    />
                  </li>
                ) : null}
                {props.overviewInvoiceData.invoice_status.toLowerCase() !==
                  "cancelled" && recurringInvoicesRolePermission.includes("Update") ? (
                  <li>
                    <Void
                      className="dropdown-item delete"
                      deleteIcon={true}
                      isMutable
                      onClick={() =>
                        voidListData(
                          props.overviewInvoiceData.id,
                          props.overviewInvoiceData.profile_name
                        )
                      }
                      isLocked={false}
                      lockDate={""}
                      name="Invoice"
                      status={props.overviewInvoiceData.invoice_status}
                    />
                  </li>
                ): null}
                { recurringInvoicesRolePermission.includes("Create") ?
                <li>
                  <SendReminder
                    className="dropdown-item delete"
                    deleteIcon={true}
                    isMutable
                    onClick={() => sendReminder(props.overviewInvoiceData.id)}
                    send_reminder={props.overviewInvoiceData.send_reminder}
                    isLocked={false}
                    lockDate={""}
                    name="Invoice"
                  />
                </li> : null}
              </KebabMenu>
            </div>
          )}
        </div>
      </div>
      <RIStopResume
        invoiceId={props.overviewInvoiceData.id}
        profileName={props.overviewInvoiceData.profile_name}
        triggerAction={
          props.overviewInvoiceData.invoice_status.toLowerCase() === "stopped"
            ? "Resume"
            : "Stop"
        }
        refreshRInvoices={() => props.recurringInvoiceDetail()}
        organizationId={currentUserInfo.organization_id}
      />
      {props.childInvoiceListData && props.childInvoiceListData.length <= 0 && (
        <RIDelete
          deleteRInvoiceId={props.overviewInvoiceData.id}
          deleteRIProfileName={props.overviewInvoiceData.profile_name}
          refreshRInvoices={backFromRecurringViewHandler}
          organizationId={currentUserInfo.organization_id}
        />
      )}
      <NotificationModal
        modalHeader="Unable to delete vendor!"
        modalBody="The vendor cannot be deleted as there are dependent transactions."
        modalSubmit="OK"
        modalCancel=""
        modalSubmitHandler={() => {}}
      />
      <RIReminder
        reminderRInvoiceId={reminderRInvoiceId}
        refreshRInvoices={backFromRecurringViewHandler}
        organizationId={currentUserInfo.organization_id}
      />
      <RIVoid
        voidRInvoiceId={voidRInvoiceId}
        voidStatus={voidStatus}
        refreshRInvoices={backFromRecurringViewHandler}
        organizationId={currentUserInfo.organization_id}
      />
    </>
  );
};

export default RecurringInvoiceViewHeader;
