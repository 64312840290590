import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { getFormatedDate } from "../../../../../helpers/helper";
import {
  ATReportGroupedTransaction,
  ATReportTransaction,
  ATReportTransactionDetails,
  ObjectType,
} from "../../../../../types";
import { currentUserSelector } from "../../../../common/commonSlice";
import InfoBox from "../../../../common/components/infoBox/InfoBox";
import { CustomizeReportModal } from "../../../../common/components/reportCustomizationModal/CustomizeReportModal";
import { message } from "../../../../constants/messages";
import Loader from "../../../components/Loader";
import {
  getAccountTransactionReport,
  reportCustomizationFilterSelector,
  setDateRange,
  setReportCustomizationFilters,
  setReportTableFiltersReset,
} from "../../ReportSlice";
import "../../accountTransactions/AccountTransactions.css";
import AccountTransactionTable from "../../accountTransactions/components/AccountTransactionTable";
import ReportFilter from "../../componets/ReportFilter";
import ReportHeader from "../../componets/ReportHeader";

import InfiniteScroll from "react-infinite-scroll-component";

const DayBook = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const reportRef = useRef<any>([]);
  const currentUserInfo = useAppSelector(currentUserSelector);
  const dayBookFormFilter = useAppSelector(
    reportCustomizationFilterSelector
  ).accountTransactionFilters;
  const hashFragment = location.hash;
  const decodedFilterString = hashFragment
    ? decodeURIComponent(hashFragment.slice(1))
    : undefined;
  const filters = decodedFilterString
    ? JSON.parse(decodedFilterString)
    : undefined;

    const initialDayBookData = {
    currency_id: 0,
    currency_code: "INR",
    date_range: "today",
    organization_id: 0,
    start_date: getFormatedDate(),
    end_date: getFormatedDate(),
    items: [],
    organization_details: {
      organization_name: "",
      email: "",
      country: "",
      state: "",
      id: 0,
      last_login: false,
      name: "",
      parent_hierarchy: "",
    },
    group_by: "",
    report_basis: "",
    transactions: [],
  };

  const initialFiltervalues = {
    start_date: filters ? filters.start_date : getFormatedDate(),
    end_date: filters ? filters.end_date : getFormatedDate(),
    date_range: filters ? filters.date_range : "today",
    organization_id: filters
      ? filters.organization_id
      : currentUserInfo.organization_id,
    currency_id: filters
      ? filters.currency_id
      : currentUserInfo.organization_currency,
    report_basis: filters ? filters.report_basis : "",
    group_by: filters ? filters.group_by : "",
    advanced_filter: [],
    tag_option_in: filters ? filters.tag_option_in : [],
  };
  const [hasMore, setHasMore] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [showReport, setShowReport] = useState(false);
  const [dayBookData, setDayBookData] =
    useState<ATReportTransactionDetails>(initialDayBookData);
  const [filterString, setFilterString] = useState("");
  const newLoadRef = useRef(true);
  useEffect(() => {
    return () => {
      dispatch(
        setReportCustomizationFilters({
          accountTransactionFilters: {
            start_date: filters ? filters.start_date : getFormatedDate(),
            end_date: filters ? filters.end_date : getFormatedDate(),
            date_range: filters ? filters.date_range : "today",
            report_basis: "Accrual",
            group_by: "",
            advanced_filter: [],
            tag_option_in: [],
          },
        })
      );
    };
  }, []);
  useEffect(() => {
    if (
      currentUserInfo.organization_id &&
      currentUserInfo.organization_currency
    ) {
      dispatch(
        setDateRange({
          start_date: getFormatedDate(),
          end_date: getFormatedDate(),
          date_range: "today",
        })
      );
      dispatch(
        setReportTableFiltersReset({
          date_range: "today",
          currency_id: currentUserInfo.organization_currency,
          end_date: getFormatedDate(),
          organization_id: currentUserInfo.organization_id,
          start_date: getFormatedDate(),
        })
      );
      getDayBookReports("initial");
    }
  }, [currentUserInfo.organization_id, currentUserInfo.organization_currency]);

  const handleReport = (type: string, argFilters: any = {}) => {
    newLoadRef.current = true;
    setHasMore(true);
    setPageNo(1);
    setIsLoading(true);
    getDayBookReports("submit", argFilters, 1);
  };

  /**
   * Function to get Day Book report
   */
  const getDayBookReports = async (
    type = "",
    argFilters = {},
    pageNum?: number
  ) => {
    setIsLoading(false);
    let filters = {
      ...reportRef.current.filterValues,
      ...dayBookFormFilter,
      ...argFilters,
    };
    if (type === "initial") {
      filters = initialFiltervalues;
      filters["currency_id"] = currentUserInfo.organization_currency;
    }
    if (
      filters.start_date === "" &&
      filters.end_date === "" &&
      filters.date_range === ""
    ) {
      filters.start_date = getFormatedDate();
      filters.end_date = getFormatedDate();
      filters.date_range = "today";
    }
    const tempFilterString = JSON.stringify(filters);
    const hashFragment = `#${encodeURIComponent(tempFilterString)}`;
    setFilterString(hashFragment);
    const responseAction = await dispatch(
      getAccountTransactionReport({
        filterValues: filters,
        orgId: currentUserInfo.organization_id,
        pageNum: pageNum || pageNo,
        pageSize: 5,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        if (response.pagination.next === null) {
          setHasMore(false);
        } else {
          setPageNo((value) => {
            return value + 1;
          });
        }
        setDayBookData(
          getUpdatedDBData(dayBookData, response.transaction_details)
        );
        setIsLoading(false);
        setShowReport(true);
      }
    }
  };
  const getUpdatedDBData = (
    previousData: ATReportTransactionDetails,
    newData: ATReportTransactionDetails
  ) => {
    let combinedData: ATReportTransactionDetails = initialDayBookData;
    if (newLoadRef.current) {
      combinedData = {
        ...newData,
      };
      newLoadRef.current = false;
    } else {
      combinedData = {
        ...previousData,
        transactions: previousData.transactions?.concat(newData.transactions),
      };
    }
    return combinedData;
  };

  return (
    <div className="reports account-transaction-report">
      <div
        className="card card-dashboard report-card account-transaction-card w-100"
        id="soa-report-card"
      >
        <div>
          <div className="card-header p-0 border-0">
            <div className="header-wrap">
              <h1>Day Book</h1>
              <CustomizeReportModal
                reportType="DB"
                handleReport={handleReport}
                onSubmit={() => {
                  newLoadRef.current = true;
                }}
              />
            </div>
          </div>
          <div className="report-body w-100">
            <div className="top-filter-container filter-box w-100">
              <ReportFilter
                isSingleDatePicker={false}
                isAdvanced={false}
                defaultValue="today"
                reportType="DB"
                handleReport={handleReport}
                clearReport={() => {
                  setShowReport(false);
                }}
                onSubmit={() => {
                  newLoadRef.current = true;
                }}
                ref={reportRef}
              />
            </div>
            {showReport ? (
              <div className="report-section w-100">
                <div className="report-body-links justify-content-between align-items-center ">
                  {/* <ReportHeaderActionLinks /> */}
                </div>
                <ReportHeader
                  reportData={dayBookData}
                  reportName={"Day Book"}
                />
                <div className="table-section report-table-section">
                  <InfoBox
                    message={
                      message(dayBookData.currency_code).reportCurrencyInfo
                    }
                    className="report-note"
                  />
                  <InfiniteScroll
                    dataLength={dayBookData?.transactions.length}
                    hasMore={hasMore}
                    next={() => {
                      newLoadRef.current = false;
                      getDayBookReports("", {});
                    }}
                    endMessage={
                      <span className="w-100 d-flex justify-content-center"></span>
                    }
                    loader={<span>{/* <CircularProgress /> */}</span>}
                  >
                    <AccountTransactionTable
                      accountTransactionData={dayBookData}
                      filterString={filterString}
                    />
                  </InfiniteScroll>
                </div>
              </div>
            ) : null}
            {isLoading ? <Loader /> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DayBook;
