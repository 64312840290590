import { useMemo } from "react";
import DetailsTableContainerSubscription from "../../../../common/components/detailsPage/DetailsTableContainerSubscription";
import TaxColumn from "../../../../common/components/detailsPage/TaxColumn";
import { NumberFormat } from "../../../../../helpers/numberFormatHelper";
import { subscriptionItemsDetail } from "../../../subscription/SubscriptionStateInitialization";
import { CreditNoteDetailValues } from "../../../../../types";
import { tdsApplyLevel } from "../../../../constants/constants";
import useCommonData from "../../../../hooks/useCommon";

type Props = {
  creditNoteData: CreditNoteDetailValues
}
const SubscriptionDeatilsTableContainer = (props: Props) => {
  const { currentUserInfo, orgCurrencyList } = useCommonData();
    /**
   * set table column headings for plan and addon
   */
  const columnsForPlanAddon = useMemo(
    () => [
      {
        Header: "Description",
        accessor: "show.description",
        className: "description",
        Cell: ({ cell: { value } }: any) => {
          return <span className="item-bold">{value}</span>;
        },
      },
      {
        Header: "Hsn/sac code",
        accessor: "show.hsnCode",
        className: "hsncode text-nowrap",
      },
      {
        Header: "Account",
        accessor: "show.account",
        className: "account",
      },
      {
        Header: "Quantity",
        accessor: "show.quantity",
        className: "quantity text-nowrap",
      },
      {
        Header: "Rate",
        accessor: "show.rate",
        className: "rate text-nowrap",
      },
      {
        Header: "Discount",
        accessor: "show.discount",
        className: "discount text-nowrap left-align",
        Cell: ({ cell: { value } }: any) => {
          let discAccount = value?.discountAccount
            ? value.discountAccount.split(" (")
            : null;
          return (
            <>
              <span className="amt">{value.discount}</span>
              <span className="acnt">
                {discAccount && discAccount.length > 0 ? discAccount[0] : ""}{" "}
                <br />{" "}
                {discAccount && discAccount.length === 2
                  ? "(" + discAccount[1]
                  : ""}
              </span>
            </>
          );
        },
      },
      {
        Header: "Tax",
        accessor: "show.tax",
        className: "tax text-nowrap text-align-left",
        Cell: ({ cell: { value } }: any) => {
          return (
            <TaxColumn
              values={value}
              currencyCode={props.creditNoteData.currency_code}
            />
          );
        },
      },
      {
        Header: "TDS",
        accessor:
          props.creditNoteData.tds_level === tdsApplyLevel.LINE_ITEM_LEVEL
            ? "show.item"
            : "TDS",
        className: "tds text-nowrap text-align-left",
        Cell: ({ cell: { value } }: any) => {
          return (
            <>
              <span className="amt">
                {props.creditNoteData.currency_code}{" "}
                {NumberFormat(
                  Number(value.tds_total),
                  props.creditNoteData.currency_code,
                  orgCurrencyList
                )}
              </span>
              <span className="actual-value d-block w-100 clear-both">
                {value.tds_name}{" "}
                {value.default_tds_percentage
                  ? "(" + value.default_tds_percentage + "%)"
                  : ""}
              </span>
              {/* <span>
                
              </span> */}
              {value.tds_percentage !== 0 &&
              value.tds_percentage !== value.default_tds_percentage ? (
                <span className="current-value  d-block w-100 clear-both">
                  Updated Rate {`(${value.tds_percentage}%)`}
                </span>
              ) : null}
            </>
          );
        },
      },
      {
        Header: "Taxable Amount",
        accessor: "show.taxableAmount",
        className: "taxableAmount text-nowrap text-align-right",
      },
    ],
    [props.creditNoteData.currency_code]
  );

  function convertToNormalText(str: string) {
    if (!str) return "";
    let words = str.toLowerCase().split("_");
    return words.join(" ");
  }
  
    return (
        <>
        <div className="table-wrap subscription-invoice-details">
          {subscriptionItemsDetail.plan_details &&
            subscriptionItemsDetail.plan_details.length > 0 &&
            subscriptionItemsDetail?.plan_details?.map((plan) => {
              return (
                <>
                  <div className="plan-description">
                    <div style={{ fontSize: "larger", marginBottom: "10px" }}>
                      Plans
                    </div>
                    <h6 className="item-bold">{plan.plan_name}</h6>
                    <div className="plan-tag">
                      <span>
                        Frequency: {plan.frequency} | Pricing model:{" "}
                        {plan.pricing_model}
                      </span>
                    </div>
                    <div>
                      <span style={{ color: "#747d84" }}>
                        Invoicing period: {plan.invoicing_period}
                      </span>
                    </div>
                  </div>
                  <DetailsTableContainerSubscription
                    columns={columnsForPlanAddon}
                    data={plan.items}
                    currency_code={props.creditNoteData.currency_code}
                    haveColumnClassName={true}
                    className="table inv-preview-table subscription-data-view-table"
                    hiddenColumns={["TDS"]}
                    pricingModel={plan.pricing_model}
                    component="invoice"
                  />
                </>
              );
            })}
            {subscriptionItemsDetail.addon_details &&
            subscriptionItemsDetail.addon_details.length > 0 && <div
            style={{
              fontSize: "larger",
              paddingLeft: "23px",
              paddingTop: "19px",
              marginBottom: "-13px",
            }}
          >
            Addons
          </div>}
          {subscriptionItemsDetail.addon_details &&
            subscriptionItemsDetail.addon_details.length > 0 &&
            subscriptionItemsDetail?.addon_details?.map((addon) => {
              return (
                <>
                  <div className="plan-description">
                    <h6 className="item-bold">{addon.addon_name}</h6>
                    <div className="plan-tag">
                      <span>
                        Frequency: {addon.frequency} | Pricing model:{" "}
                        {addon.pricing_model}
                      </span>
                    </div>
                    <div>
                      <span style={{ color: "#747d84" }}>
                        Invoicing period: {addon.invoicing_period}
                      </span>
                    </div>
                  </div>
                  <DetailsTableContainerSubscription
                    columns={columnsForPlanAddon}
                    data={addon.items}
                    currency_code={props.creditNoteData.currency_code}
                    haveColumnClassName={true}
                    className="table inv-preview-table subscription-data-view-table"
                    hiddenColumns={["TDS"]}
                    pricingModel={addon.pricing_model}
                    component="invoice"
                  />
                </>
              );
            })}
          {subscriptionItemsDetail.coupon_details &&
            subscriptionItemsDetail.coupon_details.length > 0 && <div
            style={{
              fontSize: "larger",
              paddingLeft: "23px",
              paddingTop: "19px",
              marginBottom: "-13px",
            }}
          >
            Coupons
          </div>}
          {subscriptionItemsDetail.coupon_details &&
            subscriptionItemsDetail.coupon_details.length > 0 &&
            subscriptionItemsDetail?.coupon_details?.map((coupon) => {
              return (
                <>
                  <div className="plan-description coupon-description-container">
                    <div>
                      <h6 className="item-bold">{coupon.name}</h6>
                      <div className="plan-tag">
                        <span>
                          Applicable {convertToNormalText(coupon.duration)}
                        </span>
                      </div>
                    </div>
                    <div className="coupon-discount-amount-container">
                      {coupon.discount}
                    </div>
                  </div>
                </>
              );
            })}
        </div>
        </>
    )
}

export default SubscriptionDeatilsTableContainer