import React, {
  ForwardRefRenderFunction,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Link, useParams } from "react-router-dom";
import { useAppSelector } from "../../../../../app/hooks";
import {
  CustomerItemValues,
  TCSTotalValues,
  TDSTotalValues,
  TdsPreferenceValues,
  ObjectType,
  TDSList,
} from "../../../../../types";
import ExchangeRate from "../../../../common/components/ExchangeRate";
import "../../../../common/components/lineItems/ItemDetails.css";
import {
  latestCurrencyRateSelector,
  orgCurrencyListSelector,
} from "../../../../common/commonSlice";
import "./SubscriptionDetail.css";
import {
  initialTCSTotalValues,
  initialTDSTotalValues,
  initialTaxValues,
} from "../../../invoice/components/invoiceForm/StateInitialization";
import useCommonData from "../../../../hooks/useCommon";
import { tdsDropDown, tdsTaxPreference } from "../../../tds/tdsSlice";
import { initialPreferenceValues } from "../../../tds/components/tdsPreference/StateInitialization";
import { splitNumberByDecimalPoint } from "../../../../../helpers/helper";
import { getRoundOffAmount } from "../../../../../helpers/roundOffHelper";
import { decimalPlaceOfCurrency } from "../../../../../helpers/decimalPlaceHelper";
import {
  defaultTaxPreference,
  gstTaxListSelector,
  defaultPreferenceSelector,
  clearGstTaxList,
  gstStateTypeSelector,
  getGstTaxList,
} from "../../../gst/gstSlice";
import { ErrorToaster } from "../../../../../helpers/toastHelper";
import {
  gstRegistrationTypes,
  TAX_SYSTEM,
  tdsApplyLevel,
} from "../../../../constants/constants";
import useGst from "../../../../hooks/useGst";
import AddLinePlan from "../../../../common/components/AddLinePlan";
import AddLineAddon from "../../../../common/components/AddLineAddon";
import { AddLineCoupon } from "./subscriptionAddCoupon/AddLineCoupon";
import LineCoupon from "../../../../common/components/lineItems/LineCoupon";
import PlanLineItem from "../../../../common/components/lineItems/PlanLineItem";
import { DeleteIcon } from "../../../../common/components";
import { colors } from "../../../../constants/colors";
import {
  RangePriceValue,
  SubscriptionAddonDetails,
  SubscriptionCouponList,
  SubscriptionDetailsProps,
  SubscriptionItemRef,
  SubscriptionItemValues,
  SubscriptionPlanDetails,
} from "../../subscriptionType";
import { initialPlanData } from "../../SubscriptionStateInitialization";
import {
  NON_PRICE_RANGE_PRICING_MODEL,
  PRICE_RANGE_PRICING_MODEL,
  coustomCase,
  pricingModuleRenderValue,
} from "../../../../../helpers/planHelper";
import SubscriptionTotalSection from "./SubscriptionTotalSection";
import {
  getNextInvoiceDate,
  subscriptionAddonConvertedItems,
  subscriptionConvertedCouponList,
  subscriptionPlanFrequencyItems,
} from "../../subscriptionSlice";
import { itemDropdownSelector } from "../../../items/itemSlice";
import { SubscriptionItemContext } from "./subscriptionItemTax/SubscriptionItemContext";
import {
  addSubscriptionData,
  formatDataOnChangeCustomer,
  getGstTaxItem,
} from "../helpers/subscriptionDetailsHelper";
import {
  taxableAmountHelper,
  totalCalculatedAmount,
} from "../helpers/totalAmountCalculationHelper";
import {
  defaultGstHelper,
  itemGstChangeHelper,
  itemGstChangeSet,
  setGstonFieldChange,
  taxOnBlurHelper,
} from "../helpers/onItemlevelChangeHelper";
import { validateAddedCoupon } from "./ValidateSubscriptionForm";

const SubscriptionDetails: ForwardRefRenderFunction<
  SubscriptionItemRef,
  SubscriptionDetailsProps
> = (props, ref) => {
  const { editId } = useParams();
  const { currentUserInfo, dispatch } = useCommonData();
  const itemDropdownList = useAppSelector(itemDropdownSelector);
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  const [deleteTaxItems, setDeleteTaxItems] = useState<
    { itemId: number; taxItemId: number }[]
  >([]);
  const [deleteAddonTaxItems, setDeleteAddonTaxItems] = useState<
    { itemId: number; taxItemId: number; parentId: number }[]
  >([]);
  const [deleteGstItems, setDeleteGstItems] = useState<
    { itemId: number; id: number }[]
  >([]);
  const [deleteAddonGstItems, setDeleteAddonGstItems] = useState<
    { itemId: number; id: number; parentId: number }[]
  >([]);
  const [currencyCode, setCurrencyCode] = useState<string | undefined>("");
  const [totalTaxableAmount, setTotalTaxableAmount] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [totalTaxArray, setTotalTaxArray] = useState([]);
  const [total, setTotal] = useState(0);
  const [tdsAmount, setTdsAmount] = useState(0);
  const [tcsAmount, setTcsAmount] = useState(0);
  const [gstTotalObject, setGstTotalObject] = useState<ObjectType>({});
  const [taxValues, setTaxValues] = useState(true);
  const [tdsPreferenceValues, setTdsPreferenceValues] =
    useState<TdsPreferenceValues>(initialPreferenceValues);
  const [totalTdsValues, setTotalTdsValues] = useState<TDSTotalValues>(
    initialTDSTotalValues
  );
  const [totalTcsValues, setTotalTcsValues] = useState<TCSTotalValues>(
    initialTCSTotalValues
  );
  const exchangeRateValues = useAppSelector(
    latestCurrencyRateSelector
  ).latest_conversion;
  const [tableScrolLeft, setTableScrollLeft] = useState(0);
  const itemPlanFormValuesRef =
    useRef<SubscriptionPlanDetails>(initialPlanData);
  const taxPlanFormValuesRef = useRef<SubscriptionPlanDetails>(initialPlanData);
  const itemAddonFormValuesRef = useRef<SubscriptionAddonDetails[]>([]);
  const taxAddonFormValuesRef = useRef<SubscriptionAddonDetails[]>([]);
  const { isGstOrg } = useGst();
  const gstTaxList = useAppSelector(gstTaxListSelector);
  const [roundOffValue, setRoundOffValue] = useState(null);
  const [manuallyEditedTotal, setManuallyEditedTotal] = useState<any>(null);
  const [isItemEdited, setIsItemEdited] = useState(false);
  const [couponDiscountValue, setCouponDiscountValue] = useState(null);

  const [planItemFormValues, setPlanItemFormValues] =
    useState<SubscriptionPlanDetails>(initialPlanData);
  const [planItemFormValuesForTax, setPlanItemFormValuesForTax] =
    useState<SubscriptionPlanDetails>(initialPlanData);
  const [taxPlanFormValues, setTaxPlanFormValues] =
    useState<SubscriptionPlanDetails>(initialPlanData);

  const [addonItemFormValues, setAddonItemFormValues] = useState<
    SubscriptionAddonDetails[]
  >([]);
  const [addonItemFormValuesForTax, setAddonItemFormValuesForTax] = useState<
    SubscriptionAddonDetails[]
  >([]);
  const [taxAddonFormValues, setTaxAddonFormValues] = useState<
    SubscriptionAddonDetails[]
  >([]);
  const [couponFormValues, setCouponFormValues] = useState<
    SubscriptionCouponList[]
  >([]);
  const [isCouponAdded, setCouponAdded] = useState<boolean>(false);
  const [changedPlansValues, setChangedPlansValues] = useState<number[]>([]);
  const [changedAddonValues, setChangedAddonValues] =
    useState<{ parentId: number; itemIds: number[] } | null>(null);
  const stateType = useAppSelector(gstStateTypeSelector) as string;
  let taxList = useAppSelector(gstTaxListSelector) as ObjectType[];

  const organization_tax_system = currentUserInfo.organization_tax_system;

  const defaultPreference = useAppSelector(
    defaultPreferenceSelector
  ) as ObjectType;
  const [tdsTaxes, setTdsTaxes] = useState<TDSList[]>([]);
  useImperativeHandle(
    ref,
    () => ({
      itemPlanValues: planItemFormValues,
      taxPlanValues: taxPlanFormValues,
      itemAddonValues: addonItemFormValues,
      taxAddonValues: taxAddonFormValues,
      totalTdsValues: totalTdsValues,
      totalTcsValues: totalTcsValues,
      couponFormValues: couponFormValues,
      deleteTaxItems,
      deleteAddonTaxItems,
      deleteGstItems,
      deleteAddonGstItems,
    }),
    [
      planItemFormValues,
      taxPlanFormValues,
      addonItemFormValues,
      taxAddonFormValues,
      couponFormValues,
      deleteAddonTaxItems,
      deleteTaxItems,
      totalTdsValues,
      totalTcsValues,
    ]
  );

  useEffect(() => {
    setTotalTdsValues(
      props.totalTdsValues ? props.totalTdsValues : initialTDSTotalValues
    );
  }, [props.totalTdsValues]);

  useEffect(() => {
    setTotalTcsValues(
      props.totalTcsValues ? props.totalTcsValues : initialTCSTotalValues
    );
  }, [props.totalTcsValues]);

  useEffect(() => {
    if (
      props.itemFormValues?.itemDetails.length === 1 &&
      props.itemFormValues.itemDetails[0]?.item_name === "" &&
      props.itemFormValues.itemDetails[0]?.inventory_item_id === null
    ) {
    } else {
      setPlanItemFormValues({ ...props.itemFormValues });
      setTaxPlanFormValues({ ...props.itemFormValues });
    }
  }, [props.itemFormValues]);

  useEffect(() => {
    if (
      props.addonItemFormValues.length === 1 &&
      props.addonItemFormValues[0].itemDetails[0]?.item_name === "" &&
      props.addonItemFormValues[0].itemDetails[0]?.inventory_item_id === null
    ) {
    } else {
      setAddonItemFormValues(props.addonItemFormValues);
      setTaxAddonFormValues(props.addonItemFormValues);
    }
  }, [props.addonItemFormValues]);

  useEffect(() => {
    if (props.couponFormValues && props.couponFormValues.length > 0) {
      setCouponFormValues(props.couponFormValues);
    }
  }, [props.couponFormValues]);

  useEffect(() => {
    if (props.totalFormValues.transaction_tds_amount)
      setTdsAmount(props.totalFormValues.transaction_tds_amount);
  }, [props.totalFormValues]);

  useEffect(() => {
    setManuallyEditedTotal(props?.editTotal);
  }, [props.editTotal]);

  useEffect(() => {
    if (currencyCode) {
      let planItems = [];
      let addonItems = [];
      const updatedDecimals = decimalPlaceOfCurrency(
        currencyCode,
        orgCurrencyList
      );

      for (let item of planItemFormValues.itemDetails) {
        planItems.push({
          ...item,
          discount: getRoundOffAmount(item.discount, updatedDecimals),
        });
      }
      setPlanItemFormValues({ ...planItemFormValues, itemDetails: planItems });

      for (let eachAddon of addonItemFormValues) {
        let items = [];
        for (let item of eachAddon.itemDetails) {
          items.push({
            ...item,
            discount: getRoundOffAmount(item.discount, updatedDecimals),
          });
        }
        addonItems.push({ ...eachAddon, itemDetails: items });
      }
      setAddonItemFormValues(addonItems);
    }
  }, [currencyCode]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    let tempManuallyEditedTotal = manuallyEditedTotal;
    if (isItemEdited) {
      tempManuallyEditedTotal = null;
      setManuallyEditedTotal(null);
    }
    calculateAmountAndTax(signal, tempManuallyEditedTotal);
    return () => {
      controller.abort();
    };
  }, [
    planItemFormValues.itemDetails,
    addonItemFormValues,
    couponFormValues,
    currencyCode,
    totalTdsValues,
    totalTcsValues,
    props.isRoundOffEnabled,
    props.isTotalEditable,
  ]);

  useEffect(() => {
    if (manuallyEditedTotal !== null) {
      const controller = new AbortController();
      const signal = controller.signal;
      calculateAmountAndTax(signal, manuallyEditedTotal);
      return () => {
        controller.abort();
      };
    }
  }, [manuallyEditedTotal]);
  useEffect(() => {
    if (
      taxValues &&
      planItemFormValues.itemDetails[0]?.item_name !== "" &&
      planItemFormValues.itemDetails[0]?.rate !== 0
    ) {
      setPlanItemFormValuesForTax(planItemFormValues);
      setTaxValues(false);
    }
    itemPlanFormValuesRef.current = planItemFormValues;
    taxPlanFormValuesRef.current = taxPlanFormValues;
  }, [planItemFormValues]);

  useEffect(() => {
    if (taxValues && addonItemFormValues.length > 0) {
      setAddonItemFormValuesForTax(addonItemFormValues);
      setTaxValues(false);
    }
    itemAddonFormValuesRef.current = addonItemFormValues;
    taxAddonFormValuesRef.current = taxAddonFormValues;
  }, [addonItemFormValues]);

  useEffect(() => {
    if (props.currencyId) {
      let currencyValues = orgCurrencyList
        .filter((currency: any) => currency.id === props.currencyId)
        .map((currency: any) => currency);
      if (currencyValues.length) {
        setCurrencyCode(currencyValues[0].code);
      }
    }
  }, [props.currencyId]);

  useEffect(() => {
    return () => {
      dispatch(clearGstTaxList());
    };
  }, []);

  const planItemDetailsOnCustomerChange = useCallback(
    async (value: any) => {
      const responseAction = await dispatch(
        subscriptionPlanFrequencyItems({
          planId: value.planId as number,
          pricingId: value.pricingId,
          orgId: currentUserInfo.organization_id,
          currencyId: value.currencyId ? value.currencyId : 0,
          conversionId: value.conversionId ? value.conversionId : 0,
        })
      );

      if (responseAction.payload) {
        const response = responseAction.payload;
        if (Object.keys(response).length && !("error" in response)) {
          const updatedPlanItemDetails = formatDataOnChangeCustomer(
            planItemFormValues,
            response,
            "plan"
          );
          const updatedPlanTaxItemDetails = formatDataOnChangeCustomer(
            taxPlanFormValues,
            response,
            "plan"
          );
          setPlanItemFormValues({
            ...planItemFormValues,
            itemDetails: updatedPlanItemDetails,
          });
          setTaxPlanFormValues({
            ...taxPlanFormValues,
            itemDetails: updatedPlanTaxItemDetails,
          });
        }
      }
    },
    [exchangeRateValues.id]
  );

  const addonItemDetailsOnCustomerChange = useCallback(
    async (value: any) => {
      const responseAction = await dispatch(
        subscriptionAddonConvertedItems({
          addonId: value.addonIds,
          pricingId: value.pricingIds,
          orgId: currentUserInfo.organization_id,
          currencyId: value.currencyId ? value.currencyId : 0,
          conversionId: value.conversionId ? value.conversionId : 0,
        })
      );

      if (responseAction.payload) {
        const response = responseAction.payload;

        if (Object.keys(response).length && !("error" in response)) {
          const updatedAddonItemDetails = addonItemFormValues.map(
            (eachAddon: any) => {
              const addon = response.find(
                (data: any) => data.id === Number(eachAddon.addonId)
              ).pricings;
              let eachAddonItemUpdate = formatDataOnChangeCustomer(
                eachAddon,
                addon.item_price,
                "addon"
              );
              return { ...eachAddon, itemDetails: eachAddonItemUpdate };
            }
          );
          const updatedAddonTaxItemDetails = taxAddonFormValues.map(
            (eachAddon: any) => {
              const addon = response.find(
                (data: any) => data.id === Number(eachAddon.addonId)
              ).pricings;
              let eachAddonItemUpdate = formatDataOnChangeCustomer(
                eachAddon,
                addon.item_price,
                "addon"
              );
              return { ...eachAddon, itemDetails: eachAddonItemUpdate };
            }
          );
          setAddonItemFormValues(updatedAddonItemDetails);
          setTaxAddonFormValues(updatedAddonTaxItemDetails);
        }
      }
    },
    [exchangeRateValues.id]
  );

  const couponDetailsOnCustomerChange = useCallback(
    async (value: any) => {
      const responseAction = await dispatch(
        subscriptionConvertedCouponList({
          couponIds: value.couponIds,
          orgId: currentUserInfo.organization_id,
          currencyId: value.currencyId ? value.currencyId : 0,
          conversionId: value.conversionId ? value.conversionId : 0,
        })
      );

      if (responseAction.payload) {
        const response = responseAction.payload;

        if (Object.keys(response).length && !("error" in response)) {
          setCouponFormValues(response);
        }
      }
    },
    [exchangeRateValues.id]
  );

  useEffect(() => {
    if (
      !editId &&
      props.changedCustomerData.customerId &&
      props.changedCustomerData.customerId !== 0
    ) {
      if (planItemFormValues?.itemDetails?.length > 0) {
        planItemDetailsOnCustomerChange({
          planId: planItemFormValues.planId,
          pricingId: planItemFormValues.pricing_id,
          currencyId: props.changedCustomerData.currencyId
            ? props.changedCustomerData.currencyId
            : 0,
          conversionId: exchangeRateValues.id ? exchangeRateValues.id : 0,
        });
      }
      if (addonItemFormValues?.length > 0) {
        const addonIds = addonItemFormValues.map(
          (eachAddon: any) => eachAddon.addonId
        );
        const pricingIds = addonItemFormValues.map(
          (eachAddon: any) => eachAddon.pricing_id
        );
        addonItemDetailsOnCustomerChange({
          addonIds: addonIds,
          pricingIds: pricingIds,
          currencyId: props.changedCustomerData.currencyId
            ? props.changedCustomerData.currencyId
            : 0,
          conversionId: exchangeRateValues.id ? exchangeRateValues.id : 0,
        });
      }

      if (couponFormValues?.length > 0) {
        const couponIds = couponFormValues.map((eachCoupon) => eachCoupon.id);
        couponDetailsOnCustomerChange({
          couponIds: couponIds,
          currencyId: props.changedCustomerData.currencyId
            ? props.changedCustomerData.currencyId
            : 0,
          conversionId: exchangeRateValues.id ? exchangeRateValues.id : 0,
        });
      }
    }
  }, [exchangeRateValues.id]);
  /**
   * Edit : Set the currency value based on the chosen currency of the customer.
   */
  useEffect(() => {
    if (editId && props.currencyCode !== "" && props.currencyCode !== null) {
      setCurrencyCode(props.currencyCode);
    }
  }, [props.currencyCode]);

  useEffect(() => {
    if (organization_tax_system === TAX_SYSTEM.INDIAN_TAX_SYSTEM) {
      getTdsTaxPreferenceDetails();
    }
    if (isGstOrg) {
      dispatch(
        defaultTaxPreference({ orgId: currentUserInfo.organization_id })
      );
    }
  }, [currentUserInfo.organization_id]);

  /**
   * Function to pass line item details for getting totals
   */
  const calculateAmountAndTax = async (
    signal: AbortSignal,
    editedTotal: number
  ) => {
    let formItems = JSON.parse(JSON.stringify(planItemFormValues.itemDetails));
    let filteredFormItems = formItems;

    filteredFormItems.map(async (items: any, index: any) => {
      let itemInputs = totalCalculatedAmount(
        items,
        gstTaxList,
        props.currencyId
      );
      if (
        !isCouponAdded &&
        !(
          changedPlansValues.length > 0 && !changedPlansValues.includes(index)
        ) &&
        !(changedAddonValues && changedAddonValues.itemIds.length > 0)
      ) {
        const taxResponseAction = await props.taxableAmount({
          values: itemInputs,
          orgId: currentUserInfo.organization_id,
          signal: signal,
        });
        if (taxResponseAction.payload) {
          const taxResponse = taxResponseAction.payload;
          if (Object.keys(taxResponse).length && !("error" in taxResponse)) {
            taxableAmountHelper(
              taxResponse,
              index,
              "-1",
              currencyCode,
              props.itemFormErrors,
              orgCurrencyList,
              currentUserInfo
            );
          }
        }
      }
    });

    let allAddedAddonItems: any[] = [];
    addonItemFormValues.map(async (eachAddon: any, parentIndex: any) => {
      let addonFormItems = JSON.parse(JSON.stringify(eachAddon.itemDetails));
      let filteredAddonFormItems = addonFormItems;
      filteredAddonFormItems.map(async (items: any, index: any) => {
        let itemInputs = totalCalculatedAmount(
          items,
          gstTaxList,
          props.currencyId
        );
        if (
          !isCouponAdded &&
          changedPlansValues.length <= 0 &&
          ((changedAddonValues?.parentId === parentIndex &&
            changedAddonValues?.itemIds.includes(index)) ||
            !changedAddonValues)
        ) {
          const taxResponseAction = await props.taxableAmount({
            values: itemInputs,
            orgId: currentUserInfo.organization_id,
            signal: signal,
          });
          if (taxResponseAction.payload) {
            const taxResponse = taxResponseAction.payload;
            if (Object.keys(taxResponse).length && !("error" in taxResponse)) {
              taxableAmountHelper(
                taxResponse,
                index,
                parentIndex,
                currencyCode,
                props.itemFormErrors,
                orgCurrencyList,
                currentUserInfo
              );
            }
          }
        }
      });

      allAddedAddonItems.push(...filteredAddonFormItems);
    });
    setChangedPlansValues([]);
    setChangedAddonValues(null);
    setCouponAdded(false);
    const updatedFormItems = {
      plan_item_list: filteredFormItems,
      tds_percentage: Number(totalTdsValues.tds_percentage),
      tds_amount: Number(totalTdsValues.tds_amount),
      tcs_percentage: Number(totalTcsValues.tcs_percentage),
      tcs_amount: Number(totalTcsValues.tcs_amount),
      currency_id: props.currencyId,
      roundoff_enabled: props.isRoundOffEnabled,
      is_total_editable: props.isTotalEditable,
      total: editedTotal,
      addon_item_list: allAddedAddonItems,
      coupon_list: couponFormValues.map((data: SubscriptionCouponList) =>
        Number(data.id)
      ),
      conversion_id:
        exchangeRateValues.id && !editId
          ? exchangeRateValues.id
          : props.formValues.conversion_id,
    };
    if (filteredFormItems.length) {
      const responseAction = await props.amountCalculation({
        values: updatedFormItems,
        orgId: currentUserInfo.organization_id,
        signal: signal,
      });
      if (responseAction.payload) {
        const response = responseAction.payload;
        if (Object.keys(response).length && !("error" in response)) {
          const addonError = validateAddedCoupon(
            response.total_details.sub_total_amount,
            couponFormValues,
            response.total_details?.roundoff_amount
          );
          props.setCouponError(addonError);

          setTotalTaxableAmount(response.total_details.total_taxable_amount);
          response.total_details.gst_amounts &&
            setGstTotalObject({ ...response.total_details.gst_amounts });
          setTotalTaxArray(response.total_details.taxes);
          setTotal(response.total_details.total);
          props.getTotalValue &&
            props.getTotalValue(response.total_details.total);
          setTdsAmount(response.total_details.tds_total);
          setTcsAmount(response.total_details.tcs_total);

          response.total_details.gst_amounts?.gst_total !== 0
            ? setTotalTax(response.total_details.gst_amounts?.gst_total)
            : setTotalTax(response.total_details.tax_amount);

          setRoundOffValue((prevValue) => {
            return response.total_details.hasOwnProperty("roundoff_amount")
              ? response.total_details?.roundoff_amount
              : null;
          });
          setCouponDiscountValue((prevValue) => {
            return response.total_details.hasOwnProperty("coupon_amount")
              ? response.total_details?.coupon_amount
              : null;
          });
          if (props.updateCalculatedTotal) {
            props.updateCalculatedTotal(response.total_details.total);
          }
        }
        if (!editId) getNextInvoiceAction(response.total_details.total);
      }
    } else {
      setTotalTaxableAmount(0.0);
      setTotalTax(0.0);
      setTotal(0.0);
      props.getTotalValue && props.getTotalValue(0);
      setTotalTax(0);
      setTotalTaxArray([]);
      setTdsAmount(0);
      setTcsAmount(0);
      setRoundOffValue(null);
      setCouponDiscountValue(null);
    }
  };
  const getNextInvoiceAction = async (totalAmount: number) => {
    const responseNextInvoiceAction = await dispatch(
      getNextInvoiceDate({
        invoicingoption: props.invoicingOption ? props.invoicingOption : "",
        pricing_id: planItemFormValues.pricing_id,
        plan_id: planItemFormValues.planId,
        start_date: props.startDate ? props.startDate : "",
        total_amount: totalAmount,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseNextInvoiceAction.payload) {
      props.setNextInvoiceDateDetails(responseNextInvoiceAction.payload);
    }
  };

  useEffect(() => {
    if (
      planItemFormValues.itemDetails &&
      planItemFormValues.itemDetails.length > 0 &&
      !editId
    ) {
      getNextInvoiceAction(total);
    }
  }, [props.invoicingOption, props.startDate]);

  const setAccountDropdown = () => {
    var leftScroll = 0;
    var scrollItem: any = document.querySelector(".item-det-container");
    let resizeFlag: boolean = false;
    scrollItem &&
      scrollItem.addEventListener("scroll", function (e: any) {
        setTableScrollLeft(scrollItem.scrollLeft);
      });
    $(".dropdown-toggle").on("show.bs.dropdown", function () {
      $(".items-entry-wrapper").addClass("overflow-initial");
      $(".item-det-container").removeClass("overflow-coloumn");
      $(".item-det-container .item-grid-account").css(
        "margin-left",
        "-" + tableScrolLeft + "px"
      );
    });
    $(".dropdown-toggle").on("hide.bs.dropdown", function () {
      $(".items-entry-wrapper").removeClass("overflow-initial");
      $(".item-det-container").addClass("overflow-coloumn");
      $(".item-det-container .item-grid-account").css("margin-left", 0);
    });

    window.addEventListener("resize", () => {
      if (!resizeFlag) {
        resizeFlag = true;
        ($(".dropdown-toggle") as any).dropdown("hide");
        setTimeout(() => {
          resizeFlag = false;
        }, 20);
      }
    });
  };
  $(() => {
    setAccountDropdown();
  });

  const handlePlanItemChange = (e: any, index: number) => {
    let { name, value } = e.target;
    if (planItemFormValues) {
      const items = [...planItemFormValues?.itemDetails];
      const numberRegex = /^([0-9,]{1,})?(\.)?([0-9]{1,})?$/;
      if (name === "quantity" || name === "rate" || name === "discount") {
        let decimalPointSplitArray: string[] = [];
        if (value.toString().includes(",")) {
          value = value.toString().split(",").join("");
        }
        decimalPointSplitArray.push(value);
        if (value.includes(".")) {
          decimalPointSplitArray = value.toString().split(".");
        }
        if (decimalPointSplitArray[0].length < 15) {
          if (value === "" || numberRegex.test(value)) {
            setChangedPlansValues([index]);
            items[index] = { ...items[index], [name]: value };
            setPlanItemFormValues({
              ...planItemFormValues,
              itemDetails: items,
            });
          }
        }
      } else {
        setChangedPlansValues([index]);
        items[index] = { ...items[index], [name]: value };
        setPlanItemFormValues({ ...planItemFormValues, itemDetails: items });
      }
      setIsItemEdited(true);
    }
  };

  const handleAddonItemChange = (e: any, index: number, parentIndex = 0) => {
    let { name, value } = e.target;
    if (addonItemFormValues && addonItemFormValues.length > 0) {
      const items = addonItemFormValues[parentIndex].itemDetails;
      const numberRegex = /^([0-9,]{1,})?(\.)?([0-9]{1,})?$/;
      if (name === "quantity" || name === "rate" || name === "discount") {
        let decimalPointSplitArray: string[] = [];
        if (value.toString().includes(",")) {
          value = value.toString().split(",").join("");
        }
        decimalPointSplitArray.push(value);
        if (value.includes(".")) {
          decimalPointSplitArray = value.toString().split(".");
        }
        if (decimalPointSplitArray[0].length < 15) {
          if (value === "" || numberRegex.test(value)) {
            items[index] = { ...items[index], [name]: value };
            setChangedAddonValues({ parentId: parentIndex, itemIds: [index] });
            setAddonItemFormValues([
              ...addonItemFormValues.slice(0, parentIndex),
              { ...addonItemFormValues[parentIndex], itemDetails: items },
              ...addonItemFormValues.slice(parentIndex + 1),
            ]);
          }
        }
      } else {
        items[index] = { ...items[index], [name]: value };
        setChangedAddonValues({ parentId: parentIndex, itemIds: [index] });
        setAddonItemFormValues([
          ...addonItemFormValues.slice(0, parentIndex),
          { ...addonItemFormValues[parentIndex], itemDetails: items },
          ...addonItemFormValues.slice(parentIndex + 1),
        ]);
      }

      setIsItemEdited(true);
    }
  };

  const handlePlanItemOnBlur = (
    name: string,
    amount: number,
    index: number
  ) => {
    const items = planItemFormValues?.itemDetails || [];
    if (amount.toString().includes(".")) {
      const [, fractionalPart] = splitNumberByDecimalPoint(amount);
      let decimals: number;
      switch (name) {
        case "rate":
        case "quantity":
          decimals = 6;
          break;
        case "discount":
        case "tds_amount":
          decimals = decimalPlaceOfCurrency(currencyCode, orgCurrencyList);
          break;
        default:
          decimals = decimalPlaceOfCurrency(currencyCode, orgCurrencyList);
      }

      if (fractionalPart.length > decimals) {
        const roundedAmount = getRoundOffAmount(amount, decimals);
        if ((name === "tds_amount" || name === "tds_percentage") && index < 0) {
          setTotalTdsValues((values) => ({
            ...values,
            tds_amount: roundedAmount,
          }));
        } else {
          setChangedPlansValues([index]);
          items[index] = { ...items[index], [name]: roundedAmount };
          setPlanItemFormValues({ ...planItemFormValues, itemDetails: items });
        }
      }
    }
  };

  const handleAddonItemOnBlur = (
    name: string,
    amount: number,
    index: number,
    parentIndex = 0
  ) => {
    const items = addonItemFormValues[parentIndex]?.itemDetails || [];
    if (amount.toString().includes(".")) {
      const [, fractionalPart] = splitNumberByDecimalPoint(amount);
      let decimals: number;
      switch (name) {
        case "rate":
        case "quantity":
          decimals = 6;
          break;
        case "discount":
        case "tds_amount":
          decimals = decimalPlaceOfCurrency(currencyCode, orgCurrencyList);
          break;
        default:
          decimals = decimalPlaceOfCurrency(currencyCode, orgCurrencyList);
      }

      if (fractionalPart.length > decimals) {
        const roundedAmount = getRoundOffAmount(amount, decimals);
        if ((name === "tds_amount" || name === "tds_percentage") && index < 0) {
          setTotalTdsValues((values) => ({
            ...values,
            tds_amount: roundedAmount,
          }));
        } else {
          setChangedAddonValues({ parentId: parentIndex, itemIds: [index] });
          items[index] = { ...items[index], [name]: roundedAmount };
          setAddonItemFormValues([
            ...addonItemFormValues.slice(0, parentIndex),
            { ...addonItemFormValues[parentIndex], itemDetails: items },
            ...addonItemFormValues.slice(parentIndex + 1),
          ]);
        }
      }
    }
  };

  const handlePlanItemAccountChange = (accountId: number, index: number, accountName: string) => {
    setChangedPlansValues([index]);
    if (planItemFormValues) {
      let items = [...planItemFormValues?.itemDetails];
      items[index] = { ...items[index], account_id: accountId, account_name: accountName};
      setPlanItemFormValues({ ...planItemFormValues, itemDetails: items });
    }
  };

  const handleAddonItemAccountChange = (
    accountId: number,
    index: number,
    accountName: string,
    parentIndex = 0
  ) => {
    if (addonItemFormValues && addonItemFormValues.length > 0) {
      let items = addonItemFormValues[parentIndex]?.itemDetails;
      items[index] = { ...items[index], account_id: accountId, account_name: accountName };
      setAddonItemFormValues([
        ...addonItemFormValues.slice(0, parentIndex),
        { ...addonItemFormValues[parentIndex], itemDetails: items },
        ...addonItemFormValues.slice(parentIndex + 1),
      ]);
    }
  };
  useEffect(() => {}, []);

  const handlePlanTdsLabelChange = (
    id: number,
    rate: number,
    name: string,
    index: number
  ) => {
    const items = [...planItemFormValues.itemDetails];
    items[index] = {
      ...items[index],
      tds_id: Number(id) ? Number(id) : null,
      tds_account_id: Number(id),
      tds_amount: 0,
      tds_percentage: Number(rate),
      tds_name: name,
    };
    setChangedPlansValues([index]);
    setPlanItemFormValues({ ...planItemFormValues, itemDetails: [...items] });
    let itemFormErrors: any = props.itemFormErrors ? props.itemFormErrors : [];
    itemFormErrors[index] = {
      ...itemFormErrors[index],
      tds_name: "",
    };
    setIsItemEdited(true);
  };

  const handleAddonTdsLabelChange = (
    id: number,
    rate: number,
    name: string,
    index: number,
    parentIndex = 0
  ) => {
    const items = [...addonItemFormValues[parentIndex].itemDetails];
    items[index] = {
      ...items[index],
      tds_id: Number(id) ? Number(id) : null,
      tds_account_id: Number(id),
      tds_amount: 0,
      tds_percentage: Number(rate),
      tds_name: name,
    };
    setChangedAddonValues({ parentId: parentIndex, itemIds: [index] });
    setAddonItemFormValues([
      ...addonItemFormValues.slice(0, parentIndex),
      { ...addonItemFormValues[parentIndex], itemDetails: [...items] },
      ...addonItemFormValues.slice(parentIndex + 1),
    ]);
    let itemFormErrors: any = props.itemFormErrors ? props.itemFormErrors : [];
    itemFormErrors[index] = {
      ...itemFormErrors[index],
      tds_name: "",
    };
    setIsItemEdited(true);
  };

  const clearPlanTaxSelection = (index: number) => {
    const items = [...planItemFormValues.itemDetails];
    items[index] = {
      ...items[index],
      tds_id: null,
      tds_account_id: 0,
      tds_amount: 0,
      tds_percentage: 0,
    };
    setChangedPlansValues([index]);
    setPlanItemFormValues({ ...planItemFormValues, itemDetails: [...items] });
    setIsItemEdited(true);
  };

  const clearAddonTaxSelection = (index: number, parentIndex = 0) => {
    const items = [...addonItemFormValues[parentIndex].itemDetails];
    items[index] = {
      ...items[index],
      tds_id: null,
      tds_account_id: 0,
      tds_amount: 0,
      tds_percentage: 0,
    };
    setChangedAddonValues({ parentId: parentIndex, itemIds: [index] });
    setAddonItemFormValues([
      ...addonItemFormValues.slice(0, parentIndex),
      { ...addonItemFormValues[parentIndex], itemDetails: [...items] },
      ...addonItemFormValues.slice(parentIndex + 1),
    ]);
    setIsItemEdited(true);
  };
  const clearPlanGstSelection = (itemIndex: number) => {
    const items = JSON.parse(JSON.stringify(planItemFormValues.itemDetails));
    const item = items[itemIndex];
    if (editId && item.gst_tax) deleteItemGst(item);
    setChangedPlansValues([itemIndex]);
    setPlanItemFormValues((prevValue) => {
      const itemValues = JSON.parse(JSON.stringify(prevValue.itemDetails));
      itemValues[itemIndex] = {
        ...itemValues[itemIndex],
        gst_tax: null,
      };
      return { ...planItemFormValues, itemDetails: itemValues };
    });
    setTaxPlanFormValues((prevValue) => {
      const itemValues = JSON.parse(JSON.stringify(prevValue.itemDetails));
      itemValues[itemIndex] = {
        ...itemValues[itemIndex],
        gst_tax: null,
      };
      return { ...taxPlanFormValues, itemDetails: itemValues };
    });
  };

  const clearAddonGstSelection = (itemIndex: number, parentIndex = 0) => {
    const items = JSON.parse(
      JSON.stringify(addonItemFormValues[parentIndex].itemDetails)
    );
    const item = items[itemIndex];
    if (editId && item.gst_tax) deleteAddonItemGst(item, parentIndex);
    setChangedAddonValues({ parentId: parentIndex, itemIds: [itemIndex] });
    setAddonItemFormValues((prevValue) => {
      const itemValues = JSON.parse(
        JSON.stringify(prevValue[parentIndex].itemDetails)
      );
      itemValues[itemIndex] = {
        ...itemValues[itemIndex],
        gst_tax: null,
      };
      return [
        ...prevValue.slice(0, parentIndex),
        { ...prevValue[parentIndex], itemDetails: itemValues },
        ...prevValue.slice(parentIndex + 1),
      ];
    });
    setTaxAddonFormValues((prevValue) => {
      const itemValues = JSON.parse(
        JSON.stringify(prevValue[parentIndex].itemDetails)
      );
      itemValues[itemIndex] = {
        ...itemValues[itemIndex],
        gst_tax: null,
      };
      return [
        ...prevValue.slice(0, parentIndex),
        { ...prevValue[parentIndex], itemDetails: itemValues },
        ...prevValue.slice(parentIndex + 1),
      ];
    });
  };

  const handlePlanAmountChange = (name: string, e: any, index: number) => {
    let value = e.target.value;
    const numberRegex = /^([0-9]{1,})?(\.)?([0-9]{1,})?$/;
    const items = [...planItemFormValues.itemDetails];

    let decimalPointSplitArray: string[] = [];
    if (value.toString().includes(",")) {
      value = value.toString().split(",").join("");
    }
    decimalPointSplitArray.push(value);
    if (value.includes(".")) {
      decimalPointSplitArray = value.toString().split(".");
    }
    if (decimalPointSplitArray[0].length < 15) {
      if (value === "" || numberRegex.test(value)) {
        if (
          (name === "tds_percentage" && value <= 100) ||
          name !== "tds_percentage"
        ) {
          setChangedPlansValues([index]);
          items[index] = { ...items[index], [name]: value ? value : 0 };
          setPlanItemFormValues({ ...planItemFormValues, itemDetails: items });
        }
      }
    }
    if (value <= planItemFormValues.itemDetails[index].rate) {
      let itemFormErrors: any = props.itemFormErrors
        ? props.itemFormErrors
        : [];
      itemFormErrors[index] = {
        ...itemFormErrors[index],
        tds_amount: "",
      };
      props.setErrorOnItemTaxChange(itemFormErrors);
    }
    setIsItemEdited(true);
  };

  const handleAddonAmountChange = (
    name: string,
    e: any,
    index: number,
    parentIndex = 0
  ) => {
    let value = e.target.value;
    const numberRegex = /^([0-9]{1,})?(\.)?([0-9]{1,})?$/;
    const items = [...addonItemFormValues[parentIndex].itemDetails];

    let decimalPointSplitArray: string[] = [];
    if (value.toString().includes(",")) {
      value = value.toString().split(",").join("");
    }
    decimalPointSplitArray.push(value);
    if (value.includes(".")) {
      decimalPointSplitArray = value.toString().split(".");
    }
    if (decimalPointSplitArray[0].length < 15) {
      if (value === "" || numberRegex.test(value)) {
        if (
          (name === "tds_percentage" && value <= 100) ||
          name !== "tds_percentage"
        ) {
          items[index] = { ...items[index], [name]: value ? value : 0 };
          setChangedAddonValues({ parentId: parentIndex, itemIds: [index] });
          setAddonItemFormValues([
            ...addonItemFormValues.slice(0, parentIndex),
            { ...addonItemFormValues[parentIndex], itemDetails: [...items] },
            ...addonItemFormValues.slice(parentIndex + 1),
          ]);
        }
      }
    }
    if (value <= addonItemFormValues[parentIndex].itemDetails[index].rate) {
      let itemFormErrors: any = props.addonItemFormError
        ? props.addonItemFormError
        : [[]];
      itemFormErrors[parentIndex][index] = {
        ...itemFormErrors[parentIndex][index],
        tds_amount: "",
      };
      props.setAddonErrorOnItemTaxChange(itemFormErrors);
    }
    setIsItemEdited(true);
  };
  const handlePlanItemTaxChange = (
    e: any,
    itemIndex: number,
    taxIndex: number
  ) => {
    setChangedPlansValues([itemIndex]);
    const { name, value } = e.target;
    const numberRegex = /^([0-9]{1,})?(\.)?([0-9]{1,})?$/;
    if (name === "tax_percentage" && value !== "") {
      let decimalPointSplitArray: string[] = [];
      decimalPointSplitArray.push(value);
      if (value.includes(".")) {
        decimalPointSplitArray = value.toString().split(".");
      }
      if (!numberRegex.test(value) || decimalPointSplitArray[0].length > 14) {
        return;
      }
    }
    const items = JSON.parse(JSON.stringify(planItemFormValues?.itemDetails));
    const taxItems = JSON.parse(JSON.stringify(taxPlanFormValues?.itemDetails));
    let taxExist = [];
    if (value) {
      taxExist = items[itemIndex].taxes.filter(
        (tax: any, index: number) => tax.tax_id === value && index !== taxIndex
      );
      let itemFormErrors: CustomerItemValues[] = props.itemFormErrors
        ? props.itemFormErrors
        : [];
      if (taxExist.length) {
        if (name === "tax_id") {
          if (typeof itemFormErrors[itemIndex] === "undefined") {
            itemFormErrors[itemIndex] = {
              ...itemFormErrors[itemIndex],
              taxes: [],
            };
          }
          itemFormErrors[itemIndex].taxes[taxIndex] = {
            tax_id: "",
            tax_percentage: 0,
          };
          itemFormErrors[itemIndex].taxes[taxIndex].tax_id =
            "Tax label already used";
          props.setErrorOnItemTaxChange(itemFormErrors);
        }
      } else {
        if (typeof itemFormErrors[itemIndex] !== "undefined") {
          if (name === "tax_id") {
            if (!itemFormErrors[itemIndex].taxes) {
              itemFormErrors[itemIndex] = {
                ...itemFormErrors[itemIndex],
                taxes: [],
              };
            }
            itemFormErrors[itemIndex].taxes[taxIndex] = {
              tax_id: "",
              tax_percentage: 0,
            };
            props.setErrorOnItemTaxChange(itemFormErrors);
          }
        }
      }
    }
    items[itemIndex].taxes[taxIndex] = {
      ...items[itemIndex].taxes[taxIndex],
      [name]: value,
    };
    taxItems[itemIndex].taxes[taxIndex] = {
      ...taxItems[itemIndex].taxes[taxIndex],
      [name]: value,
    };

    setTaxPlanFormValues({ ...taxPlanFormValues, itemDetails: taxItems });
    if (!taxExist.length) {
      setPlanItemFormValues({ ...planItemFormValues, itemDetails: items });
    } else {
      if (
        planItemFormValues &&
        planItemFormValues?.itemDetails[itemIndex].taxes[taxIndex].tax_id !== ""
      ) {
        planItemFormValues.itemDetails[itemIndex].taxes[taxIndex] = {
          tax_id: "",
          tax_percentage:
            planItemFormValues.itemDetails[itemIndex].taxes[taxIndex]
              .tax_percentage,
        };
        setPlanItemFormValues({
          ...planItemFormValues,
          itemDetails: [...planItemFormValues.itemDetails],
        });
      }
    }
    setIsItemEdited(true);
  };

  const handleAddonItemTaxChange = (
    e: any,
    itemIndex: number,
    taxIndex: number,
    parentIndex = 0
  ) => {
    const { name, value } = e.target;
    const numberRegex = /^([0-9]{1,})?(\.)?([0-9]{1,})?$/;
    if (name === "tax_percentage" && value !== "") {
      let decimalPointSplitArray: string[] = [];
      decimalPointSplitArray.push(value);
      if (value.includes(".")) {
        decimalPointSplitArray = value.toString().split(".");
      }
      if (!numberRegex.test(value) || decimalPointSplitArray[0].length > 14) {
        return;
      }
    }
    const items = JSON.parse(
      JSON.stringify(addonItemFormValues[parentIndex]?.itemDetails)
    );
    const taxItems = JSON.parse(
      JSON.stringify(taxAddonFormValues[parentIndex]?.itemDetails)
    );
    let taxExist = [];
    if (value) {
      taxExist = items[itemIndex].taxes.filter(
        (tax: any, index: number) => tax.tax_id === value && index !== taxIndex
      );
      let itemFormErrors: any[][] = props.addonItemFormError
        ? props.addonItemFormError
        : [[]];
      if (taxExist.length) {
        if (name === "tax_id") {
          if (
            typeof itemFormErrors[parentIndex] === "undefined" &&
            typeof itemFormErrors[parentIndex][itemIndex] === "undefined"
          ) {
            itemFormErrors[parentIndex][itemIndex] = {
              ...itemFormErrors[parentIndex][itemIndex],
              taxes: [],
            };
          }
          itemFormErrors[parentIndex][itemIndex].taxes[taxIndex] = {
            tax_id: "",
            tax_percentage: 0,
          };
          itemFormErrors[parentIndex][itemIndex].taxes[taxIndex].tax_id =
            "Tax label already used";
          props.setAddonErrorOnItemTaxChange(itemFormErrors);
        }
      } else {
        if (
          typeof itemFormErrors[parentIndex] !== "undefined" &&
          typeof itemFormErrors[itemIndex] !== "undefined"
        ) {
          if (name === "tax_id") {
            if (
              !itemFormErrors[parentIndex] &&
              !itemFormErrors[parentIndex][itemIndex].taxes
            ) {
              itemFormErrors[parentIndex][itemIndex] = {
                ...itemFormErrors[parentIndex][itemIndex],
                taxes: [],
              };
            }
            itemFormErrors[parentIndex][itemIndex].taxes[taxIndex] = {
              tax_id: "",
              tax_percentage: 0,
            };
            props.setAddonErrorOnItemTaxChange(itemFormErrors);
          }
        }
      }
    }
    items[itemIndex].taxes[taxIndex] = {
      ...items[itemIndex].taxes[taxIndex],
      [name]: value,
    };
    taxItems[itemIndex].taxes[taxIndex] = {
      ...taxItems[itemIndex].taxes[taxIndex],
      [name]: value,
    };

    setChangedAddonValues({ parentId: parentIndex, itemIds: [itemIndex] });
    setTaxAddonFormValues([
      ...taxAddonFormValues.slice(0, parentIndex),
      { ...taxAddonFormValues[parentIndex], itemDetails: taxItems },
      ...taxAddonFormValues.slice(parentIndex + 1),
    ]);
    if (!taxExist.length) {
      setAddonItemFormValues([
        ...addonItemFormValues.slice(0, parentIndex),
        { ...addonItemFormValues[parentIndex], itemDetails: items },
        ...addonItemFormValues.slice(parentIndex + 1),
      ]);
    } else {
      if (
        addonItemFormValues &&
        addonItemFormValues.length > 0 &&
        addonItemFormValues[parentIndex]?.itemDetails[itemIndex].taxes[taxIndex]
          .tax_id !== ""
      ) {
        addonItemFormValues[parentIndex].itemDetails[itemIndex].taxes[
          taxIndex
        ] = {
          tax_id: "",
          tax_percentage:
            addonItemFormValues[parentIndex].itemDetails[itemIndex].taxes[
              taxIndex
            ].tax_percentage,
        };
        setAddonItemFormValues([
          ...addonItemFormValues.slice(0, parentIndex),
          {
            ...addonItemFormValues[parentIndex],
            itemDetails: [...addonItemFormValues[parentIndex].itemDetails],
          },
          ...addonItemFormValues.slice(parentIndex + 1),
        ]);
      }
    }
    setIsItemEdited(true);
  };

  useEffect(() => {
    if (
      props.isCustomerVendorChanged &&
      planItemFormValues.itemDetails.length > 0
    ) {
      setGstonFieldChange(
        props.customerDetails,
        handlePlanItemGstChange,
        getDefaultNonTaxableGst,
        getDefaultSezGst_forSales,
        props.gstRegistrationType,
        gstRegistrationTypes,
        planItemFormValues.itemDetails
      );
    }
    if (
      props.isCustomerVendorChanged &&
      addonItemFormValues &&
      addonItemFormValues.length > 0
    ) {
      addonItemFormValues.forEach((addonData, parentIndex) => {
        if (addonData.itemDetails && addonData.itemDetails.length > 0) {
          setGstonFieldChange(
            props.customerDetails,
            handlePlanItemGstChange,
            getDefaultNonTaxableGst,
            getDefaultSezGst_forSales,
            props.gstRegistrationType,
            gstRegistrationTypes,
            planItemFormValues.itemDetails,
            parentIndex
          );
        }
      });
    }
  }, [
    props.gstRegistrationType,
    taxList,
    props.customerDetails?.customer_exemption_type,
    props.itemFormValues,
    props.addonItemFormValues,
  ]);

  useEffect(() => {
    if (
      props.isStateChanged &&
      props.customerDetails?.customer_exemption_type !== "NON_TAXABLE"
    ) {
      populateDefaultPlanGST();
      populateDefaultAddonGST();
    }
  }, [stateType]);

  const populateDefaultPlanGST = () => {
    if (
      taxList.length &&
      props?.gstRegistrationType !== gstRegistrationTypes.sez &&
      props?.gstRegistrationType !== gstRegistrationTypes.sezDeveloper &&
      props?.gstRegistrationType !== gstRegistrationTypes.overseas
    ) {
      defaultGstHelper(
        planItemFormValues.itemDetails,
        gstTaxList,
        props.customerDetails,
        stateType,
        taxList,
        defaultPreference,
        handlePlanItemGstChange
      );
    }
  };

  const populateDefaultAddonGST = () => {
    if (
      taxList.length &&
      props?.gstRegistrationType !== gstRegistrationTypes.sez &&
      props?.gstRegistrationType !== gstRegistrationTypes.sezDeveloper &&
      props?.gstRegistrationType !== gstRegistrationTypes.overseas
    ) {
      addonItemFormValues.forEach((eachAddon, parentIndex) => {
        defaultGstHelper(
          eachAddon.itemDetails,
          gstTaxList,
          props.customerDetails,
          stateType,
          taxList,
          defaultPreference,
          handleAddonItemGstChange,
          parentIndex
        );
      });
    }
  };
  // need to move it to helper
  const getDefaultNonTaxableGst = () => {
    let default_gst = gstTaxList.filter((gstTax: any) => {
      return (
        gstTax.name === "Non-Taxable" &&
        gstTax.tax_type === "NONE" &&
        gstTax.rate === 0
      );
    });
    return default_gst;
  };
  // need to move it to helper
  const getDefaultSezGst_forSales = () => {
    let default_gst = gstTaxList.filter((gstTax: any) => {
      return (
        gstTax.name === "IGST0" &&
        gstTax.tax_type === "IGST" &&
        gstTax.rate === 0
      );
    });
    return default_gst;
  };

  const handlePlanItemGstChange = (
    tax: any,
    itemIndex: number,
    itemExemption: any
  ) => {
    // Updating deleteGstItems starts
    const items = JSON.parse(JSON.stringify(planItemFormValues?.itemDetails));
    const item = items[itemIndex];

    // if isSez customer, then allow only rate zero tax.
    if (
      itemGstChangeHelper(props.gstRegistrationType, gstRegistrationTypes, tax)
    )
      return;

    if (item.gst_tax) deleteItemGst(item);
    // Updating itemFormValues with new tax values start

    const taxObj = getGstTaxItem(tax);
    const isItemGstTaxable = [
      "Out of Scope",
      "Non-Taxable",
      "Non-GST Supply",
    ].includes(tax?.name);

    setPlanItemFormValues((prevValue) => {
      const itemValues = itemGstChangeSet(
        prevValue.itemDetails,
        itemExemption,
        isItemGstTaxable,
        itemIndex,
        taxObj
      );
      return { ...planItemFormValues, itemDetails: itemValues };
    });

    setTaxPlanFormValues((prevValue) => {
      const itemValues = itemGstChangeSet(
        prevValue.itemDetails,
        itemExemption,
        isItemGstTaxable,
        itemIndex,
        taxObj
      );
      return { ...taxPlanFormValues, itemDetails: itemValues };
    });
    // Updating itemFormValues ends
  };

  const handleAddonItemGstChange = (
    tax: any,
    itemIndex: number,
    itemExemption: any,
    parentIndex = 0
  ) => {
    // Updating deleteAddonGstItems starts
    const items = JSON.parse(
      JSON.stringify(addonItemFormValues[parentIndex]?.itemDetails)
    );
    const item = items[itemIndex];

    // if isSez customer, then allow only rate zero tax.
    if (
      itemGstChangeHelper(props.gstRegistrationType, gstRegistrationTypes, tax)
    )
      return;

    if (item.gst_tax) deleteAddonItemGst(item, parentIndex);
    // Updating addonItemFormValues with new tax values start

    const taxObj = getGstTaxItem(tax);
    const isItemGstTaxable = [
      "Out of Scope",
      "Non-Taxable",
      "Non-GST Supply",
    ].includes(tax?.name);

    setTaxAddonFormValues((prevValue) => {
      const itemValues = itemGstChangeSet(
        prevValue[parentIndex].itemDetails,
        itemExemption,
        isItemGstTaxable,
        itemIndex,
        taxObj
      );
      return [
        ...prevValue.slice(0, parentIndex),
        { ...prevValue[parentIndex], itemDetails: itemValues },
        ...prevValue.slice(parentIndex + 1),
      ];
    });

    setAddonItemFormValues((prevValue) => {
      const itemValues = itemGstChangeSet(
        prevValue[parentIndex].itemDetails,
        itemExemption,
        isItemGstTaxable,
        itemIndex,
        taxObj
      );
      return [
        ...prevValue.slice(0, parentIndex),
        { ...prevValue[parentIndex], itemDetails: itemValues },
        ...prevValue.slice(parentIndex + 1),
      ];
    });
    // Updating itemFormValues ends
  };

  const deleteItemGst = (item: any) => {
    if (item.id) {
      let gst_tax = item?.gst_tax;
      if (gst_tax?.is_group) {
        let prev_gst_tax_item = gst_tax?.gst_tax_item;
        if (prev_gst_tax_item !== null && prev_gst_tax_item.length > 0) {
          // looping gst_tax_item in the case of tax group
          prev_gst_tax_item.forEach((single_gst_tax_item: ObjectType) => {
            if (single_gst_tax_item.id) {
              setDeleteGstItems((prevDeleteGstItems) => {
                // checking tax id aleady exist in deleteGstItem or not
                let filteredDeleteGstItems = prevDeleteGstItems.filter(
                  (gstItem) =>
                    gstItem.itemId === item.id &&
                    gstItem.id === single_gst_tax_item.id
                );
                if (filteredDeleteGstItems.length === 0) {
                  prevDeleteGstItems.push({
                    itemId: item.id,
                    id: single_gst_tax_item.id,
                  });
                }
                return prevDeleteGstItems;
              });
            }
          });
        }
      } else if (!gst_tax.is_group && gst_tax.id) {
        setDeleteGstItems((prevDeleteGstItems) => {
          let filteredDeleteGstItems = prevDeleteGstItems.filter(
            (gstItem) => gstItem.itemId === item.id && gstItem.id === item.id
          );
          if (filteredDeleteGstItems.length === 0) {
            prevDeleteGstItems.push({ itemId: item.id, id: gst_tax.id });
          }
          return prevDeleteGstItems;
        });
      }
    }
  };

  const deleteAddonItemGst = (item: any, parentId: number) => {
    if (item.id) {
      let gst_tax = item?.gst_tax;
      if (gst_tax?.is_group) {
        let prev_gst_tax_item = gst_tax?.gst_tax_item;
        if (prev_gst_tax_item !== null && prev_gst_tax_item.length > 0) {
          // looping gst_tax_item in the case of tax group
          prev_gst_tax_item.forEach((single_gst_tax_item: ObjectType) => {
            if (single_gst_tax_item.id) {
              setDeleteAddonGstItems((prevDeleteGstItems) => {
                // checking tax id aleady exist in deleteGstItem or not
                let filteredDeleteGstItems = prevDeleteGstItems.filter(
                  (gstItem) =>
                    gstItem.itemId === item.id &&
                    gstItem.id === single_gst_tax_item.id &&
                    gstItem.parentId === parentId
                );
                if (filteredDeleteGstItems.length === 0) {
                  prevDeleteGstItems.push({
                    itemId: item.id,
                    id: single_gst_tax_item.id,
                    parentId: parentId,
                  });
                }
                return prevDeleteGstItems;
              });
            }
          });
        }
      } else if (!gst_tax.is_group && gst_tax.id) {
        setDeleteAddonGstItems((prevDeleteGstItems) => {
          let filteredDeleteGstItems = prevDeleteGstItems.filter(
            (gstItem) =>
              gstItem.itemId === item.id &&
              gstItem.id === item.id &&
              gstItem.parentId === parentId
          );
          if (filteredDeleteGstItems.length === 0) {
            prevDeleteGstItems.push({
              itemId: item.id,
              id: gst_tax.id,
              parentId: parentId,
            });
          }
          return prevDeleteGstItems;
        });
      }
    }
  };

  /**
   * Append item tax container
   */
  const deletePlanItemTaxContainer = (
    itemIndex: number,
    taxIndex: number,
    id: number | undefined
  ) => {
    setChangedPlansValues([itemIndex]);
    planItemFormValuesForTax.itemDetails.map((item: any, index: number) => {
      if (index === itemIndex) {
        item.taxes.map((tax: any, taxIndex1: number) => {
          if (id && tax.id === id) {
            setDeleteTaxItems((prevItems) => [
              ...prevItems,
              { itemId: Number(item.id), taxItemId: Number(tax.id) },
            ]);
          }
        });
      }
    });
    const taxItems = JSON.parse(JSON.stringify(taxPlanFormValues.itemDetails));
    taxItems[itemIndex].taxes.splice(taxIndex, 1);

    const items = JSON.parse(JSON.stringify(planItemFormValues.itemDetails));
    items[itemIndex].taxes.splice(taxIndex, 1);
    setTaxPlanFormValues({ ...taxPlanFormValues, itemDetails: taxItems });
    setPlanItemFormValues({ ...planItemFormValues, itemDetails: items });

    if (props.itemFormErrors) {
      const taxErrors = [...props.itemFormErrors];
      taxErrors[itemIndex]?.taxes?.splice(taxIndex, 1);
      props.setErrorOnItemTaxChange(taxErrors);
    }
  };

  /**
   * Append item tax container
   */
  const deleteAddonItemTaxContainer = (
    itemIndex: number,
    taxIndex: number,
    id: number | undefined,
    parentIndex = 0
  ) => {
    addonItemFormValuesForTax[parentIndex].itemDetails.map((item, index) => {
      if (index === itemIndex) {
        item.taxes.map((tax, taxIndex1) => {
          if (id && tax.id === id) {
            setDeleteAddonTaxItems((prevItems) => [
              ...prevItems,
              {
                itemId: Number(item.id),
                taxItemId: Number(tax.id),
                parentId: parentIndex,
              },
            ]);
          }
        });
      }
    });
    const taxItems = JSON.parse(
      JSON.stringify(taxAddonFormValues[parentIndex].itemDetails)
    );
    taxItems[itemIndex].taxes.splice(taxIndex, 1);

    const items = JSON.parse(
      JSON.stringify(addonItemFormValues[parentIndex].itemDetails)
    );
    items[itemIndex].taxes.splice(taxIndex, 1);
    setChangedAddonValues({ parentId: parentIndex, itemIds: [itemIndex] });
    setTaxAddonFormValues([
      ...taxAddonFormValues.slice(0, parentIndex),
      { ...taxAddonFormValues[parentIndex], itemDetails: taxItems },
      ...taxAddonFormValues.slice(parentIndex + 1),
    ]);
    setAddonItemFormValues([
      ...addonItemFormValues.slice(0, parentIndex),
      { ...addonItemFormValues[parentIndex], itemDetails: items },
      ...addonItemFormValues.slice(parentIndex + 1),
    ]);

    if (props.addonItemFormError) {
      const taxErrors = props.addonItemFormError;
      taxErrors[parentIndex][itemIndex]?.taxes?.splice(taxIndex, 1);
      props.setAddonErrorOnItemTaxChange(taxErrors);
    }
  };

  const getTaxList = async () => {
    let gstTaxListArray: any = {};
    dispatch(clearGstTaxList());
    gstTaxListArray = await dispatch(
      getGstTaxList({
        stateType: stateType ? stateType : "",
        orgId: currentUserInfo.organization_id,
        is_sez: false,
        transactionSource: "Invoices",
      })
    );

    return gstTaxListArray;
  };

  /**
   * Append new Plan item container
   */

  const addPlanItemContainer = async (addedPlanData: any) => {
    if (
      ![
        editId &&
          !(props.formValues.tds_level === tdsApplyLevel.LINE_ITEM_LEVEL),
      ].every(Boolean)
    )
      getTdsDropDownList();
    if (addedPlanData?.item_price?.length > 0) {
      const exemption = {
        item_exemption_type: props.customerDetails?.customer_exemption_type,
        item_exemption_id: "",
      };
      if (!editId)
        props.selectedInvoicingCycle({
          invoicingCycle: addedPlanData.invoicing_cycle,
          invoiceCycleCount: addedPlanData.invoicing_cycle_fixed_count || 0,
        });

      const structedPlanAddedData = await addSubscriptionData(
        "plan",
        addedPlanData,
        exemption,
        props.currencyId,
        props.customerDetails,
        getDefaultNonTaxableGst,
        getDefaultSezGst_forSales,
        props.gstRegistrationType,
        stateType,
        taxList,
        defaultPreference,
        gstTaxList,
        itemDropdownList,
        getTaxList
      );

      let addedPlan = {
        ...structedPlanAddedData,
        planId: addedPlanData.planId,
        planName: addedPlanData.planName,
      };
      itemPlanFormValuesRef.current = addedPlan;
      taxPlanFormValuesRef.current = addedPlan;
      setPlanItemFormValues(addedPlan);
      setTaxPlanFormValues(addedPlan);
      setPlanItemFormValuesForTax(addedPlan);
    }
  };

  /**
   * Delete Plan item container
   */
  const deletePlanItemContainer = async (index?: number) => {
    setPlanItemFormValues(initialPlanData);
    setTaxPlanFormValues(initialPlanData);
    setPlanItemFormValuesForTax(initialPlanData);
    itemPlanFormValuesRef.current = initialPlanData;
    taxPlanFormValuesRef.current = initialPlanData;
    setAddonItemFormValues([]);
    setAddonItemFormValuesForTax([]);
    setCouponFormValues([]);
    if (!editId)
      props.selectedInvoicingCycle({
        invoicingCycle: "",
        invoiceCycleCount: 0,
      });
    setIsItemEdited(true);
    props.setErrorOnItemTaxChange([]);
    props.setAddonErrorOnItemTaxChange([[]]);
  };

  /**
   * Append new Plan item container
   */
  const addAddonItemContainer = async (addedAddonData: any) => {
    if (props.formValues.tds_level === tdsApplyLevel.LINE_ITEM_LEVEL)
      getTdsDropDownList();
    if (addedAddonData?.item_price?.length > 0) {
      const currentIndex = addonItemFormValues?.length || 0;
      const childIndexs = addedAddonData.item_price.map(
        (data: any, index: any) => index
      );
      setChangedAddonValues({ parentId: currentIndex, itemIds: childIndexs });
      const exemption = {
        item_exemption_type: props.customerDetails?.customer_exemption_type,
        item_exemption_id: "",
      };
      const structedAddonAddedData = await addSubscriptionData(
        "addon",
        addedAddonData,
        exemption,
        props.currencyId,
        props.customerDetails,
        getDefaultNonTaxableGst,
        getDefaultSezGst_forSales,
        props.gstRegistrationType,
        stateType,
        taxList,
        defaultPreference,
        gstTaxList,
        itemDropdownList,
        getTaxList
      );
      let eachAddedAddon = {
        ...structedAddonAddedData,
        addonId: addedAddonData.addonId,
        addonName: addedAddonData.addonName,
      };

      const addedAddonArray = [
        ...addonItemFormValues.slice(0, currentIndex + 1),
        { ...eachAddedAddon },
      ];

      itemAddonFormValuesRef.current = [
        ...itemAddonFormValuesRef.current.slice(0, currentIndex),
        { ...eachAddedAddon },
      ];
      taxAddonFormValuesRef.current = [
        ...taxAddonFormValuesRef.current.slice(0, currentIndex),
        eachAddedAddon,
      ];
      setAddonItemFormValues(addedAddonArray);
      setTaxAddonFormValues(addedAddonArray);
      setAddonItemFormValuesForTax(addedAddonArray);
    }
  };

  /**
   * Delete Plan item container
   */
  const deleteAddonItemContainer = async (currentIndex: number) => {
    const updateAddonList = [
      ...addonItemFormValues.slice(0, currentIndex),
      ...addonItemFormValues.slice(currentIndex + 1),
    ];

    if (props.addonItemFormError) {
      const itemErrors = props.addonItemFormError;
      itemErrors.splice(currentIndex, 1);
      props.setAddonErrorOnItemTaxChange(itemErrors);
    }
    setAddonItemFormValues(updateAddonList);
    setAddonItemFormValuesForTax(updateAddonList);
    setTaxAddonFormValues(updateAddonList);
  };

  /**
   * Append new Plan item container
   */
  const addCouponContainer = (addedCoupon: any) => {
    const currentIndex = couponFormValues.length;
    const updateCouponList = [
      ...couponFormValues.slice(0, currentIndex),
      addedCoupon,
    ];
    setCouponAdded(true);
    setCouponFormValues(updateCouponList);
  };

  /**
   * Delete Coupon container
   */

  const deleteCouponContainer = (index: number) => {
    const updatedCouponList = [
      ...couponFormValues.slice(0, index),
      ...couponFormValues.slice(index + 1),
    ];
    setCouponAdded(true);
    setCouponFormValues(updatedCouponList);
  };

  /**
   * Append item tax container
   */
  const addPlanItemTaxContainer = (itemIndex: number) => {
    setChangedPlansValues([itemIndex]);
    const taxObject = initialTaxValues;
    const items = JSON.parse(JSON.stringify(planItemFormValues.itemDetails));
    items[itemIndex].taxes.push(taxObject);
    setPlanItemFormValues({ ...planItemFormValues, itemDetails: items });
    const taxItems = JSON.parse(JSON.stringify(taxPlanFormValues.itemDetails));
    taxItems[itemIndex].taxes.push(taxObject);
    setTaxPlanFormValues({ ...taxPlanFormValues, itemDetails: taxItems });
  };

  /**
   * Append item tax container
   */
  const addAddonItemTaxContainer = (itemIndex: number, parentIndex = 0) => {
    const taxObject = initialTaxValues;
    const items = JSON.parse(
      JSON.stringify(addonItemFormValues[parentIndex].itemDetails)
    );
    items[itemIndex].taxes.push(taxObject);
    setChangedAddonValues({ parentId: parentIndex, itemIds: [itemIndex] });
    setAddonItemFormValues([
      ...addonItemFormValues.slice(0, parentIndex),
      { ...addonItemFormValues[parentIndex], itemDetails: items },
      ...addonItemFormValues.slice(parentIndex + 1),
    ]);
    const taxItems = JSON.parse(
      JSON.stringify(taxAddonFormValues[parentIndex].itemDetails)
    );
    taxItems[itemIndex].taxes.push(taxObject);
    setTaxAddonFormValues([
      ...taxAddonFormValues.slice(0, parentIndex),
      { ...taxAddonFormValues[parentIndex], itemDetails: taxItems },
      ...taxAddonFormValues.slice(parentIndex + 1),
    ]);
  };

  /**
   * Function to round off tax input elements after out of focus.
   */
  const handlePlanTaxOnBlur = (
    name: string,
    amount: number,
    itemIndex: number,
    taxIndex: number
  ) => {
    const items = JSON.parse(JSON.stringify(planItemFormValues.itemDetails));
    const taxItems = JSON.parse(JSON.stringify(taxPlanFormValues.itemDetails));
    if (amount.toString().includes(".")) {
      const [, fractionalPart] = splitNumberByDecimalPoint(amount);
      if (fractionalPart.length > 6) {
        taxOnBlurHelper(name, amount, itemIndex, taxIndex, items, taxItems);
        setChangedPlansValues([itemIndex]);
        setTaxPlanFormValues({ ...taxPlanFormValues, itemDetails: taxItems });
        setPlanItemFormValues({ ...planItemFormValues, itemDetails: items });
      }
    }
  };

  const handleAddonTaxOnBlur = (
    name: string,
    amount: number,
    itemIndex: number,
    taxIndex: number,
    parentIndex = 0
  ) => {
    const items = JSON.parse(
      JSON.stringify(addonItemFormValues[parentIndex].itemDetails)
    );
    const taxItems = JSON.parse(
      JSON.stringify(taxAddonFormValues[parentIndex].itemDetails)
    );
    if (amount.toString().includes(".")) {
      const [, fractionalPart] = splitNumberByDecimalPoint(amount);
      if (fractionalPart.length > 6) {
        taxOnBlurHelper(name, amount, itemIndex, taxIndex, items, taxItems);
        setChangedAddonValues({ parentId: parentIndex, itemIds: [itemIndex] });
        setTaxAddonFormValues([
          ...taxAddonFormValues.slice(0, parentIndex),
          { ...taxAddonFormValues[parentIndex], itemDetails: taxItems },
          ...taxAddonFormValues.slice(parentIndex + 1),
        ]);
        setAddonItemFormValues([
          ...addonItemFormValues.slice(0, parentIndex),
          { ...addonItemFormValues[parentIndex], itemDetails: items },
          ...addonItemFormValues.slice(parentIndex + 1),
        ]);
      }
    }
  };
  const getTdsTaxPreferenceDetails = async () => {
    const responseAction = await dispatch(
      tdsTaxPreference(currentUserInfo.organization_id)
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setTdsPreferenceValues(response);
      }
    }
  };

  const updateIsItemEdited = (val: boolean) => {
    setIsItemEdited(val);
  };

  const handleTotalTcsAmountChange = (e: any) => {
    let value = e.target.value;
    const numberRegex = /^([0-9]{1,})?(\.)?([0-9]{1,})?$/;
    let decimalPointSplitArray: string[] = [];
    if (value.toString().includes(",")) {
      value = value.toString().split(",").join("");
    }
    decimalPointSplitArray.push(value);
    if (value.includes(".")) {
      decimalPointSplitArray = value.toString().split(".");
    }
    if (decimalPointSplitArray[0].length < 15) {
      if (value === "" || numberRegex.test(value)) {
        setTotalTcsValues((values) => ({
          ...values,
          tcs_amount: value ? Number(value) : 0,
        }));
      }
    }
    setIsItemEdited(true);
  };

  const handleTotalAmountChange = (
    name: string,
    e: any,
    index: number,
    taxType: string
  ) => {
    let value = e.target.value;
    const numberRegex = /^([0-9]{1,})?(\.)?([0-9]{1,})?$/;
    let decimalPointSplitArray: string[] = [];
    if (value.toString().includes(",")) {
      value = value.toString().split(",").join("");
    }
    decimalPointSplitArray.push(value);
    if (value.includes(".")) {
      decimalPointSplitArray = value.toString().split(".");
    }
    if (decimalPointSplitArray[0].length < 15) {
      if (value === "" || numberRegex.test(value)) {
        if (taxType === "TCS") {
          setTotalTcsValues((values) => ({
            ...values,
            tcs_amount: value,
          }));
        } else {
          setTotalTdsValues((values) => ({
            ...values,
            [name]: value,
          }));
        }
      }
    }
    setIsItemEdited(true);
  };

  const resetTaxValues = () => {
    setTotalTcsValues(initialTCSTotalValues);
    setTotalTdsValues(initialTDSTotalValues);
  };

  const handleTotalTcsLabelChange = (
    id: number,
    rate: number,
    name: string,
    index: number
  ) => {
    setTotalTcsValues((values) => ({
      ...values,
      tcs_id: Number(id) ? Number(id) : null,
      tcs_account_id: Number(id),
      tcs_amount: 0,
      tcs_percentage: Number(rate),
      tcs_name: name,
    }));
    setIsItemEdited(true);
  };

  const handleTotalTdsLabelChange = (
    id: number,
    rate: number,
    name: string,
    index: number
  ) => {
    setTotalTdsValues((values) => ({
      ...values,
      tds_id: Number(id) ? Number(id) : null,
      tds_account_id: Number(id),
      tds_amount: 0,
      tds_percentage: Number(rate),
      tds_name: name,
    }));
  };

  const updateTotal = (val: any) => {
    setManuallyEditedTotal(val);
  };

  let allAddedItemInPlanAndAddon = planItemFormValues?.itemDetails || [];
  addonItemFormValues.forEach((eachAddon) =>
    allAddedItemInPlanAndAddon.concat(eachAddon.itemDetails)
  );

  const onPlanPointUpdate = (
    addedPlanRange: RangePriceValue[],
    itemIndex: number
  ) => {
    setPlanItemFormValues((preVal) => {
      const itemValues = JSON.parse(JSON.stringify(preVal.itemDetails));
      itemValues[itemIndex] = {
        ...itemValues[itemIndex],
        range_price: addedPlanRange,
      };
      return { ...preVal, itemDetails: itemValues };
    });
  };

  const onAddonPointUpdate = (
    addedAddonRange: RangePriceValue[],
    itemIndex: number,
    parentIndex = 0
  ) => {
    setAddonItemFormValues((preVal) => {
      const itemValues = JSON.parse(
        JSON.stringify(preVal[parentIndex].itemDetails)
      );
      itemValues[itemIndex] = {
        ...itemValues[itemIndex],
        range_price: addedAddonRange,
      };
      return [
        ...preVal.slice(0, parentIndex),
        { ...preVal[parentIndex], itemDetails: itemValues },
        ...preVal.slice(parentIndex + 1),
      ];
    });
  };

  const getSelectedPlanFrequencyItemDetails = async (
    planId: number | string,
    pricingId: number
  ) => {
    const responseAction = await dispatch(
      subscriptionPlanFrequencyItems({
        planId: planId as number,
        pricingId: pricingId,
        orgId: currentUserInfo.organization_id,
        currencyId: props.currencyId ? props.currencyId : 0,
        conversionId: exchangeRateValues.id ? exchangeRateValues.id : 0,
      })
    );

    let itemDetails: any[] = [];
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        itemDetails = response;
      }
    }
    return itemDetails;
  };
  /**
   * To reset the plan, addon and coupon to initial on organisation change
   */
  useEffect(() => {
    deletePlanItemContainer();
  }, [currentUserInfo.organization_id]);

  const getTdsDropDownList = async () => {
    let listOfTdsId: any[] = [];
    if (props.planTdsIds.length > 0) {
      listOfTdsId = props.planTdsIds;
      if (props.addonTdsIds.length > 0) {
        props.addonTdsIds.flat().forEach((data) => listOfTdsId.push(data));
        listOfTdsId = listOfTdsId.filter(
          (item, index) => listOfTdsId.indexOf(item) === index
        );
      }
    }
    const responseAction = await dispatch(
      tdsDropDown({
        orgId: currentUserInfo.organization_id,
        tdsId: editId ? listOfTdsId : [],
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      setTdsTaxes(response);
    }
  };

  useEffect(() => {
    if (editId) getTdsDropDownList();
  }, [editId, props.addonTdsIds, props.planTdsIds]);

  return (
    <>
      <section className="item-details-wrapper" id="item-details-wrapper">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <span>
            <h2 className="mb-0">Plan, Addon & Coupon Details</h2>
            <span className="mt-2 error">{props.formErrors.planItems}</span>
          </span>
          <ExchangeRate
            hideExchangeRate={props.hideExchangeRate}
            currencyId={props.currencyId}
            currencyCode={currencyCode}
            editId={editId}
            conversionId={props.formValues.conversion_id}
            conversionCurrencyId={props.conversionCurrencyId}
            conversionDate={props.conversionDate}
            newConversionDate={props.newConversionDate}
            isInititialCustomConversionFetch={
              props.isInititialCustomConversionFetch
            }
            isMutable={false}
            showTooltip={true}
          />
        </div>
        <div className="row g-0 mb-0 overflow-hidden subscription-item-details-container">
          <div className="col items-entry-wrapper cn-items-entry-wrapper px-0 overflow-hidden">
            <SubscriptionItemContext.Provider
              value={{ tdsTaxes, getTdsDropDownList }}
            >
              <div
                id="item-det-container"
                className="cn-item-container item-det-container overflow-coloumn"
              >
                <div className="row item-det-row cn-item-row g-0">
                  <div className="col">
                    <div className="item-grid-account cdn-item-grid">
                      {/* row start */}
                      {planItemFormValues && planItemFormValues.planId !== 0 ? (
                        <>
                          {" "}
                          <div className="item-row" key={0}>
                            <div className="add-detail-plan-name">
                              <div
                                style={{
                                  fontSize: "larger",
                                  marginBottom: "10px",
                                }}
                              >
                                Plans
                              </div>
                              {planItemFormValues.planName}
                              <Link
                                className="delete-taxable delete-btn"
                                to="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  deletePlanItemContainer(0);
                                }}
                              >
                                <DeleteIcon color={colors.slate} />
                              </Link>
                              <div className="plan-detail">
                                {` Frequency: ${coustomCase(
                                  planItemFormValues.frequency
                                )} | Pricing model: ${
                                  pricingModuleRenderValue.find(
                                    (data) =>
                                      data.key ===
                                      planItemFormValues.pricing_model
                                  )?.value
                                }`}
                              </div>
                            </div>
                            {planItemFormValues.itemDetails &&
                              planItemFormValues.itemDetails.map(
                                (item: any, index: number) => {
                                  return (
                                    <>
                                      <PlanLineItem
                                        parentIndex={-1}
                                        key={index}
                                        index={index}
                                        item={item}
                                        componentType={props.componentType}
                                        currencyId={
                                          props.currencyId
                                            ? props.currencyId
                                            : 0
                                        }
                                        currencyCode={currencyCode}
                                        isHsnCode={props.isHsnCode}
                                        taxFormValues={
                                          taxPlanFormValues?.itemDetails[
                                            index
                                          ] || null
                                        }
                                        handleItemChange={handlePlanItemChange}
                                        handleItemOnBlur={handlePlanItemOnBlur}
                                        handleItemAccountChange={
                                          handlePlanItemAccountChange
                                        }
                                        taxType={props.taxType}
                                        lineItemBaseAccount={
                                          props.lineItemBaseAccount
                                        }
                                        discountBaseAccount={
                                          props.discountBaseAccount
                                        }
                                        handleItemTaxChange={
                                          handlePlanItemTaxChange
                                        }
                                        handleItemGstChange={(
                                          gst,
                                          index,
                                          itemExemption,
                                          parentIndex
                                        ) => {
                                          handlePlanItemGstChange(
                                            gst,
                                            index,
                                            itemExemption
                                          );
                                          setChangedPlansValues([index]);
                                        }}
                                        handleTaxOnBlur={handlePlanTaxOnBlur}
                                        addItemTaxContainer={
                                          addPlanItemTaxContainer
                                        }
                                        deleteItemTaxContainer={
                                          deletePlanItemTaxContainer
                                        }
                                        itemFormErrors={
                                          props.itemFormErrors &&
                                          props?.itemFormErrors[index]
                                        }
                                        handleAmountChange={
                                          handlePlanAmountChange
                                        }
                                        tdsLevel={props.formValues.tds_level}
                                        sourceTaxType={
                                          props.formValues.source_tax_type
                                        }
                                        clearTaxSelection={
                                          clearPlanTaxSelection
                                        }
                                        totalTcsValues={totalTcsValues}
                                        tdsIds={props.planTdsIds}
                                        tcsIds={[]}
                                        handleTdsLabelChange={
                                          handlePlanTdsLabelChange
                                        }
                                        clearGstSelection={
                                          clearPlanGstSelection
                                        }
                                        supplyState={
                                          props.supplyState
                                            ? props.supplyState
                                            : ""
                                        }
                                        isSez={props.isSez}
                                        gstRegistrationType={
                                          props.gstRegistrationType
                                        }
                                        updateIsItemEdited={updateIsItemEdited}
                                        customerDetails={props.customerDetails}
                                        addedPricingModal={
                                          planItemFormValues.pricing_model
                                        }
                                        parentId={planItemFormValues.planId}
                                        itemType="PLAN"
                                        onPricePointUpdate={onPlanPointUpdate}
                                        tdsPreferencetoCheck={
                                          props.tdsPreferencetoCheck
                                        }
                                      />
                                    </>
                                  );
                                }
                              )}
                          </div>
                        </>
                      ) : null}
                      {/* row ends */}
                    </div>
                  </div>
                </div>
              </div>
              {planItemFormValues && planItemFormValues.planId !== 0 ? null : (
                <AddLinePlan
                  onSaveClick={addPlanItemContainer}
                  getSelectedPlanFrequencyItemDetails={
                    getSelectedPlanFrequencyItemDetails
                  }
                />
              )}

              <div
                id="item-det-container"
                className="cn-item-container item-det-container overflow-coloumn"
              >
                <div className="row item-det-row cn-item-row g-0">
                  <div className="col">
                    <div className="item-grid-account cdn-item-grid">
                      {addonItemFormValues.length > 0 && (
                        <div
                          style={{
                            fontSize: "larger",
                            marginBottom: "-2px",
                            fontWeight: "bold",
                            paddingLeft: "15px",
                          }}
                        >
                          Addons
                        </div>
                      )}
                      {/* row start */}
                      {addonItemFormValues.length > 0 ? (
                        <>
                          {addonItemFormValues.map((addon, parentIndex) => {
                            return (
                              <div className="item-row" key={0}>
                                <div className="add-detail-plan-name">
                                  {addon.addonName}
                                  <Link
                                    className="delete-taxable delete-btn"
                                    to="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      deleteAddonItemContainer(parentIndex);
                                    }}
                                  >
                                    <DeleteIcon color={colors.slate} />
                                  </Link>
                                  <div className="plan-detail">
                                    {` Frequency: ${coustomCase(
                                      addon.frequency
                                    )} | Pricing model: ${
                                      pricingModuleRenderValue.find(
                                        (data) =>
                                          data.key === addon.pricing_model
                                      )?.value
                                    }`}
                                  </div>
                                </div>
                                {addon.itemDetails.length > 0 &&
                                  addon.itemDetails.map((item, index) => {
                                    return (
                                      <>
                                        <PlanLineItem
                                          parentIndex={parentIndex}
                                          key={index}
                                          index={index}
                                          item={item}
                                          componentType={props.componentType}
                                          currencyId={
                                            props.currencyId
                                              ? props.currencyId
                                              : 0
                                          }
                                          currencyCode={currencyCode}
                                          isHsnCode={props.isHsnCode}
                                          taxFormValues={
                                            taxAddonFormValues[parentIndex]
                                              ?.itemDetails[index] || null
                                          }
                                          handleItemChange={
                                            handleAddonItemChange
                                          }
                                          handleItemOnBlur={
                                            handleAddonItemOnBlur
                                          }
                                          handleItemAccountChange={
                                            handleAddonItemAccountChange
                                          }
                                          taxType={props.taxType}
                                          lineItemBaseAccount={
                                            props.lineItemBaseAccount
                                          }
                                          discountBaseAccount={
                                            props.discountBaseAccount
                                          }
                                          handleItemTaxChange={
                                            handleAddonItemTaxChange
                                          }
                                          handleItemGstChange={(
                                            name,
                                            e,
                                            index,
                                            parentIndex = 0
                                          ) => {
                                            handleAddonItemGstChange(
                                              name,
                                              e,
                                              index,
                                              parentIndex
                                            );
                                            setChangedAddonValues({
                                              parentId: parentIndex,
                                              itemIds: [index],
                                            });
                                          }}
                                          handleTaxOnBlur={handleAddonTaxOnBlur}
                                          addItemTaxContainer={
                                            addAddonItemTaxContainer
                                          }
                                          deleteItemTaxContainer={
                                            deleteAddonItemTaxContainer
                                          }
                                          itemFormErrors={
                                            props.addonItemFormError &&
                                            props?.addonItemFormError[
                                              parentIndex
                                            ] &&
                                            props?.addonItemFormError[
                                              parentIndex
                                            ][index]
                                          }
                                          handleAmountChange={
                                            handleAddonAmountChange
                                          }
                                          tdsLevel={props.formValues.tds_level}
                                          sourceTaxType={
                                            props.formValues.source_tax_type
                                          }
                                          clearTaxSelection={
                                            clearAddonTaxSelection
                                          }
                                          totalTcsValues={totalTcsValues}
                                          tdsIds={
                                            props.addonTdsIds[parentIndex]
                                              ?.length
                                              ? props.addonTdsIds[parentIndex]
                                              : []
                                          }
                                          tcsIds={[]}
                                          handleTdsLabelChange={
                                            handleAddonTdsLabelChange
                                          }
                                          clearGstSelection={
                                            clearAddonGstSelection
                                          }
                                          supplyState={
                                            props.supplyState
                                              ? props.supplyState
                                              : ""
                                          }
                                          isSez={props.isSez}
                                          gstRegistrationType={
                                            props.gstRegistrationType
                                          }
                                          updateIsItemEdited={
                                            updateIsItemEdited
                                          }
                                          customerDetails={
                                            props.customerDetails
                                          }
                                          addedPricingModal={
                                            addon.pricing_model
                                          }
                                          parentId={addon.addonId}
                                          itemType="ADDON"
                                          onPricePointUpdate={
                                            onAddonPointUpdate
                                          }
                                          tdsPreferencetoCheck={
                                            props.tdsPreferencetoCheck
                                          }
                                        />
                                      </>
                                    );
                                  })}
                              </div>
                            );
                          })}{" "}
                        </>
                      ) : null}
                      {/* row ends */}
                    </div>
                  </div>
                </div>
              </div>
              <AddLineAddon
                onSaveClick={addAddonItemContainer}
                planFrequency={planItemFormValues?.frequency || ""}
                currencyId={props.currencyId}
                conversionId={exchangeRateValues.id}
                selectedAddonlist={addonItemFormValues.map((addon) =>
                  Number(addon.addonId)
                )}
              />
            </SubscriptionItemContext.Provider>
            <div
              id="item-det-container"
              className="cn-item-container item-det-container overflow-coloumn subscription-add-coupon-details"
            >
              <div className="row item-det-row cn-item-row g-0">
                <div className="col">
                  <div className="item-grid-account cdn-item-grid">
                    {/* row start */}
                    {couponFormValues.length > 0 && (
                      <div
                        style={{
                          fontSize: "larger",
                          marginBottom: "-2px",
                          fontWeight: "bold",
                          padding: "14px 0 12px 13px",
                        }}
                      >
                        Coupons
                      </div>
                    )}
                    {couponFormValues.map((item, index) => {
                      return (
                        <>
                          <LineCoupon
                            index={index}
                            item={item}
                            deleteCouponContainer={deleteCouponContainer}
                            couponError={props.couponError}
                          />
                        </>
                      );
                    })}
                    {/* row ends */}
                  </div>
                </div>
              </div>
            </div>

            <AddLineCoupon
              onRowClick={addCouponContainer}
              planFrequency={planItemFormValues?.frequency || ""}
              currencyId={props.currencyId}
              conversionId={exchangeRateValues.id}
              selectedCouponList={couponFormValues.map((data) =>
                Number(data.id)
              )}
              isDiscountTypePercentage={
                couponFormValues.length > 0 &&
                couponFormValues[0]?.discount.endsWith("%")
              }
            />
            <div className="row mb-0 total-sect-row">
              {props.children}
              <SubscriptionTotalSection
                couponDiscountValue={
                  couponDiscountValue ? couponDiscountValue : "0.00"
                }
                totalTaxArray={totalTaxArray}
                currencyCode={currencyCode}
                subTotal={totalTaxableAmount ? totalTaxableAmount : "0.00"}
                totalTax={totalTax ? totalTax : "0.00"}
                total={total ? total : "0.00"}
                componentType={props.componentType}
                tdsAmount={tdsAmount}
                tcsAmount={tcsAmount}
                gstTotalObject={gstTotalObject}
                formErrors={props.formErrors}
                handleOnBlur={handlePlanItemOnBlur}
                handleTcsAmountChange={handleTotalTcsAmountChange}
                totalTcsValues={totalTcsValues}
                handleAmountChange={handleTotalAmountChange}
                totalTdsValues={totalTdsValues}
                tdsLevel={props.formValues.tds_level}
                resetTaxValues={resetTaxValues}
                sourceTaxType={props.formValues.source_tax_type}
                clearTaxSelection={resetTaxValues}
                itemPlanFormValues={planItemFormValues}
                itemAddonFormValues={addonItemFormValues}
                tdsIds={props.tdsIds}
                tcsIds={props.tcsIds}
                handleTotalTcsLabelChange={handleTotalTcsLabelChange}
                handleTotalTdsLabelChange={handleTotalTdsLabelChange}
                roundOffValue={roundOffValue}
                isRoundOffEnabled={props.isRoundOffEnabled}
                isTotalEditable={props.isTotalEditable}
                updateTotal={updateTotal}
                tdsPreferencetoCheck={props.tdsPreferencetoCheck}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default React.memo(forwardRef(SubscriptionDetails));
