import { useNavigate } from "react-router-dom";
import Delete from "../../../../common/components/Delete";
import Header from "../../../../common/components/detailsPage/Header";
import NotificationModal from "../../../../common/components/NotificationModal";
import SubscriptionDelete from "../SubscriptionDelete";
import OrgTag from "../../../../common/components/OrgTag";
import Edit from "../../../../common/components/kebabMenu/Edit";
import KebabMenu from "../../../../common/components/kebabMenu/KebabMenu";
import StopResume from "../../../../common/components/kebabMenu/StopResume";
import useCommonData from "../../../../hooks/useCommon";
import {
  InvoiceList,
  SubscriptionDetailHeaderValues,
} from "../../../../../types";
import "./subscriptionView.css";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import SubscriptionStopResume from "../SubscriptionStopResume";
import { useState } from "react";
import SubscriptionVoid from "../SubscriptionVoid";
import SubscriptionReminder from "../SubscriptionReminder";
import ImpactDetailsInfo from "./ImpactDetailsInfo";
import ScheduledStopDetailsInfo from "./ScheduledStopDetailsInfo";
import SubscriptionReactivate from "../SubscriptionReactivation";
import { coustomCaseWithRemoveUnderscore } from "../../../../../helpers/planHelper";
import Reactivate from "../../../../common/components/Reactivate";
import Void from "../../../../common/components/Void";
import ScheduledVoidDetailsInfo from "./ScheduledVoidDetailsInfo";
import { NumberFormat } from "../../../../../helpers/numberFormatHelper";

type Props = {
  overviewSubscriptionData: SubscriptionDetailHeaderValues;
  childInvoiceListData: InvoiceList[];
  recurringInvoiceDetail: () => void;
  subscriptionId: string;
  scheduledSubscriptionData: any;
  subscriptionSchedulerDetails: () => void;
};

const SubscriptionViewHeader = (props: Props) => {
  const [voidRInvoiceId, setVoidRInvoiceId] = useState<number>(-1);
  const [reminderRInvoiceId, setReminderRInvoiceId] = useState<number>(-1);
  const [voidStatus, setVoidStatus] = useState<string>("Void");
  const { currentUserInfo, orgCurrencyList } = useCommonData();
  const { subscriptionRolePermission } = usePermissionHandler();
  const navigate = useNavigate();
  const [editStop, setEditStop] = useState<boolean>(false);
  const [didVoidAlertModalOpen, setDidVoidAlertModalOpen] = useState(false);
  const [didReactiveAlertModalOpen, setDidReactiveAlertModalOpen] =
    useState(false);
  /**
   * Back button handler from vendor details page
   */
  const backFromRecurringViewHandler = () => {
    navigate(-1);
  };

  const editStopHandler = (checkEditStop: any = "") => {
    checkEditStop === "cancel" ? setEditStop(true) : setEditStop(false);
  };

  const sendReminder = (invoiceId: number) => {
    setReminderRInvoiceId(invoiceId);
  };

  const voidListData = (id: number, status: string) => {
    setVoidRInvoiceId(id);
    setVoidStatus(status);
    if (
      props.overviewSubscriptionData.subscription_status.toLowerCase() ===
      "cancelled"
    ) {
      setDidReactiveAlertModalOpen(true);
    } else setDidVoidAlertModalOpen(true);
  };  

  return (
    <>
      <Header
        onBackButtonClick={backFromRecurringViewHandler}
        data={{
          num: props.overviewSubscriptionData.subscription_number,
          status:
            props.overviewSubscriptionData.subscription_status &&
            coustomCaseWithRemoveUnderscore(
              props.overviewSubscriptionData.subscription_status
            ),
        }}
        dataId=""
      />
      <div className="card-body">
        <div className="invoice-actions-wrapper detail-pg-actions-wrapper d-flex justify-content-between">
          <div className="left-group">
            <span className="biller-label ps-2">
              {props.overviewSubscriptionData.customer_name}
            </span>
            <span>|</span>
            <span className="bill-date pe-2 ps-2">
              {props.overviewSubscriptionData.subscription_created_at}
            </span>
            <span>|</span>
            <span>
              <OrgTag
                organization={
                  props.overviewSubscriptionData.org_details
                    ? props.overviewSubscriptionData.org_details
                    : ""
                }
              />
            </span>
          </div>
          {(!subscriptionRolePermission.includes("Update") &&
            !subscriptionRolePermission.includes("Create")) ||
          props.overviewSubscriptionData.organization_id !==
            currentUserInfo.organization_id ? null : (
            <div className="actions-wrap d-flex flex-no-shrink">
              <div
                className={`${
                  props.overviewSubscriptionData.subscription_status.toLowerCase() ===
                  "expired"
                }`}
              >
                {props.overviewSubscriptionData.subscription_status &&
                  !["stopped"].includes(
                    props.overviewSubscriptionData.subscription_status?.toLowerCase()
                  ) &&
                  subscriptionRolePermission.includes("Update") && (
                    <Edit
                      url={`/subscriptions/edit/${props.subscriptionId}`}
                      isMutable={true}
                      name="Invoice"
                      isLocked={false}
                      className={`${
                        props.overviewSubscriptionData.subscription_status.toLowerCase() ===
                        "expired"
                          ? "li-disables"
                          : ""
                      }`}
                      lockDate=""
                      isDisabled={
                        props.overviewSubscriptionData.subscription_status.toLowerCase() ===
                        "expired"
                      }
                    />
                  )}
              </div>
              {subscriptionRolePermission.includes("Delete") ? (
                <Delete
                  className="text-decoration-none delete-item"
                  deleteIcon={true}
                  isMutable={true}
                  onClick={() => {}}
                  isLocked={false}
                  lockDate=""
                  name="Subscription"
                  child_invoice_exists={
                    props.overviewSubscriptionData.invoice_exists
                      ? "child_invoice_exists"
                      : "child_invoice_not_exists"
                  }
                  isChildInvoice={true}
                />
              ) : null}
              <KebabMenu wrapperClass="detail-top-action-menu">
                {props.overviewSubscriptionData.subscription_status.toLowerCase() !==
                  "cancelled" &&
                subscriptionRolePermission.includes("Update") ? (
                  <li>
                    <StopResume
                      className={"dropdown-item"}
                      isDisabled={
                        props.overviewSubscriptionData.subscription_status.toLowerCase() ===
                        "expired"
                      }
                      isStopped={
                        props.overviewSubscriptionData.subscription_status.toLowerCase() ===
                        "stopped"
                      }
                      onStopResumeClick={() => editStopHandler("")}
                    />
                  </li>
                ) : null}
                {/* {subscriptionRolePermission.includes("Update") &&
                [
                  "FUTURE",
                  "CANCELLED",
                  "STOPPED",
                  "ACTIVE",
                  "NON_RENEWING",
                  "IN_TRIAL"
                ].includes(
                  props.overviewSubscriptionData.subscription_status
                ) ? ( // need to remove future checking
                  <>
                    {props.overviewSubscriptionData.subscription_status.toLowerCase() ===
                    "cancelled" ? (
                      <li>
                        <Reactivate
                          className="dropdown-item delete"
                          reactivateIcon={true}
                          isMutable
                          onClick={() =>
                            voidListData(
                              props.overviewSubscriptionData.id,
                              props.overviewSubscriptionData.profile_name
                            )
                          }
                          isLocked={false}
                          lockDate={""}
                          name="Invoice"
                          status={
                            props.overviewSubscriptionData.subscription_status
                          }
                          isButtonClick={true}
                        />
                      </li>
                    ) : (
                      <li>
                        <Void
                          className="dropdown-item delete"
                          deleteIcon={true}
                          isMutable
                          onClick={() =>
                            voidListData(
                              props.overviewSubscriptionData.id,
                              props.overviewSubscriptionData.profile_name
                            )
                          }
                          isLocked={false}
                          lockDate={""}
                          name="Invoice"
                          status={
                            props.overviewSubscriptionData.subscription_status
                          }
                          isButtonClick={true}
                        />
                      </li>
                    )}
                  </>
                ) : null} */}
              </KebabMenu>
            </div>
          )}
        </div>
        {props.scheduledSubscriptionData.edit_scheduler !== null && (
          <ImpactDetailsInfo
            scheduledSubscriptionData={props.scheduledSubscriptionData}
            subscriptionSchedulerDetails={props.subscriptionSchedulerDetails}
          />
        )}
        {props.scheduledSubscriptionData.status_change_scheduler !== null &&
          props.scheduledSubscriptionData.status_change_scheduler?.findIndex((item: { new_status: string; }) => item.new_status === "STOPPED") !== -1 && (
            <ScheduledStopDetailsInfo
              subscriptionStatus={
                props.overviewSubscriptionData.subscription_status
              }
              overviewSubscriptionData={props.overviewSubscriptionData}
              currentUserInfo={currentUserInfo}
              editStopHandler={editStopHandler}
              scheduledSubscriptionData={props.scheduledSubscriptionData}
              subscriptionSchedulerDetails={props.subscriptionSchedulerDetails}
            />
          )}
        {props.scheduledSubscriptionData.status_change_scheduler !== null &&
          props.scheduledSubscriptionData.status_change_scheduler?.findIndex((item: { new_status: string; }) => item.new_status === "CANCELLED") !== -1 && (
            <ScheduledVoidDetailsInfo
              subscriptionStatus={
                props.overviewSubscriptionData.subscription_status
              }
              overviewSubscriptionData={props.overviewSubscriptionData}
              currentUserInfo={currentUserInfo}
              editStopHandler={editStopHandler}
              scheduledSubscriptionData={props.scheduledSubscriptionData}
              subscriptionSchedulerDetails={props.subscriptionSchedulerDetails}
            />
          )}
      </div>
      <SubscriptionStopResume
        subscriptionId={props.overviewSubscriptionData?.id}
        planName={props.overviewSubscriptionData.profile_name}
        triggerAction={
          props.overviewSubscriptionData.subscription_status?.toLowerCase() ===
          "stopped"
            ? "Resume"
            : "Stop"
        }
        refreshPlan={() => props.recurringInvoiceDetail()}
        organizationId={currentUserInfo.organization_id}
        chargeOption={props.overviewSubscriptionData.charge_option}
        is_edit_scheduled={props.overviewSubscriptionData.is_edit_scheduled}
        editStop={editStop}
        scheduledSubscriptionData={props.scheduledSubscriptionData}
        subscriptionSchedulerDetails={props.subscriptionSchedulerDetails}
        nextNenewal={props.overviewSubscriptionData.next_renewal}
        editStopHandler={editStop}
      />
      {!props.overviewSubscriptionData.invoice_exists && (
        <SubscriptionDelete
          deleteSubscriptionId={props.overviewSubscriptionData.id}
          deleteSubscriptionName={
            props.overviewSubscriptionData.subscription_number
          }
          deleteActionType="Subscription"
          refreshPlan={backFromRecurringViewHandler}
          organizationId={currentUserInfo.organization_id}
        />
      )}
      <SubscriptionReminder
        reminderRInvoiceId={reminderRInvoiceId}
        refreshRInvoices={backFromRecurringViewHandler}
        organizationId={currentUserInfo.organization_id}
      />
      <SubscriptionVoid
        subscriptionId={voidRInvoiceId}
        subscriptionName={props.overviewSubscriptionData.profile_name}
        status={props.overviewSubscriptionData.subscription_status}
        refreshPlan={backFromRecurringViewHandler}
        organizationId={currentUserInfo.organization_id}
        didAlertModalOpen={didVoidAlertModalOpen}
        setDidAlertModalOpen={setDidVoidAlertModalOpen}
        nextRenewableDate={props.overviewSubscriptionData.next_renewal || ""}
        unpaidAmount={`${
          props.overviewSubscriptionData.currency_code || ""
        } ${NumberFormat(
          props.overviewSubscriptionData.total_unpaid_amount || 0,
          props.overviewSubscriptionData.currency_code || "",
          orgCurrencyList
        )}`}
      />
      <SubscriptionReactivate
        subscriptionId={voidRInvoiceId}
        subscriptionName={props.overviewSubscriptionData.profile_name}
        subscriptionStatus={props.overviewSubscriptionData.subscription_status}
        refreshSubscription={backFromRecurringViewHandler}
        organizationId={currentUserInfo.organization_id}
        didAlertModalOpen={didReactiveAlertModalOpen}
        setDidAlertModalOpen={setDidReactiveAlertModalOpen}
        subscriptionPeriod={props.overviewSubscriptionData.subscription_period}
      />
    </>
  );
};

export default SubscriptionViewHeader;
