import { CSSProperties } from "react";

type ContainerProps = {
  children: React.ReactNode;
  className?: string;
  id?: string;
  style?: CSSProperties;
};
export const Section = (props: ContainerProps) => {
  return (
    <section id={props.id} className={props.className} style={props.style}>
      {props.children}
    </section>
  );
};
