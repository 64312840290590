import { useMemo, useRef } from "react";
import { useAppSelector } from "../../../../../app/hooks";
import { joinTransactionNumber } from "../../../../../helpers/prefixHelper";
import { InvoiceDetailValues } from "../../../../../types";
import { orgCurrencyListSelector } from "../../../../common/commonSlice";
import CompanyDetails from "../../../../common/components/detailsPage/CompanyDeatails";
import DetailTotalSection from "../../../../common/components/detailsPage/DeatailsTotalSection";
import TaxColumn from "../../../../common/components/detailsPage/TaxColumn";
import FileList from "../../../../common/components/FileList";
import JournelView from "../../../../common/components/JournelView";
import {
  NumberFormat,
  zeroDisplayFormat,
} from "../../../../../helpers/numberFormatHelper";
import DetailsTableContainer from "../../../../common/components/detailsPage/DetailsTableContainer";
import { RowWiseFieldView } from "../../../../common/components/rowWiseFieldsView/rowWiseFieldsView";
import CreditNoteAndPaymentReceivedDetails from "../CNandPRDetails/CNandPRDetails";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import ApplyCredits from "./ApplyCredits";
import { tdsApplyLevel } from "../../../../constants/constants";
import useCommonData from "../../../../hooks/useCommon";
import useGst from "../../../../hooks/useGst";
import { decimalPlaceOfCurrency } from "../../../../../helpers/decimalPlaceHelper";
import { ApplyCreditsFormModal } from "./ApplyCredits/ApplyCreditsFormModal";

type Props = {
  invoiceData: InvoiceDetailValues;
  reRenderFlag: number;
  invoiceDetail: () => void;
};

const InvoicePreviewBody: React.FC<Props> = ({
  invoiceData,
  reRenderFlag,
  invoiceDetail,
}) => {
  let taxTypes = Object.entries(invoiceData.total_details.taxes);
  const { currentUserInfo, orgCurrencyList } = useCommonData();
  const { creditNoteRolePermission } = usePermissionHandler();
  const transactionRef = useRef<{ fetchCNPRList: () => void }>(null);
  const creditApplyRef = useRef<{ getApplyCreditList: () => void }>(null);
  const { isGstOrg } = useGst();

  /**
   * Format date from YYYY-MM-DD to DD-MM-YYYY
   */
  const dateFormatHandler = (date: string) => {
    if (!date) {
      return "";
    }
    let newDate = date.split("-").reverse().join("-");
    return newDate;
  };

  /**
   * set table column headings
   */
  const columns = useMemo(
    () => [
      {
        Header: "Description",
        accessor: "show.description",
        className: "description",
        Cell: ({ cell: { value } }: any) => {
          return <span className="item-bold">{value}</span>;
        },
      },
      {
        Header: "Hsn/sac code",
        accessor: "show.hsnCode",
        className: "hsncode text-nowrap",
      },
      {
        Header: "Account",
        accessor: "show.account",
        className: "account",
      },
      {
        Header: "Quantity",
        accessor: "show.quantity",
        className: "quantity text-nowrap",
      },
      {
        Header: "Rate",
        accessor: "show.rate",
        className: "rate text-nowrap",
      },
      {
        Header: "Discount",
        accessor: "show.discount",
        className: "discount text-nowrap",
        Cell: ({ cell: { value } }: any) => {
          let discAccount = value?.discountAccount
            ? value.discountAccount.split(" (")
            : null;
          return (
            <>
              <span className="amt">{value.discount}</span>
              <span className="acnt">
                {discAccount && discAccount.length > 0 ? discAccount[0] : ""}{" "}
                <br />{" "}
                {discAccount && discAccount.length === 2
                  ? "(" + discAccount[1]
                  : ""}
              </span>
            </>
          );
        },
      },
      {
        Header: "Tax",
        accessor: "show.tax",
        className: "tax text-nowrap text-align-left",
        Cell: ({ cell: { value } }: any) => {
          return (
            <TaxColumn
              values={value}
              currencyCode={invoiceData.currency_code}
            />
          );
        },
      },
      {
        Header: "TDS",
        accessor:
          invoiceData.tds_level === tdsApplyLevel.LINE_ITEM_LEVEL
            ? "show.item"
            : "TDS",
        className: "tds text-nowrap text-align-left",
        Cell: ({ cell: { value } }: any) => {
          return (
            <>
              <span className="amt">
                {invoiceData.currency_code}{" "}
                {NumberFormat(
                  Number(value.tds_total),
                  invoiceData.currency_code,
                  orgCurrencyList
                )}
              </span>
              <span className="actual-value d-block w-100 clear-both">
                {value.tds_name}{" "}
                {value.default_tds_percentage
                  ? "(" + value.default_tds_percentage + "%)"
                  : ""}
              </span>
              {/* <span>
                
              </span> */}
              {value.tds_percentage !== 0 &&
              value.tds_percentage !== value.default_tds_percentage ? (
                <span className="current-value  d-block w-100 clear-both">
                  Updated Rate {`(${value.tds_percentage}%)`}
                </span>
              ) : null}
            </>
          );
        },
      },
      {
        Header: "Taxable Amount",
        accessor: "show.taxableAmount",
        className: "taxableAmount text-nowrap text-align-right",
      },
    ],
    [invoiceData.currency_code]
  );
  /**
   * set table row data
   */
  const data = invoiceData.item_details
    ? invoiceData.item_details.map((itemData, index) => ({
        show: {
          description: itemData.item_name,
          hsnCode: itemData.hsn_or_sac_code,
          account: itemData.account_name,
          quantity: NumberFormat(
            Number(itemData.quantity),
            invoiceData.currency_code,
            orgCurrencyList
          ),
          rate:
            invoiceData.currency_code +
            " " +
            NumberFormat(
              Number(itemData.rate),
              invoiceData.currency_code,
              orgCurrencyList
            ) +
            " / Unit",
          discount: {
            discount:
              invoiceData.currency_code +
              " " +
              NumberFormat(
                Number(itemData.discount.discount_value),
                invoiceData.currency_code,
                orgCurrencyList
              ),
            discountAccount: itemData.discount_account_name,
          },
          tax:
            itemData.gst_tax?.tax_id !== null
              ? itemData.gst_tax
              : itemData.taxes,
          taxableAmount:
            invoiceData.currency_code +
            " " +
            NumberFormat(
              Number(itemData.taxable_amount),
              invoiceData.currency_code,
              orgCurrencyList
            ),
          item: itemData,
        },
      }))
    : [];

  return (
    <div id="invoice" className="invoice-preview transaction-preview">
      <div className="preview-top-butons w-100 d-flex justify-content-end">
        {invoiceData.organization_id === currentUserInfo.organization_id &&
        creditNoteRolePermission.includes("Read") &&
        invoiceData.total_details?.balance_due &&
        Number(invoiceData?.total_details?.balance_due) > 0 &&
        invoiceData.excess_credit &&
        invoiceData.excess_credit > 0 ? (
          <div>
            <div className="credits-available credits-available-label">
              Credits Available{" "}
              <span className="available-amount">
                {NumberFormat(
                  Number(invoiceData.excess_credit),
                  invoiceData.currency_code,
                  orgCurrencyList
                )}
              </span>
            </div>
            <ApplyCreditsFormModal
              invoiceData={invoiceData}
              refreshInvoiceDetails={() => {
                invoiceDetail();
                if (transactionRef.current)
                  transactionRef.current.fetchCNPRList();
              }}
              isVoid={invoiceData.invoice_status === "Void"}
            />
          </div>
        ) : null}
      </div>
      <CreditNoteAndPaymentReceivedDetails
        invoiceId={Number(invoiceData.id)}
        invoiceDetail={() => {
          invoiceDetail();
          if (creditApplyRef.current)
            creditApplyRef.current.getApplyCreditList();
        }}
        ref={transactionRef}
        orgId={Number(invoiceData.organization_id)}
      />
      <div className="invoice-header d-flex justify-content-between">
        <CompanyDetails organizationData={invoiceData.organization_details} />
        <div className="invoice-details">
          <div className="label-invoice">Invoice</div>
          <div className="invoice-number-label">Invoice Number</div>
          <div className="invoice-number">
            {joinTransactionNumber(invoiceData.invoice_number) || ""}
          </div>
          {invoiceData.reference_number ? (
            <>
              <div className="invoice-number-label">Reference Number</div>
              <div className="invoice-number">
                {invoiceData.reference_number}
              </div>
            </>
          ) : null}
          <div className="invoice-number-label">Balance Due</div>
          <div className="invoice-number mb-0">
            {invoiceData.currency_code}{" "}
            {invoiceData.balance_due
              ? NumberFormat(
                  Number(invoiceData.balance_due),
                  invoiceData.currency_code,
                  orgCurrencyList
                )
              : zeroDisplayFormat(
                  decimalPlaceOfCurrency(
                    invoiceData.currency_code
                      ? invoiceData.currency_code
                      : currentUserInfo.currency_code,
                    orgCurrencyList
                  )
                )}
          </div>
        </div>
      </div>
      <div className="bill-details-section d-flex justify-content-between">
        <div className="billed-to">
          <span className="bill-details-header">Billed To</span>
          <div className="company-address pt-3">
            {invoiceData.address_details.map((data, index) => {
              if (data.is_billing) {
                return (
                  <div key={index}>
                    <span>
                      {invoiceData.customer_name
                        ? invoiceData.customer_name
                        : ""}
                    </span>
                    {data.address && <br />}
                    <span>{data.address ? data.address : ""}</span>
                    {(data.city || data.state_name) && <br />}
                    <span>
                      {data.city ? data.city : ""}
                      {data.city === null || data.city === "" ? "" : ", "}
                      {data.state_name ? data.state_name : ""}
                    </span>
                    {(data.country_name || data.zip_code) && <br />}
                    <span>
                      {data.country_name ? data.country_name : ""}
                      {data.country_name === null ||
                      data.country_name === "" ||
                      data.zip_code === null
                        ? ""
                        : "-"}
                      {data.zip_code ? data.zip_code : ""}
                    </span>
                    {invoiceData.customer_gstin && (
                      <>
                        <br />
                        <span className="gst-info">
                          GSTIN:{" " + invoiceData.customer_gstin}
                        </span>
                      </>
                    )}
                  </div>
                );
              }
            })}
          </div>
        </div>
        <div className="ship-to">
          <span className="bill-details-header">Ship To</span>
          <div className="company-address pt-3">
            {invoiceData.address_details.map((data, index) => {
              if (data.is_shipping) {
                return (
                  <div key={index}>
                    <span>
                      {invoiceData.customer_name
                        ? invoiceData.customer_name
                        : ""}
                    </span>
                    {data.address && <br />}
                    <span>{data.address}</span>
                    {(data.city || data.state_name) && <br />}
                    <span>
                      {data.city}
                      {data.city === null || data.city === "" ? "" : ", "}
                      {data.state_name}
                    </span>
                    {(data.country_name || data.zip_code) && <br />}
                    <span>
                      {data.country_name}
                      {data.country_name === null ||
                      data.country_name === "" ||
                      data.zip_code === null
                        ? ""
                        : "-"}
                      {data.zip_code}
                    </span>
                    {(data.country_name || data.zip_code) && <br />}
                    {invoiceData.customer_gstin && (
                      <>
                        <br />
                        <span className="gst-info">
                          GSTIN:{" " + invoiceData.customer_gstin}
                        </span>
                      </>
                    )}
                  </div>
                );
              }
            })}
          </div>
        </div>
        <div className="inv-date-wrap">
          <div className="inv-date">
            <span className="bill-details-header">Invoice Date</span>
            <div>{dateFormatHandler(invoiceData.invoice_date)}</div>
          </div>
          <div className="inv-date">
            <span className="bill-details-header">Due Date</span>
            <div>{dateFormatHandler(invoiceData.due_date)}</div>
          </div>
        </div>
        <div className="payment-terms">
          <span className="bill-details-header">Payment Terms</span>
          <div>
            {invoiceData.payment_term_id ? invoiceData.payment_term : "Custom"}
          </div>
        </div>
        {invoiceData?.place_of_supply !== null && (
          <div className="invoice-supply-wrap">
            <div className="invoice-supply m-0">
              <span className="invoice-details-header">Place of supply</span>
              <div>{invoiceData.place_of_supply}</div>
            </div>
          </div>
        )}
      </div>
      <RowWiseFieldView
        mapObject={invoiceData.custom_fields}
        module="Invoices"
      />
      <div className="table-section">
        <div className="table-wrap">
          <DetailsTableContainer
            columns={columns}
            data={data}
            haveColumnClassName={true}
            className="table inv-preview-table"
            hiddenColumns={["TDS"]}
          />
        </div>
        <div className="d-flex justify-content-between pt-3 mt-1 w-100 table-bottom-sect-wrap">
          <div className="thankyou-note w-50 d-flex align-items-end">
            {invoiceData?.customer_notes && (
              <div className="note-container">
                <span className="label">Customer Notes</span>
                <p>{invoiceData.customer_notes}</p>
              </div>
            )}
          </div>
          <DetailTotalSection
            currencyCode={invoiceData.currency_code}
            totalTax={invoiceData.total_details.tax_amount}
            paymentLabel="Payment received"
            payment={invoiceData.total_details.payment_made}
            taxTypes={taxTypes}
            subTotal={invoiceData.total_details.total_taxable_amount}
            total={invoiceData.total_details.total}
            balanceLabel={"Balance Due"}
            balanceAmount={
              invoiceData.balance_due
                ? invoiceData.balance_due
                : zeroDisplayFormat(
                    decimalPlaceOfCurrency(
                      invoiceData.currency_code
                        ? invoiceData.currency_code
                        : currentUserInfo.currency_code,
                      orgCurrencyList
                    )
                  )
            }
            tdsItems={{
              tdsAmount: invoiceData.total_details.transaction_tds_amount
                ? invoiceData.total_details.transaction_tds_amount
                : 0,
              tdsName: invoiceData.tds_name,
              tdsPercentage: invoiceData.tds_percentage,
              defaultTdsPercentage: invoiceData.default_tds_percentage,
            }}
            tcsItems={{
              tcsAmount: invoiceData.tcs_total,
              tcsName: invoiceData.tcs_name,
              tcsPercentage: invoiceData.tcs_percentage,
            }}
            roundOffValue={invoiceData.total_details.roundoff_amount}
            roundoffEnabled={invoiceData.roundoff_enabled}
          >
            <></>
          </DetailTotalSection>
        </div>
      </div>
      {invoiceData.sales_invoice_files.length ? (
        <FileList files={invoiceData.sales_invoice_files} />
      ) : null}
      <JournelView
        title="Journal - Invoice"
        transactionType="Invoices"
        transactionId={Number(invoiceData.id)}
        reRenderFlag={reRenderFlag}
        isVoid={invoiceData.invoice_status === "Void"}
      />
    </div>
  );
};
export default InvoicePreviewBody;
