import React, { useEffect, useMemo, useState } from "react";
import {
  FormTextAreaField,
  PaymentModeSelect,
} from "../../../../../../common/components";
import CustomDatepicker from "../../../../../../utils/atoms/datepicker";
import PaymentAmount from "../paymentAmount/PaymentAmount";
import { ObjectType } from "../../../../../../../types";
import useCommonData from "../../../../../../hooks/useCommon";
import {
  setExchangeApiSatus,
  createExchangeRateList,
  orgCurrencyListSelector,
} from "../../../../../../common/commonSlice";
import { getFormatedDate } from "../../../../../../../helpers/helper";
import { SuccessToaster } from "../../../../../../../helpers/toastHelper";
import "./refundForm.css";
import { decimalPlaceOfCurrency } from "../../../../../../../helpers/decimalPlaceHelper";
import { getRoundOffAmount } from "../../../../../../../helpers/roundOffHelper";
import { useAppSelector } from "../../../../../../../app/hooks";
import moment from "moment";
import {
  RefundHeading,
  ConsumerPaymentEditPopper,
  FieldContainer,
  BankCharges,
} from "../../../../../../common/components/refund";
import messages from "../../resources/messages";
import { zeroDisplayFormat } from "../../../../../../../helpers/numberFormatHelper";
import { getLatestCurrencyConversion } from "../../../../../../utils/exchangeRate-utils";
import AccountSelector from "../../../../../../common/components/AccountSelector/AccountSelector";
import { getRoundedAmount } from "../../../../../../utils/currency-utils";
import usePmRefundForm from "../../hooks/usePmRefundForm";
import CheckDetails from "../../../../../../common/components/refund/checkDetails/CheckDetails";

type Props = {
  handleModalClose: () => void;
  paymentMadeData: any;
  editId: number;
  refreshList: () => void;
};

const RefundForm = (props: Props) => {
  const { dispatch, currentUserInfo } = useCommonData();
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);

  const { formik, initialFormValues, fiscal_year_date } = usePmRefundForm(
    props,
    dispatch,
    currentUserInfo,
    orgCurrencyList
  );

  const exchangeRateSubmitHandler = async (data: any) => {
    dispatch(setExchangeApiSatus(true));
    const createResponseAction = await dispatch(
      createExchangeRateList({ values: data })
    );
    const createResponse = createResponseAction.payload;
    if (Object.keys(createResponse).length && !("error" in createResponse)) {
      SuccessToaster(
        "Exchange rate updated successfully!",
        "rate-create-success"
      );
      let newData = {
        conversion_id: createResponse.id,
        conversion_rate: createResponse.rate,
        conversion_timestamp: getFormatedDate(createResponse.timestamp),
      } as any;

      if (
        formik.values.bank_currency_id === currentUserInfo.organization_currency
      ) {
        newData.bank_conversion_id = createResponse.id;
        newData.bank_conversion_rate = createResponse.rate;
        newData.bank_conversion_timestamp = getFormatedDate(
          createResponse.timestamp
        );
        newData.bank_amount =
          createResponse.rate * Number(formik.values.amount);
      }

      formik.setValues((values: any) => {
        return {
          ...values,
          ...newData,
        };
      });
    }
  };

  const bankExchangeRateSubmitHandler = async (data: any) => {
    dispatch(setExchangeApiSatus(true));
    const createResponseAction = await dispatch(
      createExchangeRateList({ values: data })
    );
    const createResponse = createResponseAction.payload;
    if (Object.keys(createResponse).length && !("error" in createResponse)) {
      SuccessToaster(
        messages.success.exchangeRateUpdate,
        "rate-create-success"
      );

      let newData = {
        bank_conversion_id: createResponse.id,
        bank_conversion_rate: createResponse.rate,
        bank_conversion_timestamp: getFormatedDate(createResponse.timestamp),
        bank_amount: createResponse.rate * Number(formik.values.amount),
      } as any;

      if (
        formik.values.bank_currency_id === currentUserInfo.organization_currency
      ) {
        newData.conversion_id = createResponse.id;
        newData.conversion_rate = createResponse.rate;
        newData.onversion_timestamp = getFormatedDate(createResponse.timestamp);
      }

      formik.setValues((values: any) => {
        return {
          ...values,
          ...newData,
        };
      });
    }
  };

  const dateChangeHandler = async (date: Date | null) => {
    if (date) {
      if (date?.toString() === "Invalid Date") {
        formik.setFieldValue("refunded_on", "Invalid date");
      } else {
        const dateFormated = getFormatedDate(date);
        if (moment(dateFormated, "YYYY-MM-DD", true).isValid()) {
          formik.setFieldValue("refunded_on", dateFormated);

          let currencyConversionData = await getLatestCurrencyConversion(
            dispatch,
            props.paymentMadeData.currency_id,
            currentUserInfo.organization_currency,
            dateFormated
          );
          if (currencyConversionData) {
            formik.setValues((values: any) => {
              return {
                ...values,
                conversion_id: currencyConversionData.id,
                conversion_rate: currencyConversionData.rate,
                conversion_timestamp: currencyConversionData.timestamp,
                ...(values.bank_currency_id &&
                  values.bank_currency_id ===
                    currentUserInfo.organization_currency && {
                    bank_amount:
                      currencyConversionData.rate * Number(values.amount),
                    bank_conversion_id: currencyConversionData.id,
                    bank_conversion_rate: currencyConversionData.rate,
                    bank_conversion_timestamp: currencyConversionData.timestamp,
                  }),
              };
            });
          }
          // fetching latest bank currency
          if (
            formik.values.bank_currency_id &&
            formik.values.bank_currency_id !==
              currentUserInfo.organization_currency
          ) {
            let bankCurrencyConversionData = await getLatestCurrencyConversion(
              dispatch,
              props.paymentMadeData.currency_id,
              formik.values.bank_currency_id,
              dateFormated
            );
            formik.setValues((values: any) => {
              return {
                ...values,
                bank_amount:
                  bankCurrencyConversionData.rate * Number(values.amount),
                bank_conversion_id: bankCurrencyConversionData.id,
                bank_conversion_rate: bankCurrencyConversionData.rate,
                bank_conversion_timestamp: bankCurrencyConversionData.timestamp,
              };
            });
          }
        } else {
          formik.setFieldValue("refunded_on", "Invalid date");
        }
      }
    }
  };

  const paymentModeChangeHandler = (val: { value: number; label: string }) => {
    formik.setFieldValue("payment_mode", val.label);
  };

  const accountChangeHandler = async (account: any) => {
    formik.setValues((values: any) => {
      return {
        ...values,
        to_account: account.id,
        bank_currency_id: account.currency_id,
        bank_currency_code: account.currency_code,
      };
    });
    if (
      account.currency_id &&
      // account.currency_id !== props.paymentMadeData.currency_id &&
      formik.values.refunded_on &&
      formik.values.refunded_on !== "Invalid date"
    ) {
      const bankConversionData = await getLatestCurrencyConversion(
        dispatch,
        props.paymentMadeData.currency_id,
        account.currency_id,
        formik.values.refunded_on
      );
      formik.setValues((values: any) => {
        return {
          ...values,
          bank_amount: bankConversionData.rate * values.amount,
          bank_conversion_id: bankConversionData.id,
          bank_conversion_rate: bankConversionData.rate,
          bank_conversion_timestamp: bankConversionData.timestamp,
        };
      });
    }
  };

  const amountChangeHandler = (e: any) => {
    formik.setValues((values: any) => {
      return {
        ...values,
        amount: e.target.value,
        bank_amount: e.target.value * values.bank_conversion_rate,
      };
    });
  };

  const amountHandleBlur = (name: string, amount: number, index: number) => {
    let currencyCode = props.paymentMadeData.currency_code;
    const decimals = decimalPlaceOfCurrency(currencyCode, orgCurrencyList);
    const roundedAmount = getRoundOffAmount(amount, decimals);
    formik.setFieldValue("amount", roundedAmount);
  };

  const bankChargeAccountChangeHandler = (e: ObjectType) => {
    if (!e) {
      formik.setValues((values: any) => {
        return {
          ...values,
          bank_charges_account_id: 0,
          bank_charge_account_name: "",
          bank_charges_amount: 0,
        };
      });
      return;
    }
    formik.setValues((values: any) => {
      return {
        ...values,
        bank_charges_account_id: e.value,
        bank_charge_account_name: e.label,
      };
    });
  };

  const bankChargeChangeHandler = (e: any) => {
    formik.setFieldValue("bank_charges_amount", e.target.value);
  };

  const onCustomBankAmountSubmitHandler = (
    currencyConversionData: any,
    newBankAmount: string
  ) => {
    formik.setValues((values: any) => {
      return {
        ...values,
        ...(formik.values.bank_currency_id ===
          currentUserInfo.organization_currency && {
          conversion_id: currencyConversionData.id,
          conversion_rate: currencyConversionData.rate,
          conversion_timestamp: currencyConversionData.timestamp,
        }),
        bank_amount: newBankAmount,
        bank_conversion_id: currencyConversionData.id,
        bank_conversion_rate: currencyConversionData.rate,
        bank_conversion_timestamp: currencyConversionData.timestamp,
      };
    });
  };

  const checkDetailsChangeHandler = (newData: ObjectType[]) => {
    formik.setValues((values: any) => {
      return {
        ...values,
        ...newData,
      };
    });
  };

  const refundStartDate =
  props.paymentMadeData.payment_date > fiscal_year_date.startDate
    ? props.paymentMadeData.payment_date
    : fiscal_year_date.startDate;

  return (
    <>
      <div className="refund-form-wrapper main add-tax-form">
        <RefundHeading
          consumer_name={props.paymentMadeData.vendor_name}
          currency_code={props.paymentMadeData.currency_code}
          amount_excess={getRoundedAmount(
            props.paymentMadeData.currency_code,
            orgCurrencyList,
            props.paymentMadeData.amount_excess +
              Number(initialFormValues.amount)
          )}
        />
        <form className={` create-settings-form-wrap`}>
          <FieldContainer
            label="Refunded On"
            labelhtmlFor="refunded_on"
            required={true}
            error={
              (formik.touched.refunded_on &&
                formik.errors.refunded_on?.toString()) ||
              ""
            }
          >
            <div id="date" className="date-selector-wrapper">
              <CustomDatepicker
                date={new Date(formik.values.refunded_on)}
                handleDate={dateChangeHandler}
                type="paymentDate"
                error={
                  (formik.touched.refunded_on &&
                    formik.errors.refunded_on?.toString()) ||
                  ""
                }
                id="payment_date"
                zIndex={0}
                placeholder="Enter refund date"
                minDate={new Date(refundStartDate)}
                maxDate={new Date(fiscal_year_date.endDate)}
                enableFutureDate={true}
              />
            </div>
          </FieldContainer>
          <FieldContainer
            label="Payment Mode"
            labelhtmlFor="payment_mode"
            required={true}
            error={
              (formik.touched.payment_mode &&
                formik.errors.payment_mode?.toString()) ||
              ""
            }
          >
            <PaymentModeSelect
              isDisabled={false}
              handleChange={paymentModeChangeHandler}
              paymentMode={formik.values.payment_mode}
              error={
                (formik.touched.payment_mode &&
                  formik.errors.payment_mode?.toString()) ||
                ""
              }
            />
          </FieldContainer>
          {formik.values.payment_mode === "Check" ? (
            <CheckDetails
              check_date={formik.values.check_date}
              check_expiry_in={formik.values.check_expiry_in}
              check_expiry={formik.values.check_expiry}
              check_number={formik.values.check_number}
              onChange={checkDetailsChangeHandler}
              errors={{
                check_date:
                  (formik.touched.check_date &&
                    formik.errors.check_date?.toString()) ||
                  "",
                check_expiry_in:
                  (formik.touched.check_expiry_in &&
                    formik.errors.check_expiry_in?.toString()) ||
                  "",
                check_expiry:
                  (formik.touched.check_expiry &&
                    formik.errors.check_expiry?.toString()) ||
                  "",
                check_number:
                  (formik.touched.check_number &&
                    formik.errors.check_number?.toString()) ||
                  "",
              }}
            />
          ) : (
            ""
          )}
          <FieldContainer
            label="Reference Number"
            labelClass="form-label"
            labelhtmlFor="payment_mode"
            required={false}
            error=""
          >
            <input
              type="text"
              id="reference_number"
              name="reference_number"
              value={formik.values.reference_number}
              className={"form-control"}
              placeholder="Enter Reference Number"
              onChange={formik.handleChange}
              maxLength={12}
            />
          </FieldContainer>
          <FieldContainer
            label="To Account"
            labelhtmlFor=""
            required={true}
            error={
              (formik.touched.to_account &&
                formik.errors.to_account?.toString()) ||
              ""
            }
          >
            <AccountSelector
              itemAccount={Number(formik.values.to_account)}
              itemAccountName={formik.values.to_account_name}
              organizationId={currentUserInfo.organization_id}
              onChange={accountChangeHandler}
              itemIndex={0}
              error={
                (formik.touched.to_account &&
                  formik.errors.to_account?.toString()) ||
                ""
              }
              baseAccount={"Cash~Bank Accounts"}
              wrapperClass="w-100 clear-both"
              className="account-full-width clear-both"
            />
          </FieldContainer>
          <PaymentAmount
            label="Amount"
            subLabel="Customer Currency"
            name="amount"
            id="amount"
            value={formik.values.amount}
            changeHandler={amountChangeHandler}
            prefix={props.paymentMadeData.currency_code}
            isDisabled={false}
            error={
              formik.errors.amount && formik.touched.amount
                ? formik.errors.amount?.toString()
                : ""
            }
            conversionDate={formik.values.refunded_on}
            currencyId={props.paymentMadeData.currency_id}
            currencyCode={props.paymentMadeData.currency_code}
            toCurrencyId={currentUserInfo.organization_currency}
            toCurrencyCode={currentUserInfo.currency_code}
            conversionId={formik.values.conversion_id}
            conversionRate={formik.values.conversion_rate}
            conversionTimestamp={formik.values.conversion_timestamp}
            isExchangeEditable={true}
            wrappperClass="mb-4 w-100"
            exchangeRateSubmitHandler={exchangeRateSubmitHandler}
            handleBlur={amountHandleBlur}
            exchangeRateMaxLength={6}
          />
          {formik.values.bank_currency_id &&
          formik.values.bank_currency_id !==
            props.paymentMadeData.currency_id ? (
            <PaymentAmount
              label="Amount"
              subLabel="Bank Currency"
              name="bank_amount"
              id="bank_amount"
              value={formik.values.bank_amount}
              changeHandler={amountChangeHandler}
              prefix={formik.values.bank_currency_code}
              isDisabled={true}
              error={
                formik.errors.bank_amount && formik.touched.bank_amount
                  ? formik.errors.bank_amount?.toString()
                  : ""
              }
              conversionDate={formik.values.refunded_on}
              currencyId={props.paymentMadeData.currency_id}
              currencyCode={props.paymentMadeData.currency_code}
              toCurrencyId={formik.values.bank_currency_id}
              toCurrencyCode={formik.values.bank_currency_code}
              conversionId={formik.values.bank_conversion_id}
              conversionRate={formik.values.bank_conversion_rate}
              conversionTimestamp={formik.values.bank_conversion_timestamp}
              isExchangeEditable={true}
              wrappperClass="mb-4 w-100"
              exchangeRateSubmitHandler={bankExchangeRateSubmitHandler}
              handleBlur={amountHandleBlur}
              exchangeRateMaxLength={6}
              isExchangeRateHidden={
                formik.values.bank_currency_id ===
                currentUserInfo.organization_currency
                  ? true
                  : false
              }
              endAdornment={
                <ConsumerPaymentEditPopper
                  label="Amount"
                  subLabel={"Bank Currency"}
                  formik={formik}
                  name="bank_amount"
                  id="bank_amount"
                  prefix={formik.values.bank_currency_code}
                  amount={formik.values.amount}
                  bankAmount={formik.values.bank_amount}
                  error={""}
                  onSave={onCustomBankAmountSubmitHandler}
                  isDisabled={false}
                  conversionDate={formik.values.refunded_on}
                  toCurrencyId={formik.values.bank_currency_id}
                  toCurrencyCode={formik.values.bank_currency_code}
                  baseCurrencyId={props.paymentMadeData.currency_id}
                  baseCurrencyCode={props.paymentMadeData.currency_code}
                  conversionRate={formik.values.bank_conversion_rate}
                  editId={props.editId.toString()}
                  isButtonDisabled={false}
                  placeholder={zeroDisplayFormat(
                    decimalPlaceOfCurrency(
                      formik.values.bank_currency_code,
                      orgCurrencyList
                    )
                  )}
                />
              }
            />
          ) : (
            ""
          )}
          {formik.values.bank_currency_id ? (
            <div className="col-12 col-lg-4 mb-4 w-100">
              <BankCharges
                bankChargesAccountId={formik.values.bank_charges_account_id}
                onBankChargeAccountChange={bankChargeAccountChangeHandler}
                bankChargesAmount={formik.values.bank_charges_amount}
                onBankChargeChange={bankChargeChangeHandler}
                currencyCode={props.paymentMadeData.currency_code}
                bankCurrencyCode={formik.values.bank_currency_code}
                formErrors={
                  (formik.touched.bank_charges_amount &&
                    formik.touched.bank_charges_account_id &&
                    formik.errors.bank_charges_amount?.toString()) ||
                  ""
                }
                classWidth="w-49"
                disabled={!formik.values.bank_currency_id ? true : false}
                bankChargeConvertedAmount={(
                  Number(formik.values.bank_charges_amount) *
                  Number(formik.values.bank_conversion_rate)
                ).toString()}
              />
            </div>
          ) : (
            <></>
          )}
          <FormTextAreaField
            onChange={formik.handleChange}
            label="Description"
            id="description"
            name="description"
            className="form-control w-100"
            placeholder="Enter description"
            value={formik.values.description}
            wrapperClass={"w-100"}
            type={""}
          />
          <div className="form-btn-group-wrapper">
            <button
              className="btn btn-block fa-lg invite-btn save-butn mb-4"
              type="button"
              disabled={formik.isSubmitting}
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              {props.editId === 0 ? "Save" : "Update"}
            </button>
            <button
              className="btn btn-block fa-lg cancel-btn mb-4"
              type="button"
              onClick={() => {
                props.handleModalClose();
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default RefundForm;
