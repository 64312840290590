import * as React from "react";
import { colors } from "../../../constants/colors";
import { LineItemProps } from "../../../../types";
import ItemField from "./ItemField";
import { useAppSelector } from "../../../../app/hooks";
import Grid from "@mui/material/Grid";
import CustomSearchSelect from "../CustomSearchSelect/CustomSearchSelect";
import InfoTooltip from "../InfoTooltip";
import Discount from "../Discount";
import Itemtax from "../ItemTax";
import { IsFeatureEnabled } from "../../../../helpers/featureFlagHelper";
import { AddTagIcon } from "../../../../assets/images";
import { DeleteIcon } from "../customSvgIcons/deleteIcon";
import { useParams } from "react-router-dom";
import { tdsPreferenceSelector } from "../../../home/tds/tdsSlice";
import {
  TAX_SYSTEM,
  sourceTaxTypes,
  tdsApplyLevel,
} from "../../../constants/constants";
import SelectTDSAndTCS from "./SelectTDSAndTCS";
import GstItemTax from "../GstItemTax";
import useGst from "../../../hooks/useGst";
import { NumberFormat, zeroDisplayFormat } from "../../../../helpers/numberFormatHelper";
import { decimalPlaceOfCurrency } from "../../../../helpers/decimalPlaceHelper";
import useCommonData from "../../../hooks/useCommon";
import { Label } from "../htmlTags/Label";
import { Container } from "../htmlTags/Container";
import { RangePriceValue, SubscriptionLineItemProps } from "../../../home/subscription/subscriptionType";
import { NON_PRICE_RANGE_PRICING_MODEL, PRICE_RANGE_PRICING_MODEL } from "../../../../helpers/planHelper";
import SubscriptionItemtax from "../../../home/subscription/components/subscriptionForm/subscriptionItemTax/SubscriptionItemTax";
import SubscriptionGstItemTax from "../../../home/subscription/components/subscriptionForm/subscriptionItemTax/SubscriptionCstItemTax";
import SubscriptionSelectTDSAndTCS from "../../../home/subscription/components/subscriptionForm/subscriptionItemTax/SubscriptionSelectTDSANDTCS";
import SubscriptionPricingDetails from "../../../home/subscription/components/subscriptionForm/SubscriptionPricingDetails";

function PlanLineItem({
  parentIndex,
  index,
  item,
  componentType,
  currencyId,
  currencyCode,
  isHsnCode,
  taxFormValues,
  handleItemChange,
  handleItemOnBlur,
  handleItemAccountChange,
  taxType,
  lineItemBaseAccount,
  discountBaseAccount,
  handleItemTaxChange,
  handleItemGstChange,
  handleTaxOnBlur,
  addItemTaxContainer,
  deleteItemTaxContainer,
  itemFormErrors,
  handleAmountChange,
  tdsLevel,
  sourceTaxType,
  clearTaxSelection,
  totalTcsValues,
  tdsIds,
  tcsIds,
  handleTdsLabelChange,
  clearGstSelection,
  supplyState,
  isSez,
  gstRegistrationType,
  updateIsItemEdited,
  customerDetails,
  addedPricingModal,
  parentId,
  itemType,
  onPricePointUpdate,
  tdsPreferencetoCheck,
}: SubscriptionLineItemProps) {
  const { orgCurrencyList, currentUserInfo } = useCommonData();
  const tdsPreference = useAppSelector(tdsPreferenceSelector);
  const [isCopyPaste, setCopyPaste] = React.useState(false);
  const { editId } = useParams();
  const { isGstOrg } = useGst();
  const organization_tax_system = currentUserInfo.organization_tax_system;

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // Check for copy (Ctrl+C) and paste (Ctrl+V) shortcuts
    if (
      (event.ctrlKey || event.metaKey) &&
      (event.key === "c" || event.key === "v" || event.key === "a")
    ) {
      setCopyPaste(true);
      return; // Allow copy-paste functionality
    } else {
      setCopyPaste(false);
    }

    // Check if the pressed key is a functional key (e.g., Ctrl, Shift, Alt) or a printable character (0-9, .)
    if (event.key.length === 1 && !/^(\d*\.?\d*)$/.test(event.key)) {
      event.preventDefault(); // Prevent the input of special characters
    }
  };
  const handleDiscountType = (e: any, index: number) => {
    e.preventDefault();
    let discType: any = document.querySelectorAll(".discount-type-" + index);
    let target = e.target as Element;
    discType.forEach((elem: any) => {
      elem.classList.remove("active");
    });
    target.classList.add("active");
  };

  const getTdsTaxPreference = () => {
    if (!tdsPreference.is_enabled) {
      return false;
    }
    if (componentType === "invoice" || componentType === "creditNote") {
      if (
        tdsPreference.support_tds_for.includes("Customers") &&
        tdsPreference.apply_tds_at === "Line item level"
      ) {
        return true;
      }
    }
    if (componentType === "bill" || componentType === "debitNote") {
      if (
        tdsPreference.support_tds_for.includes("Vendors") &&
        tdsPreference.apply_tds_at === "Line item level"
      ) {
        return true;
      }
    }
    return false;
  };
  const isTdsAtLineItemEnabled = () => {
    if (editId) {
      if (!tdsPreference.is_enabled && tdsLevel === tdsApplyLevel.LINE_ITEM_LEVEL) return true
      else return tdsPreferencetoCheck === "LINE_LEVEL" && tdsPreference.is_enabled
    } else {
      return getTdsTaxPreference();
    }
  };

  const isTCSSelected = () => {
    if (totalTcsValues.tcs_id) {
      return true;
    }
    return false;
  };

  return (
    <div key={index} className="item-inner-row">
      <Grid container gridRow={2}>
      <Grid container gridColumn={2}>
      <Grid item xs={addedPricingModal === 'PER_UNIT' ? 2 : 2.5} className="p-l-10 p-t-24">
      <Container className="p-t-15 each-item-detail-label row">
        <Label htmlFor="item_name" className="per-unit-min-width">
           {item.item_name}
        </Label>
        {
           NON_PRICE_RANGE_PRICING_MODEL.includes(addedPricingModal)
           ? <div className="per-unit-min-width">
           {`${ addedPricingModal === 'PER_UNIT' ? 'Per unit' : 'Flat Fee'} - ${ item.currency_code || currencyCode|| ''} ${NumberFormat(
           item?.price || 0,
           item.currency_code || currencyCode || '',
           orgCurrencyList
         )} 
         `}
         <div>{ addedPricingModal === 'PER_UNIT' && item.free_quantity ? `Free Quantities: ${item.free_quantity}` : ''}</div>
       </div> : <div className="per-unit-min-width">
        <SubscriptionPricingDetails
           value = {
            [{
              id: item.item_id,
              parentId: parentId,
              itemType: itemType,
              item_name: item.item_name,
              pricing_id: item.pricing_id,
              range_price: item.range_price,
              is_restore_enabled: item.is_restore_enabled
            }]
           }
           pricingModel={addedPricingModal}
           index={index}
           parentIndex={parentIndex}
           currencyId={currencyId}
           currencyCode={ item.currency_code || currencyCode  || ""}
           onPricePointUpdate={(rangeData: RangePriceValue[]) => onPricePointUpdate( rangeData ,index, parentIndex)}
        />
          </div>
        }
        </Container>
      </Grid>
      <Grid item xs={addedPricingModal === 'PER_UNIT' ? 10 : 9.5} className="col-padding-left">
      <div className="row g-0 p-t-24">
        {isHsnCode && isGstOrg && (
          <div className={`col w-310`}>
            <label htmlFor="hsn_code">hsn/sac code</label>
            <input
              type="text"
              className={`form-control hsn_code w-100 hsn_or_sac_code ${
                itemFormErrors?.hsn_or_sac_code ? " error" : ""
              }`}
              name="hsn_or_sac_code"
              placeholder="Enter code"
              id="hsn_code"
              value={item.hsn_or_sac_code ? item.hsn_or_sac_code : ""}
              onChange={(e) => handleItemChange(e, index, parentIndex)}
              maxLength={15}
            />
            <span className="error">{itemFormErrors?.hsn_or_sac_code}</span>
          </div>
        )}
        {isTdsAtLineItemEnabled() &&
          organization_tax_system === TAX_SYSTEM.INDIAN_TAX_SYSTEM && (
            <div
              className={`col w-359 select-tds-dropdown tds-dropdown-container`}
              style={
                isTCSSelected() ? { pointerEvents: "none", opacity: "0.5" } : {}
              }
            >
              <label htmlFor={"tds-entry"}>TDS</label>
              <SubscriptionSelectTDSAndTCS
                parentIndex={parentIndex}
                id="tds-select"
                selectPlaceholderText="Choose TDS type"
                inputId="tds-entry"
                inputPlaceHolder="Enter amount"
                index={index}
                item={{
                  tds_id: item.tds_id,
                  tds_amount: item.tds_amount,
                  tds_rate: item.tds_percentage,
                  tds_name: item.tds_name,
                  tcs_id: null,
                  tcs_amount: 0,
                  tcs_rate: 0,
                  tcs_name: "",
                }}
                handleAmountChange={(name: string, e: any, index: number) => handleAmountChange(name,e, index, parentIndex)}
                handleOnBlur={(name: string, tdsAmount: number, index: number) => handleItemOnBlur(name, tdsAmount, index, parentIndex)}
                taxType="TDS"
                clearTaxSelection={(index: number) => clearTaxSelection(index, parentIndex)}
                tdsIds={tdsIds}
                tcsIds={tcsIds}
                handleTdsLabelChange={(
                  id: number,
                  rate: number,
                  name: string,
                  index: number
                ) => handleTdsLabelChange(
                  id,
                  rate,
                  name,
                  index,
                  parentIndex
                )}
                handleTotalTcsLabelChange={() => {}}
                applyTdsAt={"line_item_level"}
                error={itemFormErrors}
              />
              {itemFormErrors?.tds_name ? (
                <span className="error">{itemFormErrors?.tds_name}</span>
              ) : itemFormErrors?.tds_amount ? (
                <span className="error">{itemFormErrors?.tds_amount}</span>
              ) : (
                <></>
              )}
            </div>
          )}
      </div>
      </Grid>
    </Grid>
    <Grid container gridColumn={2}>
      <Grid item xs={addedPricingModal === 'PER_UNIT' ? 2 : 2.5} className="p-l-10">
        <div className="item-col accnt-col">
          <label htmlFor="account" className="required">
            Account
          </label>
          <CustomSearchSelect
            itemAccount={Number(item.account_id)}
            itemAccountName={item.account_name}
            organizationId={currentUserInfo.organization_id}
            handleItemAccountChange={(accountId: number, index: number, accountName: string) => handleItemAccountChange(accountId, index, accountName, parentIndex)}
            itemIndex={index}
            error={itemFormErrors?.account_id ? "error" : ""}
            baseAccount={lineItemBaseAccount}
          />
          <span className="error">{itemFormErrors?.account_id}</span>
        </div>
      </Grid>
      <Grid item xs={addedPricingModal === 'PER_UNIT' ? 10 : 9.5} className="col-padding-left">
      <div className="row g-0 justify-content-between">
        
        {addedPricingModal === 'FLAT_FEE' ? null : <div className="item-col width-100 pright-20">
          <label htmlFor="quantity">Quantity</label>
          <input
            type="text"
            className={`form-control quantity w-100 ${
              itemFormErrors?.quantity ? " error" : ""
            }`}
            name="quantity"
            id={"quantity_" + index}
            placeholder={zeroDisplayFormat(
              decimalPlaceOfCurrency(
                currencyCode ? currencyCode : item.currency_code,
                orgCurrencyList
              )
            )}
            value={item.quantity ? item.quantity : ""}
            onChange={(e) => {
              handleItemChange(e, index, parentIndex);
            }}
            onBlur={() =>
              handleItemOnBlur(
                "quantity",
                item.quantity ? item.quantity : 0,
                index,
                parentIndex
              )
            }
          />
          <span className="error">{itemFormErrors?.quantity}</span>
        </div>}
        {PRICE_RANGE_PRICING_MODEL.includes(addedPricingModal) ? null : <div className="item-col width-100 pright-20">
          <label htmlFor="rate" className="required">
            Rate
          </label>
          <input
            type="text"
            className={`form-control rate w-100 ${
              itemFormErrors?.rate ? " error" : ""
            }`}
            onKeyDown={handleKeyPress}
            name="rate"
            id={"rate_" + index}
            placeholder={zeroDisplayFormat(
              decimalPlaceOfCurrency(
                currencyCode ? currencyCode : item.currency_code,
                orgCurrencyList
              )
            )}
            value={item.rate ? item.rate : ""}
            onChange={(e) => {
              const { id, name, value } = e.target;
              const regex = /^(\d*\.?\d*)$/;
              let filteredValue = value === "." ? "0." : value;
              if (value === "" || regex.test(filteredValue.toString())) {
                handleItemChange(
                  {
                    ...e,
                    target: {
                      ...e.target,
                      id: id,
                      name: name,
                      value: String(filteredValue),
                    },
                  },
                  index,
                  parentIndex
                );
              }
            }}
            onBlur={() =>
              handleItemOnBlur("rate", item.rate ? item.rate : 0, index)
            }
          />
          <span className="error">{itemFormErrors?.rate}</span>
        </div>}
        <div className={`item-col discount-col error pright-20 text-align-left ${addedPricingModal !== 'PER_UNIT' ? 'w-310' : "item-level" }`}>
          <div className="label-wrapper discount" id="discount_account_id">
            <label htmlFor="discount">Discount</label>
            <InfoTooltip
              title={
                "Add the percentage/amount to be deducted from the sub-total as a discount"
              }
            />
            <div className="discount-type-wrapper d-flex">
              <button
                className={
                  item.discount_type === "PERCENTAGE"
                    ? "discount-type-" + index + " percentage active"
                    : "discount-type-" + index + " percentage"
                }
                onClick={(e: any) => {
                  handleDiscountType(e, index);
                  const data = {
                    target: {
                      name: "discount_type",
                      value: "PERCENTAGE",
                    },
                  };
                  handleItemChange(data, index, parentIndex);
                }}
              >
                %
              </button>
              <button
                className={
                  item.discount_type === "ABSOLUTE"
                    ? "discount-type-" + index + " currency active"
                    : "discount-type-" + index + " currency"
                }
                onClick={(e) => {
                  handleDiscountType(e, index);
                  const data = {
                    target: {
                      name: "discount_type",
                      value: "ABSOLUTE",
                    },
                  };
                  handleItemChange(data, index, parentIndex);
                }}
              >
                {currencyCode ? currencyCode : item.currency_code}
              </button>
            </div>
          </div>
          <Discount
            item={item}
            organizationId={currentUserInfo.organization_id}
            handleItemAccountChange={(e: any, index: number) => handleItemAccountChange(e, index, '', parentIndex)}
            itemFormErrors={itemFormErrors}
            index={index}
            discount={item.discount}
            discount_type={item.discount_type}
            currencyCode={currencyCode}
            baseAccount={discountBaseAccount}
            error={
              itemFormErrors?.discount || itemFormErrors?.discount_account_id
                ? "error"
                : ""
            }
            handleItemChange={(e, index) => handleItemChange(e, index, parentIndex)}
            handleOnBlur={(name: string, amount: number, index: number) => handleItemOnBlur(name, amount, index, parentIndex)}
          />
          <span className="error">{itemFormErrors?.discount}</span>
          <span className="error">{itemFormErrors?.discount_account_id}</span>
        </div>
        <div className="item-col w-194 pright-16 taxes">
          <div className="label-wrapper">
            <label htmlFor="tax_id">Tax</label>
            <InfoTooltip
              title={
                "Add the tax label and amounts to be added to the discounted amount"
              }
            />
          </div>

          {isGstOrg ? (
            <SubscriptionGstItemTax
              parentIndex={parentIndex}
              item={item}
              itemIndex={index}
              gst_tax={item.gst_tax ? item.gst_tax : null}
              gstTaxErrors={itemFormErrors ? itemFormErrors : undefined}
              organizationId={currentUserInfo.organization_id}
              gstOnChange={(gst, itemExemption) =>
                handleItemGstChange(gst, index, itemExemption, parentIndex)
              }
              disabledFeature={currentUserInfo.disabled_feature}
              clearGstSelection={(index: number) => clearGstSelection(index, parentIndex)}
              supplyState={supplyState}
              isSez={isSez}
              gstRegistrationType={gstRegistrationType}
              updateIsItemEdited={updateIsItemEdited}
              customerDetails={customerDetails}
            />
          ) : (
            <>
              <SubscriptionItemtax
                parentIndex={parentIndex}
                itemIndex={index}
                taxes={taxFormValues?.taxes || []}
                taxErrors={itemFormErrors?.taxes}
                organizationId={currentUserInfo.organization_id}
                taxType={taxType}
                taxLabelOnChange={(e, num, taxIndex) => {
                  const data = {
                    target: {
                      name: "tax_id",
                      value: e,
                    },
                  };
                  handleItemTaxChange(data, index, taxIndex, parentIndex);
                }}
                taxValueOnChange={(e, num, taxIndex) =>
                  handleItemTaxChange(e, index, taxIndex, parentIndex)
                }
                deleteItemTax={(taxIndex, id) =>
                  deleteItemTaxContainer(index, taxIndex, id, parentIndex)
                }
                handleTaxOnBlur={(
                  name: string,
                  amount: number,
                  itemIndex: number,
                  taxIndex: number
                ) => handleTaxOnBlur(
                  name,
                  amount,
                  itemIndex,
                  taxIndex,
                  parentIndex
                )}
                disabledFeature={currentUserInfo.disabled_feature}
                currencyCode={currencyCode}
              />
            </>
          )}
          {IsFeatureEnabled(
            "Tax Configuration",
            currentUserInfo.disabled_feature
          ) &&
            !isGstOrg && (
              <a
                href="#"
                role="button"
                className="add-tags-button"
                onClick={(e) => e.preventDefault()}
              >
                <div
                  className="d-flex align-items-center mx-0"
                  onClick={() => addItemTaxContainer(index, parentIndex)}
                >
                  <img className="add-tax-button" src={AddTagIcon} alt="" />
                  <span className="px-2">Add tax </span>
                </div>
              </a>
            )}
        </div>
        <div className="item-col tax-amt-col w-145 p-0">
          <div className="label-wrapper text-nowrap">
            <label htmlFor="">Taxable Amount</label>
            <InfoTooltip title={"Total Taxable Amount"} />
          </div>
          <div className="d-flex taxable-amount align-items-center justify-content-between w-100 py-2 text-nowrap">
            <span
              className="amount-taxable-nil"
              id={"taxable_amount_span_" + index + '_' + parentIndex}
            >
              0.00
            </span>
          </div>
        </div>
      </div>
      </Grid>
    </Grid>
      </Grid>
    </div>
  );
}

export default React.memo(PlanLineItem);
