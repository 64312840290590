import {
  CreditApply,
  InvoiceValues,
  TaxableAmountCalculationValues,
  TotalAmountCalculationValues,
} from "./../../../types";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getInvoiceList,
  deleteInvoice,
  createNewInvoice,
  editInvoice,
  deleteInvoiceItem,
  deleteInvoiceRecipient,
  getInvoiceDetails,
  getInvoiceNumber,
  invoiceNumberExist,
  deleteInvoiceItemTax,
  sendInvoice,
  calculateTaxableAmount,
  calculateAmount,
  invoicePdfLink,
  invoiceAttachments,
  invoiceAttachmentsDelete,
  transactionLimitStatus,
  writeOffCreate,
  writeOffUpdate,
  writeOffDetails,
  writeOffDelete,
  applyCreditList,
  creditsCalculation,
  applyCreditsToInvoice,
  getReportingTagJournals,
  putReportingTags,
  getTagsOptionsAssociatedToInvoice,
  invoiceVoidMarker,
} from "./invoiceAPI";

export const invoiceList = createAsyncThunk(
  "invoiceList",
  async (data: {
    page: number;
    itemsPerPage: number;
    dateSortOrder: string;
    orgId: number;
    customerList: number[];
    orgIds: number[];
    statusList: string[];
    startDate?: string;
    endDate?: string;
    startDueDate?:string;
    endDueDate?: string;
  }) => {
    return await getInvoiceList(data);
  }
);

export const deleteInvoiceDetails = createAsyncThunk(
  "deleteInvoice",
  async (data: { deleteInvoiceId: number; orgId: number }) => {
    return await deleteInvoice(data);
  }
);

export const createInvoice = createAsyncThunk(
  "create/invoice",
  async (data: {
    values: InvoiceValues;
    status: string;
    isSendMail: boolean;
    isPrintOrDownload: boolean;
    orgId: number;
  }) => {
    return await createNewInvoice(data);
  }
);

export const editInvoiceDetails = createAsyncThunk(
  "edit/invoice",
  async (data: {
    id: number;
    status: string;
    isSendMail: boolean;
    isPrintOrDownload: boolean;
    editInvoiceInputs: InvoiceValues;
    orgId: number;
  }) => {
    return await editInvoice(data);
  }
);

export const deleteInvoiceItemDetails = createAsyncThunk(
  "delete/invoice/item",
  async (data: { invoiceId: number; itemId: number; orgId: number }) => {
    return await deleteInvoiceItem(data);
  }
);

export const deleteInvoiceRecipientDetails = createAsyncThunk(
  "invoice/recipient/delete",
  async (data: { invoiceId: number; emailIds: number[]; orgId: number }) => {
    return await deleteInvoiceRecipient(data);
  }
);

export const invoiceDetails = createAsyncThunk(
  "invoiceDetail",
  async (data: { invoiceId: number; orgId: number }) => {
    return await getInvoiceDetails(data);
  }
);

export const getNewInvoiceNumber = createAsyncThunk(
  "invoice/number",
  async (orgId: number) => {
    return await getInvoiceNumber(orgId);
  }
);

export const checkInvoiceNumber = createAsyncThunk(
  "invoice/number-exist",
  async (data: {
    invoiceNumber: string;
    orgId: number;
    invoiceId: number | null;
  }) => {
    return await invoiceNumberExist(data);
  }
);

export const deleteInvoiceItemTaxDetails = createAsyncThunk(
  "delete/invoice/item/tax",
  async (data: { itemId: number; taxId: number; orgId: number }) => {
    return await deleteInvoiceItemTax(data);
  }
);

export const attachInvoiceFiles = createAsyncThunk(
  "invoice/files/upload",
  async (data: { file: FormData; orgId: number }) => {
    return await invoiceAttachments(data);
  }
);

export const deleteInvoiceFile = createAsyncThunk(
  "invoice/file/delete",
  async (data: { invoiceId: number; fileId: number; orgId: number }) => {
    return await invoiceAttachmentsDelete(data);
  }
);

export const sendInvoiceData = createAsyncThunk(
  "send-invoice",
  async (data: { invoiceId: number; emails: string[]; orgId: number }) => {
    return await sendInvoice(data);
  }
);

export const getTransactionLimitStatus = createAsyncThunk(
  "customer/limit/status",
  async (orgId: number) => {
    return await transactionLimitStatus(orgId);
  }
);

export const getTaxableAmount = createAsyncThunk(
  "taxableamount",
  async (data: {
    values: TaxableAmountCalculationValues;
    orgId: number;
    signal: Object;
  }) => {
    return await calculateTaxableAmount(data);
  }
);

export const getAmountCalculation = createAsyncThunk(
  "amount-calculation",
  async (data: {
    values: TotalAmountCalculationValues;
    orgId: number;
    signal: Object;
  }) => {
    return await calculateAmount(data);
  }
);

export const getInvoicePdfLink = createAsyncThunk(
  "invoice/pdf",
  async (data: { invoiceId: number; orgId: number }) => {
    return await invoicePdfLink(data);
  }
);
export const createWriteOff = createAsyncThunk(
  "invoice/write-off/create",
  async (data: {
    orgId: number;
    write_off_values: {
      amount: string | number;
      account_id: number | null;
      invoice_id: number | null;
      is_partial: boolean;
    };
  }) => {
    return await writeOffCreate(data);
  }
);
export const updateWriteOff = createAsyncThunk(
  "invoice/write-off/edit",
  async (data: {
    orgId: number;
    write_off_id: number | null;
    write_off_values: {
      amount: string | number;
      is_partial: boolean;
    };
  }) => {
    return await writeOffUpdate(data);
  }
);
export const getWriteOffDetails = createAsyncThunk(
  "invoice/write-off/details",
  async (data: { orgId: number; invoice_id: number | null }) => {
    return await writeOffDetails(data);
  }
);
export const deleteWriteOff = createAsyncThunk(
  "invoice/write-off/delete",
  async (data: { orgId: number; write_off_id: number | null }) => {
    return await writeOffDelete(data);
  }
);
export const creditList = createAsyncThunk(
  "invoice/apply-credit/list",
  async (data: { invoiceId: number; orgId: number }) => {
    return await applyCreditList(data);
  }
);
export const applyCreditsCalculation = createAsyncThunk(
  "invoice/apply-credit/calculate",
  async (data: {
    orgId: number;
    values: {
      invoice_balance: number;
      allocation_list: number[];
      currency_id: number | null;
    };
    signal?: AbortSignal;
  }) => {
    return await creditsCalculation(data);
  }
);
export const applyCredits = createAsyncThunk(
  "invoice/apply-credit",
  async (data: {
    invoice_id: number;
    orgId: number;
    values: CreditApply[];
  }) => {
    return await applyCreditsToInvoice(data);
  }
);

export const generateJournal = createAsyncThunk(
  "reporting-tag/journal-generate",
  async (data: { invoiceId: number; orgId: number }) => {
    return await getReportingTagJournals(data);
  }
);

export const fetchTagOptionForInvoices = createAsyncThunk(
  "reporting-tag/tag-options-for-invoices",
  async (data: { invoiceIds: number[]; orgId: number }) => {
    return await getTagsOptionsAssociatedToInvoice(data);
  }
);

export const updateReportingTags = createAsyncThunk(
  "reporting-tag/update",
  async (data: { invoiceId: number; orgId: number; payload: any }) => {
    return await putReportingTags(data);
  }
);
export const markInvoiceVoid = createAsyncThunk(
  "mark-void",
  async (data: { invoiceId: number | null; orgId: number}) => {
    return await invoiceVoidMarker(data);
  }
);

const initialState = {};

export const invoiceSlice = createSlice({
  name: "invoiceManagement",
  initialState,
  reducers: {},
});

export default invoiceSlice.reducer;
