import { useEffect, useState } from "react";
import { ReportTagFormValues, ReportTagOptions } from "../../../../types";

export const useInitializeForm = (tagDetails?: ReportTagFormValues) => {

  const [initialValues, setInitialValues] = useState<ReportTagFormValues>({
    name: "",
    options: [
      { id: null, name: "" },
      { id: null, name: "" },
      { id: null, name: "" },
    ] as ReportTagOptions[],
  });
  useEffect(() => {
    setInitialValues(
      tagDetails || {
        name: "",
        options: [
          { id: null, name: "" },
          { id: null, name: "" },
          { id: null, name: "" },
        ] as ReportTagOptions[],
      }
    );
  }, [tagDetails]);

  return { initialValues };
};
