import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import "../../../styles/ListFilter.css";
import { RolesListImg } from "../../../../../assets/images";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
  listFilterSelector,
  setIsFilterApplied,
  setListFilters,
} from "../../../../../appSlice";
import {
  currentUserSelector,
  setDateListFilter,
  setDueDateListFilter,
} from "../../../commonSlice";
import {
  FiltersType,
  ObjectType,
  SelectedFiltersType,
  DateFilterType,
} from "../../../../../types";
import Divider from "@mui/material/Divider";
import usePreservedLocation from "../../../../hooks/usePreservedLocation";
import FilterCategory from "./FilterCategory";
import FilterOption from "./FilterOption";
import DateFilter from "../DateFilter/DateFilter";
import DatePicker from "../../../../utils/atoms/DatePicker/DatePicker";
import { getFormatedDate } from "../../../../../helpers/helper";
import DateRangeFilterContainer from "./DateRangeFilterContainer/DateRangeFilterContainer";

interface ListFilterProps {
  initialFilterObject?: ObjectType;
  // filters: FiltersType[];
  filters: any;
  applyFilter?: (value: boolean) => void;
  onFiltersSelected?: (selectedFilters: SelectedFiltersType) => void;
  module?: string;
}
type LocationProps = {
  isDashboard: boolean;
};
function ListFilter(
  {
    initialFilterObject,
    filters,
    applyFilter,
    onFiltersSelected,
    module,
  }: ListFilterProps,
  ref?: any
) {
  const dispatch = useAppDispatch();
  const [showFilter, setShowFilter] = useState(false);
  const selectedFilter = useAppSelector(listFilterSelector);
  const currentUserInfo = useAppSelector(currentUserSelector);
  const [isInitialLoad, setInitialLoad] = useState<ObjectType>({});
  const [selectedOptions, setSelectedOptions] =
    useState<SelectedFiltersType>(selectedFilter);
  const [selectedCategory, setSelectedCategory] = useState(
    Object.keys(filters[0])[0]
  );

  const [searchValue, setSearchValue] = useState("");
  const location = usePreservedLocation();
  const locationState = location?.state as LocationProps;
  const orgID = useRef(currentUserInfo.organization_id);
  const selectedFilterData = useAppSelector(listFilterSelector);
  const [selectedFilters, setSelectedFilters] = useState<ObjectType>({});

  const [moduleDateLabel, setModuleDateLabel] = useState("");
  const [moduleDueDateLabel, setModuleDueDateLabel] = useState("");
  const [moduleItemTypeLabel, setModuleItemTypeLabel] = useState("");
  const [moduleTransactionCategory, setModuleTransactionCategory] =
    useState("");
  // const [startDate, setStartDate] = useState<Date | null>(null);
  // const [endDate, setEndDate] = useState<Date | null>(null);
  // const [prevStartDate, setPrevStartDate] = useState<Date | null>(null);
  // const [prevEndDate, setPrevEndDate] = useState<Date | null>(null);
  const [selectedDateFilter, setSelectedDateFilter] = useState<any>({});
  const [startDateErrorPresent, setStartDateErrorPresent] = useState(false);
  const [endDateErrorPresent, setEndDateErrorPresent] = useState(false);

  useEffect(() => {
    if (filters[2]?.Customers) {
      const validIdsSet = new Set(
        filters[2]?.Customers?.map((data: ObjectType) => data.id)
      );
      const filteredIds = selectedOptions?.Customers?.filter((id: number) =>
        validIdsSet.has(id)
      );
      let filterData = selectedOptions;
      filterData = { ...filterData, Customers: filteredIds };
      setSelectedOptions(filterData);
    }
  }, [filters[2]?.Customers]);

  useEffect(() => {
    if (filters[2]?.Vendors) {
      const validIdsSet = new Set(
        filters[2]?.Vendors?.map((data: ObjectType) => data.id)
      );
      const filteredIds = selectedOptions?.Vendors?.filter((id: number) =>
        validIdsSet.has(id)
      );
      let filterData = selectedOptions;
      filterData = { ...filterData, Vendors: filteredIds };
      setSelectedOptions(filterData);
    }
  }, [filters[2]?.Vendors]);

  const isFromDashboard = locationState?.isDashboard
    ? locationState.isDashboard
    : false;

  useImperativeHandle(
    ref,
    () => ({
      refreshFilter: refreshFilter,
      initializeFilters: initializeFilters,
      makeInitialLoad: makeInitialLoad,
    }),
    []
  );

  useEffect(() => {
    if (!Object.keys(selectedFilterData).length) {
      initializeFilters();
    }
  }, []);

  useEffect(() => {
    if (orgID.current !== currentUserInfo.organization_id) {
      initializeFilters();
    }
  }, [currentUserInfo.organization_id]);

  useEffect(() => {
    setSelectedOptions(selectedFilter);
  }, [selectedFilter]);

  useEffect(() => {
    makeInitialLoad();
  }, [currentUserInfo.organization_id]);

  useEffect(() => {
    handleFiltersSelected(selectedOptions);
  }, [selectedOptions]);

  useEffect(() => {
    setSelectedCategory(Object.keys(filters[0])[0]);
  }, [showFilter]);

  /**
   * Reset filters if organization switches to new org
   */
  useEffect(() => {
    return () => {
      if (
        sessionStorage.getItem("orgId") &&
        Number(sessionStorage.getItem("orgId")) !==
          currentUserInfo.organization_id
      ) {
        initializeFilters();
      }
    };
  }, [currentUserInfo.organization_id]);

  useEffect(() => {
    document.body.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.body.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    document.body.addEventListener("keydown", handleClickOutside);
    return () => {
      document.body.removeEventListener("keydown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    switch (module) {
      case "Invoices":
        setModuleDateLabel("Invoice Date");
        setModuleDueDateLabel("Due Date");
        break;
      case "RI":
        setModuleDateLabel("Next Invoice Date");
        setModuleDueDateLabel("End Date");
        break;
      case "Bills":
        setModuleDateLabel("Bill Date");
        setModuleDueDateLabel("Due Date");
        break;
      case "DebitNote":
        setModuleDateLabel("Debit Note Date");
        break;
      case "CreditNote":
        setModuleDateLabel("Credit Note Date");
        break;
      case "Items":
        setModuleItemTypeLabel("Item Type");
        setModuleTransactionCategory("Transaction Category");
        break;
      case "PaymentReceived":
      case "PaymentMade":
        setModuleDateLabel("Payment Date");
        break;
      case "JournalEntry":
        setModuleDateLabel("Date");
        break;
      default:
        setModuleDateLabel("Date");
    }
  }, [module]);

  const refreshFilter = () => {
    initializeFilters();
    makeInitialLoad();
  };

  const makeInitialLoad = () => {
    let updatedObject: ObjectType = {};
    filters.map((category: any) => {
      updatedObject = { ...updatedObject, [Object.keys(category)[0]]: true };
    });
    setInitialLoad(updatedObject);
  };

  const initializeFilters = () => {
    applyFilter && applyFilter(false);
    if (initialFilterObject) {
      let filterData = initialFilterObject;
      Object.keys(selectedFilters).map((filter) => {
        filterData = {
          ...filterData,
          [Object.keys(filter)[0]]: Object.values(filter)[0],
        };
      });
      dispatch(setListFilters(filterData));
      setSelectedDateFilter({});
      setStartDateErrorPresent(false);
      setEndDateErrorPresent(false);
      switch (module) {
        case "Invoices":
        case "Bills":
        case "RI":
          dispatch(setDateListFilter({ date: { startDate: "", endDate: "" } }));
          dispatch(
            setDueDateListFilter({ dueDate: { startDate: "", endDate: "" } })
          );
          break;
        case "CreditNote":
        case "DebitNote":
        case "PaymentReceived":
        case "PaymentMade":
        case "JournalEntry":
          dispatch(setDateListFilter({ date: { startDate: "", endDate: "" } }));
          break;
        default:
          dispatch(setDateListFilter({ date: { startDate: "", endDate: "" } }));
      }
    }
  };

  function handleClickOutside(event: MouseEvent | KeyboardEvent) {
    const target = event.target as HTMLElement;
    const filterMenu = document.getElementById("filter-menu");
    if (filterMenu && !filterMenu.contains(target)) {
      const isCalendarClicked = target.closest(".custom-calendar-class");
      if (!isCalendarClicked) {
        setShowFilter(false);
      }
    }
  }
  const startDateErrorStateHandler = (isError: boolean) => {
    setStartDateErrorPresent(isError);
  };
  const endDateErrorStateHandler = (isError: boolean) => {
    setEndDateErrorPresent(isError);
  };
  function handleFilterSelection(
    category: string,
    filterId: number,
    e: any,
    index: number
  ) {
    let tempFilters: ObjectType[] | any = Object.values(filters[index])[0];
    let tempFilterIds = tempFilters
      .filter((filtr: ObjectType) => filtr.id !== filterId)
      .map((fil: any) => fil.id);

    if (!e.currentTarget.checked && isInitialLoad[category]) {
      setInitialLoad({ ...isInitialLoad, [category]: false });
      setSelectedOptions({ ...selectedOptions, [category]: tempFilterIds });
    } else {
      const updatedFilters = JSON.parse(JSON.stringify(selectedOptions));
      let categoryIndex = Object.keys(updatedFilters).findIndex(
        (filter) => filter === category
      );
      if (categoryIndex !== -1) {
        const selectedCategory = category;
        const categoryFilters = updatedFilters[selectedCategory];
        const filterIndex = categoryFilters.indexOf(filterId);
        if (filterIndex !== -1) {
          categoryFilters.splice(filterIndex, 1);
        } else {
          categoryFilters.push(filterId);
        }
      } else {
        updatedFilters[category]?.push(filterId);
      }
      setSelectedOptions({ ...updatedFilters });
    }
  }

  function handleAllFilterSelection(category: string, checked: boolean) {
    setInitialLoad({ ...isInitialLoad, [category]: false });
    const updatedFilters = JSON.parse(JSON.stringify(selectedOptions));
    const categoryIndex = filters.findIndex(
      (filter: any) => Object.keys(filter)[0] === category
    );
    if (categoryIndex !== -1) {
      const selectedCategory: string = category;
      let categoryFilters = updatedFilters[selectedCategory];
      if (checked) {
        // Select all filters in the category
        const allFilterIds = filters[categoryIndex][selectedCategory].map(
          (option: any) => option.id
        );
        updatedFilters[selectedCategory] = allFilterIds;
      } else {
        // Deselect all filters in the category
        if (categoryFilters) {
          updatedFilters[selectedCategory] = [];
        }
      }
    }
    setSelectedOptions({ ...updatedFilters });
  }

  function checkIsFilterEmpty(obj: ObjectType): boolean {
    for (let key in obj) {
      if (Array.isArray(obj[key]) && obj[key].length > 0) {
        return false;
      }
    }
    return true;
  }
  const handleSearch = (e: any) => {
    setSearchValue(e.target.value);
  };

  function handleSaveFilters() {
    const updatedObject: ObjectType = {};
    let defaultApplyFilter: ObjectType = {};
    let defaultPayloadFilter: ObjectType = {};

    if (!startDateErrorPresent && !endDateErrorPresent) {
      switch (module) {
        case "Invoices":
        case "Bills":
        case "RI":
          dispatch(
            setDateListFilter({
              date: {
                startDate: selectedDateFilter["date"]?.startDate
                  ? getFormatedDate(
                      new Date(selectedDateFilter["date"]?.startDate)
                    )
                  : "",
                endDate: selectedDateFilter["date"]?.endDate
                  ? getFormatedDate(
                      new Date(selectedDateFilter["date"]?.endDate)
                    )
                  : "",
              },
            })
          );
          dispatch(
            setDueDateListFilter({
              dueDate: {
                startDate: selectedDateFilter["dueDate"]?.startDate
                  ? getFormatedDate(
                      new Date(selectedDateFilter["dueDate"]?.startDate)
                    )
                  : "",
                endDate: selectedDateFilter["dueDate"]?.endDate
                  ? getFormatedDate(
                      new Date(selectedDateFilter["dueDate"]?.endDate)
                    )
                  : "",
              },
            })
          );
          break;
        case "DebitNote":
        case "CreditNote":
        case "PaymentReceived":
        case "PaymentMade":
        case "JournalEntry":
          dispatch(
            setDateListFilter({
              date: {
                startDate: selectedDateFilter["date"]?.startDate
                  ? getFormatedDate(
                      new Date(selectedDateFilter["date"]?.startDate)
                    )
                  : "",
                endDate: selectedDateFilter["date"]?.endDate
                  ? getFormatedDate(
                      new Date(selectedDateFilter["date"]?.endDate)
                    )
                  : "",
              },
            })
          );
          break;
        default:
          dispatch(
            setDateListFilter({
              date: {
                startDate: selectedDateFilter["date"]?.startDate
                  ? getFormatedDate(
                      new Date(selectedDateFilter["date"]?.startDate)
                    )
                  : "",
                endDate: selectedDateFilter["date"]?.endDate
                  ? getFormatedDate(
                      new Date(selectedDateFilter["date"]?.endDate)
                    )
                  : "",
              },
            })
          );
      }

      filters.map((filter: any) => {
        if (
          Object.keys(filter)[0] !== "date" &&
          Object.keys(filter)[0] !== "dueDate"
        ) {
          defaultApplyFilter = {
            ...defaultApplyFilter,
            [Object.keys(filter)[0]]: Object.values(
              filter as ObjectType
            )[0].map((filter: ObjectType) => filter.id),
          };
        }
      });
      for (const key in isInitialLoad) {
        if (isInitialLoad.hasOwnProperty(key)) {
          if (isInitialLoad[key] === true) {
            defaultPayloadFilter = {
              ...defaultPayloadFilter,
              [key]: defaultApplyFilter[key],
            };
          } else {
            defaultPayloadFilter = {
              ...defaultPayloadFilter,
              [key]: selectedOptions[key],
            };
          }
        }
      }
      for (const key in isInitialLoad) {
        if (isInitialLoad.hasOwnProperty(key)) {
          updatedObject[key] = false;
        }
      }
      setInitialLoad({ ...updatedObject });
      dispatch(setListFilters(defaultPayloadFilter));
      dispatch(setIsFilterApplied(true));
      setShowFilter(false);
      // isFilterApplied && isFilterApplied();
      applyFilter && applyFilter(true);
    }
  }
  /**
   * handling filter selection from ListFilter
   */
  const handleFiltersSelected = (selectedFilters: any) => {
    setSelectedFilters(selectedFilters);
    if (onFiltersSelected) onFiltersSelected(selectedFilters);
  };

  function handleResetFilters() {
    const updatedObject: ObjectType = {};

    for (const key in isInitialLoad) {
      if (isInitialLoad.hasOwnProperty(key)) {
        updatedObject[key] = true;
      }
    }
    setInitialLoad({ ...updatedObject });
    initializeFilters();
    setSelectedDateFilter({});
    setStartDateErrorPresent(false);
    setEndDateErrorPresent(false);
    switch (module) {
      case "Invoices":
      case "Bills":
      case "RI":
        dispatch(setDateListFilter({ date: { startDate: "", endDate: "" } }));
        dispatch(
          setDueDateListFilter({ dueDate: { startDate: "", endDate: "" } })
        );
        break;
      case "DebitNote":
      case "CreditNote":
      case "PaymentReceived":
      case "PaymentMade":
      case "JournalEntry":
        dispatch(setDateListFilter({ date: { startDate: "", endDate: "" } }));
        break;
      default:
        dispatch(setDateListFilter({ date: { startDate: "", endDate: "" } }));
    }
    setShowFilter(false);
  }
  // const setDateFilterSelectState = (date: Date | null, dateType: string) => {
  //   date
  //     ? setSelectedDateFilter((prevState: any) => ({
  //         ...prevState,
  //         [selectedCategory]: {
  //           ...prevState[selectedCategory],
  //           ...(dateType === "startDate"
  //             ? { startDate: date }
  //             : { endDate: date }),
  //         },
  //       }))
  //     : setSelectedDateFilter({});
  // };
  const setDateFilterSelectState = (date: Date | null, dateType: string) => {
    setSelectedDateFilter((prevState: any) => {
      const category = selectedCategory;
      if (date) {
        return {
          ...prevState,
          [category]: {
            ...prevState[category],
            ...(dateType === "startDate"
              ? { startDate: date }
              : { endDate: date }),
          },
        };
      } else {
        const updatedCategory = { ...prevState[category] };
        if (dateType === "startDate") {
          delete updatedCategory.startDate;
        } else {
          delete updatedCategory.endDate;
        }
        return {
          ...prevState,
          [category]: updatedCategory,
        };
      }
    });
  };

  const clearDateFieldsForCategory = (
    category: string,
    clearStartDate: boolean,
    clearEndDate: boolean
  ) => {
    setSelectedDateFilter((prevState: any) => {
      const updatedCategory = {
        ...prevState[category],
        ...(clearStartDate ? { startDate: undefined } : {}),
        ...(clearEndDate ? { endDate: undefined } : {}),
      };

      if (
        updatedCategory.startDate === undefined &&
        updatedCategory.endDate === undefined
      ) {
        const { [category]: _, ...rest } = prevState;
        return rest;
      } else {
        return {
          ...prevState,
          [category]: updatedCategory,
        };
      }
    });
  };

  return (
    <div className="filter-componet-wrapper position-relative">
      <button
        type="button"
        id="filterList-btn"
        className={`filter-btn ${
          !checkIsFilterEmpty(selectedFilter) ? "open" : ""
        }`}
        aria-haspopup="true"
        aria-controls="filter-menu"
        aria-expanded={showFilter}
        onClick={() => {
          setShowFilter(!showFilter);
          setSearchValue("");
        }}
      >
        <img src={RolesListImg} alt="Filter" width={16} height={16} />
        <span>Filter</span>
      </button>
      {showFilter && (
        <div
          className="filter-menu position-absolute"
          id="filter-menu"
          aria-labelledby="filterList-btn"
          aria-hidden="false"
        >
          <div className="d-flex tab-wrapper">
            <div className="tab-grid d-flex w-100">
              <div className="tab-container">
                <ul
                  className="nav nav-tabs vertical-tab p-0 m-0"
                  id="filterTab"
                  role="tablist"
                >
                  {filters.map((filter: any, index: number) => {
                    const category = Object.keys(filter)[0];
                    return (
                      <FilterCategory
                        category={category}
                        categoryLabel={
                          category === "date"
                            ? moduleDateLabel
                            : category === "dueDate"
                            ? moduleDueDateLabel
                            : category === "ItemType"
                            ? moduleItemTypeLabel
                            : category === "TransactionCategory"
                            ? moduleTransactionCategory
                            : category
                        }
                        categoryType={
                          category === "date"
                            ? "date"
                            : category === "dueDate"
                            ? "date"
                            : ""
                        }
                        index={index}
                        selectedCategory={selectedCategory}
                        selectedOptions={selectedOptions}
                        selectedDateFilters={selectedDateFilter}
                        onClick={() => {
                          setSelectedCategory(category);
                          setSearchValue("");
                        }}
                      />
                    );
                  })}
                </ul>
              </div>
              <div className="tab-content">
                {filters.map((filter: any, index: number) => {
                  filter = JSON.parse(JSON.stringify(filter));
                  const category = Object.keys(filter)[0];
                  const options = filter[category];
                  return (
                    <div
                      className={`tab-pane ${index === 0 ? "active" : ""}`}
                      id={category}
                      role="tabpanel"
                      aria-labelledby={`${category}-tab`}
                      key={index}
                    >
                      {category !== "date" && category !== "dueDate" ? (
                        <div className="checkbox-container w-100">
                          <div className="card-customer-header filter-search">
                            <input
                              type="text"
                              name="searchTable"
                              id="search_table"
                              placeholder="Search"
                              value={searchValue}
                              onChange={handleSearch}
                            />
                          </div>
                          {selectedOptions[category].length !== 0 &&
                          options.length > 0 ? (
                            <div className="selected-filters">
                              Selected filters
                            </div>
                          ) : (
                            ""
                          )}
                          {selectedOptions[category].length ===
                            options.length && options.length > 0 ? (
                            <FilterOption
                              label="All"
                              name={`${category}-all-check`}
                              index={index + 1}
                              onFilterChange={(e) =>
                                handleAllFilterSelection(
                                  category,
                                  e.target.checked
                                )
                              }
                              handleChecked={
                                selectedOptions.hasOwnProperty(category) &&
                                selectedOptions[category].length ===
                                  options.length
                              }
                            />
                          ) : (
                            ""
                          )}
                          {selectedOptions[category].length !== 0 &&
                            options.length > 0 &&
                            options.filter((option: any) =>
                              selectedOptions[category].includes(option.id)
                            ).length &&
                            options
                              .filter((option: any) =>
                                selectedOptions[category].includes(option.id)
                              )
                              .filter((option: any) =>
                                option.name
                                  .toLowerCase()
                                  .includes(searchValue.toLowerCase())
                              )
                              .map((option: any) => (
                                <FilterOption
                                  label={option.name}
                                  name={`${category}-${option.id}-check`}
                                  index={index + option.id + 2}
                                  onFilterChange={(e) =>
                                    handleFilterSelection(
                                      category,
                                      option.id,
                                      e,
                                      index
                                    )
                                  }
                                  handleChecked={
                                    selectedOptions.hasOwnProperty(category) &&
                                    selectedOptions[category].includes(
                                      option.id
                                    )
                                  }
                                />
                              ))}
                          {selectedOptions[category].length !== 0 &&
                          options.length > 0 ? (
                            <Divider />
                          ) : (
                            ""
                          )}
                          {selectedOptions[category].length !==
                            options.length && options.length > 0 ? (
                            <FilterOption
                              label="All"
                              name={`${category}-all-check`}
                              index={index + 1}
                              onFilterChange={(e) =>
                                handleAllFilterSelection(
                                  category,
                                  e.target.checked
                                )
                              }
                              handleChecked={
                                (selectedOptions.hasOwnProperty(category) &&
                                  selectedOptions[category].length ===
                                    options.length) ||
                                (isInitialLoad[category] && !isFromDashboard)
                              }
                            />
                          ) : (
                            ""
                          )}
                          {options.length > 0
                            ? options
                                .filter(
                                  (option: any) =>
                                    !selectedOptions[category].includes(
                                      option.id
                                    )
                                )
                                .filter((option: any) =>
                                  option.name
                                    .toLowerCase()
                                    .includes(searchValue.toLowerCase())
                                )
                                .map((option: any) => (
                                  <FilterOption
                                    label={option.name}
                                    name={`${category}-${option.id}-check`}
                                    index={index + option.id + 2}
                                    onFilterChange={(e) =>
                                      handleFilterSelection(
                                        category,
                                        option.id,
                                        e,
                                        index
                                      )
                                    }
                                    handleChecked={
                                      (selectedOptions.hasOwnProperty(
                                        category
                                      ) &&
                                        selectedOptions[category].includes(
                                          option.id
                                        )) ||
                                      (isInitialLoad[category] &&
                                        !isFromDashboard)
                                    }
                                  />
                                ))
                            : null}
                        </div>
                      ) : category === "date" || category === "dueDate" ? (
                        <>
                          <DateRangeFilterContainer
                            module={module}
                            category={category}
                            setDateSelected={setDateFilterSelectState}
                            clearDateFields={clearDateFieldsForCategory}
                            options={options ? options : ""}
                            handleStartDateErrorFlag={
                              startDateErrorStateHandler
                            }
                            handleEndDateErrorFlag={endDateErrorStateHandler}
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="menu-footer">
            <button
              id="reset-filter"
              className="reset-filter"
              tabIndex={filters.length + 1}
              onClick={handleResetFilters}
            >
              Reset filter
            </button>
            <div className="filter-button-wrapper">
              <button
                type="button"
                className="cancel-button"
                tabIndex={filters.length + 2}
                onClick={() => {
                  setShowFilter(false);
                  setSelectedOptions(selectedFilter);
                  setSelectedDateFilter({});
                  setStartDateErrorPresent(false);
                  setEndDateErrorPresent(false);
                  switch (module) {
                    case "Invoices":
                    case "Bills":
                    case "RI":
                      dispatch(
                        setDateListFilter({
                          date: { startDate: "", endDate: "" },
                        })
                      );
                      dispatch(
                        setDueDateListFilter({
                          dueDate: { startDate: "", endDate: "" },
                        })
                      );
                      break;
                    case "DebitNote":
                    case "CreditNote":
                    case "PaymentReceived":
                    case "PaymentMade":
                    case "JournalEntry":
                      dispatch(
                        setDateListFilter({
                          date: { startDate: "", endDate: "" },
                        })
                      );
                      break;
                    default:
                      dispatch(
                        setDateListFilter({
                          date: { startDate: "", endDate: "" },
                        })
                      );
                  }
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="save-btn form-btn"
                tabIndex={filters.length + 3}
                onClick={handleSaveFilters}
                disabled={
                  startDateErrorPresent || endDateErrorPresent ? true : false
                }
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default forwardRef(ListFilter);
