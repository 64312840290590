import React, { useEffect, useState } from "react";
import {
  FiltersType,
  ObjectType,
  OrgListObjectType,
  SelectedFiltersType,
} from "../../../../../types";
import { getModuleStatusFilter } from "../../../../../helpers/statusFilterHelper";
import { useAppSelector } from "../../../../../app/hooks";
import {
  getOrganizationListForReport,
  reportOrgSelector,
  dateListFilterSelector,
  dueDateListFilterSelector,
} from "../../../commonSlice";
import useCommonData from "../../../../hooks/useCommon";
import { customerFilterList } from "../../../../home/customerManagement/customerSlice";
import { listFilterSelector, setListFilters } from "../../../../../appSlice";
import { vendorFilterList } from "../../../../home/vendorManagement/vendorSlice";
import { itemType, transactionCategory } from "../../../../constants/constants";

function useFilterData(props: any) {
  const { dispatch, currentUserInfo } = useCommonData();
  const orgList = useAppSelector(reportOrgSelector);
  const selectedFilterData = useAppSelector(listFilterSelector);
  const dateListFilter = useAppSelector(dateListFilterSelector);
  const dueDateListFilter = useAppSelector(dueDateListFilterSelector);
  const [orgListFilter, setOrgListFilter] = useState<OrgListObjectType[]>([]);
  const [customerFilterArray, setCustomerFilterArray] = useState<
    OrgListObjectType[]
  >([]);
  const [vendorFilterArray, setVendorFilterArray] = useState<
    OrgListObjectType[]
  >([]);
  const [selectedFilters, setSelectedFilters] = useState<ObjectType>({});
  const [dateFilter, setDateFilter] = useState({
    date: { startDate: "", endDate: "" },
  });
  const [dueDateFilter, setDueDateFilter] = useState({
    dueDate: { startDate: "", endDate: "" },
  });

  useEffect(() => {
    if (props.haveFilter) getOrgList();
  }, [currentUserInfo.organization_id]);

  useEffect(() => {
    getOrgListFilter();
  }, [orgList]);

  useEffect(() => {
    setSelectedFilters({ ...selectedFilterData });
  }, [selectedFilterData]);

  useEffect(() => {
    if (
      props.type === "Invoices" ||
      props.type === "CN" ||
      props.type === "PR" ||
      props.type === "RI" ||
      props.type === "Subscription" ||
      props.type === "CalenderInvoice"
    )
      getCustomerFilterList();
    if (props.type === "Bills" || props.type === "DN" || props.type === "PM")
      getVendorsFilterList();
  }, [selectedFilters?.Organizations, currentUserInfo.organization_id]);

  useEffect(() => {
    setDateFilter(dateListFilter);
  }, [dateListFilter]);
  useEffect(() => {
    setDueDateFilter(dueDateListFilter);
  }, [dueDateListFilter]);

  const initialFilterObject = (): ObjectType => {
    switch (props.type) {
      case "Invoices":
        return {
          Organizations: [],
          Status: [],
          Customers: [],
          date: {},
          DueDate: {},
        };
        break;
      case "CN":
      case "PR":
        return {
          Organizations: [],
          Status: [],
          Customers: [],
          date: {},
        };
        break;
      case "RI":
        return {
          Organizations: [],
          Status: [],
          Customers: [],
          date: {},
          DueDate: {},
        };
        break;
      case "Bills":
        return {
          Organizations: [],
          Status: [],
          Vendors: [],
          date: {},
          DueDate: {},
        };
        break;
      case "DN":
      case "PM":
        return {
          Organizations: [],
          Status: [],
          Vendors: [],
          date: {},
        };
        break;
      case "JV":
        return {
          Organizations: [],
          Status: [],
          date: {},
        };
        break;
      case "Plan":
      case "ADDON":
        return {
          Organizations: [],
          Status: [],
        };
        break;
      case "Items":
        return {
          Status: [],
          ItemType: [],
          TransactionCategory: [],
        };
        break;
      case "Subscription":
        return {
          Organizations: [],
          Status: [],
          Customers: [],
          Frequency: [],
        };
        break;
      case "CalenderInvoice":
        return {
          Frequency: [],
        };
        break;
      default:
        return { Organizations: [] };
        break;
    }
  };
  /**
   * Setting filter options for the ListFilter Component
   */
  const filters = (): any[] => {
    switch (props.type) {
      case "Invoices":
        return [
          { Organizations: orgListFilter },
          { Status: getModuleStatusFilter(props.type) },
          { Customers: customerFilterArray },
          dateFilter,
          dueDateFilter,
        ];
        break;
      case "CN":
      case "PR":
        return [
          { Organizations: orgListFilter },
          { Status: getModuleStatusFilter(props.type) },
          { Customers: customerFilterArray },
          dateFilter,
        ];
        break;
      case "RI":
        return [
          { Organizations: orgListFilter },
          { Status: getModuleStatusFilter(props.type) },
          { Customers: customerFilterArray },
          dateFilter,
          dueDateFilter,
        ];
        break;
      case "Bills":
        return [
          { Organizations: orgListFilter },
          { Status: getModuleStatusFilter(props.type) },
          { Vendors: vendorFilterArray },
          dateFilter,
          dueDateFilter,
        ];
        break;
      case "DN":
      case "PM":
        return [
          { Organizations: orgListFilter },
          { Status: getModuleStatusFilter(props.type) },
          { Vendors: vendorFilterArray },
          dateFilter,
        ];
        break;
      case "JV":
        return [
          { Organizations: orgListFilter },
          { Status: getModuleStatusFilter(props.type) },
          dateFilter,
        ];
        break;
      case "Plan":
      case "ADDON":
        return [
          { Organizations: orgListFilter },
          { Status: getModuleStatusFilter(props.type) },
        ];
        break;
      case "Items":
        return [
          // { Organizations: orgListFilter },
          { ItemType: itemType },
          { Status: getModuleStatusFilter(props.type) },
          { TransactionCategory: transactionCategory },
        ];
        break;
      case "Subscription":
        return [
          { Organizations: orgListFilter },
          { Status: getModuleStatusFilter(props.type) },
          { Customers: customerFilterArray },
          {
            Frequency: [
              { name: "Daily", id: 1 },
              { name: "Weekly", id: 2 },
              { name: "Monthly", id: 3 },
              { name: "Yearly", id: 4 },
            ],
          },
        ];
        break;
        case "CalenderInvoice":
          return [
            {
              Frequency: [
                { name: "Weekly", id: 1 },
                { name: "Monthly", id: 2 },
                { name: "Yearly", id: 3 },
              ],
            },
          ];
          break;
      default:
        return [
          {
            Organizations: orgListFilter,
          },
        ];
        break;
    }
  };
  /**
   * Fetching report org list
   */
  const getOrgList = async () => {
    // if (currentOrganizationId !== 0 && isLastOrgIdNull === 0) {
    const responseAction = await dispatch(
      getOrganizationListForReport({ orgId: currentUserInfo.organization_id })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
      }
    }
    // }
  };
  /**
   * setting org list for filters
   */
  const getOrgListFilter = async () => {
    let orgsList: OrgListObjectType[] = [];
    if (orgList.length) {
      orgList.map((orgs: ObjectType) => {
        const { name, id } = orgs;
        orgsList.push({ name: name, id: id });
      });
    }
    setOrgListFilter(orgsList);
  };

  /**
   * fetching Customer list according to the selected orgs for filters
   */
  const getCustomerFilterList = async () => {
    const responseAction = await dispatch(
      customerFilterList({
        orgId: currentUserInfo.organization_id,
        organizationIds: selectedFilters?.Organizations,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setCustomerFilterArray(response);
      } else {
        setCustomerFilterArray([]);
      }
    }
  };
  /**
   * fetching Vendor list according to the selected orgs for filters
   */
  const getVendorsFilterList = async () => {
    const responseAction = await dispatch(
      vendorFilterList({
        orgId: currentUserInfo.organization_id,
        organizationIds: selectedFilters?.Organizations,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setVendorFilterArray(response);
      } else {
        setVendorFilterArray([]);
      }
    }
  };

  const filterChange = (selectedFilters: SelectedFiltersType) => {
    setSelectedFilters(selectedFilters);
  };

  return {
    initialFilterObject,
    filters,
    filterChange,
  };
}

export default useFilterData;
