import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import "./ChooseTemplate.css";

type Props = {
  handlePreviewClose: () => void;
  open: boolean;
};

const ChooseTemplatePreview: React.FC<Props> = ({
  handlePreviewClose,
  open,
}) => {
  const [openFlag, setOpenFlag] = useState<Boolean>(true);
  useEffect(() => {
    setOpenFlag(open);
  }, []);

  const closeModal = () => {
    setOpenFlag(false);
  };

  return (
    <Modal
      open={true}
      onClose={handlePreviewClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      id="customiseView"
    >
      <div className="modal-dialog modal-dialog-centered custom-preview-modal">
        <div className="modal-content ">
          <div className="modal-header">
            <h5 className="modal-title" id="previewTemplateModalLabel">
              Standard Template
            </h5>
          </div>
          <div className="modal-body">
            <div id="invoice">
              <div className="invoice-header d-flex justify-content-between">
                <div className="company-details">
                  <div className="company-logo"></div>
                  <div className="company-address">
                    <span className="company-name">Company Name</span>
                    <span>44, Retiwala Premises, S V Rd, </span>
                    <br />
                    <span>Opp Khira Nagar,Mumbai</span>
                    <br />
                    <span>Maharashtra 600018, India</span>
                    <br />
                    <span>Email - support@bigbinary.com</span>
                    <br />
                  </div>
                </div>
                <div className="invoice-details">
                  <div className="label-invoice">Invoice</div>
                  <div className="invoice-number-label">Invoice Number</div>
                  <div className="invoice-number">INV-9432</div>
                  <div className="invoice-number-label">Balance Due</div>
                  <div className="invoice-number">$0.00</div>
                </div>
              </div>
              <div className="bill-details-section d-flex justify-content-between">
                <div className="billed-to">
                  <span className="bill-details-header">Billed To</span>
                  <div className="company-address pt-2">
                    <span>Snyder Corportaion</span>
                    <br />
                    <span>Moti Bhavan, Dr D Silva Road, Dadar(w)</span>
                    <br />
                    <span>Mumbai, Maharashtra</span>
                    <br />
                    <span>India - 400028</span>
                    <br />
                  </div>
                </div>
                <div className="ship-to">
                  <span className="bill-details-header">Ship To</span>
                  <div className="company-address pt-2">
                    <span>Snyder Corportaion</span>
                    <br />
                    <span>Moti Bhavan, Dr D Silva Road, Dadar(w)</span>
                    <br />
                    <span>Mumbai, Maharashtra</span>
                    <br />
                    <span>India - 400028</span>
                    <br />
                  </div>
                </div>
                <div className="inv-date-wrap">
                  <div className="inv-date">
                    <span className="bill-details-header">Invoice Date</span>
                    <div>13-01-2022</div>
                  </div>
                  <div className="inv-date">
                    <span className="bill-details-header">Due Date</span>
                    <div>13-01-2022</div>
                  </div>
                </div>
                <div className="payment-terms">
                  <span className="bill-details-header">Payment Terms</span>
                  <div>Due on Receipt</div>
                </div>
              </div>

              <div className="table-section">
                <table className="table mb-0">
                  <thead>
                    <tr>
                      <th scope="col">Description</th>
                      <th scope="col">hsn/sac code</th>
                      <th scope="col">quantity</th>
                      <th scope="col">RATE</th>
                      <th scope="col">Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Item number #1</th>
                      <td>568902</td>
                      <td>200</td>
                      <td>$20 / Unit</td>
                      <td>$300</td>
                    </tr>
                    <tr>
                      <th scope="row">Item number #2</th>
                      <td>568902</td>
                      <td>200</td>
                      <td>$20 / Unit</td>
                      <td>$300</td>
                    </tr>
                    <tr>
                      <th scope="row">Item number #3</th>
                      <td>568902</td>
                      <td>200</td>
                      <td>$20 / Unit</td>
                      <td>$300</td>
                    </tr>
                  </tbody>
                </table>
                <div className="d-flex justify-content-between pt-3 mt-1 w-100 table-bottom-sect-wrap">
                  <div className="thankyou-note w-50 invisible d-flex align-items-end">
                    Thank you for your business
                  </div>
                  <div className="w-75 right-content amt-calculation-portion">
                    <div className="calc-section w-75 me-auto">
                      <div className="subtotal-section pb-3">
                        <div>Subtotal</div>
                        <div>820.00</div>
                      </div>
                      <div className="discount-section pb-3">
                        <div>Discount</div>
                        <div>$20</div>
                      </div>
                      <div className="tax-section pb-3">
                        <div>Tax</div>
                        <div>8%</div>
                      </div>
                    </div>
                    <div className="total-calc w-75 me-auto">
                      <div className="total-section">
                        <div>Total</div>
                        <div>$920.00</div>
                      </div>
                      {/* <div className="payment-made-section pb-3">
                        <div>Payment made</div>
                        <div>(-)$960.00</div>
                      </div> */}
                    </div>

                    <div className="total-calc mb-0 w-75 me-auto">
                      <div className="total-section bal-due-section">
                        <div>Balance Due</div>
                        <div>$0.00</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer w-100 justify-content-start border-top-0">
            <button
              type="button"
              className="btn choose-button save-button m-0"
              data-bs-dismiss="modal"
            >
              Choose Template
            </button>
            <button
              type="button"
              className="btn cancel-button m-0"
              onClick={closeModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ChooseTemplatePreview;
