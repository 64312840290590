import { useState } from "react";
import { AddCouponList } from "./AddCouponList";
import useCommonData from "../../../../../hooks/useCommon";
import { AddCouponFormInitialValueType } from "../../../subscriptionType";
import { addCouponFormInitialValue } from "../../../SubscriptionStateInitialization";
import { FormInputField } from "../../../../../common/components";
import { subscriptionCouponSearchList } from "../../../subscriptionSlice";
import { ModalContext } from "../../../../../common/components/sideModal/sideModalContext";
import { SideModal } from "../../../../../common/components/sideModal/SideModal";
import { useFormik } from "formik";
import { setLoaderState } from "../../../../../common/commonSlice";
import "./addLineCoupon.css";
type Props = {
  planFrequency: string;
  onRowClick: (addedCoupon: any) => void;
  selectedCouponList: number[];
  currencyId: number | undefined;
  conversionId: number | undefined;
  isDiscountTypePercentage: boolean | undefined
};
export const AddLineCoupon = (props: Props) => {
  const { dispatch, currentUserInfo, orgCurrencyList } = useCommonData();
  const [isMenuListOpen, setIsMenuListOpen] = useState<boolean>(false);
  const formik = useFormik({
    initialValues: {
      ...addCouponFormInitialValue,
    } as AddCouponFormInitialValueType,
    enableReinitialize: true,
    onSubmit: () => {
      formik.resetForm();
    },
  });
  const fetchCouponList = async (
    searchTerm: string,
    count: number,
    triggerAction: string
  ) => {

    if (props.selectedCouponList.length < 2) {
      if (count && count <= 1) dispatch(setLoaderState(true));
      const responseAction = await dispatch(
        subscriptionCouponSearchList({
          page: count,
          itemsPerPage: 25,
          orgId: currentUserInfo.organization_id,
          couponIds: props.selectedCouponList,
          searchTerm,
          currencyId: props.currencyId ? props.currencyId : 0,
          conversionId: props.conversionId ? props.conversionId : 0,
          discountType: props.isDiscountTypePercentage ? "ABSOLUTE" : props.selectedCouponList.length === 0 ? '' : "PERCENTAGE"
        })
      );
  
      if (responseAction.payload) {
        setTimeout(function () {
          dispatch(setLoaderState(false));
        }, 500);
        const response = responseAction.payload;
        if (Object.keys(response).length && !("error" in response)) {
          if (triggerAction === "scroll") {
            formik.setFieldValue(
              "couponList",
              formik.values.couponList.concat(response.coupons)
            );
          } else formik.setFieldValue("couponList", response.coupons);
  
          formik.setFieldValue(
            "isNextEnabled",
            !(response.pagination.next === null)
          );
          if (response.pagination.next !== null) {
            const regex = new RegExp(`page_num=(\\d+)`);
            const modifiedString = response.pagination.next.match(regex);
            const count = modifiedString[1]
              ? modifiedString[1]
              : formik.values.count;
            formik.setFieldValue("count", count);
          }
        } else if ("error" in response) {
        }
      }
    }
  };

  const onCouponSearch = (event: any) => {
    formik.setFieldValue("searchTerm", event.currentTarget.value);
    if (
      event.currentTarget.value.length >= 3 ||
      event.currentTarget.value === ""
    ) {
      fetchCouponList(event.currentTarget.value, 1, "");
      formik.setFieldValue("count", 1);
    }
  };
  const ConfigureButton = () => {
    const handleButtonClick = (e:any) => {
      if (props?.planFrequency) {
        fetchCouponList("", 1, "");
        setIsMenuListOpen(true);
      }
    };
    return (
      <div>
        <span
          id="subscription-add-coupon-button"
          onClick={(e) => handleButtonClick(e)}
        > 
          Select Coupons
        </span>
      </div>
    );
  };

  return (
    <SideModal
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onCloseModal } = context;
            return (
              <AddCouponList
                couponList={formik.values.couponList}
                onCouponSelect={(values: any) => {
                  props.onRowClick(values);
                  formik.submitForm();
                  onCloseModal();
                  setIsMenuListOpen(false);
                }}
                onMenuScrollToBottom={() => {
                  if (formik.values.isNextEnabled) {
                    fetchCouponList(
                      formik.values.searchTerm,
                      formik.values.count,
                      "scroll"
                    );
                  }
                }}
                searchTerm={formik.values.searchTerm}
                isMenuListOpen={isMenuListOpen}
                count={formik.values.count}
              />
            );
          }}
        </ModalContext.Consumer>
      }
      onCloseFunction={() => {
        formik.submitForm();
      }}
      isAddIconEnabled={true}
      modalTitle="Add Coupon"
      isButtonDisabled={props?.planFrequency ? false : true}
      buttonContent={<ConfigureButton />}
      drawerWrapperClass="subscription-add-coupon"
      wrapperClass="subscription-add-coupon-button w-100 add-line d-flex"
      buttonClass={`add-coupon-button add-tags-button ${
        props?.planFrequency ? "" : "add-detail-disabled"
      }`}
      modalTitleComponent={
        <FormInputField
          type="text"
          name=""
          id="ref_no"
          className="form-control w-325 m-t-20"
          wrapperClass="coupon-search-field"
          label=""
          placeholder="Search Coupon"
          maxLength={16}
          value={formik.values.searchTerm}
          onChange={onCouponSearch}
          error={""}
        />
      }
    />
  );
};
