import { Api } from "../../../constants";
import {
  get,
  post,
  postFormData,
  put,
  remove,
} from "../../../helpers/apiHelper";
import {
  TaxableAmountCalculationValues,
  TotalAmountCalculationValues,
  CreditNoteValues,
  CreditNoteAllocation,
} from "../../../types";
/**
 * API call for getting all credit notes.
 */
export async function getCreditNoteList(data: {
  page: number;
  itemsPerPage: number;
  dateSortOrder: string;
  orgId: number;
  customerList: number[];
  orgIds: number[];
  statusList: string[];
  invoiceId?: number | null;
  startDate?: string;
  endDate?: string;
  startDueDate?: string;
  endDueDate?: string;
}) {
  let url =
    Api.creditNote +
    "credit/list" +
    `?page_num=${data.page}&page_size=${
      data.itemsPerPage
    }&current_organization=${data.orgId} ${
      data.invoiceId ? `&invoice_obj_id=${data.invoiceId}` : ""
    }${data.startDate ? `&start_date=${data.startDate.trim()}` : ""}${
      data.endDate ? `&end_date=${data.endDate.trim()}` : ""
    }${data.startDueDate ? `&start_due_date=${data.startDueDate.trim()}` : ""}${
      data.endDueDate ? `&end_due_date=${data.endDueDate.trim()}` : ""
    }`.trim();
  if (data.dateSortOrder !== "") {
    url += `&date_sort_order=${data.dateSortOrder}`;
  }
  if (data.customerList.length !== 0) {
    const customerIds = data.customerList
      .map((customerId) => {
        return `&customer_ids=${customerId}`;
      })
      .join("");
    url += customerIds;
  }
  if (data.orgIds.length !== 0) {
    const orgIds = data.orgIds
      .map((orgId) => {
        return `&org_ids=${orgId}`;
      })
      .join("");
    url += orgIds;
  }
  if (data.statusList.length !== 0) {
    const statuses = data.statusList
      .map((status) => {
        return `&status_list=${status}`;
      })
      .join("");
    url += statuses;
  }
  const result = await get(url);
  return result;
}
/**
 * API call for deleting credit note
 */
export async function deleteCreditnote(data: {
  creditNoteId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.creditNote +
      "credit/delete" +
      `/${data.creditNoteId}?current_organization=${data.orgId}`
  );
  return result;
}
/**
 * API call for creating credit note
 */
export async function createNewCreditNote(data: {
  values: CreditNoteValues;
  status: string;
  orgId: number;
}) {
  const result = await post(
    Api.creditNote +
      `credit/create?credit_note_status=${data.status}&current_organization=${data.orgId}`,
    data.values
  );
  return result;
}
/**
 * API call for edit credit note
 */
export async function editCreditNote(data: {
  id: number;
  values: CreditNoteValues;
  status: string;
  orgId: number;
}) {
  const result = await put(
    Api.creditNote +
      "credit/edit/" +
      data.id +
      `?credit_note_status=${data.status}&current_organization=${data.orgId}`,
    data.values
  );
  return result;
}
/**
 * API call for delete credit note item
 */
export async function deleteCreditNoteItem(data: {
  creditNoteId: number;
  itemId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.creditNote +
      "credit-item/delete/" +
      data.creditNoteId +
      "/" +
      data.itemId +
      `?current_organization=${data.orgId}`
  );
  return result;
}
/**
 * API call for delete credit note item tax
 */
export async function deleteCreditNoteItemTax(data: {
  itemId: number;
  taxId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.creditNote +
      "credit-item-tax/delete/" +
      data.itemId +
      "/" +
      data.taxId +
      `?current_organization=${data.orgId}`
  );
  return result;
}
/**
 * API call for delete credit note recipients
 */
export async function deleteCreditNoteRecipient(data: {
  creditNoteId: number;
  emailIds: number[];
  orgId: number;
}) {
  const result = await remove(
    Api.creditNote +
      "credit-recipient/delete/" +
      data.creditNoteId +
      `?current_organization=${data.orgId}`,
    data.emailIds
  );
  return result;
}
/**
 * API call for get credit note number
 */
export async function getCreditNoteNumber(orgId: number) {
  const result = await get(
    Api.creditNote + `new-credit-note-number?current_organization=${orgId}`
  );
  return result;
}
/**
 * API call for checking the credit note number exist or not
 */
export async function creditNoteNumberExist(data: {
  creditNoteNumber: string;
  orgId: number;
  creditNoteId: number;
}) {
  const result = await get(
    Api.creditNote +
      "credit-note-number-exist?credit_note_number=" +
      data.creditNoteNumber +
      `&credit_note_id=${data.creditNoteId}&current_organization=${data.orgId}`
  );
  return result;
}
/**
 * API call for getting credit note detail
 */
export async function getCreditNoteDetails(data: {
  creditNoteId: number;
  orgId: number;
}) {
  const result = await get(
    Api.creditNote +
      `credit/detail/${data.creditNoteId}?current_organization=${data.orgId}`
  );
  return result;
}
/**
 * API call for calculating taxable amount
 */
export async function calculateTaxableAmount(data: {
  values: TaxableAmountCalculationValues;
  orgId: number;
  signal: Object;
}) {
  const result = await post(
    Api.creditNote +
      `credit-item/taxable-amount?current_organization=${data.orgId}`,
    data.values,
    data.signal
  );
  return result;
}
/**
 * API call for calculating credit note amount
 */
export async function calculateAmount(data: {
  values: TotalAmountCalculationValues;
  orgId: number;
  signal: Object;
}) {
  const result = await post(
    Api.creditNote +
      `credit/amount-calculation?current_organization=${data.orgId}`,
    data.values,
    data.signal
  );
  return result;
}
/**
 * Api to upload credit note attachments
 */
export async function creditNoteAttachments(data: {
  file: FormData;
  orgId: number;
}) {
  const result = await postFormData(
    Api.creditNote + `credit-attachments?current_organization=${data.orgId}`,
    data.file
  );
  return result;
}
/**
 * Api to delete credit note attachments
 */
export async function creditNoteAttachmentsDelete(data: {
  creditNoteId: number;
  fileId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.creditNote +
      `credit-attachments/${data.creditNoteId}?file_id=${data.fileId}&current_organization=${data.orgId}`
  );
  return result;
}
/**
 * Api to get credit note pdf link
 */
export async function creditNotePdfLink(data: {
  creditNoteId: number;
  orgId: number;
}) {
  const result = await get(
    Api.creditNote +
      "download-link?credit_note_id=" +
      data.creditNoteId +
      `&current_organization=${data.orgId}`
  );
  return result;
}
/**
 * API call for sending credit note to contact details
 */
export async function sendCreditNote(data: {
  creditNoteId: number;
  emails: string[];
  orgId: number;
}) {
  const result = await post(
    Api.creditNote +
      `send-credit-note?credit_note_id=${data.creditNoteId}&current_organization=${data.orgId}`,
    data.emails
  );
  return result;
}
/**
 * Api call for get allocation details
 */
export async function allocationDetails(data: {
  creditNoteId: number;
  orgId: number;
}) {
  const result = await get(
    Api.creditNote +
      `credit/allocation-detail?credit_note_id=${data.creditNoteId}&current_organization=${data.orgId}`
  );
  return result;
}
/**
 * Api for get invoices to allocate
 */
export async function invoicesToAllocate(data: {
  creditNoteId: number;
  orgId: number;
  invoiceId?: number;
}) {
  const result = await get(
    Api.creditNote +
      `credit/invoices-to-allocate?credit_note_id=${data.creditNoteId}${
        data.invoiceId ? "&invoice_id=" + data.invoiceId : ""
      }&current_organization=${data.orgId}`
  );
  return result;
}
/**
 * Api for update credit note allocation
 */
export async function updateAllocation(data: {
  creditNoteId: number;
  orgId: number;
  values: CreditNoteAllocation[];
}) {
  const result = await put(
    Api.creditNote +
      `credit/update-allocation?credit_note_id=${data.creditNoteId}&current_organization=${data.orgId}`,
    data.values
  );
  return result;
}

/**
 * Api for calculate allocation total
 */
export async function allocationCalculation(data: {
  creditNoteId: number;
  orgId: number;
  values: number[];
  signal?: AbortSignal;
}) {
  const result = await post(
    Api.creditNote +
      `allocation/calculation?credit_note_id=${data.creditNoteId}&current_organization=${data.orgId}`,
    data.values,
    data.signal
  );
  return result;
}
/**
 * Api for delete credit note allocation
 */
export async function deleteAllocation(data: {
  creditNoteId: number;
  allocationId: number;
  orgId: number;
}) {
  const result = await remove(
    Api.creditNote +
      `credit/delete-allocation/${data.creditNoteId}/${data.allocationId}?current_organization=${data.orgId}`
  );
  return result;
}
/**
 * Api to get the transaction limit status
 */
export async function transactionLimitStatus(orgId: number) {
  const result = await get(
    Api.creditNote + `transaction-limit-status?current_organization=${orgId}`
  );
  return result;
}
/**
 * Api to get the reason dropdown
 */
export async function reason(orgId: number) {
  const result = await get(
    Api.creditNote + `credit/reason?current_organization=${orgId}`
  );
  return result;
}

/**
 * Api to get journal for reporting tags
 */
export async function getReportingTagJournals(data: {
  creditNoteId: number;
  orgId: number;
}) {
  const result = await get(
    Api.creditNote +
      `reporting-tag/journal-generate?credit_note_id=${data.creditNoteId}&current_organization=${data.orgId}`
  );
  return result;
}

/**
 * Api to update reporting tag
 */
export async function putReportingTags(data: {
  creditNoteId: number;
  orgId: number;
  payload: any;
}) {
  const result = await put(
    Api.creditNote +
      `reporting-tag/edit/${data.creditNoteId}?credit_note_id=${data.creditNoteId}&current_organization=${data.orgId}`,
    data.payload
  );
  return result;
}
/**
 * Api to make credit note void
 */
export async function creditNoteVoidMarker(data: {
  creditNoteId: number | null;
  orgId: number;
}) {
  const result = await put(
    Api.creditNote +
      `credit/mark-void/${data.creditNoteId}?credit_note_id=${data.creditNoteId}&current_organization=${data.orgId}`
  );
  return result;
}
