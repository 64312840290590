import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { currentUserSelector } from "../../../../common/commonSlice";
import { organizationDetails } from "../../../organizationProfile/OrganizationProfileSlice";
import { useSubModulePermission } from "../../../../hooks/useSubModulePermissions";
import { IsFeatureEnabled } from "../../../../../helpers/featureFlagHelper";
import "./OrganizationDetail.css";
import { UpdateOrganizationProfileModal } from "./UpdateOrganizationProfileModal";

function OrganizationDetails() {
  const dispatch = useAppDispatch();
  const currentUserInfo = useAppSelector(currentUserSelector);
  const [isProfileComplete, setProfileComplete] = useState(false);
  const subModulePermission = useSubModulePermission();

  useEffect(() => {
    if (subModulePermission.includes("Organization Profile")) {
      getOrganizationProfileData();
    }
  }, [currentUserInfo.organization_id]);
  /**
   * To fetch org profile data to enable and disable org profile update link
   */

  const getOrganizationProfileData = async () => {
    // setIsLoading(true);
    const responseAction = await dispatch(
      organizationDetails(currentUserInfo.organization_id)
    );
    if (responseAction.payload) {
      // setIsLoading(false);
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        if (response["country_id"] === 1) {
          setProfileComplete(
            Object.values(response).every(
              (value) => value !== null && value !== ""
            )
          );
        } else {
          const { gst, pan_number, tan_number, ...rest } = response;
          setProfileComplete(
            Object.values(rest).every((value) => value !== null && value !== "")
          );
        }
      }
    }
  };
  return (
    <>
      {subModulePermission.includes("Organization Profile") &&
      !isProfileComplete &&
      IsFeatureEnabled(
        "Organization Profile",
        currentUserInfo.disabled_feature
      ) ? (
        <div className="note-wrapper">
          <div className="note-header pb-2">Update Organizational Details</div>
          <p className="mb-0 d-inline-block">
            Your invoice would look more authentic If you have your company logo
            and your organizational address.{" "}<UpdateOrganizationProfileModal/>
            
          </p>
        </div>
      ) : null}
    </>
  );
}

export default OrganizationDetails;
