import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../../../app/hooks";
import { setErrorID, setListFilters } from "../../../../../appSlice";
import {
  DateFormatHandler,
  getFormatedDate,
} from "../../../../../helpers/helper";
import {
  AddressValues,
  CustomerItemValues,
  FilesValues,
  ItemTaxValues,
  ObjectType,
  TCSTotalValues,
  TDSTotalValues,
  TotalAmountCalculationValues,
  TotalDetailsValues,
} from "../../../../../types";
import {
  currentUserOrgSelector,
  exchangeApiStatusSelector,
  latestCurrencyRateSelector,
  setLoaderState,
} from "../../../../common/commonSlice";
import { Card } from "../../../../common/components/htmlTags/Card/Card";
import { CardBody } from "../../../../common/components/htmlTags/Card/CardBody";
import { CardHeader } from "../../../../common/components/htmlTags/Card/CardHeader";
import { Form } from "../../../../common/components/htmlTags/Form";
import AddressModal from "../../../../common/components/AddressModal";
import DropZone from "../../../../common/components/DropZone/DropZone";
import NoteTextArea from "../../../../common/components/NoteTextArea/NoteTextArea";
import MultiEmailBox from "../../../../common/components/multiEmailComponent/EmailTo";
import {
  sourceTaxTypes,
  tdsApplyLevel,
  gstRegistrationTypes,
  noAccessErrorCodes,
} from "../../../../constants/constants";
import useCommonData from "../../../../hooks/useCommon";
import {
  addAddress,
  addressReset,
  addressSelector,
} from "../../../customerManagement/components/customerForm/AddressDetails/addressSlice";
import { customerDetailsForTransactions } from "../../../customerManagement/customerSlice";
import "./Subscription.css";
import {
  checkSubscriptionNumber,
  createSubscription,
  editSubscriptionDetails,
  getAmountCalculation,
  getNextInvoiceDate,
  getTaxableAmount,
  subscriptionEditDetails,
} from "../../subscriptionSlice";

import {
  initialTCSTotalValues,
  initialTDSTotalValues,
  initialTotalValues,
} from "../../../invoice/components/invoiceForm/StateInitialization";

import useGst from "../../../../hooks/useGst";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import { clearGstTaxList, getGstTaxList } from "../../../gst/gstSlice";
import { tdsPreferenceSelector } from "../../../tds/tdsSlice";
import "./SubscriptionForm.css";
import { fetchRoundOffPreference } from "../../../preferences/preferencesSlice";
import { validate, validateItemValues } from "./ValidateSubscriptionForm";
import SubscriptionFormFooter from "./SubscriptionFormFooter";
import { FormControlLabel, Grid, Switch } from "@mui/material";
import OrgTag from "../../../../common/components/OrgTag";
import SubscriptionBasicDetails from "./SubscriptionBasicDetails";
import SubscriptionOrganizationDetails from "./SubscriptionOrganizationDetails";
import SubscriptionAddress from "./subscriptionAddress/SubscriptionAddress";
import { NextInvoiceDateType, SubscriptionAddonDetails, SubscriptionAddressValues, SubscriptionCouponList, SubscriptionDefaultRef, SubscriptionDefaultValues, SubscriptionItemRef, SubscriptionItemValues, SubscriptionPlanDetails, SubscriptionValues } from "../../subscriptionType";
import { initialSubscriptionValue, initialDefaultValues, initialTaxValues, initialPlanData } from "../../SubscriptionStateInitialization";
import SubscriptionDetail from "./SubscriptionDetail";
import { attachSubscriptionFiles, deleteSubscriptionFile } from "../../subscriptionSlice";
import SubscriptionPreview from "./SubscriptionPreview";
import { ScrollToError } from "../../../../common/components";
import { itemDropdown } from "../../../items/itemSlice";
import { SubscriptionUpdateAlertModal } from "./updateSubscriptionAlertModal/SubscriptionUpdateAlertModal";
import { NON_PRICE_RANGE_PRICING_MODEL } from "../../../../../helpers/planHelper";

function SubscriptionForm() {
  const { editId } = useParams();
  let interval: any = null;
  const { dispatch, navigate, currentUserInfo } = useCommonData();
  const { invoicesRolePermission } = usePermissionHandler();
  const defaultRef = useRef<SubscriptionDefaultRef | null>(null);
  const itemRef = useRef<SubscriptionItemRef | null>(null);
  const addressList: any[] = useAppSelector(addressSelector);
  const exchangeRateValues = useAppSelector(
    latestCurrencyRateSelector
  ).latest_conversion;
  const exchangeRateAPiStatus = useAppSelector(exchangeApiStatusSelector);
  const tdsPreference = useAppSelector(tdsPreferenceSelector);
  const [isCustomerChanged, setIsCustomerChanged] = useState(false);
  const [isStateChanged, setIsStateChanged] = useState(false);

  const [subscriptionFormValues, setSubscriptionFormValues] =
    useState<SubscriptionValues>(initialSubscriptionValue);
  const [defaultFormValues, setDefaultFormValues] =
    useState<SubscriptionDefaultValues>(initialDefaultValues);
  
  const [planItemFormValues, setPlanItemFormValues] = useState<SubscriptionPlanDetails>(initialPlanData);
    const [addonItemFormValues, setAddonItemFormValues] = useState<SubscriptionAddonDetails[]>([]);
    const [couponFormValues, setCouponFormValues] = useState<SubscriptionCouponList[]>([]);
  const [totalFormValues, setTotalFormValues] =
    useState<TotalDetailsValues>(initialTotalValues);
  const [formErrors, setFormErrors] = useState<ObjectType>({});
  const [planItemFormErrors, setPlanItemFormErrors] = useState<CustomerItemValues[]>(
    []
  );
  const [addonItemFormErrors, setAddonItemFormErrors] = useState<CustomerItemValues[][]>(
    [[]]
  );
  const [couponError, setCouponError] = useState<ObjectType[]>([]);
  const [billingAddressFormValues, setBillingAddressFormValues] =
    useState<AddressValues | undefined>();
  const [shippingAddressFormValues, setShippingAddressFormValues] =
    useState<AddressValues | undefined>();
  const [billingAddressFormValuesForEdit, setBillingAddressFormValuesForEdit] = useState<AddressValues | undefined>();
  const [shippingAddressFormValuesForEdit, setShippingAddressFormValuesForEdit] = useState<AddressValues | undefined>();
  const [isCustomerName, setIsCustomerName] = useState<boolean>(false);
  const [customerData, setCustomerData] =
    useState<AddressValues[] | undefined>();
  const [emailLists, setEmailLists] = useState<string[] | []>([]);
  const [customerId, setCustomerId] = useState<number>();
  const [currencyId, setCurrencyId] = useState<number | undefined>();
  const [currencyCode, setCurrencyCode] = useState("");
  const [emailData, setEmailData] = useState<{ email: string; id: number }[]>(
    []
  );
  const [changedCustomerData, setChangedCustomerData] = useState<{customerId: number, currencyId: number}>({customerId: 0, currencyId: 0})
  const [deleteEmailIds, setDeleteEmailIds] = useState<{ emailId: number }[]>(
    []
  );
  const [planDeleteId, setPlanDeleteId] = useState<number>(0)
  const [addonDeleteIds, setAddonDeleteIds] = useState<number[]>([])
  const [couponDeleteIds, setCouponDeleteIds] = useState<number[]>([])
  const [customerIdForEdit, setCustomerIdForEdit] = useState<number>(0);
  const [uploadedFiles, setUploadedFiles] = useState<any>([]);
  const [formSubmitAction, setFormSubmitAction] = useState(true);
  const [formSubmit, setFormSubmit] = useState(true);
  const [fileIds, setFileIds] = useState<any>([]);
  const [insertedFiles, setInsertedFiles] = useState<FilesValues[]>([]);
  const fileRef = useRef<any>([]);
  const [conversionDate, setConversionDate] = useState<string>(
    getFormatedDate(new Date())
  );
  const [newConversionDate, setNewConversionDate] = useState<string>("");
  const [subscriptionValuesForEdit, setSubscriptionValuesForEdit] =
    useState<SubscriptionValues>(initialSubscriptionValue);
  const [conversionCurrencyId, setConversionCurrencyId] = useState<number>();
  const [selectedBillingAddressId, setSelectedBillingAddressId] =
    useState<number | string>(0);
  const [selectedShippingAddressId, setSelectedShippingAddressId] =
    useState<number | string>(0);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [addressId, setAddressId] = useState<number | string>("");
  const [customerBillingAddressId, setCustomerBillingAddressId] = useState(0);
  const [customerShippingAddressId, setCustomerShippingAddressId] = useState(0);
  const [isBillingAddressSame, setIsBillingAddressSame] = useState(false);
  const [isShippingAddressSame, setIsShippingAddressSame] = useState(false);
  const [isSubscriptionChecking,setIsSubscriptionChecking] = useState<boolean>(false)
  const [totalTdsValues, setTotalTdsValues] = useState<TDSTotalValues>(
    initialTDSTotalValues
  );
  const [totalTcsValues, setTotalTcsValues] = useState<TCSTotalValues>(
    initialTCSTotalValues
  );
  const [tdsIds, setTdsIds] = useState<number[]>([]);
  const [tcsIds, setTcsIds] = useState<number[]>([]);
  const [planTdsIds, setPlanTdsIds] = useState<number[]>([]);
  const [addonTdsIds, setAddonTdsIds] = useState<number[][]>([]);
  const [isRoundOffEnabled, setIsRoundOffEnabled] = useState(false);
  const [stateType, setStateType] = useState("");
  const [initialStateType, setInitialStateType] = useState("");
  const [supplyState, setSupplyState] = useState("");
  const [invoicingOption, setInvoicingOption] = useState("IMMEDIATELY");
  const [totalAmount, setTotalAmount] = useState(0);
    useState<number>();
  const [planTransactionTaxDetails, setPlanTransactionTaxDetails] =
    useState<[{ is_group: boolean; tax_id: number }]>();
    const [addonTransactionTaxDetails, setAddonTransactionTaxDetails] =
    useState<[{ is_group: boolean; tax_id: number }]>();
  const { isGstOrg } = useGst();
  const [editTotal, setEditTotal] = useState<number | null>(null);
  const [calculatedTotal, setCalculatedTotal] = useState(0);
  const [gstRegistrationType, setGstRegistrationType] = useState("");
  const [isSendEmailToggleEnable, setIsSendEmailToggleEnable] = useState<boolean>(true)
  const [isTotalEditable, setIsTotalEditable] = useState(false);
  const userOrganization = useAppSelector(currentUserOrgSelector)
  const [selectedInvoicingCycle, setSelectedInvoicingCycle] = useState<any>({ invoicingCycle: '', invoiceCycleCount: 0});
  const [isSubscriptionNumberExist, setIsSubscriptionNumberExist] = useState(false);
  const [nextInvoiceDateDetails, setNextInvoiceDateDetails] = useState<NextInvoiceDateType | null>(null);
  const [buttonAction, setButtonAction] = useState<{
    status: string;
    action: string;
  }>({ status: "", action: "" });
  const [didAlertModalOpen, setDidAlertModalOpen] = useState(false);
  const [nextRenewableDate, setNextRenewableDate] = useState<string>('');
  const [subscriptionStatus, setSubscriptionStatus] = useState<string>('');
  const [subscriptionStopDate, setSubscriptionStopDate] = useState<string>('');
  const [formDetailForNexInvoice, setFormDetailForNexInvoice] = useState({planId: 0, pricing_id: 0, total: 0});
  const [nextInvoiceEdit, setNextInvoiceEdit] = useState<NextInvoiceDateType | null>(null);
  const [savedTDSPreference, setSavedTDSPreference] =  useState<string>('');
  const [selectedStartDate, setSelectedStartDate] =  useState<string>(getFormatedDate(new Date()));
  /**
   * To check whether subscription address is same as customer address
   */
  useEffect(() => {
    if (editId) {
      let updatedAddress = addressList.map((address: any) => {
        return {
          address: address.address,
          city: address.city,
          country_id: address.country_id,
          is_billing: address.is_billing,
          is_shipping: address.is_shipping,
          phone: address.phone,
          state_id: address.state_id,
          zip_code: address.zip_code,
        };
      });
      let billingAddress = { ...billingAddressFormValues };
      let shippingAddress = { ...shippingAddressFormValues };
      delete billingAddress.country_name;
      delete billingAddress.customer_address_id;
      delete billingAddress.id;
      delete billingAddress.state_name;
      delete shippingAddress.country_name;
      delete shippingAddress.customer_address_id;
      delete shippingAddress.id;
      delete shippingAddress.state_name;
      setIsBillingAddressSame(false);
      setIsShippingAddressSame(false);
      for (let address of updatedAddress) {
        if (address.is_billing) {
          if (_.isEqual(address, billingAddress)) {
            setSelectedBillingAddressId(customerBillingAddressId);
            setIsBillingAddressSame(true);
          }
        }
        if (address.is_shipping) {
          if (_.isEqual(address, shippingAddress)) {
            setSelectedShippingAddressId(customerShippingAddressId);
            setIsShippingAddressSame(true);
          }
        }
      }
    }
  }, [addressList]);

  useEffect(() => {
    dispatch(setLoaderState(true));
    if (!editId) {
      setIsCustomerName(false);
      getRoundOffPreference();
    }
    if (editId) {
      subscriptionDetailsResponse();
    }
    dispatch(addressReset());
  }, [currentUserInfo.organization_id]);

  const getRoundOffPreference = async () => {
    const response = await dispatch(
      fetchRoundOffPreference({
        orgId: currentUserInfo.organization_id,
      })
    );
    let payload = response.payload;
    setIsRoundOffEnabled(payload.is_enabled);
    setIsTotalEditable(payload.is_total_editable);
  };

  const openAddressModal = () => {
    setShowAddressModal(true);
  };
  const closeAddressModal = () => {
    setShowAddressModal(false);
  };
 
  /**
   * Set subscription default form values
   */
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setSubscriptionFormValues({ ...subscriptionFormValues, [name]: value });
  };

  const handleNewConversionDate = (value: string) => {
    setNewConversionDate(value);
  };

  const asynchronousSubmit = (status: string, action: string) => {
    setButtonAction({ status: status, action: action });
    handleSubmit(status, action);
  };

  const handleCustomerChange = async (e: any) => {
    if (e.value && e.value !== customerIdForEdit) {
      setIsCustomerChanged(true);
      let response = await getCustomerDetails(Number(e.value), "customer");
      let state_type = "";
      if (
        response.gst_registration_type === gstRegistrationTypes.sez ||
        response.gst_registration_type === gstRegistrationTypes.sezDeveloper ||
        response.gst_registration_type === gstRegistrationTypes.overseas ||
        response.other_details.tax_preference === "NON_TAXABLE"
      ) {
        state_type = "Inter State";
      } else {
        state_type = stateType;
      }

      if (state_type !== "") {
        await fetchGstTaxList(
          state_type,
          response.gst_registration_type,
          planTransactionTaxDetails
        );
      } else {
        dispatch(clearGstTaxList());
      }
      setGstRegistrationType(response.gst_registration_type);
      setIsCustomerName(true);
      setCustomerId(Number(e.value));
      setBillingAddressFormValues(undefined);
      setShippingAddressFormValues(undefined);
    } else {
      getCustomerDetails(Number(e.value), "subscription");
      setSubscriptionFormValues(subscriptionValuesForEdit);
      setBillingAddressFormValues(billingAddressFormValuesForEdit);
      setShippingAddressFormValues(shippingAddressFormValuesForEdit);
      setCustomerId(customerIdForEdit);

      let emailContacts = subscriptionValuesForEdit.subscription_emails;
      let contactEmail: string[] = [];
      let emailDetails: any[] = [];
      let removeEmails: { emailId: number }[] = deleteEmailIds;
      emailContacts.map((contact: any) => {
        contactEmail.push(contact.email);
        emailDetails.push(contact);
        removeEmails = removeEmails.filter(
          (email) => email.emailId !== contact.id
        );
      });
      setEmailLists(contactEmail);
      setEmailData(emailDetails);
      setDeleteEmailIds(removeEmails);
      setCurrencyId(Number(subscriptionValuesForEdit.currency_id)); // need to check
    }
    setSelectedBillingAddressId(0);
    setSelectedShippingAddressId(0);
  };

  useEffect(() => {
    customerId &&
      getCustomerDetails(customerId, "subscription");
  }, [customerId]);

  /**
   * Store customer email contacts to delete if customer name is changed on Edit subscription
   */
  useEffect(() => {
    if (editId) {
      emailData.map((contact) => {
        setDeleteEmailIds((prevItems) => [
          ...prevItems,
          { emailId: contact.id },
        ]);
      });
    }
  }, [isCustomerName]);

  const setErrorOnItemTaxChange = (errors: CustomerItemValues[]) => {
    setPlanItemFormErrors([...errors]);
  };
 
  const setAddonErrorOnItemTaxChange = (errors: CustomerItemValues[][]) => {
    setAddonItemFormErrors([...errors]);
  };
  /**
   * Fetch customer details by id
   */
  const getCustomerDetails = async (customerId: number, type: string) => {
    const responseAction = await dispatch(
      customerDetailsForTransactions({
        customerId: customerId,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        dispatch(addressReset());
        setChangedCustomerData({customerId: customerId, currencyId:response.other_details.currency_id})
        let addressData = response.address_details;
        addressData.forEach(async (addressDetails: any) => {
          let address = {
            country_id: addressDetails.country_id
              ? addressDetails.country_id
              : 0,
            address: addressDetails.address,
            city: addressDetails.city,
            state_id: addressDetails.state_id ? addressDetails.state_id : 0,
            zip_code: addressDetails.zip_code,
            phone: addressDetails.phone ? addressDetails.phone : null,
            is_billing: addressDetails.is_billing,
            is_shipping: addressDetails.is_shipping,
            is_primary: addressDetails.is_primary,
            id: addressDetails.id,
            state_name: addressDetails.state_name,
            country_name: addressDetails.country_name,
          };
          dispatch(addAddress(address));
        });
        if (type === "subscription") {
          setCustomerData(response.address_details);
          let defaultFormValues = {
            customer_exemption_type: response.other_details.tax_preference,
            customer_exemption_id: response.other_details.exemption_reason_id,
          };
          if (defaultRef.current?.defaultData) {
            setDefaultFormValues((prevData) => {
              return {
                ...prevData,
                ...defaultRef.current?.defaultData,
                ...defaultFormValues,
              };
            });
          }
        } else {
          setCustomerData(response.address_details);
          if (defaultRef.current?.defaultData) {
            const exemptionType =
              response.other_details.tax_preference === "Non-Taxable"
                ? "NON_TAXABLE"
                : response.other_details.tax_preference === "Taxable"
                ? "TAXABLE"
                : "";
            let defaultFormValues = {
              ...defaultRef.current?.defaultData,
              tag_ids: response.other_details.tags.map((tag: any) => tag.id),
              payment_term_id: response.other_details.payment_term_id,
              currency_id: response.other_details.currency_id,
              customer_exemption_type: response.other_details.tax_preference,
              customer_exemption_id: response.other_details.exemption_reason_id,
            };
            setDefaultFormValues({ ...defaultFormValues });
          }
          let contacts = response.contact_persons;
          let contactEmail: string[] = [];
          contacts.map((contact: any) => {
            if (contact.email) contactEmail.push(contact.email);
          });
          setEmailLists(contactEmail);
          setCurrencyId(response.other_details.currency_id);
        }
      }
      return responseAction.payload;
    }
  };

  const getTdsApplyLevel = () => {
    const itemDetails = itemRef.current?.itemPlanValues.itemDetails
      .filter((item: any) => item.tds_id)
      .map((item: any) => item);
    
    let addonItemDetails: any[] = []
    itemRef.current?.itemAddonValues.forEach((eachAddon: any) => {
      const eachAddonItem = eachAddon.itemDetails
      .filter((item: any) => item.tds_id)
      .map((item: any) => item);
      if (eachAddonItem.length > 0) addonItemDetails.push(eachAddonItem)
    })
    if (itemRef.current?.totalTcsValues.tcs_id) {
      return tdsApplyLevel.NO_TDS;
    }
    if (itemRef?.current?.totalTdsValues.tds_id) {
      return tdsApplyLevel.TRANSACTION_LEVEL;
    }
    if (itemDetails?.length || addonItemDetails?.length) {
      return tdsApplyLevel.LINE_ITEM_LEVEL;
    }
    if (
      tdsPreference.is_enabled &&
      tdsPreference.support_tds_for.includes("Customers")
    ) {
      if (tdsPreference.apply_tds_at === "Transaction level") {
        return itemRef.current?.totalTdsValues.tds_id
          ? tdsApplyLevel.TRANSACTION_LEVEL
          : tdsApplyLevel.NO_TDS;
      }
      if (tdsPreference.apply_tds_at === "Line item level") {
        return itemDetails?.length || addonItemDetails?.length
          ? tdsApplyLevel.LINE_ITEM_LEVEL
          : tdsApplyLevel.NO_TDS;
      }
    }
    return tdsApplyLevel.NO_TDS;
  };

  const getSourceTaxType = () => {
    const planItemDetails = itemRef.current?.itemPlanValues.itemDetails
      .filter((item: any) => item.tds_id)
      .map((item: any) => item);

      let addonItemDetails = []
      itemRef.current?.itemAddonValues.forEach((eachAddon: any) => {
        const eachAddonItem = eachAddon.itemDetails
        .filter((item: any) => item.tds_id)
        .map((item: any) => item);
        if (eachAddonItem.length > 0)  addonItemDetails.push(eachAddonItem)
      })
    if (itemRef.current?.totalTcsValues.tcs_id) {
      return sourceTaxTypes.TCS;
    }
    if (itemRef.current?.totalTdsValues.tds_id || planItemDetails?.length || addonItemDetails?.length) {
      return sourceTaxTypes.TDS;
    }
    return sourceTaxTypes.NO_SOURCE_TAX;
  };

  /**
   * Handle form submit and set form validation errors
   */
  const handleSubmit = (status: string, action: string, updatedData?: any) => {
    dispatch(setListFilters({}));
    let defaultValues = defaultRef.current?.defaultData;
    let planItemValues: SubscriptionPlanDetails = itemRef.current?.itemPlanValues
      ? itemRef.current?.itemPlanValues
      : planItemFormValues;
    let addonItemValues: SubscriptionAddonDetails[] = itemRef.current?.itemAddonValues
      ? itemRef.current?.itemAddonValues
      : addonItemFormValues;
    let couponDetails: SubscriptionCouponList[] = itemRef.current?.couponFormValues
      ? itemRef.current?.couponFormValues
      : couponFormValues
    let totalTdsValues = itemRef.current?.totalTdsValues;
    let totalTcsValues = itemRef.current?.totalTcsValues;
    let subscriptionInputs: any = {
      ...subscriptionFormValues,
      ...defaultValues,
      ...totalTdsValues,
      ...totalTcsValues,
      tds_level: getTdsApplyLevel(),
      source_tax_type: getSourceTaxType(),
    };
    
    let errors,
    planItemErrorResponse,
    addonItemErrorResponse: any = [];
    let palnItemErrors: any = [];
    let addonItemErros: any = [];

  errors = validate(
    subscriptionInputs,
    isSubscriptionNumberExist,
    emailLists,
    planItemValues,
    isGstOrg
  );
  if (errors) {
    ScrollToError(Object.keys(errors)[0]);
  }
  let errorExist = false;

  let taxValues = itemRef.current?.taxPlanValues.itemDetails
      ? itemRef.current?.taxPlanValues.itemDetails
      : [];
      planItemValues.itemDetails.map((item: any, itemIndex: any) => {
        planItemErrorResponse = validateItemValues(
        subscriptionInputs,
        item,
        itemIndex,
        taxValues,
        planItemValues.pricing_model
      );
      if (planItemErrorResponse) {
        if (planItemErrorResponse) {
          ScrollToError(Object.keys(planItemErrorResponse)[0]);
        }
        errorExist = true;
        palnItemErrors.push(planItemErrorResponse);
      }else {
        palnItemErrors.push({});
      }
    });
    
    addonItemValues.map((eachAddon, index) => {

      let addonTaxValues = itemRef.current?.taxAddonValues[index].itemDetails
      ? itemRef.current?.taxAddonValues[index].itemDetails
      : [];
      let addonEachItemErrors: any = [];
      eachAddon.itemDetails.map((item, itemIndex) => {
        addonItemErrorResponse = validateItemValues(
        subscriptionInputs,
        item,
        itemIndex,
        addonTaxValues,
        eachAddon.pricing_model
      );

      if (addonItemErrorResponse) {
        addonEachItemErrors.push(addonItemErrorResponse);
        errorExist = true;
        ScrollToError(Object.keys(addonEachItemErrors)[0]);
      }else {
        addonEachItemErrors.push({});
      }
    })
    addonItemErros.push(addonEachItemErrors);
  })

  if (
    Object.keys(errors).length ||
    (errorExist && palnItemErrors.length) ||
    (errorExist && addonItemErros.length) || couponError.length > 0
  ) {
    setFormErrors(errors);
    setPlanItemFormErrors(palnItemErrors);
    setAddonItemFormErrors(addonItemErros);

  } else {
    setFormErrors({});
    setPlanItemFormErrors([]);
    setAddonItemFormErrors([[]]);
    if (editId) {
      if (editId && !didAlertModalOpen) {
        getNextInvoiceAction(getFormatedDate(new Date()), planItemValues.planId, planItemValues.pricing_id, calculatedTotal, invoicingOption)
        setFormDetailForNexInvoice({planId: planItemValues.planId, pricing_id: planItemValues.pricing_id, total: calculatedTotal})
        setDidAlertModalOpen(true);
    return; 
    } else if (['SPECIFIC_DATE', 'END_OF_CURRENT_TERM'].includes(updatedData.update_type)) {
      createSubscriptionSubmit(subscriptionInputs, planItemValues, addonItemValues, couponDetails, action, updatedData);
    } else UpdateSubscriptionSubmit(subscriptionInputs, planItemValues, addonItemValues, couponDetails, updatedData);
    } else createSubscriptionSubmit(subscriptionInputs, planItemValues, addonItemValues, couponDetails, action);
  }
  };
  /**
   * Create new subscription
   */
  const createSubscriptionSubmit = async (
    subscriptionInputs: any,
    planItemValues: SubscriptionPlanDetails,
    addonItemValues: SubscriptionAddonDetails[],
    couponDetails: SubscriptionCouponList[],
    action: string,
    updatedData?: any
  ) => {
    dispatch(setLoaderState(true));
    subscriptionInputs.conversion_id = exchangeRateValues.id;
    subscriptionInputs.organization_id = currentUserInfo.organization_id
    subscriptionInputs.is_email_reminder_automatic = isSendEmailToggleEnable
    let key: keyof typeof subscriptionInputs;
    for (key in subscriptionInputs) {
      if (subscriptionInputs[key] === "") {
        subscriptionInputs[key] = null;
      }
      if (
        key === "subscription_number" &&
        subscriptionInputs[key] !== "" &&
        subscriptionInputs[key] !== null
      ) {       
        subscriptionInputs[key] = defaultRef.current?.subscriptionNumber;
      }
      if (key === "tds_percentage") {
        subscriptionInputs[key] =
          subscriptionInputs[key] === "" || subscriptionInputs[key] === null
            ? 0
            : Number(subscriptionInputs[key]);
      }
      if (isGstOrg && key === "place_of_supply") {
        subscriptionInputs[key] = subscriptionInputs[key];
      }
    }
   
    let subscriptionPlanItems = JSON.parse(JSON.stringify(planItemValues.itemDetails));

    for (let items of subscriptionPlanItems) {
      let itemInputs = items;
      if (isGstOrg) {
        // updating gst_id in request payload
        itemInputs.gst_id = itemInputs?.gst_tax?.tax_id
          ? itemInputs?.gst_tax?.tax_id
          : null;
      } else {
        delete itemInputs.gst_id;
        delete itemInputs.gst_tax;
      }

      let itemkey: keyof typeof itemInputs;
      for (itemkey in itemInputs) {
        if (itemInputs[itemkey] === "") {
          if (itemkey === "rate" || itemkey === "quantity") {
            itemInputs[itemkey] = 0;
          } else {
            itemInputs[itemkey] = null;
          }
        }
        if (itemkey === "discount") {
          if (itemInputs[itemkey]?.discount_value == 0) {
            itemInputs[itemkey] = itemInputs[itemkey]?.discount_value;
          } else if (itemInputs[itemkey]?.discount_percentage == 0) {
            itemInputs[itemkey] = itemInputs[itemkey]?.discount_percentage;
          } else {
            itemInputs[itemkey] = Number(itemInputs[itemkey]);
          }
        }
      }

      let itemTax = itemInputs.taxes;
      let taxData: ItemTaxValues[] = [];
      itemTax.map((item: any) => {
        if (item.tax_percentage === "") {
          item.tax_percentage = 0;
        }
        if (item.tax_id !== "" || item.tax_percentage !== 0) {
          taxData.push(item);
        }
      });
      items.taxes = taxData;
    }
    
    let subscriptionAddon = [] 
    for (let eachAddons of addonItemValues) {
    let subscriptionAddonItems = JSON.parse(JSON.stringify(eachAddons.itemDetails));

    for (let items of subscriptionAddonItems) {
      let itemInputs = items;
      if (isGstOrg) {
        // updating gst_id in request payload
        itemInputs.gst_id = itemInputs?.gst_tax?.tax_id
          ? itemInputs?.gst_tax?.tax_id
          : null;
      } else {
        delete itemInputs.gst_id;
        delete itemInputs.gst_tax;
      }

      let itemkey: keyof typeof itemInputs;
      for (itemkey in itemInputs) {
        if (itemInputs[itemkey] === "") {
          if (itemkey === "rate" || itemkey === "quantity") {
            itemInputs[itemkey] = 0;
          } else {
            itemInputs[itemkey] = null;
          }
        }
        if (itemkey === "discount") {
          if (itemInputs[itemkey]?.discount_value == 0) {
            itemInputs[itemkey] = itemInputs[itemkey]?.discount_value;
          } else if (itemInputs[itemkey]?.discount_percentage == 0) {
            itemInputs[itemkey] = itemInputs[itemkey]?.discount_percentage;
          } else {
            itemInputs[itemkey] = Number(itemInputs[itemkey]);
          }
        }
      }

      let itemTax = itemInputs.taxes;
      let taxData: ItemTaxValues[] = [];
      itemTax.map((item: any) => {
        if (item.tax_percentage === "") {
          item.tax_percentage = 0;
        }
        if (item.tax_id !== "" || item.tax_percentage !== 0) {
          taxData.push(item);
        }
      });
      items.taxes = taxData;
    }

    subscriptionAddon.push({addon_id: eachAddons.addonId, addon_pricing_id: eachAddons.pricing_id, items: subscriptionAddonItems })
  }
    $("#form-btn-invoice").addClass("customer-form-section-disable");
    subscriptionInputs.plans = {plan_id: planItemValues.planId, plan_pricing_id: planItemValues.pricing_id, items: subscriptionPlanItems};
    subscriptionInputs.files = fileRef.current.fileIds;
    subscriptionInputs.addons = subscriptionAddon;
    subscriptionInputs.coupons = couponDetails.length > 0 ? couponDetails.map((eachData: SubscriptionCouponList) => eachData.id) : []
    if (emailLists.length > 0) {
      subscriptionInputs.subscription_emails = emailLists;
    }
    let address = [];
    if (billingAddressFormValues?.is_billing === true) {
      let payload: any = billingAddressFormValues;
      address.push({
        is_billing: !!payload.is_billing,
        is_shipping: !!payload.is_shipping,
        customer_address_id: selectedBillingAddressId,
      });
    } 
    if (shippingAddressFormValues?.is_shipping === true) {
      let payload: any = shippingAddressFormValues;
      address.push({
        is_billing: !!payload.is_billing,
        is_shipping: !!payload.is_shipping,
        customer_address_id: selectedShippingAddressId,
      });
    } 
    subscriptionInputs.address = address;

    subscriptionInputs.total = calculatedTotal;
    if (!editId &&
      tdsPreference.is_enabled &&
      tdsPreference.support_tds_for.includes("Customers")
    ) {
      if (tdsPreference.apply_tds_at === "Transaction level") {
        subscriptionInputs.tds_preference = "TRANSACTION_LEVEL"
      }
      if (tdsPreference.apply_tds_at === "Line item level") {
        subscriptionInputs.tds_preference = "LINE_LEVEL"
      }
    } else subscriptionInputs.tds_preference = savedTDSPreference
    if (updatedData && editId) {
      subscriptionInputs.edit_scheduler_details = {
        subscription_id: editId,
        scheduler_option: updatedData.update_type,
        specific_date: getFormatedDate(updatedData.updation_date)
      }
    }
    const createSubscrptionResponseAction = await dispatch(
      createSubscription({
        values: subscriptionInputs,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (createSubscrptionResponseAction.payload) {
      const createSubscriptionResponse = createSubscrptionResponseAction.payload;
      if (
        Object.keys(createSubscriptionResponse).length &&
        !("error" in createSubscriptionResponse)
      ) {
        dispatch(setLoaderState(false));
          if ( editId && updatedData ) {
          navigate(`/subscriptions/detail/${Number(editId)}`, {
            replace: true,
          });
            toast.success("Subscription scheduled successfully!", {
              toastId: "subscription-scheduled-success",
              closeButton: false,
              position: "top-center",
            });
          } else {
          navigate(`/subscriptions/detail/${Number(createSubscriptionResponse.id)}`, {
            replace: true,
          });
            toast.success("Subscription created successfully!", {
              toastId: "subscription-create-success",
              closeButton: false,
              position: "top-center",
            });
          }
      } else if ("error" in createSubscriptionResponse) {
        dispatch(setLoaderState(false));
      } else {
        dispatch(setLoaderState(false));
      }
    }
  };


  const UpdateSubscriptionSubmit = async (
    subscriptionInputs: any,
    planItemValues: SubscriptionPlanDetails,
    addonItemValues: SubscriptionAddonDetails[],
    couponDetails: SubscriptionCouponList[],
    updatedData: any
  ) => {
    dispatch(setLoaderState(true));
    subscriptionInputs.invoicing_option = updatedData.invoicing_option ? updatedData.invoicing_option : "IMMEDIATELY";
    subscriptionInputs.conversion_id = exchangeRateValues.id;
    let key: keyof typeof subscriptionInputs;
    for (key in subscriptionInputs) {
      if (subscriptionInputs[key] === "") {
        subscriptionInputs[key] = null;
      }

      if (key === "payment_term_id" && subscriptionInputs[key] !== "") {
        if (subscriptionInputs[key] === null) {
          subscriptionInputs[key] = null;
        } else {
          subscriptionInputs[key] = Number(subscriptionInputs[key]);
        }
      }

      if (
        key === "subscription_number" &&
        subscriptionInputs[key] !== "" &&
        subscriptionInputs[key] !== null
      ) {
        subscriptionInputs[key] = defaultRef.current?.subscriptionNumber;
        // subscriptionInputs[key] = defaultRef.current?.defaultData.subscription_number;
      }
      if (key === "tds_percentage") {
        subscriptionInputs[key] =
          subscriptionInputs[key] === "" || subscriptionInputs[key] === null
            ? 0
            : Number(subscriptionInputs[key]);
      }
      if (key === "reference_number" && subscriptionInputs[key]) {
        subscriptionInputs[key] = subscriptionInputs[key].toString().trim();
      }
      if (isGstOrg && key === "place_of_supply") {
        subscriptionInputs[key] = subscriptionInputs[key];
      }
      if (key === "tds_amount" || key === "tcs_amount") {
        subscriptionInputs[key] =
          subscriptionInputs[key] === null ? 0 : subscriptionInputs[key];
      }
    }
    $("#form-btn-invoice").addClass("customer-form-section-disable");
    if (editId) {
    let deleteContactIds: number[] = [];
    deleteEmailIds.map((contact) => {
      deleteContactIds.push(contact.emailId);
    });

    // if (deleteContactIds.length) {
    //   await dispatch(
    //     deleteInvoiceRecipientDetails({
    //       invoiceId: Number(editId),
    //       emailIds: deleteContactIds,
    //       orgId: currentUserInfo.organization_id,
    //     })
    //   );
    // }
  }
    let subscriptionPlanItems = JSON.parse(JSON.stringify(planItemValues.itemDetails));

    for (let items of subscriptionPlanItems) {
      let itemInputs = items;
      if (isGstOrg) {
        // updating gst_id in request payload
        itemInputs.gst_id = itemInputs?.gst_tax?.tax_id
          ? itemInputs?.gst_tax?.tax_id
          : null;
      } else {
        delete itemInputs.gst_id;
        delete itemInputs.gst_tax;
      }

      let itemkey: keyof typeof itemInputs;
      for (itemkey in itemInputs) {
        if (itemInputs[itemkey] === "") {
          if (itemkey === "rate" || itemkey === "quantity") {
            itemInputs[itemkey] = 0;
          } else {
            itemInputs[itemkey] = null;
          }
        }
        if (itemkey === "discount") {
          if (itemInputs[itemkey]?.discount_value == 0) {
            itemInputs[itemkey] = itemInputs[itemkey]?.discount_value;
          } else if (itemInputs[itemkey]?.discount_percentage == 0) {
            itemInputs[itemkey] = itemInputs[itemkey]?.discount_percentage;
          } else {
            itemInputs[itemkey] = Number(itemInputs[itemkey]);
          }
        }
      }
   

      let itemTax = itemInputs.taxes;
      let taxData: ItemTaxValues[] = [];
      itemTax.map((item: any) => {
        if (item.tax_percentage === "") {
          item.tax_percentage = 0;
        }
        if (item.tax_id !== "" || item.tax_percentage !== 0) {
          taxData.push(item);
        }
      });
      items.taxes = taxData;
    }
    
    subscriptionPlanItems = subscriptionPlanItems.map((data: any) => {
      if (NON_PRICE_RANGE_PRICING_MODEL.includes(planItemValues.pricing_model)){
        const returnVal = {
          ...data,
          item_pricing: {
            id: data.itemPricingId, 
            price: data.price,
            free_quantity: data.free_quantity
          },
        }

        delete data.itemPricingId
        delete data.price
        delete data.free_quantity

        return returnVal
      }
     const rangeData = data.range_price
     delete data.range_price
      const returnVal = {
        ...data,
        item_pricing_range : rangeData
      }

       return  returnVal
    })

    let subscriptionAddon = [] 
    for (let eachAddons of addonItemValues) {
    let subscriptionAddonItems = JSON.parse(JSON.stringify(eachAddons.itemDetails));

    for (let items of subscriptionAddonItems) {
      let itemInputs = items;
      if (isGstOrg) {
        // updating gst_id in request payload
        itemInputs.gst_id = itemInputs?.gst_tax?.tax_id
          ? itemInputs?.gst_tax?.tax_id
          : null;
      } else {
        delete itemInputs.gst_id;
        delete itemInputs.gst_tax;
      }

      let itemkey: keyof typeof itemInputs;
      for (itemkey in itemInputs) {
        if (itemInputs[itemkey] === "") {
          if (itemkey === "rate" || itemkey === "quantity") {
            itemInputs[itemkey] = 0;
          } else {
            itemInputs[itemkey] = null;
          }
        }
        if (itemkey === "discount") {
          if (itemInputs[itemkey]?.discount_value == 0) {
            itemInputs[itemkey] = itemInputs[itemkey]?.discount_value;
          } else if (itemInputs[itemkey]?.discount_percentage == 0) {
            itemInputs[itemkey] = itemInputs[itemkey]?.discount_percentage;
          } else {
            itemInputs[itemkey] = Number(itemInputs[itemkey]);
          }
        }
      }
      let itemTax = itemInputs.taxes;
      let taxData: ItemTaxValues[] = [];
      itemTax.map((item: any) => {
        if (item.tax_percentage === "") {
          item.tax_percentage = 0;
        }
        if (item.tax_id !== "" || item.tax_percentage !== 0) {
          taxData.push(item);
        }
      });
      items.taxes = taxData;
    }
   
    subscriptionAddonItems = subscriptionAddonItems.map((data: any) => {
      if (NON_PRICE_RANGE_PRICING_MODEL.includes(eachAddons.pricing_model)){
        const returnVal = {
          ...data,
          item_pricing: {
            id: data.itemPricingId, 
            price: data.price,
            free_quantity: data.free_quantity
          },
        }

        delete data.itemPricingId
        delete data.price
        delete data.free_quantity

        return returnVal
      }
      const rangeData = data.range_price
      delete data.range_price
       const returnVal = {
         ...data,
         item_pricing_range : rangeData
       }

       return  returnVal
    })
    subscriptionAddon.push({
      id: eachAddons.id || null,
      addon_id: eachAddons.addonId,
      addon_pricing_id: eachAddons.pricing_id,
      frequency: eachAddons.frequency,
      addon_name: eachAddons.addonName,
      pricing_model: eachAddons.pricing_model,
      items: subscriptionAddonItems })
  }
  subscriptionInputs.plan_delete_id = planItemValues.id && planItemValues.id === planDeleteId ? 0 : planDeleteId
  subscriptionInputs.plans = {
    id: planItemValues.id || null,
    plan_id: planItemValues.planId,
    plan_pricing_id: planItemValues.pricing_id,
    plan_name: planItemValues.planName,
    frequency: planItemValues.frequency,
    pricing_model: planItemValues.pricing_model,
    items: subscriptionPlanItems};
    
    let addonIds: number[] = []
    if (addonDeleteIds?.length) {
      addonDeleteIds.forEach((data) => {
        if (addonItemValues?.length && addonItemValues?.length > 0) {
          if (!addonItemValues.find((addonData) => addonData.id === data)) {
          addonIds.push(data)
        } 
      } else {
        addonIds.push(data)}
      })
    }
  subscriptionInputs.addon_delete_ids = addonIds
  subscriptionInputs.addons = subscriptionAddon;

  let couponIds: number[] = []
  if (couponDeleteIds?.length) {
    couponDeleteIds.forEach((data) => {
      if (couponDetails?.length && couponDetails?.length > 0) {
        if (!couponDetails.find((couponData) => couponData.index === data)) {
          couponIds.push(data)
        }
      } else couponIds.push(data)
    })
  }
  subscriptionInputs.coupons_delete_ids = couponIds
  subscriptionInputs.coupons = couponDetails.length > 0 ? couponDetails.map((eachData: SubscriptionCouponList) =>  ({
    id: eachData.index || null,
    coupon_id: eachData.id
  })) : []
  
  subscriptionInputs.files = fileRef.current.fileIds;
  if (emailLists.length > 0) {
    
    let deleteEmails: number[] = []
    if (emailData?.length > 0) {emailData.forEach((each) => {
      if (emailLists?.length){
        let allEmail = emailLists as string[]
        if (!allEmail.includes(each.email)) {
          deleteEmails.push(each.id)
        }

      }
    })}

    subscriptionInputs.email_delete_ids = deleteEmails
    subscriptionInputs.subscription_emails = emailLists?.length > 0 ? emailLists.map((each) => {
      return emailData.find((data) => data.email === each) ? emailData.find((data) => data.email === each) : {email: each}
    }): [];
  }

    let address = [];
    if (billingAddressFormValues?.is_billing === true) {
      let payload: any = billingAddressFormValues;
      address.push({
        is_billing: !!payload.is_billing,
        is_shipping: !!payload.is_shipping,
        customer_address_id: selectedBillingAddressId,
        id: payload.id
      });
    } 
    if (shippingAddressFormValues?.is_shipping === true) {
      let payload: any = shippingAddressFormValues;
      address.push({
        is_billing: !!payload.is_billing,
        is_shipping: !!payload.is_shipping,
        customer_address_id: selectedShippingAddressId,
        id: payload.id
      });
    } 
    subscriptionInputs.address = address;
    subscriptionInputs.total = calculatedTotal;
    subscriptionInputs.roundoff_enabled = isRoundOffEnabled;
    subscriptionInputs.is_total_editable = isTotalEditable;
    subscriptionInputs.is_email_reminder_automatic = isSendEmailToggleEnable
    
    const updateInvoiceResponseAction = await dispatch(
      editSubscriptionDetails({
        id: Number(editId),
        editInvoiceInputs: subscriptionInputs,
        orgId: currentUserInfo.organization_id,
      })
    );
    const updateInvoiceResponse = updateInvoiceResponseAction.payload;
    if (
      Object.keys(updateInvoiceResponse).length &&
      !("error" in updateInvoiceResponse)
    ) {
      dispatch(setLoaderState(false));
      navigate(`/subscriptions/detail/${Number(updateInvoiceResponse.id)}`, {
        replace: true,
      });
        toast.success("Subscription updated successfully!", {
          toastId: "subscription-update-success",
          closeButton: false,
          position: "top-center",
        });
    } else if ("error" in updateInvoiceResponse) {
      dispatch(setLoaderState(false));
    } else {
      dispatch(setLoaderState(false));
    }
  };

  /**
   * Get invoice details for the edit invoice purpose.
   */
  const subscriptionDetailsResponse = async () => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      subscriptionEditDetails({
        subscriptionId: Number(editId),
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 500);
      ($(".selectpicker") as any).selectpicker("refresh");
      const response = responseAction.payload;
      const isSameOrg =
        response.organization_id === currentUserInfo.organization_id;
      if (
        Object.keys(response).length &&
        !("error" in response) &&
        isSameOrg 
      ) {
        let defaultValues = {
          customer_id: response.customer_id,
          customer_name: response.customer_name,
          subscription_number: response.subscription_number,
          payment_term_id: response.payment_term_id,
          currency_id: response.currency_id,
          place_of_supply: response.place_of_supply,
          invoicing_option: response.invoicing_option,
          start_subscription: DateFormatHandler(response.start_date) === response.subscription_created_at ? 'IMMEDIATELY' : 'CHOOSE_DATE',
          start_date: response.start_date,
          invoicing_cycle: response.invoicing_cycle,
          invoicing_cycle_fixed_count: response.invoicing_cycle_fixed_count,
          is_mutable: true,
        };
        let otherValues: SubscriptionValues = {
          ...defaultValues,
          tds_level: response.tds_level,
          source_tax_type: response.source_tax_type,
          conversion_id: response.conversion_id,
          conversion_date: response?.conversion_date || '',
          tcs_percentage: response.tcs_percentage,
          tcs_id: response.tcs_id || 0,
          tcs_account_id: response.tcs_account_id,
          tcs_amount: response.tcs_amount,
          total: response.total_details.total,
          tds_id:response.tds_id || 0,
          tds_amount: response.tds_amount,
          tds_account_id: response.tds_account_id,
          addons: response.addon_details.map((eachAddon: any) => ({...eachAddon, addonId: eachAddon.addon_id, addonName: eachAddon.addon_name, pricing_id: eachAddon.addon_pricing_id, itemDetails: eachAddon.items})),
          coupons: response.coupon_details.map((eachCoupon: any) => ({
            ...eachCoupon,
            id: eachCoupon.coupon_id,
            index: eachCoupon.id})),
          plans: {...response.plan_details, planId: response.plan_details.plan_id, planName: response.plan_details.plan_name, pricing_id: response.plan_details.plan_pricing_id, itemDetails:response.plan_details.items},
          address: response.address,
          customer_notes: response.customer_notes,
          files: response?.files?.map((file: any) => file.id) || [],
          tds_percentage: response.tds_percentage,
          subscription_emails: response.subscription_emails.map((data: any) => data.email),
          is_email_reminder_automatic: response.is_email_reminder_automatic,
        };
        let totalTdsValue = {
          tds_id: response.tds_id,
          tds_account_id: response.tds_account_id,
          tds_percentage: response.tds_percentage,
          tds_amount: response.tds_amount,
          tds_name: response.tds_name || '',
        };
        let totalTcsValue = {
          tcs_id: response.tcs_id,
          tcs_account_id: response.tcs_account_id,
          tcs_percentage: response.tcs_percentage,
          tcs_amount: response.tcs_amount,
          tcs_name: response.tcs_name || '',
        };
        setTotalTdsValues(totalTdsValue);
        setTotalTcsValues(totalTcsValue);
        setUploadedFiles(response.files);
        setFileIds(response.files.map((file: any) => file.id));
        setInsertedFiles(
          response.files.map((file: any) => file.id)
        );
        let customerDetails = await getCustomerDetails(
          response.customer_id,
          "subscription"
        );

        const preferenceResponse = await dispatch(
          fetchRoundOffPreference({
            orgId: currentUserInfo.organization_id,
            roundoff_transaction_type: "Sales",
          })
        );
        let preferencePayload = preferenceResponse.payload;

        setIsRoundOffEnabled((prevValue) => {
          return response?.roundoff_enabled || preferencePayload.is_enabled
            ? true
            : false;
        });

        setIsTotalEditable((prevValue) => {
          if (response?.roundoff_enabled || preferencePayload.is_enabled) {
            if (response?.roundoff_enabled) {
              return response?.is_total_editable;
            } else {
              return preferencePayload.is_total_editable;
            }
          } else {
            return false;
          }
        });
        if (isGstOrg) {
          let stateType =
            !response.place_of_supply || !currentUserInfo.organization_gst_state
              ? ""
              : response.place_of_supply ===
                currentUserInfo.organization_gst_state
              ? "Intra State"
              : "Inter State";
          if (
            customerDetails.gst_registration_type ===
              gstRegistrationTypes.sez ||
            customerDetails.gst_registration_type ===
              gstRegistrationTypes.sezDeveloper ||
            customerDetails.gst_registration_type ===
              gstRegistrationTypes.overseas
          ) {
            stateType = "Inter State";
          }
          setStateType(stateType);
          setInitialStateType(stateType);

          let transaction_tax_details = [] as any;
          response.plan_details.items.forEach((item: any) => {
            let gst_tax = item.gst_tax;
            if (gst_tax?.tax_id) {
              transaction_tax_details.push({
                is_group: gst_tax?.is_group ? true : false,
                tax_id: gst_tax?.tax_id,
              });
            }
          });
          response.addon_details.forEach((eachAddon: any) => {
            eachAddon.items.forEach((item: any) => {
              let gst_tax = item.gst_tax;
              if (gst_tax?.tax_id) {
                transaction_tax_details.push({
                  is_group: gst_tax?.is_group ? true : false,
                  tax_id: gst_tax?.tax_id,
                });
              }
            })
          })
          setPlanTransactionTaxDetails(transaction_tax_details);
          await fetchGstTaxList(
            stateType,
            customerDetails.gst_registration_type,
            transaction_tax_details
          );
         
          setGstRegistrationType(customerDetails.gst_registration_type);
        }
        if (response.conversion_date) setConversionDate(getFormatedDate(new Date(response.conversion_date)));
        setSubscriptionStatus(response.subscription_status)
        setSubscriptionStopDate(response.scheduled_stop_date)
        setNextRenewableDate(response.next_renewal)
        setCustomerId(response.customer_id);
        setCustomerIdForEdit(response.customer_id);
        setCurrencyCode(response.currency_code);
        setCurrencyId(response?.currency_id || 0);
        setConversionCurrencyId(response.currency_id);
        setSavedTDSPreference(response.tds_preference)
        setSubscriptionFormValues({...otherValues });
        setSubscriptionValuesForEdit({...otherValues });
        setDefaultFormValues({ ...defaultValues });
        setNextInvoiceEdit({
          first_invoice_start_date: DateFormatHandler(response.next_renewal),
          first_invoice_end_date: DateFormatHandler(response.next_invoice_end_date),
          first_invoice_amount: response.next_invoice_amount,
          second_invoice_start_date:  '',
          second_invoice_end_date:  '',
          second_invoice_amount: 0})
        let addressData = response.address;
        addressData.map((values: any) => {
          if (values.is_billing) {
            setBillingAddressFormValues(values);
            setBillingAddressFormValuesForEdit(values);
            if (values.customer_address_id) {
              setCustomerBillingAddressId(values.customer_address_id);
            }
          }
          if (values.is_shipping) {
            setShippingAddressFormValues(values);
            setShippingAddressFormValuesForEdit(values);
            if (values.customer_address_id) {
              setCustomerShippingAddressId(values.customer_address_id);
            }
          }
        });
        let planItemDetails = response.plan_details;
        let tdsIdArray: number[] = [];
        planItemDetails.items.map((item: any) => {
          if (!item.taxes.length) {
            item.taxes = [initialTaxValues];
          }
          if (item.tds_id) {
            tdsIdArray.push(item.tds_id);
          }
        });
        if (planItemDetails.items.length) {
          const updatetedItems = planItemDetails.items.map((data: any) => {
            if (NON_PRICE_RANGE_PRICING_MODEL.includes(planItemDetails.pricing_model)){
              return ({...data, itemPricingId:  data.item_pricing.id || 0, price: data.item_pricing.price || 0, free_quantity: data.item_pricing.free_quantity || 0})
            }
             return  ({...data, range_price: data.item_pricing_range || []})
          })
          setPlanItemFormValues({id: planItemDetails.id, planId: planItemDetails.plan_id, planName: planItemDetails.plan_name, pricing_id:planItemDetails.plan_pricing_id, frequency: planItemDetails.frequency, pricing_model: planItemDetails.pricing_model, itemDetails: updatetedItems});
        } else {
          setPlanItemFormValues({ planId: 0,
            planName: '',
            frequency: '',
            pricing_model: '',
            pricing_id: 0,
            itemDetails: []});
        }
        if (response.tds_id) {
          tdsIdArray.push(response.tds_id);
          setTdsIds(tdsIdArray)
        } else setPlanTdsIds(tdsIdArray);
  
        let tcsIdArray: number[] = [];
        if (response.tcs_id) {
          tcsIdArray.push(response.tcs_id);
          setTcsIds(tcsIdArray)
        }
        setPlanDeleteId(response.plan_details.id)
        if (response.addon_details?.length){
        setAddonDeleteIds(response.addon_details.map((eachAddon: any) => eachAddon.id))
        let addonItemDetails = response.addon_details;
        let addonTdsIdArray: number[][] = [];
        addonItemDetails.forEach((eachAddon: any) => {
          let eachAddonTdsIdArray: number[] = [];
          eachAddon.items.forEach((item: any) => {
            if (!item.taxes.length) {
              item.taxes = [initialTaxValues];
            }
            if (item.tds_id) {
              eachAddonTdsIdArray.push(item.tds_id);
            }
          });
          if(eachAddonTdsIdArray.length > 0) addonTdsIdArray.push(eachAddonTdsIdArray)
        })
        setAddonTdsIds(addonTdsIdArray)
        if (addonItemDetails.length) {

            const updatedAddonData = addonItemDetails.map((eachAddon: any) => {
              const updatetedItems = eachAddon.items.map((data: any) => {
                if (NON_PRICE_RANGE_PRICING_MODEL.includes(eachAddon.pricing_model)){
                return ({...data, itemPricingId:  data.item_pricing.id || 0, price: data.item_pricing.price || 0, free_quantity: data.item_pricing.free_quantity || 0})
              }
               return  ({...data, range_price: data.item_pricing_range || []})
            })
              return ({...eachAddon,
                addonId: eachAddon.addon_id,
                addonName: eachAddon.addon_name,
                pricing_id: eachAddon.addon_pricing_id,
                itemDetails: updatetedItems})
            }
          )
          setAddonItemFormValues(updatedAddonData);
        } else {
          setAddonItemFormValues([]);
        }
        }
        
        if (response.coupon_details?.length && response.coupon_details.length > 0) {
          setCouponDeleteIds(response.coupon_details.map((eachCoupon: any) =>  eachCoupon.id))
          setCouponFormValues(response.coupon_details.map((eachCoupon: any) => ({
            id: eachCoupon.coupon_id,
            index: eachCoupon.id,
            name: eachCoupon.name,
            discount: eachCoupon.discount, 
            duration: eachCoupon.duration
          })))
        } else setCouponFormValues([])
        let totalDetails = response.total_details;
        setTotalFormValues(totalDetails);
        let emailContacts = response.subscription_emails;
        let contactEmail: string[] = [];
        let emailDetails: any[] = [];
        emailContacts.map((contact: any) => {
          contactEmail.push(contact.email);
          emailDetails.push(contact);
        });
        setEmailLists(contactEmail);
        setEmailData(emailDetails);
        setEditTotal(response.total_details.total || 0);
      } 
    }
  };
  const setEmailHandler = (email: string[]) => {
    setEmailLists(email);
  };

  const handleAddressFormValues = async (values: AddressValues) => {
    if (values.is_billing === true) {
      setBillingAddressFormValues(values);
    }
    if (values.is_shipping === true) {
      setShippingAddressFormValues(values);
    }
  };

  useEffect(() => {

    dispatch(setLoaderState(true));
    const fetchItems = async () => {
        if (currentUserInfo.organization_id) {
            await dispatch(
                itemDropdown({
                    currencyId: currentUserInfo.organization_currency,
                    conversionId: 0,
                    orgId: currentUserInfo.organization_id,
                    transaction_type: "SALES",
                })
            );
        }
        dispatch(setLoaderState(false));
    }
    fetchItems()
}, [currentUserInfo, dispatch]);

  useEffect(() => {
    customerData?.map((item: any) => {
      if (
        item.is_billing === true &&
        ((item.is_primary && selectedBillingAddressId === 0) ||
          item.id === selectedBillingAddressId) &&
        (item.country_id !== null ||
          item.address !== null ||
          item.city !== null ||
          item.state_id !== null ||
          item.zip_code !== null ||
          item.phone !== null)
      ) {
        if (editId && customerId === customerIdForEdit) {
          if (!isBillingAddressSame) setSelectedBillingAddressId(0);
          const billingAddressCopy = { ...billingAddressFormValuesForEdit };
          delete billingAddressCopy.id;
          delete billingAddressCopy.is_billing;
          delete billingAddressCopy.is_shipping;
          const isNullish = Object.values(billingAddressCopy).every((value) => {
            if (value === null) {
              return true;
            }
            return false;
          });
          if (!isNullish) {
            setBillingAddressFormValues(billingAddressFormValuesForEdit);
          } else {
            setSelectedBillingAddressId(item.id);
            setBillingAddressFormValues(item);
          }
        } else {
          setSelectedBillingAddressId(item.id);
          setBillingAddressFormValues(item);
        }
      }
      if (
        item.is_shipping === true &&
        ((item.is_primary && selectedShippingAddressId === 0) ||
          item.id === selectedShippingAddressId) &&
        (item.country_id !== null ||
          item.address !== null ||
          item.city !== null ||
          item.state_id !== null ||
          item.zip_code !== null ||
          item.phone !== null)
      ) {
        if (editId && customerId === customerIdForEdit) {
          if (!isShippingAddressSame) setSelectedShippingAddressId(0);
          const shippingAddressCopy = { ...shippingAddressFormValuesForEdit };
          delete shippingAddressCopy.id;
          delete shippingAddressCopy.is_billing;
          delete shippingAddressCopy.is_shipping;
          const isNullish = Object.values(shippingAddressCopy).every(
            (value) => {
              if (value === null) {
                return true;
              }
              return false;
            }
          );
          if (!isNullish) {
            setShippingAddressFormValues(shippingAddressFormValuesForEdit);
          } else {
            setSelectedShippingAddressId(item.id);
            setShippingAddressFormValues(item);
          }
        } else {
          setSelectedShippingAddressId(item.id);
          setShippingAddressFormValues(item);
        }
      }
    });
  }, [customerData]);

  /**
   * Function to set Primary billing address
   */
  const selectBillingAddressHandler = (index: number | undefined | string) => {
    if (index) {
      setSelectedBillingAddressId(index);
    }
  };
  /**
   * Function to set Primary shipping address
   */
  const selectShippingAddressHandler = (index: number | undefined | string) => {
    if (index) {
      setSelectedShippingAddressId(index);
    }
  };

  /**
   * Function to store emailIds to be deleted in Edit subscription
   */
  const removeEmailHandler = (email: string) => {
    emailData.map((contact) => {
      if (contact.email === email) {
        setDeleteEmailIds((prevItems) => [
          ...prevItems,
          { emailId: contact.id },
        ]);
      }
    });
  };

  /**
   * function to get taxable amount
   */
  const amountCalculation = async (data: {
    values: TotalAmountCalculationValues;
    orgId: number;
    signal: Object;
  }) => {
    const responseAction = await dispatch(getAmountCalculation(data));
    return responseAction;
  };
  /**
   * function to get taxable amount
   */
  const taxableAmount = async (data: {
    values: SubscriptionItemValues;
    orgId: number;
    signal: Object;
  }) => {
    const taxResponseAction = await dispatch(getTaxableAmount(data));
    return taxResponseAction;
  };

  /**
   * function to get upload files
   */
  const uploadAttachedFiles = async (file: any) => {
    let formData = new FormData();
    formData.append("file", file);
    let responseAction = await dispatch(
      attachSubscriptionFiles({
        file: formData,
        orgId: currentUserInfo.organization_id,
      })
    );
    return responseAction;
  };

  /**
   * Remove uploaded files
   */
  const removeFile = async (fileId: any) => {
    if (insertedFiles.includes(fileId)) {
      await dispatch(
        deleteSubscriptionFile({
          fileId,
          orgId: currentUserInfo.organization_id,
        })
      );
    }

    let defaultValues = defaultRef.current?.defaultData;
    let planItemValues: SubscriptionPlanDetails = itemRef.current?.itemPlanValues
      ? itemRef.current?.itemPlanValues
      : planItemFormValues;
    let totalTdsValues = itemRef.current?.totalTdsValues;
    let totalTcsValues = itemRef.current?.totalTcsValues;
    let subscriptionInputs: any = {
      ...subscriptionFormValues,
      ...defaultValues,
      ...totalTdsValues,
      ...totalTcsValues,
      tds_level: getTdsApplyLevel(),
      source_tax_type: getSourceTaxType(),
    };
    let errors = validate(
      subscriptionInputs,
      isSubscriptionNumberExist,
      emailLists,
      planItemValues,
      isGstOrg
    );
    if (Object.keys(errors).length) {
      setFormErrors(errors);
    } else {
      setFormErrors({});
    }
    return {}
  };

  useEffect(() => {
    let onProgressFiles = uploadedFiles.filter(
      (files: any) => files.progressInfo === 0 && files.status !== "removed"
    ).length;
    if (onProgressFiles) {
      setFormSubmit(false);
    } else {
      setFormSubmit(true);
    }
  }, [uploadedFiles]);

  const handleSubmitAction = (status: boolean) => {
    if (status) {
      setFormSubmitAction(false);
    } else {
      setFormSubmitAction(true);
    }
  };

  /**
   * set address ID handler
   */
  const setAddressIdHandler = (id: number | string) => {
    setAddressId(id);
  };

  const fetchConsumerDetail = (customerId: number) => {
    getCustomerDetails(customerId, "subscription");
  };

  const fetchGstTaxList = async (
    stateType: string,
    gstRegType?: string,
    transaction_tax_details?: [{ is_group: boolean; tax_id: number }]
  ) => {
    if (!transaction_tax_details && planTransactionTaxDetails) {
      transaction_tax_details = planTransactionTaxDetails;
    }
    if (
      gstRegType === gstRegistrationTypes.sez ||
      gstRegType === gstRegistrationTypes.sezDeveloper ||
      gstRegType === gstRegistrationTypes.overseas
    ) {
      stateType = "Inter State";
    }
    dispatch(clearGstTaxList());
    await dispatch(
      getGstTaxList({
        stateType: stateType,
        orgId: currentUserInfo.organization_id,
        is_sez: false,
        transaction_tax_details,
        transactionSource: "Invoices", // needs to be "Subscription"
      })
    );
  };

  const getTotalValues = (total: number | string) => {
    setTotalAmount(Number(total));
  };

  const updateCalculatedTotal = (val: any) => {
    setCalculatedTotal(val);
  };

  
  /**
   * Check subscription number already exist
   */
  const checkSubscriptionNumberExist = async (subscriptionNumber: string) => {
    setIsSubscriptionChecking(true)
    if (!subscriptionNumber.includes("undefined")) {
      if (interval) {
        clearTimeout(interval);
      }
      interval = setTimeout(async () => {
        const responseAction = await dispatch(
          checkSubscriptionNumber({
            subscriptionNumber: subscriptionNumber,
            orgId: currentUserInfo.organization_id,
            subscriptionId: editId ? Number(editId) : 0,
          })
        );
        if (responseAction.payload) {
          const response = responseAction.payload;
          if (Object.keys(response).length && !("error" in response)) {
            if (response.subscription_number_available === false) {
              setIsSubscriptionNumberExist(true);
              setFormErrors({
                ...formErrors,
                ["subscription_number"]:
                  "Subscription number already exists. Please choose a different one",
              });
            } else {
              setIsSubscriptionNumberExist(false);
              setFormErrors({ ...formErrors, ["subscription_number"]: "" });
            }
            setIsSubscriptionChecking(false)
          }
        }
      }, 1000);
    }
  };

  const getNextInvoiceAction = async (startDate: string, planId: number, pricing_id: number, total: number, invOption: string) => {
    const date = new Date(startDate)
    const selectedStartDate = date.setHours(0, 0, 0, 0);
    let initalNextInvoiceDate = null

    if (nextInvoiceEdit) {
      const [year, month, day] = nextInvoiceEdit.first_invoice_start_date.split('-').map(Number);
      const stopScheduledDate = new Date(`${month}-${day}-${year}`)
      initalNextInvoiceDate = stopScheduledDate.setHours(0, 0, 0, 0);
    }
    if (initalNextInvoiceDate && initalNextInvoiceDate < selectedStartDate && subscriptionStatus !== "CANCELLED") {
      setNextInvoiceDateDetails(nextInvoiceEdit)
    } else {
      const responseNextInvoiceAction = await dispatch(
    getNextInvoiceDate({
      invoicingoption: invOption ? invOption : '',
      pricing_id: pricing_id,
      plan_id: planId,
      start_date:  startDate ? startDate : '',
      total_amount: total,
      orgId: currentUserInfo.organization_id,
    }))
    if (responseNextInvoiceAction.payload) {
      setNextInvoiceDateDetails(responseNextInvoiceAction.payload)
    }
}
}
   return (
    <>
    <Card wrapperClass="subscription-form card-create-item ">
    <CardHeader isBackButtonEnabled={true}>
        <div className="form-subscription-header">
         {editId ? "Edit Subscription" : "Create Subscription"}
        <span className="subscription-header-org-span">
          <OrgTag organization={userOrganization?.find((org: any) => org.id === currentUserInfo.organization_id)?.name || ''} />
        </span> </div>
    </CardHeader>
    <CardBody>
      <Form
        name="add_invoice_form"
        id="add_invoice_form"
        className={`add-module-item-form ${
          defaultRef.current?.defaultData?.customer_id ? "" : " inactive" 
        }`}
        tabIndex={-1}
      >
          <div className="fields-wrapper pt-0">
            <div className="fields-wrappers">
              <SubscriptionBasicDetails
                defaultFormValues={defaultFormValues}
                subscriptionFormValues={subscriptionFormValues}
                handleCustomerChange={handleCustomerChange}
                checkSubscriptionNumberExist={checkSubscriptionNumberExist}
                formErrors={formErrors}
                organizationId={currentUserInfo.organization_id}
                handleNewConversionDate={handleNewConversionDate}
                ref={defaultRef}
                customFieldRef={{}}
                getCustomFieldData={() => {}}
                customFieldValue={[]}
                setStateType={setStateType}
                fetchGstTaxList={fetchGstTaxList}
                setSupplyState={setSupplyState}
                initialStateType={initialStateType}
                setIsStateChanged={setIsStateChanged}
                selectedInvoicingCycle={selectedInvoicingCycle}
                setInvoicingOption={setInvoicingOption}
                startDateChange={setSelectedStartDate}
              />
              {(isCustomerName || editId) && (
                <SubscriptionAddress
                  billingAddressFormValues={billingAddressFormValues}
                  shippingAddressFormValues={shippingAddressFormValues}
                  customerData={customerData}
                  createBillingAddress={(values) =>
                    handleAddressFormValues(values)
                  }
                  createShippingAddress={(values) =>
                    handleAddressFormValues(values)
                  }
                  selectBillingAddressHandler={selectBillingAddressHandler}
                  selectShippingAddressHandler={selectShippingAddressHandler}
                  selectedBillingAddressId={selectedBillingAddressId}
                  selectedShippingAddressId={selectedShippingAddressId}
                  openAddressModal={openAddressModal}
                  setAddressIdHandler={setAddressIdHandler}
                  customerId={customerId}
                  customerIdForEdit={customerIdForEdit}
                />
              )}
              <SubscriptionDetail
              formValues={subscriptionFormValues}
              itemFormValues={planItemFormValues}
              addonItemFormValues={addonItemFormValues}
              couponFormValues={couponFormValues}
              totalFormValues={totalFormValues}
              formErrors={formErrors}
              itemFormErrors={planItemFormErrors}
              addonItemFormError={addonItemFormErrors}
              currencyId={currencyId}
              currencyCode={currencyCode}
              amountCalculation={amountCalculation}
              taxableAmount={taxableAmount}
              isHsnCode={true}
              taxType="payable"
              lineItemBaseAccount="Incomes"
              discountBaseAccount="Expenses"
              conversionDate={conversionDate}
              newConversionDate={newConversionDate}
              conversionCurrencyId={conversionCurrencyId}
              userId={customerId}
              componentType={"invoice"}
              setErrorOnItemTaxChange={setErrorOnItemTaxChange}
              setAddonErrorOnItemTaxChange={setAddonErrorOnItemTaxChange}
              totalTdsValues={totalTdsValues}
              totalTcsValues={totalTcsValues}
              tdsIds={tdsIds}
              tcsIds={tcsIds}
              planTdsIds={planTdsIds}
              addonTdsIds={addonTdsIds}
              ref={itemRef}
              stateType={stateType}
              supplyState={supplyState}
              getTotalValue={getTotalValues}
              gstRegistrationType={gstRegistrationType}
              isRoundOffEnabled={isRoundOffEnabled}
              isTotalEditable={isTotalEditable}
              editTotal={editTotal}
              updateCalculatedTotal={updateCalculatedTotal}
              hideExchangeRate={false}
              customerDetails={defaultFormValues}
              isStateChanged={isStateChanged}
              isCustomerVendorChanged={isCustomerChanged}
              selectedInvoicingCycle={setSelectedInvoicingCycle}
              invoicingOption={invoicingOption}
              startDate={selectedStartDate}
              setNextInvoiceDateDetails={setNextInvoiceDateDetails}
              changedCustomerData={changedCustomerData}
              tdsPreferencetoCheck={savedTDSPreference}
              setCouponError={setCouponError}
              couponError={couponError}
              >
              <NoteTextArea
                  label="Customer Notes"
                  name="customer_notes"
                  id="customerNotes"
                  className="notes-textarea"
                  placeholder="Enter notes here..."
                  value={subscriptionFormValues.customer_notes || ''}
                  onChange={handleChange}
                  formErrors={formErrors}
                />
              </SubscriptionDetail>
              <section id="upload_section">
                <div className="upload-header pb-3">Attach Files</div>
                <DropZone
                  uploadedFiles={uploadedFiles}
                  uploadFileIds={fileIds}
                  uploadAttachedFiles={uploadAttachedFiles}
                  removeFile={removeFile}
                  handleSubmitAction={handleSubmitAction}
                  ref={fileRef}
                />
                <span className="error">{formErrors.attachFiles}</span>
              </section>
              <div className="email-reciepients-wrapper" >
              <Grid container sx={{maxWidth: '682px'}}>
                  <Grid item xs={2} sm={2} md={7} sx={{ alignSelf: 'flex-end' }}>
                  <label htmlFor="deposit_to">Email to</label>
                 </Grid>
               <Grid item columns={2} xs={10} sm={10} md={5} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <FormControlLabel
                 className="emailEnableAndDisable"
                 control={<Switch 
                  checked={isSendEmailToggleEnable} color="primary" disableRipple />}
                 label="Automatic Reminder Emails"
                 labelPlacement="start"
                 onClick={(event) => {
                  event.stopPropagation();
                  setIsSendEmailToggleEnable(!isSendEmailToggleEnable)
              }}
                />
               </Grid>
               </Grid>
                <MultiEmailBox
                  onChange={setEmailHandler}
                  emailList={emailLists}
                  removeEmailHandler={removeEmailHandler}
                />
                <span className="error">{formErrors.email}</span>
              </div>
              <SubscriptionOrganizationDetails />
             {nextInvoiceDateDetails && !editId ? <SubscriptionPreview previewDetails={nextInvoiceDateDetails} currencyId={currencyId}/> : null}
            </div>
            <SubscriptionFormFooter
              editId={editId}
              isLocked={false}
              lockDate={''}
              name="subscription"
              isFormSubmit={formSubmit && formSubmitAction}
              onClickSubmit={asynchronousSubmit}
              rolePermission={invoicesRolePermission}
              isPrint={true}
              isDisabledDraft={
                exchangeRateAPiStatus || defaultRef.current?.defaultData?.customer_id === "" || isSubscriptionChecking
                ? true
                : false
              }
              isDisabledSave={defaultRef.current?.defaultData?.customer_id === "" || isSubscriptionChecking ? true : false}
              onClickDelete={() => { }}
            />
          </div>
          </Form>
    </CardBody>
    </Card>
      {showAddressModal ? (
        <AddressModal
          closeAddressModal={closeAddressModal}
          addressId={addressId}
          createdFrom="subscription_page"
          fetchConsumerDetail={fetchConsumerDetail}
          consumerId={customerId}
          selectedBillingAddressId={selectedBillingAddressId}
          billingAddressFormValues={billingAddressFormValues}
          storeNewAddressIdHandler={() => { }}
        />
      ) : null}
        <SubscriptionUpdateAlertModal
        onCancel={() => {
          setDidAlertModalOpen(false);
        }}
        onSubmit={(updatedData) => {
          handleSubmit(buttonAction.status, buttonAction.action, updatedData);
        }}
        alertModalOpen={didAlertModalOpen}
        isSubscriptionStatusFuture={subscriptionStatus === "FUTURE"}
        nextRenewableDate={nextRenewableDate}
        isPlanUpdated={subscriptionValuesForEdit.plans.planId !== formDetailForNexInvoice.planId
          && subscriptionValuesForEdit.plans.pricing_id !== formDetailForNexInvoice.pricing_id}
        nextInvoiceDateDetails={nextInvoiceDateDetails}
        getNextInvoiceAction={(date, invoicingOption) => getNextInvoiceAction(date, formDetailForNexInvoice.planId, formDetailForNexInvoice.pricing_id, formDetailForNexInvoice.total, invoicingOption)}
        subscriptionStopDate={subscriptionStopDate}
        currencyCode={currencyCode}
        subscriptionStatus={subscriptionStatus}
      />
    </>
  );
}

export default React.memo(SubscriptionForm);