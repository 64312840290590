import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Popper from "@mui/material/Popper";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  searchLoaderStateSelector,
  setSearchResult,
} from "../../../common/commonSlice";
import "./SearchOptions.css";

const SearchOptions = (props: any) => {
  const popperRef = useRef<any>(null);
  const navigate = useNavigate();
  const dispatch: any = useAppDispatch();
  const isSearching = useAppSelector(searchLoaderStateSelector);
  const handleBackdropClick = (event: any) => {
    if (popperRef.current && !popperRef.current?.contains(event.target)) {
      props.setOpen(false);
    }
  };
  useEffect(() => {
    if (props.open) {
      document.addEventListener("click", handleBackdropClick);
    }
    return () => {
      document.removeEventListener("click", handleBackdropClick);
    };
  }, [props.open]);

  const handleClick = () => {
    props.handleClick();
    dispatch(setSearchResult(props.result));
    navigate("/search");
    props.setOpen(false);
  };

  return (
    <Popper
      {...props}
      placement="bottom-start"
      disablePortal
      onClick={(e) => {
        e.stopPropagation();
      }}
      ref={popperRef}
      className="search-popper"
      sx={{
        zIndex: 1316,
      }}
    >
      {props.children}

      <Box sx={{ p: 0, display: "flex", justifyContent: "start" }}>
        {props.total && Number(props.total) > 5 && !isSearching ? (
          <Button
            className="view-all-results-btn"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              handleClick();
            }}
          >
            View all {` ${props.total} `} results
          </Button>
        ) : (
          <></>
        )}
      </Box>
    </Popper>
  );
};

export default SearchOptions;
