import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../../../app/hooks";
import {
  ItemTaxValues,
  ObjectType,
  InvoiceDefaultValues,
} from "../../../types";
import GstTaxCode from "./GstTaxCode";
import "../styles/TaxCode.css";
import ItemCloseButton from "./button/ItemCloseButton";
import { Link } from "react-router-dom";

type Props = {
  item: ObjectType;
  itemIndex: number;
  gstTaxErrors: ObjectType | undefined;
  organizationId: number;
  gstOnChange: (e: any, itemExemption: any) => void;
  disabledFeature: ObjectType[];
  gst_tax: ObjectType | null;
  clearGstSelection: (index: number) => void;
  supplyState: string;
  isSez?: boolean;
  gstRegistrationType?: string;
  updateIsItemEdited?: (val: boolean) => void;
  customerDetails: any;
};
const GstItemTax = (props: Props) => {
  const dispatch = useAppDispatch();
  const [taxDisabled, setTaxDisabled] = useState<boolean>(false);
  return (
    <>
      <div>
        <div
          className={
            props.gstTaxErrors?.gst_tax ||
            props.gstTaxErrors?.gst_tax_item ||
            props.gstTaxErrors?.tax_exemption
              ? "d-flex tax-selector gst_tax_item error align-items-center w-151 mt-0"
              : "d-flex tax-selector gst_tax_item align-items-center w-151 mt-0"
          }
          key={"itemtax-" + props.itemIndex}
        >
          <GstTaxCode
            item={props.item}
            gst_tax={props.gst_tax}
            gstOnChange={props.gstOnChange}
            itemIndex={props.itemIndex}
            organizationId={props.organizationId}
            disabledFeature={props.disabledFeature}
            supplyState={props.supplyState}
            isSez={props.isSez}
            gstRegistrationType={props.gstRegistrationType}
            updateIsItemEdited={props.updateIsItemEdited}
            isTaxDisabled={taxDisabled}
            setTaxDisabled={setTaxDisabled}
            customerDetails={props.customerDetails}
          />
          {props.gst_tax && props.gst_tax?.tax_id ? (
            <Link
              to="#"
              className={`removeItem ${
                taxDisabled ? "tax-delete-is-disabled" : ""
              }`}
              onClick={() => props.clearGstSelection(props.itemIndex)}
            >
              <ItemCloseButton />
            </Link>
          ) : null}
        </div>
        <span
          key={"itemtaxlabel-" + props.itemIndex}
          className={
            props.gstTaxErrors
              ? "display-none tax-amount-label p-0"
              : "tax-amount-label p-0"
          }
          id={"tax-amount-label_" + props.itemIndex}
        ></span>
        <span className="p-0 error">{props.gstTaxErrors?.gst_tax?.gst}</span>
        <span className="error">{props.gstTaxErrors?.gst_tax_item}</span>
        <span className="error">{props.gstTaxErrors?.tax_exemption}</span>
      </div>
    </>
  );
};

export default GstItemTax;
