import React, {
  Dispatch,
  ForwardRefRenderFunction,
  SetStateAction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useAppSelector } from "../../../../../app/hooks";
import {
  CNDefaultRef,
  CNDefaultValues,
  CreditNoteValues,
  ObjectType,
} from "../../../../../types";
import CustomDatepicker from "../../../../utils/atoms/datepicker";
import { useParams } from "react-router-dom";
import { customerListSelector } from "../../../customerManagement/customerSlice";
import PrefixInput from "../../../../common/components/PrefixInput/PrefixInput";
import usePreservedLocation from "../../../../hooks/usePreservedLocation";
import { initialDefaultValues } from "./StateInitialization";
import {
  joinForPayload,
  splitTransactionNumber,
} from "../../../../../helpers/prefixHelper";
import { AddCustomerModal } from "../../../customerManagement/components/addCustomerModal/AddCustomerModal";
import CustomerSelect from "../../../../common/components/CustomerSelect";
import { FormInputField } from "../../../../common/components/formInputField/FormInputField";
import SubjectInput from "../../../../common/components/SubjectInput";
import { getFormatedDate } from "../../../../../helpers/helper";
import moment from "moment";
import { fiscalTearFilterForValidationSelector, setLoaderState } from "../../../../common/commonSlice";
import { getNewCreditNoteNumber } from "../../creditNoteSlice";
import useCommonData from "../../../../hooks/useCommon";
import { FormInputFieldsInRows } from "../../../../common/components/formInputFieldInRows/FormInputFieldInRows";
import { useModuleCustomFieldList } from "../../../../hooks/useModuleCustomFieldList";
import ReasonSelect from "./ReasonSelect";
import SupplyStates from "../../../../common/components/SupplyStates";
import useGst from "../../../../hooks/useGst";
import AlertModalPopup from "../../../../common/components/alertModalPopup/AlertModalPopup";
import { gstRegistrationTypes } from "../../../../constants/constants";

type Props = {
  defaultFormValues: CNDefaultValues;
  creditNoteFormValues: CreditNoteValues;
  formErrors: any;
  handleCustomerChange: (e: any) => void;
  checkCreditNoteNumberExist: (creditnoteNumber: string) => void;
  handleNewConversionDate: (value: string) => void;
  customFieldValue: ObjectType;
  customFieldRef: any;
  getCustomFieldData: (data: ObjectType) => void;
  setStateType?: any;
  fetchGstTaxList: (
    stateType: string,
    gst_registration_type: string | undefined
  ) => Promise<void>;
  setSupplyState?: any;
  initialStateType: string;
  gstRegistrationType?: string;
  setIsStateChanged: Dispatch<SetStateAction<boolean>>;
};

const DefaultDetails: ForwardRefRenderFunction<CNDefaultRef, Props> = (
  props,
  ref
) => {
  type LocationProps = {
    consumerId?: string;
  };
  const location = usePreservedLocation();
  const { dispatch, currentUserInfo } = useCommonData();
  const { customerId, editId } = useParams();
  const locationState = location?.state as LocationProps;
  const consumerId = customerId; //locationState?.consumerId;
  const customerList = useAppSelector(customerListSelector);
  let [defaultFormValues, setDefaultFormValues] =
    useState<CNDefaultValues>(initialDefaultValues);
  const [creditNoteDate, setCreditNoteDate] = useState(new Date());
  const [creditNoteNumberObj, setCreditNoteNumberObj] = useState<ObjectType>(
    {}
  );
  const [
    placeOfSupplyUpdateModalTriggered,
    setPlaceOfSupplyUpdateModalTriggered,
  ] = useState<number>();
  const creditNotesCustomFields = useModuleCustomFieldList("Credit Notes");
  const { isGstOrg } = useGst();
  const placeOfSupplyUpdateModalRef = useRef<any>();
  const fiscalYearFilter = useAppSelector(fiscalTearFilterForValidationSelector);

  useImperativeHandle(
    ref,
    () => ({
      defaultData: defaultFormValues,
      creditNoteNumber: joinForPayload(creditNoteNumberObj),
    }),
    [defaultFormValues, creditNoteNumberObj]
  );

  useEffect(() => {
    if (!editId) creditNoteNumber();
  }, []);

  useEffect(() => {
    setDefaultFormValues({ ...defaultFormValues, ...props.defaultFormValues });
    if (editId) {
      const transaction_obj = splitTransactionNumber(
        props.defaultFormValues.credit_note_number
      );
      const CN_obj = {
        credit_note_prefix: transaction_obj["prefix"],
        credit_note_number: transaction_obj["number"],
      };
      setCreditNoteNumberObj(CN_obj);
    }
  }, [props.defaultFormValues]);

  useEffect(() => {
    if (consumerId) {
      let customer = customerList
        .filter((customer) => customer.id === Number(consumerId))
        .map((customer) => ({
          label: customer.customer_display_name,
          value: customer.id,
        }))[0];
      if (customer) {
        props.handleCustomerChange(customer);
        setDefaultFormValues((values) => {
          return {
            ...values,
            customer_id: consumerId,
            customer_name: customer?.label,
          };
        });
      }
    }
  }, [consumerId, customerList]);

  useEffect(() => {
    if (!editId) {
      handleDate(creditNoteDate, "creditNote");
    }
  }, [creditNoteDate]);
  /**
   * For checking if the CN number exist on prefix change
   */
  useEffect(() => {
    props.checkCreditNoteNumberExist(joinForPayload(creditNoteNumberObj));
  }, [creditNoteNumberObj["credit_note_prefix"]]);

  useEffect(() => {
    placeOfSupplyUpdateModalTriggered &&
      placeOfSupplyUpdateModalRef.current?.openAlertModal &&
      placeOfSupplyUpdateModalRef.current?.openAlertModal();
  }, [placeOfSupplyUpdateModalTriggered]);

  /**
   * Get credit note number
   */
  const creditNoteNumber = async () => {
    const responseAction = await dispatch(
      getNewCreditNoteNumber(currentUserInfo.organization_id)
    );
    if (responseAction.payload) {
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        setCreditNoteNumberObj(response);
        let CN_number = joinForPayload(response);
        setDefaultFormValues((values) => ({
          ...values,
          credit_note_number: CN_number ? CN_number : "",
        }));
      }
    }
    dispatch(setLoaderState(false));
  };
  /**
   * Set credit note default form values
   */
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "credit_note_number") {
      props.checkCreditNoteNumberExist(
        creditNoteNumberObj["credit_note_prefix"] +
          "~" +
          value.replace("~", "").toUpperCase()
      );
      setDefaultFormValues({
        ...defaultFormValues,
        [name]:
          creditNoteNumberObj["credit_note_prefix"] +
          "~" +
          value.replace("~", "").toUpperCase(),
      });
      setCreditNoteNumberObj({
        ...creditNoteNumberObj,
        [name]: value.replace("~", "").toUpperCase(),
      });
    } else if (name === "subject" && value.length < 250) {
      setDefaultFormValues({ ...defaultFormValues, [name]: value });
    } else if (name === "reference_number") {
      if (value.length < 17) {
        setDefaultFormValues({ ...defaultFormValues, [name]: value });
      }
    } else {
      setDefaultFormValues({ ...defaultFormValues, [name]: value });
    }
  };

  const handleCustomerChange = (e: {
    label: string;
    value: string | number;
  }) => {
    setDefaultFormValues((values) => ({
      ...values,
      customer_id: e.value.toString(),
      customer_name: e.label.trim(),
    }));
    props.handleCustomerChange(e);
  };

  /**
   * To get prefix from PrefixInput component.
   */
  const getPrefix = (value: string) => {
    setCreditNoteNumberObj({
      ...creditNoteNumberObj,
      ["credit_note_prefix"]: value.toUpperCase(),
    });
  };
  const handleDate = (date: Date | null, type: string) => {
    if (date) {
      if (date?.toString() === "Invalid Date") {
        if (type === "creditNote") {
          setDefaultFormValues((values) => ({
            ...values,
            credit_note_date: "Invalid date",
          }));
        }
      // } else if (new Date(fiscalYearFilter.startDate).setHours(5, 30).toLocaleString() > new Date(date).setHours(5, 30).toLocaleString() && type !== "dueDate") {
      //   setDefaultFormValues((values) => ({
      //     ...values,
      //     credit_note_date: "Old date",
      //   }));
      } else if (new Date(date) > new Date() && type !== "dueDate") {
        setDefaultFormValues((values) => ({
          ...values,
          credit_note_date: "Future date",
        }));
      } else {
        const dateFormated = getFormatedDate(date);
        if (moment(dateFormated, "YYYY-MM-DD", true).isValid()) {
          if (type === "creditNote") {
            setDefaultFormValues((values) => ({
              ...values,
              credit_note_date: dateFormated,
            }));
            props.handleNewConversionDate(dateFormated);
          }
        } else {
          setDefaultFormValues((values) => ({
            ...values,
            credit_note_date: "Invalid date",
          }));
        }
      }
    }
  };
  const handleReasonChange = (e: { label: string; value: string }) => {
    setDefaultFormValues((values) => ({
      ...values,
      reason: e.value.toString(),
    }));
  };

  const onStateChanged = async (
    name: string,
    option: { label: string; value: string }
  ) => {
    let newDefaultFormValues = { ...defaultFormValues, [name]: option.value };
    setDefaultFormValues(newDefaultFormValues);
    props.setIsStateChanged(true);
    props.setSupplyState(option.value);
    if (
      newDefaultFormValues?.place_of_supply &&
      currentUserInfo.organization_gst_state
    ) {
      let stateType = "";
      if (
        newDefaultFormValues?.place_of_supply ===
        currentUserInfo.organization_gst_state
      ) {
        if (props.initialStateType === "Inter State") {
          setPlaceOfSupplyUpdateModalTriggered(
            Math.floor(Math.random() * 1000)
          );
        }
        stateType = "Intra State";
        props.setStateType("Intra State");
      } else {
        if (props.initialStateType === "Intra State") {
          setPlaceOfSupplyUpdateModalTriggered(
            Math.floor(Math.random() * 1000)
          );
        }
        stateType = "Inter State";
        props.setStateType("Inter State");
      }
      props.fetchGstTaxList(stateType, props?.gstRegistrationType);
    } else {
      props.setStateType("");
    }
  };

  return (
    <section className="form-top-section" id="form-default-section">
      <div className="row custom-row">
        <div className="col-12 col-lg-4 mw-325">
          <div className="link-label-grouped">
            <label htmlFor="customer" className="required">
              Customer Name
            </label>
            <AddCustomerModal />
          </div>
          <div>
            <CustomerSelect
              customerId={defaultFormValues.customer_id}
              errors={props.formErrors.customer_name}
              onChange={handleCustomerChange}
            />
            <span className="error">{props.formErrors.customer_name}</span>
          </div>
        </div>
        <div className="col-12 col-lg-4 mw-325">
          <label htmlFor="credit_note_number" className="required">
            Credit Note Number
          </label>
          <br />
          <PrefixInput
            prefix={defaultFormValues.credit_note_number.split("~")[0]}
            name={"credit_note_number"}
            id={"credit_note_number"}
            error={
              props.formErrors.credit_note_number
                ? props.formErrors.credit_note_number
                : ""
            }
            className="form-control border-end-0"
            placeholder="Enter credit note number"
            value={defaultFormValues.credit_note_number.split("~")[1]}
            disabled={false}
            maxLength={13}
            getPrefix={getPrefix}
            onChange={handleChange}
          />
          <span className="error">{props.formErrors.credit_note_number}</span>
        </div>
      </div>
      <div className="row custom-row">
        <div className="col-12 col-lg-4 mw-325">
          <label htmlFor="cn_date" className="required">
            Credit Note Date
          </label>
          <br />
          <div className="date-selector-wrapper">
            <CustomDatepicker
              date={
                editId
                  ? new Date(defaultFormValues.credit_note_date)
                  : creditNoteDate
              }
              handleDate={handleDate}
              id="credit_note_date"
              type="creditNote"
              error={props.formErrors.credit_note_date ? "error" : ""}
              zIndex={0}
              module="CreditNote"
            />
          </div>
          <span className="error">{props.formErrors.credit_note_date}</span>
        </div>
        <FormInputField
          type="text"
          name="reference_number"
          id="ref_no"
          className={
            props.formErrors.reference_number
              ? "form-control error"
              : "form-control"
          }
          label="Reference Number"
          placeholder="Enter reference number"
          maxLength={16}
          value={defaultFormValues.reference_number}
          onChange={handleChange}
          error={props.formErrors.reference_number}
        />
      </div>
      <div>
        <FormInputFieldsInRows
          getFieldValues={props.getCustomFieldData}
          customFieldsArray={creditNotesCustomFields}
          initialValues={props.customFieldValue}
          ref={props.customFieldRef}
          editId={editId ? Number(editId) : null}
        />
      </div>
      <div className="row custom-row">
        <SubjectInput
          isDisabled={defaultFormValues.customer_id ? false : true}
          infoTitle={
            "You can enter up to 250 characters. This subject will be displayed in the credit note pdf."
          }
          subject={defaultFormValues.subject}
          onChange={handleChange}
        />
        <div className="col-12 col-lg-4 mw-325">
          <ReasonSelect
            reason={defaultFormValues.reason}
            onChange={handleReasonChange}
            error={props.formErrors.reason}
          />
        </div>
      </div>
      {isGstOrg &&
      props.gstRegistrationType !== gstRegistrationTypes.overseas ? (
        <div className="row custom-row">
          <SupplyStates
            name="place_of_supply"
            id="place_of_supply"
            label="Place of Supply"
            value={
              defaultFormValues?.place_of_supply
                ? {
                    value: defaultFormValues?.place_of_supply,
                    label: defaultFormValues?.place_of_supply,
                  }
                : ""
            }
            onChange={(option: any) => {
              onStateChanged("place_of_supply", option);
            }}
            error={props.formErrors.place_of_supply}
          />
        </div>
      ) : null}
      <AlertModalPopup
        message="We will update the taxes applied to the items in this transaction as you've changed the place of supply"
        modalTitle="Alert!"
        isCloseButtonEnabled={true}
        isSubmitEnabled={false}
        openRef={placeOfSupplyUpdateModalRef}
      />
    </section>
  );
};

export default React.memo(forwardRef(DefaultDetails));
