import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
  currentUserSelector,
  orgCurrencyListSelector,
  setLoaderState,
} from "../../../../common/commonSlice";
import { vendorDetails } from "../../vendorSlice";
import VendorDetailHeader from "./VendorDetailHeader";
import ConsumerStatement from "../../../../common/components/detailsPage/ConsumerStatement";
import "./VendorDetail.css";
import { vendorDetailsValues } from "../../../../../types";
import ConsumerOverView from "../../../../common/components/detailsPage/overView/ConsumerOverView";
import VendorTransactions from "./transactions/VendorTransactions";
import { IsFeatureEnabled } from "../../../../../helpers/featureFlagHelper";
import { addressReset } from "../../../customerManagement/components/customerForm/AddressDetails/addressSlice";
import { setErrorID } from "../../../../../appSlice";
import { noAccessErrorCodes } from "../../../../constants/constants";
import OutstandingAmount from "../../../../common/components/detailsPage/overView/OutstandingAmount";
import { NumberFormat } from "../../../../../helpers/numberFormatHelper";
import usePreservedLocation from "../../../../hooks/usePreservedLocation";
import { AccountsImg, SalesDefault } from "../../../../../assets/images";

function VendorDetail() {
  type LocationProps = {
    haveTransaction: boolean;
    openingBalance: string;
  };
  const location = usePreservedLocation();
  const dispatch = useAppDispatch();
  const { editId } = useParams();
  const locationState = location?.state as LocationProps;
  const existTransaction = locationState?.haveTransaction
    ? locationState.haveTransaction
    : false;
  const currentUserInfo = useAppSelector(currentUserSelector);
  const orgCurrencyList = useAppSelector(orgCurrencyListSelector);
  const detailsInitialValues = {
    id: "",
    company_name: "",
    email: "",
    phone: "",
    display_name: "",
    short_code: "",
    address_details: [],
    contact_persons: [],
    is_mutable: true,
    other_details: {
      currency_id: "",
      currency_code: "",
      gst_registration_type: "",
      gstin: "",
      opening_balance: "",
      pan_number: "",
      payment_term_id: "",
      payment_term: "",
      tags: [],
    },
    purchase_default: {
      purchase_account_name: "",
      payment_term: "",
      discount: 0,
      discount_type: "",
      discount_account_name: "",
    },
    organization_id: 0,
    organization_name: "",
    balance_amount: 0,
    unused_amount: 0,
  };

  const [consumerData, setConsumerData] =
    useState<vendorDetailsValues>(detailsInitialValues);
  const [subModulePermission, setSubModulePermission] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState("overview");

  let subModules: any[] = [];
  useEffect(() => {
    const organizationRole = JSON.parse(
      sessionStorage.getItem("organizationRole") || "{}"
    );
    subModules = organizationRole.length
      ? organizationRole.map((permission: any) => {
          return permission.subModule;
        })
      : [];
    let unique = Array.from(new Set(subModules));
    setSubModulePermission(unique);
  }, [
    currentUserInfo.organization_id,
    sessionStorage.getItem("organizationRole"),
  ]);

  useEffect(() => {
    if (editId) {
      getVendorDetails(Number(editId));
    }
  }, [editId, currentUserInfo.organization_id]);
  useEffect(() => {
    const storedTab = sessionStorage.getItem("activeVendorTab");
    if (storedTab) {
      setActiveTab(storedTab);
    }
  }, []);
  useEffect(() => {
    dispatch(addressReset());
  }, []);
  /**
   * Setting active tab in session storage
   */
  function handleTabClick(tab: string) {
    setActiveTab(tab);
    sessionStorage.setItem("activeVendorTab", tab);
  }
  /**
   * Fetch vendor details by id
   */
  const getVendorDetails = async (editId: number) => {
    dispatch(setLoaderState(true));
    const responseAction = await dispatch(
      vendorDetails({
        id: editId,
        orgId: currentUserInfo.organization_id,
      })
    );
    if (responseAction.payload) {
      setTimeout(function () {
        dispatch(setLoaderState(false));
      }, 500);
      const response = responseAction.payload;
      if (Object.keys(response).length && !("error" in response)) {
        let vendorInfo = response;
        vendorInfo["is_mutable"] = existTransaction ? false : true;
        vendorInfo["display_name"] = response.vendor_display_name;
        vendorInfo["email"] = response.vendor_email;
        vendorInfo["phone"] = response.vendor_phone;
        vendorInfo["balance_amount"] = response.payables;
        vendorInfo["unused_amount"] = response.unused_credits;
        setConsumerData(vendorInfo);
      } else {
        dispatch(setErrorID(noAccessErrorCodes.vendorDetail));
        // navigate("/vendors");
      }
    }
  };

  const discount_suffix =
    consumerData?.purchase_default?.discount_type === "Absolute"
      ? consumerData.other_details.currency_code
      : "%";

  return (
    <div className="">
      <div
        className="card card-dashboard customer-detail-card vendor-detail-card w-100"
        id="customer-detail-card"
      >
        <VendorDetailHeader consumerDetails={consumerData} />
        <div className="tab-container vendor-detail-tab-container w-100 px-0">
          <ul
            className="nav nav-pills pills-tab mt-0"
            id="pills-tab"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <button
                className={
                  activeTab === "overview" ? "nav-link active" : "nav-link"
                }
                id="pills-overview-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-overview"
                type="button"
                role="tab"
                aria-controls="pills-overview"
                aria-selected="true"
                onClick={() => handleTabClick("overview")}
              >
                Overview
              </button>
            </li>
            {subModulePermission.includes("Statement of Accounts") &&
            IsFeatureEnabled(
              "Statement of Accounts",
              currentUserInfo.disabled_feature
            ) ? (
              <li className="nav-item" role="presentation">
                <button
                  className={
                    activeTab === "statement" ? "nav-link active" : "nav-link"
                  }
                  id="pills-statement-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-statement"
                  type="button"
                  role="tab"
                  aria-controls="pills-statement"
                  aria-selected="false"
                  onClick={() => handleTabClick("statement")}
                >
                  Statement
                </button>
              </li>
            ) : null}
            {(subModulePermission.includes("Bills") &&
              IsFeatureEnabled("Bills", currentUserInfo.disabled_feature)) ||
            (subModulePermission.includes("Debit Notes") &&
              IsFeatureEnabled(
                "Debit Notes",
                currentUserInfo.disabled_feature
              )) ||
            (subModulePermission.includes("Payments Made") &&
              IsFeatureEnabled(
                "Payments Made",
                currentUserInfo.disabled_feature
              )) ? (
              <li className="nav-item" role="presentation">
                <button
                  className={
                    activeTab === "transaction" ? "nav-link active" : "nav-link"
                  }
                  id="pills-transaction-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-transaction"
                  type="button"
                  role="tab"
                  aria-controls="pills-transaction"
                  aria-selected="false"
                  onClick={() => handleTabClick("transaction")}
                >
                  Transactions
                </button>
              </li>
            ) : null}
          </ul>
          <div className="tab-content pb-0" id="pills-tabContent">
            <div
              className={
                activeTab === "overview"
                  ? "tab-pane fade show active"
                  : "tab-pane fade"
              }
              id="pills-overview"
              role="tabpanel"
              aria-labelledby="pills-overview-tab"
              tabIndex={0}
            >
              <div className="tab-content-overview outstanding-data">
                <OutstandingAmount
                  title="Payables & Credits"
                  data={[
                    {
                      label: "Payables",
                      value: consumerData.balance_amount
                        ? consumerData.other_details.currency_code +
                          " " +
                          NumberFormat(
                            Number(consumerData.balance_amount),
                            consumerData.other_details.currency_code,
                            orgCurrencyList
                          )
                        : consumerData.other_details.currency_code + " " + 0,
                      className: "outstanding-red",
                    },
                    {
                      label: "Unused Credits",
                      value: consumerData.unused_amount
                        ? consumerData.other_details.currency_code +
                          " " +
                          NumberFormat(
                            Number(consumerData.unused_amount),
                            consumerData.other_details.currency_code,
                            orgCurrencyList
                          )
                        : consumerData.other_details.currency_code + " " + 0,
                      className: "outstanding-green",
                    },
                  ]}
                  imgSrc={AccountsImg}
                />
              </div>
              <div className="tab-content-overview purchase-defaults">
                <OutstandingAmount
                  title="Purchase Default"
                  data={[
                    {
                      label: "Purchase Account",
                      value:
                        consumerData.purchase_default.purchase_account_name,
                      className: "",
                    },
                    {
                      label: "Payment Term",
                      value: consumerData.purchase_default.payment_term,
                      className: "",
                    },
                    {
                      label: "Discount (" + discount_suffix + ")",
                      value: consumerData.purchase_default.discount,
                      className: "",
                    },
                    {
                      label: "Discount Account",
                      value:
                        consumerData.purchase_default.discount_account_name,
                      className: "",
                    },
                  ]}
                  imgSrc={SalesDefault}
                />
              </div>
              <div className="tab-content-overview">
                <ConsumerOverView
                  consumerDetails={consumerData}
                  fetchConsumerDetail={getVendorDetails}
                  consumerType={"vendor"}
                />
              </div>
            </div>
            {subModulePermission.includes("Statement of Accounts") ? (
              <div
                className={
                  activeTab === "statement"
                    ? "tab-pane fade show active"
                    : "tab-pane fade"
                }
                id="pills-statement"
                role="tabpanel"
                aria-labelledby="pills-statement-tab"
                tabIndex={0}
              >
                <ConsumerStatement
                  consumerDetails={consumerData}
                  consumerType="vendor"
                />
              </div>
            ) : null}
            {(subModulePermission.includes("Bills") &&
              IsFeatureEnabled("Bills", currentUserInfo.disabled_feature)) ||
            (subModulePermission.includes("Debit Notes") &&
              IsFeatureEnabled(
                "Debit Notes",
                currentUserInfo.disabled_feature
              )) ||
            (subModulePermission.includes("Payments Made") &&
              IsFeatureEnabled(
                "Payments Made",
                currentUserInfo.disabled_feature
              )) ? (
              <div
                className={
                  activeTab === "transaction"
                    ? "tab-pane fade show active"
                    : "tab-pane fade"
                }
                id="pills-transaction"
                role="tabpanel"
                aria-labelledby="pills-transaction-tab"
                tabIndex={0}
              >
                <VendorTransactions
                  isSameOrg={
                    consumerData.organization_id ===
                    currentUserInfo.organization_id
                  }
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default VendorDetail;
