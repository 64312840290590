import CenterModal from "../../../../common/components/centerModal/CenterModal";
import CreateItem from "../createItemForm/CreateItem";
import { IsFeatureEnabled } from "../../../../../helpers/featureFlagHelper";
import { useAppSelector } from "../../../../../app/hooks";
import { currentUserSelector } from "../../../../common/commonSlice";
import { ModalContext } from "../../../../common/components/centerModal/centerModalContext";
import { AddNewSquarePlusIcon } from "../../../../common/components/customSvgIcons/addNewSquarePlusIcon";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";

export const CreateItemModal = (props: any) => {
  const currentUserInfo = useAppSelector(currentUserSelector);
  const { itemsRolePermission } = usePermissionHandler();
  const ConfigureButton = () => {
    return (
      <>
        {itemsRolePermission.includes("Create") &&
        IsFeatureEnabled(
          "Item",
          currentUserInfo.disabled_feature
            ? currentUserInfo.disabled_feature
            : []
        ) ? (
          <span>
            <AddNewSquarePlusIcon />
            Add Item
          </span>
        ) : null}
      </>
    );
  };
  return (
    <CenterModal
      modalTitle="Add Item"
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onCloseModal } = context;
            return (
              <CreateItem
                handleModalClose={onCloseModal}
                fetchItemDropdown={props.fetchItemDropdown}
                buttonWrapperClass="form-footer-wrapper-modal"
                bodyWrapperClass="addItem-form-body-wrapper-scroll-in-modal"
                isModal={true}
              />
            );
          }}
        </ModalContext.Consumer>
      }
      buttonContent={<ConfigureButton />}
      wrapperClass="add-customer-modal large-form-modal"
      buttonClass="add-customer-link"
    />
  );
};
