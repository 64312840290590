import { SideModal } from "../sideModal/SideModal";
import { PaymentTerms } from "./paymentTerms";
import SettingsIcon from "../../../../assets/images/settings-icon-blue-bg.svg";

export const PaymentTermsModal = () => {
  const ConfigureButton = () => {
    return (
      <span>
        <img src={SettingsIcon} />
        Configure Payment Terms
      </span>
    );
  };
  return (
    <SideModal
      modalTitle="Configure Payment Terms"
      modalContent={<PaymentTerms />}
      buttonContent={<ConfigureButton />}
      wrapperClass="configure-payment-term-modal w-100"
      buttonClass="payment-term-modal-btn"
    />
  );
};
