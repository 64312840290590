import CenterModal from "../../../../common/components/centerModal/CenterModal";
import "./cancelChangesModal.css";
import { useAppSelector } from "../../../../../app/hooks";
import { currentUserSelector } from "../../../../common/commonSlice";
import { ModalContext } from "../../../../common/components/centerModal/centerModalContext";
import CancelDetailsModalContent from "./CancelDetailsModalContent";
import { cancelScheduler } from "../../subscriptionSlice";
import { useAppDispatch } from "../../../../../app/hooks";
import { toast } from "react-toastify";

export const CancelChangesModal = (props: any) => {
  const currentUserInfo = useAppSelector(currentUserSelector);
  const dispatch = useAppDispatch();

  const cancelModalSubmitHandler = async () => {

    if(props.type === 'subscriptionCancel') {
      const cancelSchedulerResponseAction = await dispatch(
        cancelScheduler({
          schedulerId: props.schedulerId,
          orgId: currentUserInfo.organization_id,
        })
      );
      if (cancelSchedulerResponseAction.payload) {
        const cancelSchedulerResponse = cancelSchedulerResponseAction.payload;
        if (
          Object.keys(cancelSchedulerResponse).length &&
          !("error" in cancelSchedulerResponse)
        ) {
          props.subscriptionSchedulerDetails();
          $("#scheduler-cancel-btn").click();
          toast.success("Scheduler canceled successfully!", {
            toastId: "role-delete-success",
            closeButton: false,
            position: "top-center",
          });
        } else if ("error" in cancelSchedulerResponse) {
          $("#scheduler-cancel-btn").click();
        }
      }
    } else {
      const cancelSchedulerResponseAction = await dispatch(
        cancelScheduler({
          schedulerId: props.schedulerId,
          orgId: currentUserInfo.organization_id,
        })
      );
      if (cancelSchedulerResponseAction.payload) {
        const cancelSchedulerResponse = cancelSchedulerResponseAction.payload;
        if (
          Object.keys(cancelSchedulerResponse).length &&
          !("error" in cancelSchedulerResponse)
        ) {
          props.subscriptionSchedulerDetails();
          $("#scheduler-cancel-btn").click();
          toast.success("Scheduler canceled successfully!", {
            toastId: "role-delete-success",
            closeButton: false,
            position: "top-center",
          });
        } else if ("error" in cancelSchedulerResponse) {
          $("#scheduler-cancel-btn").click();
        }
      }
    }
  };


  const ConfigureButton = () => {
    return (
      <>
      {props.type === 'subscriptionCancel' ? <span className="subscription-scheduler-cancel">Cancel changes</span> : <span className="subscription-scheduler-cancel">Remove Scheduled {props.subscriptionStatus === 'STOPPED' ? 'Resume' : 'Stop'}</span>}
      </>
    );
  };
  return (
    <CenterModal
      modalTitle="Cancel scheduled changes"
      modalContent={
        <ModalContext.Consumer>
          {(context) => {
            const { onCloseModal } = context;
            return <CancelDetailsModalContent onCloseModal={onCloseModal} cancelModalSubmitHandler={cancelModalSubmitHandler}/>;
          }}
        </ModalContext.Consumer>
      }
      buttonContent={props.subscriptionStatus !== 'STOPPED' ? <ConfigureButton />: ''}
      wrapperClass="show-impact-details-modal large-form-modal view-impact-details-modal cancel-impact-details-modal"
      buttonClass="add-customer-link"
      isCloseButtonEnabled={true}
    />
  );
};
