import React from "react";
import "./NoteTextArea.css";

type Props = {
  label: string;
  name: string;
  id: string;
  className: string;
  placeholder: string;
  value: string;
  onChange: (e: any) => void;
  formErrors: any;
  isDisabled?: boolean;
  labelClassName?: string;
};
export default function NoteTextArea(props: Props) {
  return (
    <div className="col customer-notes">
      <label htmlFor="notes" className={props.labelClassName? props.labelClassName : ''}>{props.label}</label>

      <textarea
        name={props.name}
        id={props.id}
        className={props.className}
        placeholder={props.placeholder}
        onChange={props.onChange}
        value={props.value}
        maxLength={300}
        disabled={props.isDisabled}
      ></textarea>
      <span className="error">{props.formErrors.notes}</span>
    </div>
  );
}
