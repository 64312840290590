import { useFormik } from "formik";
import Dialog from '@mui/material/Dialog';
import Grid from "@mui/material/Grid";
import SubscriptionUpdateModal from "./SubscriptionUpdateModal";
import { Card } from "../../../../../common/components/htmlTags/Card/Card";
import { CardHeader } from "../../../../../common/components/htmlTags/Card/CardHeader";
import CloseButton from "../../../../../common/components/button/CloseButton";
import { CardBody } from "../../../../../common/components/htmlTags/Card/CardBody";
import { Form } from "../../../../../common/components/htmlTags/Form";
import './SubscriptionUpdateModal.css'
import { getFormatedDate } from "../../../../../../helpers/helper";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { getNextDate } from "../../../../../../helpers/subscriptionHelper";
import { ObjectType } from "../../../../../../types";
import { validatUpdateForm } from "../ValidateSubscriptionForm";
import { NextInvoiceDateType } from "../../../subscriptionType";
type RemovalAlertProps = {
  onCancel: () => void;
  onSubmit: (updatedData: any) => void;
  alertModalOpen: boolean;
  isSubscriptionStatusFuture: boolean;
  nextRenewableDate: string;
  isPlanUpdated: boolean;
  subscriptionStopDate: string;
  nextInvoiceDateDetails: NextInvoiceDateType | null
  getNextInvoiceAction: (date: string, invoicingOption: string) => void
  currencyCode: string
  subscriptionStatus: string;
};

export const SubscriptionUpdateAlertModal = (
  props: RemovalAlertProps
) => {
  const [updateFormErrors, setUpdateFormErrors] = useState<ObjectType>({})
  const subsFormik = useFormik({
    initialValues: {
      update_type: "IMMEDIATELY",
      updation_date: getFormatedDate(new Date()),
      invoicing_option: "IMMEDIATELY",
      is_proration_enabled: true
    } as {
      update_type: string;
      updation_date: string;
      invoicing_option: string;
      is_proration_enabled: boolean;
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      let errors = validatUpdateForm(values, props.subscriptionStopDate)
      if (Object.keys(errors).length) {
        setUpdateFormErrors(errors)
      } else {
      setUpdateFormErrors({})
      props.onSubmit(values)
      props.onCancel()
      subsFormik.resetForm()
      }
    },
  });

  useEffect(() => {
    if(subsFormik.values.update_type) {
      setUpdateFormErrors({}) 
    }
  }, [subsFormik.values.update_type])

  return (
    <Dialog
      open={props.alertModalOpen}
      className={`subscription-update-popover ${props.isSubscriptionStatusFuture ? 'update-future-popover' : ''}`}
      onClose={() => {
        subsFormik.resetForm()
        props.onCancel()
      }
      }
    >
      <Card wrapperClass="subscription-update">
        <CardHeader wrapperClass={`subscription-update-header-wrapper`}>
          <Grid container>
            <Grid item xs={10} className="p-t-15 header">
              {props.isSubscriptionStatusFuture ? 'Are you sure you want update this subscription': "Update Subscription"}
            </Grid>
            <Grid item xs={2} className="popover-close-button">
              <CloseButton closeModalHandler={() => {
                subsFormik.resetForm()
                props.onCancel()
              }} />
            </Grid>
          </Grid>
        </CardHeader>
        <CardBody>
           <Form
            name="add_invoice_form"
            id="add_invoice_form"
            className=""
            tabIndex={-1}
          >
            {props.isSubscriptionStatusFuture ? <div className="row custom-row">
            <div className="p-r-l-20">All the updates will be reflected immediately  
            </div>
            </div> : <SubscriptionUpdateModal 
              heading={'Update Subscription!'}
              subHeading={`Are you sure you want to  the subscription`}
              modalCancelHandler={() => props.onCancel()}
              formik={subsFormik}
              isPlanUpdated={props.isPlanUpdated}
              nextRenewableDate={props.nextRenewableDate}
              updateFormErrors={updateFormErrors}
              nextInvoiceDateDetails={props.nextInvoiceDateDetails}
              getNextInvoiceAction={props.getNextInvoiceAction}
              currencyCode={props.currencyCode}
              subscriptionStatus={props.subscriptionStatus}
            /> }
            <div className="row mb-0">
          <div className="col">
          <div
            className={`form-button-wrapper w-100 ${props.isSubscriptionStatusFuture ? 'd-flex justify-content-end mt-3' : ''} 
            ${props.subscriptionStatus === "CANCELLED" ? 'form-button-wrapper-cancelled': ''}`}
            id="form-btn"
          >
            <button onClick={(e: any) => {
              e.preventDefault();
              subsFormik.submitForm()
            }} className="save-button">
              {props.isSubscriptionStatusFuture ? 'Yes Proceed' : 'Update'}
            </button>
            <button
              type="button"
              onClick={() => {
                subsFormik.resetForm()
                props.onCancel()
              }}
              className="text-decoration-none cancel-button"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
          </Form>
        </CardBody>
      </Card>
    </Dialog>
  )
};