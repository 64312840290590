import {
  AddressValues,
  CreditNoteAllocation,
  CreditNoteValues,
  TaxableAmountCalculationValues,
  TotalAmountCalculationValues,
} from "../../../types";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createNewCreditNote,
  getCreditNoteNumber,
  deleteCreditNoteItemTax,
  sendCreditNote,
  calculateTaxableAmount,
  calculateAmount,
  creditNotePdfLink,
  deleteCreditNoteItem,
  getCreditNoteList,
  creditNoteNumberExist,
  creditNoteAttachments,
  creditNoteAttachmentsDelete,
  deleteCreditNoteRecipient,
  getCreditNoteDetails,
  deleteCreditnote,
  editCreditNote,
  allocationDetails,
  invoicesToAllocate,
  updateAllocation,
  allocationCalculation,
  deleteAllocation,
  transactionLimitStatus,
  reason,
  getReportingTagJournals,
  putReportingTags,
  creditNoteVoidMarker,
} from "./creditNoteAPI";
import { RootState } from "../../../app/store";

export const creditNoteList = createAsyncThunk(
  "credit-note/List",
  async (data: {
    page: number;
    itemsPerPage: number;
    dateSortOrder: string;
    orgId: number;
    customerList: number[];
    orgIds: number[];
    statusList: string[];
    invoiceId?: number | null;
    startDate?: string;
    endDate?: string;
    startDueDate?: string;
    endDueDate?: string;
  }) => {
    return await getCreditNoteList(data);
  }
);

export const deleteCreditNoteDetails = createAsyncThunk(
  "delete/credit-note",
  async (data: { creditNoteId: number; orgId: number }) => {
    return await deleteCreditnote(data);
  }
);

export const createCreditNote = createAsyncThunk(
  "create/credit-note",
  async (data: { values: CreditNoteValues; status: string; orgId: number }) => {
    return await createNewCreditNote(data);
  }
);

export const editCreditNoteDetails = createAsyncThunk(
  "edit/credit-note",
  async (data: {
    id: number;
    values: CreditNoteValues;
    status: string;
    orgId: number;
  }) => {
    return await editCreditNote(data);
  }
);

export const deleteCreditNoteItemDetails = createAsyncThunk(
  "delete/credit-note/item",
  async (data: { creditNoteId: number; itemId: number; orgId: number }) => {
    return await deleteCreditNoteItem(data);
  }
);

export const deleteCreditNoteItemTaxDetails = createAsyncThunk(
  "delete/credit-note/item/tax",
  async (data: { itemId: number; taxId: number; orgId: number }) => {
    return await deleteCreditNoteItemTax(data);
  }
);

export const deleteCreditNoteRecipientDetails = createAsyncThunk(
  "credit-note/recipient/delete",
  async (data: { creditNoteId: number; emailIds: number[]; orgId: number }) => {
    return await deleteCreditNoteRecipient(data);
  }
);

export const creditNoteDetails = createAsyncThunk(
  "creditNoteDetail",
  async (data: { creditNoteId: number; orgId: number }) => {
    return await getCreditNoteDetails(data);
  }
);

export const getNewCreditNoteNumber = createAsyncThunk(
  "credit-note/number",
  async (orgId: number) => {
    return await getCreditNoteNumber(orgId);
  }
);

export const checkCreditNoteNumber = createAsyncThunk(
  "credit-note/number-exist",
  async (data: {
    creditNoteNumber: string;
    orgId: number;
    creditNoteId: number;
  }) => {
    return await creditNoteNumberExist(data);
  }
);

export const sendCreditNoteData = createAsyncThunk(
  "send-credit-note",
  async (data: { creditNoteId: number; emails: string[]; orgId: number }) => {
    return await sendCreditNote(data);
  }
);

export const getTaxableAmount = createAsyncThunk(
  "taxableamount",
  async (data: {
    values: TaxableAmountCalculationValues;
    orgId: number;
    signal: Object;
  }) => {
    return await calculateTaxableAmount(data);
  }
);

export const getAmountCalculation = createAsyncThunk(
  "amount-calculation",
  async (data: {
    values: TotalAmountCalculationValues;
    orgId: number;
    signal: Object;
  }) => {
    return await calculateAmount(data);
  }
);

export const getCreditNotePdfLink = createAsyncThunk(
  "credit-note/pdf",
  async (data: { creditNoteId: number; orgId: number }) => {
    return await creditNotePdfLink(data);
  }
);

export const attachFiles = createAsyncThunk(
  "credit-note/files/upload",
  async (data: { file: FormData; orgId: number }) => {
    return await creditNoteAttachments(data);
  }
);

export const deleteCreditNoteFile = createAsyncThunk(
  "credit-note/file/delete",
  async (data: { creditNoteId: number; fileId: number; orgId: number }) => {
    return await creditNoteAttachmentsDelete(data);
  }
);

export const getAllocationDetails = createAsyncThunk(
  "credit-note/allocation",
  async (data: { creditNoteId: number; orgId: number }) => {
    return await allocationDetails(data);
  }
);

export const getInvoicesToAllocate = createAsyncThunk(
  "credit-note/allocation/invoice",
  async (data: { creditNoteId: number; orgId: number; invoiceId?: number }) => {
    return await invoicesToAllocate(data);
  }
);

export const updateCreditAllocation = createAsyncThunk(
  "credit-note/update/allocation",
  async (data: {
    creditNoteId: number;
    orgId: number;
    values: CreditNoteAllocation[];
  }) => {
    return await updateAllocation(data);
  }
);

export const creditAllocationCalculation = createAsyncThunk(
  "credit-note/allocation/calculation",
  async (data: {
    creditNoteId: number;
    orgId: number;
    values: number[];
    signal?: AbortSignal;
  }) => {
    return await allocationCalculation(data);
  }
);

export const deleteCreditAllocation = createAsyncThunk(
  "credit-note/delete/allocation",
  async (data: {
    creditNoteId: number;
    allocationId: number;
    orgId: number;
  }) => {
    return await deleteAllocation(data);
  }
);

export const getTransactionLimitStatus = createAsyncThunk(
  "credit-note/limit/status",
  async (orgId: number) => {
    return await transactionLimitStatus(orgId);
  }
);

export const getReason = createAsyncThunk(
  "credit-note/reason",
  async (orgId: number) => {
    return await reason(orgId);
  }
);

export const markCreditNoteVoid = createAsyncThunk(
  "credit-note/mark-void",
  async (data: { creditNoteId: number | null; orgId: number }) => {
    return await creditNoteVoidMarker(data);
  }
);

const initialState = {
  listParams: {
    page: 1,
    itemsPerPage: 10,
    startCount: 0,
    endCount: 0,
    pageCount: 1,
    itemOffset: 0,
    dateSortOrder: "",
  },
};

export const creditNoteSlice = createSlice({
  name: "creditNoteManagement",
  initialState,
  reducers: {
    setListParams: (state, action) => {
      state.listParams = { ...state.listParams, ...action.payload };
    },
  },
});

export const generateJournal = createAsyncThunk(
  "reporting-tag/journal-generate",
  async (data: { creditNoteId: number; orgId: number }) => {
    return await getReportingTagJournals(data);
  }
);

export const updateReportingTags = createAsyncThunk(
  "reporting-tag/update",
  async (data: { creditNoteId: number; orgId: number; payload: any }) => {
    return await putReportingTags(data);
  }
);

export const { setListParams } = creditNoteSlice.actions;
export default creditNoteSlice.reducer;

export const listParamsSelector = (state: RootState) =>
  state.creditNoteManagement.listParams;
