import {
  useMemo,
} from "react";
import { Box, Grid } from "@mui/material";
import MUICard from "@mui/material/Card";
import useCommonData from "../../../../hooks/useCommon";
import { usePermissionHandler } from "../../../../hooks/usePermissionHandler";
import useListData from "../../../../hooks/useListData";
import { usePaginationDispatch } from "../../../../hooks/usePaginationDispatch";
import PaginationContainer from "../../../items/components/list/PaginationContainer";
import TableInfo from "../../../../common/components/table/TableInfo";
import TableContainer from "../../../../common/components/table/TableContainer";
import EmptyReport from "../../../../common/components/EmptyReport";
import { listboxCustomStyle } from "../../../../common/components/ListBoxCustomStyle";
import ListActions from "./ListActions";
import {
  CalendarSiteWideBillingList,
  CalenderSideWideBillingListDetails,
} from "../../calendarBillingTypes";
import React from "react";
import { capitalizeDay, formatDate, getOrdinalSuffix, toTitleCase } from "../../helpers/calendarInvoicingHelper";

type Props = {
  siteWideBillingList: CalendarSiteWideBillingList;
  refreshList: () => void;
  deleteListData: (actionType: string, id: number, addonName: string) => void
}

function SideWideBillingList(props: Props) {
  const { currentUserInfo } = useCommonData();
  const { page, itemsPerPage, startCount, endCount, totalList } = useListData();
  const { setItemsPerPage, setPage } = usePaginationDispatch();

  /**
   * set table column headings
   */
  const columns = useMemo(
    () => [
      {
        Header: "Frequency",
        accessor: "show.frequency",
        sort: false,
        sortOrder: "",
        className: "billing-frequency",
      },
      {
        Header: "Calendar Invoicing type",
        accessor: "show.billingType",
        sort: false,
        sortOrder: "",
        className: "calendar-billing-type",
      },
      {
        Header: "Invoicing Date/Day",
        accessor: "show.invoiveDate",
        sort: false,
        sortOrder: "",
        className: "invoicing-date-day",
      },
      {
        Header: "",
        accessor: "show.actions",
        sort: false,
        sortOrder: "",
        className: "action",
        Cell: ({ cell: { value } }: any) => {
          return (
            <ListActions
              value={value}
              getSiteWideBilling={props.refreshList}
              onClickDelete={() => {
                props.deleteListData(
                  "Site-Wide Invoicing",
                  value.id,
                  value.invoicing_frequency
                );
              }}
            />
          );
        },
      },
    ],
    [currentUserInfo.organization_id, page, itemsPerPage]
  );
  /**
   * set table row data
   */
  const data: any =
    props.siteWideBillingList &&
    props.siteWideBillingList.calendar_invoicing.length > 0
      ? props.siteWideBillingList.calendar_invoicing.map(
          (data: CalenderSideWideBillingListDetails) => ({
            show: {
              frequency: toTitleCase(data.invoicing_frequency),
              billingType: toTitleCase(data.invoicing_type) === "Set date"? "Custom": toTitleCase(data.invoicing_type),
              invoiveDate:
                data.invoicing_frequency === "WEEKLY"
                  ? capitalizeDay(data.set_day)
                  : data.invoicing_frequency === "MONTHLY"
                  ? `${getOrdinalSuffix(data.set_date)} of every month`
                  : formatDate(data.set_date),
              actions: data,
            },
          })
        )
      : [];

  return (
    <>
      <MUICard variant="outlined" sx={listboxCustomStyle.cardBox}>
        <Box
          component="div"
          id="invoice-table-wrapper"
          className={
            "list-table-wrapper dataTables_wrapper dt-bootstrap5 no-footer " +
            `${totalList > 0 ? "" : "no-data"}`
          }
        >
          <TableInfo
            total={totalList}
            startCount={startCount}
            endCount={endCount}
            itemsPerPage={itemsPerPage}
            setPageInfo={(countPerPage, page) => {
              setItemsPerPage(countPerPage);
              setPage(page);
            }}
          />
          <Grid>
            <TableContainer
              className="list-table table table-striped w-100"
              columns={columns}
              data={data}
              url={"#"}
              sortHandler={() => {}}
              editPermission={false}
              isDetailViewExist={false}
            >
              <EmptyReport
                heading="No site wide invoicing found!"
                alt="no site wide invoicing"
              />
            </TableContainer>
          </Grid>
        </Box>
      </MUICard>
      {/* {totalList > itemsPerPage ? <PaginationContainer /> : ""} */}
    </>
  );
}

export default React.memo(SideWideBillingList);
