import React, { useState } from "react";
import "./amountInWords.css";
import InfoTooltip from "../../../../common/components/InfoTooltip";

const AmountInWords = ({ formik }: any) => {
  const handleMainCheckboxChange = (event: any) => {
    const { checked } = event.target;
    formik.setValues({
      showAmountInWords: checked,
      invoice: checked,
      creditNotes: checked,
      paymentReceived: checked,
      bills: checked,
      debitNotes: checked,
      paymentMade: checked,
      journalVoucher: checked,
    });
  };

  const handleCheckboxChange = (field: any, checked: any) => {
    formik.setFieldValue(field, checked);

    if (!checked) {
      formik.setFieldValue("showAmountInWords", false);
    } else {
      const allChecked =
        formik.values.invoice &&
        formik.values.creditNotes &&
        formik.values.paymentReceived &&
        formik.values.bills &&
        formik.values.debitNotes &&
        formik.values.paymentMade &&
        formik.values.journalVoucher;

      if (allChecked) {
        formik.setFieldValue("showAmountInWords", true);
      }
    }
  };

  return (
    <div className="amount-in-words">
      <h3>Amount in words <InfoTooltip title='Amount in words will be displayed in the respective detail screens, PDFs and Email'/></h3>
      <label>
        <input
          type="checkbox"
          name="showAmountInWords"
          checked={formik.values.showAmountInWords}
          onChange={handleMainCheckboxChange}
          className="margin-right-10"
        />
        Show amount in words for all transactions
      </label>
      <div className="sub-options">
        <>
          <label>
            <input
              type="checkbox"
              name="invoice"
              checked={formik.values.invoice}
              onChange={(e) =>
                handleCheckboxChange("invoice", e.target.checked)
              }
            />
            Invoice
          </label>
          <label>
            <input
              type="checkbox"
              name="creditNotes"
              checked={formik.values.creditNotes}
              onChange={(e) =>
                handleCheckboxChange("creditNotes", e.target.checked)
              }
            />
            Credit Notes
          </label>
          <label>
            <input
              type="checkbox"
              name="paymentReceived"
              checked={formik.values.paymentReceived}
              onChange={(e) =>
                handleCheckboxChange("paymentReceived", e.target.checked)
              }
            />
            Payment Received
          </label>
          <label>
            <input
              type="checkbox"
              name="bills"
              checked={formik.values.bills}
              onChange={(e) => handleCheckboxChange("bills", e.target.checked)}
            />
            Bills
          </label>
          <label>
            <input
              type="checkbox"
              name="debitNotes"
              checked={formik.values.debitNotes}
              onChange={(e) =>
                handleCheckboxChange("debitNotes", e.target.checked)
              }
            />
            Debit Notes
          </label>
          <label>
            <input
              type="checkbox"
              name="paymentMade"
              checked={formik.values.paymentMade}
              onChange={(e) =>
                handleCheckboxChange("paymentMade", e.target.checked)
              }
            />
            Payment Made
          </label>
          <label>
            <input
              type="checkbox"
              name="journalVoucher"
              checked={formik.values.journalVoucher}
              onChange={(e) =>
                handleCheckboxChange("journalVoucher", e.target.checked)
              }
            />
            Journal Voucher
          </label>
        </>
      </div>
    </div>
  );
};

export default AmountInWords;
