import React, { useState } from "react";
import { ErrorImg } from "../assets/images";
import { Id, toast } from "react-toastify";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import { CopyButton } from "../features/common/components/CopyButton";

export const ErrorToaster = (data: string, code?: number) => {
  const ToastContent = () => {
    const [isCopied, setCopied] = useState(false);
    const handleButtonClick = () => {
      const lastWord = data.split(" ").pop();
      navigator.clipboard.writeText(lastWord ? lastWord : "");
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    };
    return (
      <div>
        {code ? (
          <div>
            <span>{data + "  "}</span>
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              title={isCopied ? "Copied ERROR_ID!" : "Copy ERROR ID"}
            >
              <CopyButton onClick={handleButtonClick} color="red" />
            </Tooltip>
          </div>
        ) : (
          <span>{data + "  "}</span>
        )}
      </div>
    );
  };
  toast.error(<ToastContent />, {
    icon: ({ theme, type }) => (
      <img src={ErrorImg} width="20px" height="18px" alt="errorImage" />
    ),
    toastId: code,
    closeButton: code ? true : false,
    className: "toast-error toast-custom-style",
    position: "top-center",
    autoClose: code ? false : 3000,
    closeOnClick: false,
  });
};

export const SuccessToaster = (message: string, toastId: Id | undefined) => {
  return toast.success(message, {
    toastId: toastId,
    closeButton: false,
    position: "top-center",
  });
};

export const WarningToaster = (message: string, toastId: Id | undefined) => {
  return toast.warning(message, {
    toastId: toastId,
    closeButton: false,
    position: "top-center",
  });
};

export const InfoToaster = (message: string, toastId: Id | undefined) => {
  return toast.info(message, {
    toastId: toastId,
    closeButton: false,
    className: "toast-info",
    position: "top-center",
  });
};
