  import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
  import {
    getPlanOverviewDetails,
    updateCouponStatus,
    deleteCoupon,
    checkCouponName,
    createNewCoupon,
    getCouponList,
    getCouponForEdit,
    editCouponApi,
    deletePricing,
    getPlanItemList,
    createPlanPrice,
    getEditPriceDetails,
    editPlanPrice,
    checkPriceInternalName,
    couponTransactionLimitStatus
  } from "./couponAPI";
  import { 
    PlanDefaultValues,
    TaxableAmountCalculationValues,
    TotalAmountCalculationValues,
    PlanPriceDefaultValue
  } from "../../../types";
import { CouponDefaultValue, CouponformValue } from "./couponType";

  export const checkCouponNameExist = createAsyncThunk(
    "couponName",
    async (data: {
      name: string;
      orgId: number;
      coupon_id: number;
    }) => {
      return await checkCouponName(data);
    }
  );

  export const createCoupon = createAsyncThunk(
    "coupon/create",
    async (data: {
      values: CouponformValue;
      orgId: number;
    }) => {
      return await createNewCoupon(data);
    }
  );
  
  export const couponList = createAsyncThunk(
    "plansList",
    async (data: {
      page: number;
      itemsPerPage: number;
      orgId: number;
      orgIds: number[];
      statusList: string[];
    }) => {
      return await getCouponList(data);
    }
  );

  export const couponDetailsForEdit = createAsyncThunk(
    "couponEditView",
    async (data: {
      couponId: number | string;
      orgId: number
    }) => {
      return await getCouponForEdit(data);
    }
  );


 
  export const editCoupon = createAsyncThunk(
    "coupon/edit",
    async (data: {
      values: CouponformValue;
      orgId: number;
      couponId: string | undefined;
    }) => {
      return await editCouponApi(data);
    }
  );
  
  export const planDetails = createAsyncThunk(
    "planDetail",
    async (data: { planId: number; orgId: number }) => {
      return await getPlanOverviewDetails(data);
    }
  );
  
  export const changeCouponStatus = createAsyncThunk(
    "coupon/update-status",
    async (data: { id: number; orgId: number; status: string }) => {
      return await updateCouponStatus(data);
    }
  );

  export const deleteCouponDetails = createAsyncThunk(
    "coupon/delete",
    async (data: { deletePlanId: number; orgId: number }) => {
      return await deleteCoupon(data);
    }
  );
  

  const initialState = {};
  
  export const invoiceSlice = createSlice({
    name: "PlanManagement",
    initialState,
    reducers: {},
  });

  export const getCouponTransactionLimitStatus = createAsyncThunk(
    "coupon/limit/status",
    async (orgId: number) => {
      return await couponTransactionLimitStatus(orgId);
    }
  );
  export default invoiceSlice.reducer;
  